import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";
import {MyTextInput} from "../../CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function AdditionalInformation({ additionalInformation }) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Additional Information">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>
                            Expand
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={additionalInformation}
                        onSubmit={(values) =>{
                            console.log(values)
                        }}
                    >
                        <Form>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Patent Name"
                                        name="patentName"
                                        type="text"
                                        value={additionalInformation.patentName}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Insurance Company"
                                        name="insuranceCompany"
                                        type="text"
                                        value={additionalInformation.insuranceCompany}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Liquor License Number"
                                        name="liquorLicenseNumber"
                                        type="text"
                                        value={additionalInformation.liquorLicenseNumber}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Entitled Person"
                                        name="entitledPerson"
                                        type="text"
                                        value={additionalInformation.entitledPerson}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {additionalInformation.mortgageTerm !== '' ? 
                                        <div>
                                            <MyTextInput
                                                label="Are you the property owner?"
                                                name="isPropertyOwner"
                                                type="text"
                                                value="Yes"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Date of Purchase"
                                                name="dateOfPurchase"
                                                type="text"
                                                value={additionalInformation.dateOfPurchase}
                                                disabled
                                            />
                                        </div>
                                        :
                                        <MyTextInput
                                            label="Are you the property owner?"
                                            name="isPropertyOwner"
                                            type="text"
                                            value="No"
                                            disabled
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    {additionalInformation.mortgageTerm !== '' ? 
                                        <div>
                                            <MyTextInput
                                                label="Motgage Term (in months)"
                                                name="mortgageTerm"
                                                type="text"
                                                value={additionalInformation.mortgageTerm}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Financed By"
                                                name="financedBy                                        "
                                                type="text"
                                                value={additionalInformation.financedBy}
                                                disabled
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {additionalInformation.contractTerm !== '' ? 
                                        <div>
                                            <MyTextInput
                                                label="Are you in lease contract?"
                                                name="isInLeaseContract"
                                                type="text"
                                                value="Yes"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Monthly Rent"
                                                name="monthlyRent"
                                                type="text"
                                                value={additionalInformation.monthlyRent}
                                                placeholder="N/A"
                                                disabled
                                            />
                                        </div>
                                        :
                                        <MyTextInput
                                            label="Are you in lease contract?"
                                            name="isInLeaseContract"
                                            type="text"
                                            value="No"
                                            disabled
                                        />
                                    }
                                    
                                </Grid>
                                <Grid item xs={6}>
                                    {additionalInformation.contractTerm !== '' ? 
                                        <div>
                                            <MyTextInput
                                                label="Contract Term"
                                                name="contractTerm                                        "
                                                type="text"
                                                value={additionalInformation.contractTerm}
                                                disabled
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {additionalInformation.bankruptcyExplanation !== '' ? 
                                        <MyTextInput
                                            label="Have you or any businesses you have been associated with been involved in any bankrupcy?"
                                            name="isBeenInvolvedInBankruptcy"
                                            type="text"
                                            value="Yes"
                                            disabled
                                        />
                                        :
                                        <MyTextInput
                                            label="Have you or any businesses you have been associated with been involved in any bankrupcy?"
                                            name="isBeenInvolvedInBankruptcy"
                                            type="text"
                                            value="No"
                                            disabled
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {additionalInformation.bankruptcyExplanation !== '' ? 
                                        <MyTextInput
                                            label="Bankrupcy Explanation"
                                            name="bankruptcyExplanation"
                                            type="text"
                                            value={additionalInformation.bankruptcyExplanation}
                                            disabled
                                        />
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    )
}