import { GET_APPLICATIONS_BY_COMPANY_ID_LIST, GET_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION, GET_TOTAL_AUTHORIZED_CREDIT } from "../constants";


const initialState = {
    applicationList: [],
    applicationListPaginationData: {
        data: [],
        totalCount: 0,
        pageSize: 5
    },
    totalAuthorizedCredit: 0,
    RemainingAuthorizedCredit: 0
};

export default function (state = initialState, action) {
    let applicationList = [],
        applicationListPaginationData = {},
        totalAuthorizedCredit = 0;
    switch (action.type) {
        case GET_APPLICATIONS_BY_COMPANY_ID_LIST:
            applicationList = action.payload ? action.payload : state.applicationList;

            return Object.assign({}, state, {
                applicationList: applicationList,
            });
        case GET_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION:
            if (action.payload) {
                applicationListPaginationData = {
                    data: action.payload.data,
                    totalCount: action.payload.totalCount,
                    pageSize: 5
                }
            }
            return Object.assign({}, state, {
                applicationListPaginationData: applicationListPaginationData
            });
        case GET_TOTAL_AUTHORIZED_CREDIT:
            totalAuthorizedCredit = action.payload.filter(item => item.creditInformation)
                .map(item => item.creditInformation.authorizedCredit)
                .reduce((prevCredit, currCredit) => prevCredit + currCredit, 0);
            return Object.assign({}, state, {
                totalAuthorizedCredit: totalAuthorizedCredit
            });
        default:
            return state;
    }
}