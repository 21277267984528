import { ADD_COMPANY, GET_COMPANIES } from '../constants';
import { api } from './../index';

export const COMPANY_URL = `${process.env.REACT_APP_API_URL}/company`;
export const USER_COMPANY_GET_URL = `${COMPANY_URL}/user`;

export function getCompanies(userId) {
    return function (dispatch) {
        api.get(`/company/user/${userId}`)
            .then(response => {
                // console.log("Companies: ", response.data)
                dispatch({ type: GET_COMPANIES, payload: response.data });
            }).catch(error => {
                console.log(error);
            });
    }
}

export function postCompany(company) {
    return function (dispatch) {
        api.post("/company", company)
            .then(response => {
                dispatch({ type: ADD_COMPANY, payload: response.data });
            })
    }
}