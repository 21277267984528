import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../../../_metronic/layout";
// import {AuthPage} from "./modules/Auth"
import OrdersPage from "./Orders/OrdersPage";
import AccountSettingsPage from '../Buyer/AccountSettingsPage'
import {useSelector} from "react-redux";
import {SupplierDashboardPage} from "./Dashboard/SupplierDashboardPage"
import {ClientsPage} from "./Clients/ClientsPage";
import ApplicationsPage from "./Applications/ApplicationsPage";
import AnalyticsPage from './Analytics/AnalyticsPage'
import RoutesPage from "./Routes/RoutesPage";
import {SupplierInformation} from "../Admin/Suppliers/SupplierInformation";

const GoogleMaterialPage = lazy(() =>
    import("../../modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("../../modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("../../modules/ECommerce/pages/eCommercePage")
);

export default function SupplierBasePage(props) {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    const accountType = useSelector(state => state.auth.user.type)

    return (
            <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                    {props.pathName === 'orders' &&
                        <Redirect exact from="/" to="/orders"/>
                    }
                    {props.pathName === 'applications' &&
                        <Redirect exact from="/" to={`/applications/${props.pathId}`}/>
                    }
                    {
                        /* Redirect from root URL to /dashboard. */
                        <Redirect exact from="/" to="/routes"/>
                    }
                    <Route path="/dashboard" render={(props) => <SupplierDashboardPage accountType={accountType} {...props} />}/>
                    <ContentRoute path="/applications" component={ApplicationsPage}/>
                    <ContentRoute path="/orders" component={OrdersPage} pathId={props.pathId}/>
                    <ContentRoute path="/routes" component={RoutesPage}/>
                    <ContentRoute path="/myclients" component={ClientsPage}/>
                    <ContentRoute path="/analytics" component={AnalyticsPage}/>
                    <ContentRoute path="/accountsettings" component={AccountSettingsPage}/>
                    <ContentRoute path="/supplierAdmin/:id" component={SupplierInformation}/>
                    <Route path="/google-material" component={GoogleMaterialPage}/>
                    <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                    <Route path="/e-commerce" component={ECommercePage}/>
                    <Redirect to="error/error-v1"/>
                </Switch>
            </Suspense>
    );
}
