import React, {useState} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import axios from "axios";

import { ConfigTabs } from "../Config/ConfigTabs"

function SupplierConfig({supplierId, currCutOffTime, currIsCutOffSameDay }) {

    const [open,setOpen] = useState(false);
    const [listEdit, setListEdit] = useState({});
    const [clients, setClients] = useState([]);
    const [openInfo, setOpenInfo] = useState(false)
    const [routeId, setRouteId] = useState('');

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const [data,setData] = useState()

    return (
        <>
            <ConfigTabs supplierId={supplierId} currCutOffTime={currCutOffTime} currIsCutOffSameDay={currIsCutOffSameDay} /> :
                
        </>
    );
}

export default injectIntl(SupplierConfig);