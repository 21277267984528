import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {fetchCategories, fetchProducts, fetchSuppliers} from "../AdminPagesCrud";
import Link from "@material-ui/core/Link";
import {useParams} from "react-router-dom";
import SupplierSummarySteps from "./SupplierSummarySteps";
import EditOrAddSupplier from "./EditOrAddSupplier";

export function SupplierInformation({props}) {

    const initialValues = {
        entityName: "",
        pointOfContact: "",
        phoneNumber: "",
        postalAddress: "",
        postalCity: "",
        postalZipcode: "",
        physicalAddress: "",
        physicalCity: "",
        physicalZipcode: "",
        email: "",
        description: "",
        logoPath: "",
        active: "",
        isOfficial: ""
    };

    const inititalCategoryTree = {
        branches: 0,
        selectedCategories:[],
        categoryType:"",
        children:[]
    }

    const initialCategory = {
        supplierId: "",
        categories: [],
        type: ""
    }

    const {id} = useParams();
    // const [categoriesTree,setCategoriesTree] = useState(inititalCategoryTree)
    const [objectsArray,setObjectsArray] = useState([]);
    // const [defaultCategory,setDefaultCategory] = useState("")
    const [supplier, setSupplier] = useState(initialValues);
    const [products, setProducts] = useState([]);
    const [activeStep,setStep] = useState(0);
    const [isTrue, setIsTrue] = useState(false);
    const [isSupplierInfoFetched, setIsSupplierInfoFetched] = useState(false);
    const[edit, setEdit] = useState(false);
    const maxStep = 1

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    useEffect(() =>{
        fetchSuppliers(id,accessToken).then(response =>{
            setSupplier(response.data)
            setIsSupplierInfoFetched(true)
        });
    },[ edit ])

    useEffect(() =>{

        fetchProducts(id,accessToken).then(response => {
            setProducts(response.data)
        })

        fetchCategories(id,accessToken).then(response =>{
            setObjectsArray(response.data)
            setIsTrue(true)
        })
    },[  ])


    const renderActualComponent = (activeStep) => {
        switch(activeStep){
            case 0: return <SupplierSummarySteps supplierInformation={supplier} categories={objectsArray} products={products} edit={edit} setEdit={setEdit} />
                // <SupplierSummary supplierInformation={supplier} categories={objectsArray} products={products}/>
            case 1: return <EditOrAddSupplier supplierInformation={supplier}/>
            default: return <></>
        }
    }


    return (
        <>
            {isTrue && isSupplierInfoFetched ? renderActualComponent(activeStep) : null}
            {activeStep !== maxStep ? (
                <div className="row mt-3">
                    {/*<button className="btn btn-primary mr-1" onClick={()=>{*/}
                    {/*    if(activeStep < maxStep){*/}
                    {/*        setStep(activeStep + 1)*/}
                    {/*    }*/}
                    {/*}}>Submit</button>*/}
                    <Link className="btn btn-primary ml-4" href="/routes">Back</Link>
                </div>
            ): ""}
        </>
    );
}