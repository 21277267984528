import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {SupplierInformation} from "./Suppliers/SupplierInformation";
import SupplierAdminPage from "./Suppliers/SupplierAdminPage";
import {ContentRoute, LayoutSplashScreen} from "../../../_metronic/layout";
import {PriceListInformation} from "./PriceLists/PriceListInformation";

export default function SuppliersPage() {


    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <ContentRoute path="/supplierAdmin/:id" component={SupplierInformation}/>
                <ContentRoute path="/supplierAdmin/" component={SupplierAdminPage}/>
                <ContentRoute path="/supplierAdmin/priceList/:id" component={PriceListInformation}/>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
