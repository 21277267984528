
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { headerSortingClasses, sortCaret } from "../../../../_metronic/_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { PaginationTable } from "../../../components/PaginationTable/PaginationTable";
import { getCompaniesLocations } from '../CommonApplication/CommonApplicationCrud';
import AddCompanyPage from './AddCompanyPage';
import * as columnFormatters from "./column-formatters";
import CompanySummary from "./CompanySummary";
import { getLocationsByCompanyIdList } from './../../../../redux/actions/locations';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '90%',
//   },
//   paper: {
//     padding: theme.spacing(2, 2),
//     // height: 140,
//     // width: 300,
//   },
//   modalPaper: {
//     position: 'absolute',
//     // width: 400,
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     outline: 'none',
//   },
//   button: {
//     marginRight: theme.spacing(1),
//   },
//   backButton: {
//     marginRight: theme.spacing(1),
//   },
//   completed: {
//     display: 'inline-block',
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
// }));

// const newCompanyInitialValues = {
//   corporationName: "",
//   businessName: "",
//   deliveryAddress: "",
//   deliveryCity: "",
//   deliveryZipcode: "",
//   businessPhoneNumber: "",
//   businessFax: "",
//   businessEmail: "",
//   businessType: "",
//   employerIdentificationNumber: "",
//   numeroCatastro: "",
//   registroComerciante: "",
//   businessEstablishmentDate: "",
//   placeOfBusinessDescription: "",
// }

function MyCompaniesPage(props) {
  // const [open, setOpen] = React.useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false)
  const [isAddLocations, setIsAddLocations] = useState(false)
  const [addLocationsToComapnyId, setAddLocationsToCompanyId] = useState("")
  const [isCompanySummary, setIsCompanySummary] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState({})
  const [selectedCompanyLocations, setSelectedCompanyLocations] = useState([])
  const [openDiaogue, setOpenDiaogue] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const { user, accessToken } = useSelector(
    ({ auth }) => ({
      user: auth.user,
      accessToken: auth.authToken,
    })),
    { companies, companyIdList } = useSelector(state => state.companyReducer);

  const dispatch = useDispatch();

  function handleCloseDialogue() {
    setOpenDiaogue(false);
    setDialogMessage("")
  }

  function handleOpenDialogue(message){
    setDialogMessage(message)
    setOpenDiaogue(true);
  }

  const initialData = {
    data: [],
    totalCount: 0,
    pageSize: 3
  }
  const [data, setData] = useState(initialData)

  const fetchCompaniesLocations = async (queryParams) => {
    // console.log("Redux Companies: ", companies)
    await getCompaniesLocations(user.id, queryParams, accessToken).then(response => {
      console.log("Response: ", response.data)

      const data = {
        data: response.data.data,
        totalCount: response.data.totalCount,
        pageSize: response.data.pageSize
      }
      setData(data)
    }).catch((error) => {
      console.log(error)
    });

    // setData(data);
    // Authorization head should be fulfilled in interceptor.
    // const options = {
    //     headers: { 'x-auth-token': accessToken},
    //     validateStatus: status => status < 500,
    //     params: queryParams
    // }
    // axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + user.id + '/applications',options).then(response =>{
    //     setData(response.data)
    // });
  }

  async function fetchCompanyData() {
    dispatch(getLocationsByCompanyIdList(companyIdList))
  }

  useEffect(() => {
    fetchCompanyData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleAddCompanyClicked = () => {
    setIsAddCompany(isAddCompany => !isAddCompany)
  }

  const handleAddLocationsClick = (companyId) => {
    setAddLocationsToCompanyId(companyId)
    setIsAddCompany(isAddCompany => !isAddCompany)
    setIsAddLocations(isAddLocations => !isAddLocations)
  }

  const handleComapnyClicked = (company) => {
    setSelectedCompanyLocations(company.locations)
    setSelectedCompany(company)
    setIsCompanySummary(true)
  }

  const handleAddCompanyBack = () => {
    setIsAddCompany(false);
};

  let companyColumn = {
    dataField: "businessName",
    text: "Company",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: columnFormatters.CompanyFormatter,
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        handleComapnyClicked(row)
      }
    },
  }

  if(user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ){
      companyColumn.text = "Customer"
  }

  const columns = [
    // {
    //     dataField: "orderNumber",
    //     text: "Order Number",
    //     sort: true,
    //     sortCaret: sortCaret,
    //     headerSortingClasses,
    //     events: {
    //         onClick: (e, column, columnIndex, row, rowIndex) => {
    //             console.log("Pepe")
    //         }
    //     },
    //     // formatter: columnFormatters.OrderNumberFormatter,
    // },
    // {
    //     dataField: "companyName",
    //     text: "Company",
    //     sort: true,
    //     sortCaret: sortCaret,
    //     headerSortingClasses,
    // },
    companyColumn,
    {
      dataField: "deliveryCity",
      text: "City",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.CityFormatter,
    },
    {
      dataField: "createdDate",
      text: "Date Created",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: columnFormatters.DateFormatter,
    },
    {
      text: "Locations",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: columnFormatters.LocationsCountFormatter,
    },
    {
      // dataField: "status",
      text: "Actions",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: columnFormatters.ActionsColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log("Row: ", row)
          if(row.locations.length > 0 && user.tenant == process.env.REACT_APP_BIOWARE_TENANT){
            handleOpenDialogue("To add more than one location they must be added in Sage.")
          } else{
            handleAddLocationsClick(row._id)
          }
          
        }
      },
    },
  ];

  const sizePerPageList = [
    {text: "10", value: 10},
    {text: "20", value: 20},
    {text: "50", value: 50}
  ]

  const initialFilter = {
    filter: {
      businessName: "",
    },
    sortOrder: "asc",
    sortField: "businessName",
    pageNumber: 1,
    pageSize: 10
  }

  const sorting = [{ dataField: "businessName", order: "asc" }]
  const pagination = { custom: true, sizePerPageList: sizePerPageList }
  const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

  const prepareFilter = (queryParams, values) => {
    const { searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.businessName = searchText ?? "";

    newQueryParams.filter = filter;
    return newQueryParams;
  }

  return (
    <div>

      {isCompanySummary ? <CompanySummary businessInformation={selectedCompany} companyLocations={selectedCompanyLocations} setCompanyLocations={setSelectedCompanyLocations}
        accessToken={accessToken} userId={user.id} setIsCompanySummary={setIsCompanySummary} user={user}
      /> :
        isAddCompany ?
        <>
          <button type="button" className="btn btn-primary mb-6" onClick={() => handleAddCompanyBack()} >Back</button>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">Add 
                  { user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                      " Customer"
                  :
                      " Company"
                  }
                </span>
              </h3>
              <div className="card-toolbar">

              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className="card-body py-0">
              <AddCompanyPage user={user} userId={user.id} accessToken={accessToken} handleAddCompanyClose={handleAddCompanyClicked} isAddLocations={isAddLocations}
                setIsAddLocations={setIsAddLocations} addLocationsToComapnyId={addLocationsToComapnyId}/>
            </div>
          </div>
        </>
          


          :
          // <div className="card card-custom card-stretch gutter-b">
          //   <div className="card-header border-0 py-5">
          //     <h3 className="card-title align-items-start flex-column">
          //       <span className="card-label font-weight-bolder text-dark">Choose Company</span>
          //     </h3>
          //     <div className="card-toolbar">

          //       <div>
          //           <button
          //           type="button"
          //           onClick={handleAddCompanyClicked}
          //           className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          //           >
          //               <span>{"Add Company"}</span>
          //           </button>
          //       </div>
          //     </div>
          //   </div>
          //       <div className="card-body py-0">
          //         <div className="table-responsive">
          //           <table
          //             className="table table-head-custom table-head-bg table-borderless table-vertical-center"
          //             id="kt_advance_table_widget_1"
          //           >
          //             <thead>
          //               <tr className="text-left">
          //                 <th className="pr-0" style={{ minWidth: "150px" }}>
          //                   Company
          //                 </th>
          //                 <th style={{ minWidth: "150px" }}>City</th>
          //                 <th style={{ minWidth: "150px" }}>Date Created</th>
          //                 <th style={{ minWidth: "150px" }}>Locations</th>
          //                 <th className="text-center" style={{ minWidth: "150px" }}>Actions</th>
          //               </tr>
          //             </thead>
          //           <tbody>
          //             {companies.map(company => (
          //                 <tr key={company._id}>
          //                   <td className="pl-0 py-2">
          //                     <a
          //                         onClick={() => handleComapnyClicked(company)}
          //                         className="text-dark-75 font-weight-bolder d-block font-size-lg"
          //                     >
          //                         {company.businessName}
          //                     </a>
          //                     <span className="text-muted font-weight-bold">
          //                         {company.deliveryAddress}
          //                     </span>
          //                   </td>
          //                   <td>
          //                     <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          //                           {company.deliveryCity}
          //                       </span>
          //                       <span className="text-muted font-weight-bold">
          //                           {"Puerto Rico"}
          //                       </span>
          //                   </td>
          //                   <td>
          //                     <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
          //                       {company.createdDate ? company.createdDate : company.businessEstablishmentDate}
          //                     </span>
          //                   </td>
          //                   <td>
          //                     <span className="text-muted font-weight-bold">
          //                         {locations.filter(location => location.companyId === company._id).length + " Locations"}
          //                     </span>
          //                   </td>
          //                   <td className="pr-0 text-center">

          //                     <button 
          //                       className="btn btn-success font-weight-bolder font-size-sm"
          //                       onClick={() => handleAddLocationsClick(company._id)}
          //                     >
          //                       Add Location
          //                     </button>
          //                   </td>
          //                 </tr>
          //             ))}
          //         </tbody>
          //       </table>
          //     </div>
          //   </div>
          // </div>
          <div>
            <Card>
              {user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                <CardHeader title="All Customers">
                    <CardHeaderToolbar>
                    <button type="button" className="btn btn-primary" onClick={() => { handleAddCompanyClicked() }} >
                      Add Customer
                        </button>
                  </CardHeaderToolbar>
                </CardHeader>
                :
                <CardHeader title="All Companies">
                    <CardHeaderToolbar>
                    <button type="button" className="btn btn-primary" onClick={() => { handleAddCompanyClicked() }} >
                      Add Company
                        </button>
                  </CardHeaderToolbar>
                </CardHeader>
              }
              
                
              <CardBody>
                <PaginationTable
                  data={data}
                  columns={columns}
                  pagination={pagination}
                  isLoading={false}
                  sorting={sorting}
                  tableClasses={tableClasses}
                  fetcher={fetchCompaniesLocations}
                  filterFunction={prepareFilter}
                  initialFilter={initialFilter} />
              </CardBody>
            </Card>
          </div>
      }

      <Dialog
          open={openDiaogue}
          onClose={handleCloseDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {dialogMessage}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseDialogue} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(MyCompaniesPage);