import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

export function ActionColumnFormatter(cellContent, row, rowIndex, {clientsToAdd}){

    const setAction = () =>{
        let message = 'Select'
        const currClient = clientsToAdd.find(client => client == row._id)
        
        if(currClient) {
            message = 'Selected'
        }
        return (
            <button
                type="button"
                className="btn btn-primary font-weight-bolder font-size-sm mr-1"
            >
                {message}
            </button>
        )
    }


    return (
        <>
            {setAction()}
        </>
    )
}