
import React from "react";

export function SalesRepFormatter(cellContent, row){
    
    return (
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg d-block">
                
                {   
                    typeof(row.user[0]) !== 'undefined' && row.user[0] ?
                    `${row.user[0].fullName}`
                    :
                    ""
                }
            </span>
            <span className="text-muted font-weight-bold">
            {   
                    typeof(row.user[0]) !== 'undefined' && row.user[0] ?
                    `${row.user[0].email}`
                    :
                    null
                }
            </span>
        </>
    )
}