import React from 'react';
import {useField} from 'formik';
import Input from '@material-ui/core/Input';

export const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className="form-group fv-plugins-icon-container">
            <label htmlFor={props.id || props.name}>{label}</label>
            <Input className={`form-control form-control-solid h-auto py-2 px-6 text-dark`}
                   {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{meta.error}</div>
                </div>
            ) : null}
        </div>
    );
};

export const TableInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className="form-group fv-plugins-icon-container">
            <Input className={`form-control h-auto py-2 px-6 text-dark`}
                   {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{meta.error}</div>
                </div>
            ) : null}
        </div>
    );
};


