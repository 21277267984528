// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ';

// Companies
export const GET_COMPANIES = 'GET_COMPANIES';
export const ADD_COMPANY = 'ADD_COMPANY';

// Applications
export const GET_TOTAL_AUTHORIZED_CREDIT = 'GET_TOTAL_AUTHORIZED_CREDIT';
export const GET_APPLICATIONS_BY_COMPANY_ID_LIST = 'GET_APPLICATIONS_BY_COMPANY_ID_LIST';
export const GET_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION = 'GET_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION';
export const GET_DASHBOARD_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION = 'GET_DASHBOARD_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION'

// Locations
export const GET_LOCATIONS_BY_COMPANY_ID_LIST = 'GET_LOCATIONS_BY_COMPANY_ID_LIST';
export const ADD_LOCATION = "ADD_LOCATION";

// Orders
export const GET_ORDERS_BY_USER = 'GET_ORDERS_BY_USER';