
import React from "react";

export function EmailFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {row.email}
            </span>
        </>
    )
}