import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";
import {MyTextInput} from "../../CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function CommercialReferenceInformation({commercialReferenceInformation}) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Commercial References Information">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>
                            Expand
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                {commercialReferenceInformation.map(reference => 
                    <div>
                        <CardBody>
                            <Formik
                                initialValues={reference}
                                onSubmit={(values) =>{
                                    console.log(values)
                                }}
                            >
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Corporation Name"
                                                name="commercialCompanyName"
                                                type="text"
                                                value={reference.commercialCompanyName}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Business Name"
                                                name="commercialAccountNumber"
                                                type="text"
                                                value={reference.commercialAccountNumber}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical Address"
                                                name="commercialPhysicalAddress"
                                                type="text"
                                                value={reference.commercialPhysicalAddress}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical City"
                                                name="commercialPhysicalCity"
                                                type="text"
                                                value={reference.commercialPhysicalCity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical Zipcode"
                                                name="commercialPhysicalZipcode"
                                                type="text"
                                                value={reference.commercialPhysicalZipcode}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Postal Address"
                                                name="commercialPostalAddress"
                                                type="text"
                                                value={reference.commercialPostalAddress}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal City"
                                                name="commercialPostalCity"
                                                type="text"
                                                value={reference.commercialPostalCity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal Zipcode"
                                                name="commercialPostalZipcode"
                                                type="text"
                                                value={reference.commercialPostalZipcode}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            </Formik>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </div>
                )}
            </Card>
        </div>
    )
}