import {Card, CardBody, CardHeader, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";
import {MyTextInput} from "../../../Buyer/CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function BankReferenceInformation({bankReferenceInformation}) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Bank References Information">
                    {/*<CardHeaderToolbar>*/}
                    {/*    <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>*/}
                    {/*        Expand*/}
                    {/*    </button>*/}
                    {/*</CardHeaderToolbar>*/}
                </CardHeader>
                {bankReferenceInformation.map(reference => 
                    <div>
                        <CardBody>
                            <Formik
                                initialValues={reference}
                                onSubmit={(values) =>{
                                    console.log(values)
                                }}
                            >
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Corporation Name"
                                                name="institutionName"
                                                type="text"
                                                value={reference.institutionName}
                                                
                                            />
                                            <MyTextInput
                                                label="Business Name"
                                                name="bankAccountNumber"
                                                type="text"
                                                value={reference.bankAccountNumber}
                                                
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Business Type"
                                                name="bankBranch"
                                                type="text"
                                                value={reference.bankBranch}
                                                
                                            />
                                            <MyTextInput
                                                label="Phone Number"
                                                name="bankPhoneNumber"
                                                type="text"
                                                value={reference.bankPhoneNumber}
                                                
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            </Formik>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </div>
                )}
            </Card>
        </div>
    )
}