import React, {lazy, Suspense, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import {cartCountActions} from '../../../redux/reducers/cartCount';
import {ordersCountActions} from '../../../redux/reducers/ordersCount';
import {ContentRoute, LayoutSplashScreen} from "../../../_metronic/layout";
import {getCompanies} from './../../../redux/actions/companies';
import AccountSettingsPage from './AccountSettingsPage';
import CommonApplicationPage from "./CommonApplication/CommonApplicationPage";
import MyCompaniesPage from './Companies/MyCompaniesPage';
import {ClientDashboardPage} from "./Dashboard/ClientDashboardPage";
// import {AuthPage} from "./modules/Auth"
import InvoicesPage from "./Invoices/InvoicesPage";
import MyLocationsPage from './MyLocations/MyLocationsPage';
import ReportsPage from './Reports/ReportsPage';
import ConfirmationPage from "./Orders/Confirmation/ConfirmationPage";
import {applicationsCountActions} from '../../../redux/reducers/applicationsCount'
import OrdersPage from "./Orders/OrdersPage";
import SuppliersPage2 from './Suppliers/SuppliersPage';
import OrderFavoritesBasePage from "./OrderFavorites/OrderFavoritesBasePage";

const GoogleMaterialPage = lazy(() =>
    import("../../modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
    import("../../modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
    import("../../modules/ECommerce/pages/eCommercePage")
);

export default function BuyerBasePage(props) {
    const dispatch = useDispatch();
    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {
        dispatch(cartCountActions.getProductCount(accessToken, user.id))
        dispatch(ordersCountActions.getOrdersCount(accessToken, user.id))
        dispatch(applicationsCountActions.getApplicationsCount(accessToken, user.id))
        dispatch(getCompanies(user.id));
    }, [])

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {props.pathName === 'orders' && props.pathId === 'new' &&
                <Redirect exact from="/" to="/orders/new"/>
                }
                {props.pathName === 'orders' &&
                <Redirect exact from="/" to="/orders"/>
                }
                {props.pathName === 'invoices' &&
                <Redirect exact from="/" to="/invoices"/>
                }
                {props.pathName === 'commonApplication' &&
                <Redirect exact from="/" to="/commonApplication"/>
                }
                {props.pathName === 'mycompanies' &&
                <Redirect exact from="/" to="/mycompanies"/>
                }
                {props.pathName === 'mylocations' &&
                <Redirect exact from="/" to="/mylocations"/>
                }
                {props.pathName === 'reports' &&
                <Redirect exact from="/" to="/reports"/>
                }
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/orders"/>
                }
                <ContentRoute path="/dashboard" component={ClientDashboardPage}/>
                <ContentRoute path="/commonApplication/draft" component={CommonApplicationPage} pathId={props.pathId}
                              status={"draft"}/>
                <ContentRoute path="/commonApplication/submitted" component={CommonApplicationPage}
                              pathId={props.pathId} status={"submitted"}/>
                <ContentRoute path="/commonApplication/approved" component={CommonApplicationPage} pathId={props.pathId}
                              status={"approved"}/>
                <ContentRoute path="/commonApplication/pending" component={CommonApplicationPage} pathId={props.pathId}
                              status={"pending"}/>
                <ContentRoute path="/commonApplication/denied" component={CommonApplicationPage} pathId={props.pathId}
                              status={"denied"}/>
                <ContentRoute path="/commonApplication" component={CommonApplicationPage} pathId={props.pathId}
                              status={""}/>
                <ContentRoute path="/orders/confirmation" component={ConfirmationPage}/>
                <ContentRoute path="/orders/notsubmitted" component={OrdersPage} pathId={props.pathId}
                              status={"Not Submitted"}/>
                <ContentRoute path="/orders/submitted" component={OrdersPage} pathId={props.pathId}
                              status={"Submitted"}/>
                <ContentRoute path="/orders/received" component={OrdersPage} pathId={props.pathId}
                              status={"Received"}/>
                <ContentRoute path="/orders/processed" component={OrdersPage} pathId={props.pathId}
                              status={"Processed"}/>
                <ContentRoute path="/orders/intransit" component={OrdersPage} pathId={props.pathId}
                              status={"In Transit"}/>
                <ContentRoute path="/orders/delivered" component={OrdersPage} pathId={props.pathId}
                              status={"Delivered"}/>
                <ContentRoute path="/orders/quote" component={OrdersPage} pathId={props.pathId}
                              status={"Quote"}/>
                <ContentRoute path="/orders/backorder" component={OrdersPage} pathId={props.pathId}
                              status={"Back Order"}/>
                <ContentRoute path="/orders/new" component={OrdersPage} pathId={props.pathId}
                              status={""} isNewOrder={true}/>
                <ContentRoute path="/orders" component={OrdersPage} pathId={props.pathId} status={""}/>
                <ContentRoute path="/invoices" component={InvoicesPage}/>
                <ContentRoute path="/suppliers" component={SuppliersPage2}/>
                <ContentRoute path="/mycompanies" component={MyCompaniesPage}/>
                <ContentRoute path="/mylocations" component={MyLocationsPage}/>
                <ContentRoute path="/reports" component={ReportsPage}/>
                <ContentRoute path="/accountsettings" component={AccountSettingsPage}/>
                <ContentRoute path="/orders-favorites" component={OrderFavoritesBasePage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
