import React, { useState, useEffect } from "react";
import {injectIntl} from "react-intl";
import {Card} from "react-bootstrap";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import OrdersFavoritesNumberField from "./OrderFavoritesNumberField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { getFavoriteListProductsInSequence } from "./crud/ordersFavoritesCrud"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}));

function OrderFavoritesProductSequenceEdit({ accessToken, favoriteListId, flProducts, setFlProducts }){

    const classes = useStyles();

    const fetchFavoriteListProductsInSequence = async () => {
        const favoriteListProducts = await getFavoriteListProductsInSequence(accessToken, favoriteListId)
        console.log("Response data: ", favoriteListProducts.data)
        setFlProducts(favoriteListProducts.data)

    }

    useEffect(() => {
        fetchFavoriteListProductsInSequence();

    }, [])

    const onDragEnd = result => {
        // dropped outside the list
        const { destination, source, draggableId, type } = result;

        if (!destination) {
          return;
        }

        if (destination.index === source.index) {
            return;
        }
    
        let newFlProducts = [...flProducts]
        const currProduct = flProducts.find(item => item._id == draggableId)

        newFlProducts.splice(source.index, 1);
        newFlProducts.splice(destination.index, 0, currProduct);
    
        setFlProducts(newFlProducts)
        return;
    }

    return (
        <>
            <div>
                <>

                    <div className="col-12 my-5">
                        <Card>
                            <Card.Body>
                                <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        Name
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        Description
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        Category
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        Price
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        QoH
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <Droppable droppableId="droppable" key={favoriteListId + 'k'} type="task">
                                            {(provided, snapshot) => (
                                                <TableBody
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    
                                                    {flProducts != null ?
                                                        (flProducts.map((product, productIndex) => (
                                                            <>
                                                                <Draggable draggableId={product._id} key={product._id + 'd'} index={productIndex}>
                                                                    {(provided, snapshot) => (
                                                                        <TableRow
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            <TableCell>
                                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                                    {product.name}
                                                                                </span>
                                                                                    <span className={'text-muted font-weight-bold'}>
                                                                                    {product.sku}
                                                                                </span>
                                                                            </TableCell>
                                                                            <TableCell>{product.description ? product.description : product.name}</TableCell>
                                                                            <TableCell>{product.productCategory[0].name}</TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    product.favoritePrice ? 
                                                                                        product.favoritePrice
                                                                                    : 
                                                                                    product.listPrice || product.listPrice == '' ?                  
                                                                                        product.listPrice
                                                                                    :
                                                                                        product.price
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>{product.qtyOnHand}</TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </Draggable>
                                                            </>
                                                        )))
                                                        :
                                                        null
                                                    }
                                                    {provided.placeholder}
                                                </TableBody>
                                            )}
                                        </Droppable>
                                    </Table>
                                </DragDropContext>
                            </Card.Body>
                        </Card>
                    </div>
                </>
            </div>
        </>
    )
}

export default injectIntl(OrderFavoritesProductSequenceEdit);