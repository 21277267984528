
import React from "react";

export function OrderNumberFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                {row.orderNumber}
            </span>
        </>
    )
}