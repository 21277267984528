import axios from "axios";

const favoritesUrl = process.env.REACT_APP_API_URL + "/orders-favorites"

export function getFavorite(id, accessToken){
   const options = {
      headers: {'x-auth-token': accessToken}
   };

   return axios.get(favoritesUrl + "/" + id, options)
}

export function getFavorites(accessToken, flSearchName, pageNumber, pageSize, createdById){
   const options = {
      headers: {'x-auth-token': accessToken},
      params: {
         searchText: flSearchName,
         pageNumber, 
         pageSize,
         createdById
      }
   }

   return axios.get(favoritesUrl, options);
}

export function postFavorites(favorite, supplierId, accessToken){
   const options = {
      headers: {'x-auth-token': accessToken}
   }

   favorite.supplierId = supplierId;

   return axios.post(favoritesUrl, favorite, options);
}

export function updateFavorites(favoriteId, favorite, accessToken){
   const options = {
      headers: {'x-auth-token': accessToken}
   }

   return axios.patch(`${favoritesUrl}/${favoriteId}`, favorite, options);
}

export function updateFavoritesSequence(favoriteId, favorite, accessToken){
   const options = {
      headers: {'x-auth-token': accessToken}
   }

   return axios.patch(`${favoritesUrl}/sequence/${favoriteId}`, favorite, options);
}

export function addProduct(favoritesId, product, accessToken){
   const options = {
      headers: {'x-auth-token': accessToken}
   }

   return axios.post(`${favoritesUrl}/${favoritesId}/addProduct`, product, options);
}

export function deleteProduct(favoritesId, productId, accessToken){
   const options = {
      headers: {'x-auth-token': accessToken}
   }

   return axios.delete(`${favoritesUrl}/${favoritesId}/products/${productId}`, options);
}

export function getProducts(queryParams, accessToken, supplierId){
   const options = {
      headers: {'x-auth-token': accessToken},
      validateStatus: status => status < 400,
      params: {
         ...queryParams,
         supplierId: supplierId
      }
   }

   return axios.get(`${favoritesUrl}/products`, options);
}

export function getCompanies(id, accessToken, supplierId){
   const options = {
      headers: {'x-auth-token': accessToken},
   };

   return axios.get(process.env.REACT_APP_API_URL + '/company/user/' + id + '/supplier/' + supplierId, options);
}

export function fetchApprovedSuppliersCategories(id, authToken){
   const options = {
      headers: {'x-auth-token': authToken}
   }

   return axios.get(process.env.REACT_APP_API_URL + '/buyers/' + id + '/suppliers/categories', options)
}

export function fetchApprovedSuppliers(id, authToken){
   const options = {
      headers: {'x-auth-token': authToken}
   }

   return axios.get(process.env.REACT_APP_API_URL + '/buyers/' + id + '/suppliers', options)
}

export function fetchCompanies(authToken, supplierId) {
   const options = {
      headers: {'x-auth-token': authToken}
   }

   return axios.get(process.env.REACT_APP_API_URL + '/company/supplier/' + supplierId, options);
}

export function postProductsCart(id, authToken, locations, product, supplierId, entityName, companyId, businessName){
   const options = {
      headers: {'x-auth-token': authToken}
   }

   const body = {
      product: product,
      locations: locations,
      supplierId: supplierId,
      companyId: companyId,
      supplierName: entityName,
      companyName: businessName
   }

   return axios.post(process.env.REACT_APP_API_URL + '/orders/addProducts', body, options);
}

export function favoriteOrderSubmit(authToken, data){
   const options = {
      headers: {'x-auth-token': authToken}
   }

   return axios.post(process.env.REACT_APP_API_URL + '/orders/favorites/submit', data, options);
}

export function favoriteOrderSave(authToken, data){
   const options = {
      headers: {'x-auth-token': authToken}
   }

   return axios.post(process.env.REACT_APP_API_URL + '/orders/favorites/save', data, options);
}

export function fetchFavoriteListProducts(authToken, favoriteListId, companyId, supplierId, searchText) {
   const options = {
      headers: { 'x-auth-token': authToken },
      params: { 
         favoriteListId, 
         companyId, 
         supplierId,
         searchText
      }
   }

   return axios.get(process.env.REACT_APP_API_URL + '/products/favorites/list', options)

}

export function getFavoriteListProductsInSequence(authToken, favoriteListId) {
   const options = {
      headers: { 'x-auth-token': authToken },
      params: { 
         favoriteListId
      }
   }

   return axios.get(process.env.REACT_APP_API_URL + '/products/favorites/list/sequence', options)

}