// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function StatusFormatter(cellContent, row) {
    const getStatusLabel = (status) => {
        if(status === "submitted") {
            return (
                <span className="label label-lg label-light-primary label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "in progress") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "approved") {
            return (
                <span className="label label-lg label-light-success label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "denied") {
            return (
                <span className="label label-lg label-light-danger label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "Not Applied") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "pending") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } 
    }

    return (
        <>
            {   row.supplierApplications ? 
                    <ul style={{ 'list-style-type':'none', padding:0, margin:0}} >
                    {row.supplierApplications.map(companyStatus => 

                        <li>
                            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {getStatusLabel(companyStatus.status)}
                            </span>
                        </li>
                    )}
                    </ul>
                : 
                    getStatusLabel(row.status)
            }
            
        </>
    );
}
