import React, {useState, useEffect} from 'react';
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import useTheme from "@material-ui/core/styles/useTheme";
import PropTypes from 'prop-types';
import PriceListProductConfig from "../Suppliers/PriceListProductConfig";
import { fetchPriceListProducts, fetchPriceListProductsPagination } from "../AdminPagesCrud";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// function createData(name, calories, fat) {
//     return { name, calories, fat };
// }


// const useStyles2 = makeStyles(theme => ({
//     root: {
//         width: '100%',
//         marginTop: theme.spacing(3),
//     },
//     table: {
//         minWidth: 500,
//     },
//     tableWrapper: {
//         overflowX: 'auto',
//     },
// }));


function PriceListProducts({priceList, supplier, products}) {
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [edit, setEdit] = useState(false)
    const [priceListProducts, setPriceListProducts] = useState([])
    const [data, setData] = useState(initialData)

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

    const { accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {
        getPriceListProducts()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ edit ])

    const getPriceListProducts = async () => {
        await fetchPriceListProducts(priceList._id, accessToken).then(response => {
            
            setPriceListProducts(response.data)
        }).catch(error => console.log(error))
    }

    const fetchPriceListProductsPaginationTable = async (queryParams) => {
        await fetchPriceListProductsPagination(priceList._id, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    // function handleChangePage(event, newPage) {
    //     setPage(newPage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    // }

    const columns = [
        {
            dataField: "description",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        
        {
            dataField: "units",
            text: "Units",
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
        },
        {
            dataField: "price",
            text: "Original Price",
        },
        {
            dataField: "listPrice",
            text: "List Price",
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {edit ?  <PriceListProductConfig priceListId={priceList._id} currentPriceListProducts={priceListProducts} setEdit={setEdit}
                    /> :
                <Card>
                    <CardHeader title="Price List Products">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setEdit(true)}>
                                Edit
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            initialFilter={initialFilter}
                            tableClasses={tableClasses}
                            fetcher={fetchPriceListProductsPaginationTable}
                            filterFunction={prepareFilter}
                            searchField={true}/>
                        
                    </CardBody>
                    {/*<AdminSnackbar*/}
                    {/*    message={'Price List  updated successfully'}*/}
                    {/*    setShowSnackbar={setSnackBarPL}*/}
                    {/*    showSnackbar={snackbarPL}*/}
                    {/*    variant={'success'}*/}
                    {/*/>*/}
                </Card>
            }
        </>
    );
}

export default injectIntl(PriceListProducts);