import React from "react";
import {Link} from "react-router-dom";


export function SupplierInfoColumnFormatter(cellContent, row) {
    return (
        <>
            <Link to={'/supplierAdmin/' + row._id}>
                <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">
                    {row.entityName}
                </span>
            </Link>
        </>
    );
}
