import React, {useEffect, useState} from "react";
import {ProductBox} from "./ProductBox";
import {useSelector} from "react-redux";
import Pagination from "react-js-pagination"
import {Formik} from "formik";
import {getCompanyLocations, patchOrder, fetchCategories, getSupplierProductsNameBrand} from "../ordersCrud";

export function ProductList({ order, setIsOrderUpdated, isOrderUpdated }){
    const {accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    // console.log("Locations: ", locations)
    // console.log("Company: ", company)

    const initialPaginationOptions = {
        totalSize: 0,
        sizePerPage: 10,
        page: 1
    }

    const inititalCategoryTree = {
        branches: 0,
        selectedCategories:[],
        categoryType:"",
        children:[]
    }

    const [products,setProducts] = useState([]);
    const [paginationOptions,setPaginationOptions] = useState(initialPaginationOptions)
    const [searchName,setSearchName] = useState("")
    const [searchBrand, setSearchBrand] = useState("All")
    const [searchCategory, setSearchCategory] = useState({})
    const [categoriesTree,setCategoriesTree] = useState(inititalCategoryTree)
    const [filteredCategoryList, setFilteredCategoryList] = useState([])
    const [isCategoriesFetched,setIsCategoriesFetched] = useState(false)
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [filterCategory, setFilterCategory] = useState({})
    const [fullCategoryTree,setFullCategoryTree] = useState([])
    const [supplier, setSupplier] = useState(order.supplier[0])
    const [company, setCompany] = useState(order.company[0])
    const [isDateTodayFuture, setIsDateTodayFuture] = useState(false)

    useEffect(() => {
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, searchBrand, filterCategory, company._id).then(response=>{
            
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isDateInFuture = function(firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) >= secondDate.setHours(0, 0, 0, 0)) {
          return true;
        }
      
        return false;
    };

    useEffect(() =>{

        if(order.status !== 'Not Submitted'){
            var invoiceDate = new Date(order.invoiceDate.replace(/-/g, '\/'));
            var today = new Date();

            setIsDateTodayFuture(isDateInFuture(invoiceDate, today))
        }

        fetchCategories(order.supplier[0]._id,accessToken).then(response =>{
            // setObjectsArray(response.data)
            setFullCategoryTree(response.data)
            setCategoriesTree({
                branches: 0,
                selectedCategories: response.data.slice(0, 1),
                categoryType: response.data.type,
                children:response.data
            })
            // setDefaultCategory(response.data.type)
            setIsCategoriesFetched(true)
        });

    },[])

    const handlePageChange = (pageNumber) =>{
        getSupplierProductsNameBrand(supplier._id,accessToken,pageNumber,paginationOptions.sizePerPage,searchName, searchBrand, searchCategory, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:pageNumber,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const handleBrandClick = (brand) =>{
        if(brand === 'All'){
            setFilteredCategoryList([])
        }
        setSearchCategory({})
        setSearchBrand(brand)
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, brand, {}, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const handleCategoryClick = (category, catLevel) =>{
        setSearchCategory(category)
        setSearchBrand("")
        console.log(`${category.name}: ${catLevel}`)

        setFilteredCategoryList([...filteredCategoryList.slice(0, catLevel), category.name])
        
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, "", category, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const getCategoryBrandList = (categories, listItems = [], catLvl) => {
        
        categories.forEach((category, index) => {
            let catLevel = 0
            if(typeof(catLvl) !== 'undefined'){
                catLevel = catLvl
            }
            
            // console.log("Local category lvl: ", catLevel, category.name)
            if(category.children.length === 0){
                //Product Categoriess
                listItems.push(<li key={index + '-' + category.name} style={{ 'listStyleType':'none', paddingLeft: catLevel * 15, margin:0}}>
                    <div className="d-flex align-items-center mb-1">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        <a 
                        href="javascript:void(0);"
                        onClick={() => handleCategoryClick(category, catLevel)}
                        className={"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}>
                            {category.name}
                        </a>
                    </div>
                </li>)
                
            } else {
                //Top level categories
                if(category.parent === 'root'){
                    listItems.push(<li key={index + '-' + category.name} style={{ 'listStyleType':'none', padding:0, margin:0}}>
                        <a 
                        href="javascript:void(0);"
                        onClick={() => handleCategoryClick(category, catLevel)}
                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                            {category.name}
                        </a>
                    </li>)
                } else {
                    listItems.push(<li key={index + '-' + category.name} style={{ 'listStyleType':'none', paddingLeft: catLevel * 15, margin:0}}>
                    <div className="d-flex align-items-center mb-1">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        <a 
                        href="javascript:void(0);"
                        onClick={() => handleCategoryClick(category, catLevel)}
                        className={"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}>
                            {category.name}
                        </a>
                    </div>
                </li>)
                }
                
                // console.log(filteredCategory, filteredCategory === "" || filteredCategory === category.name)
                if(filteredCategoryList.length > 0 && filteredCategoryList.includes(category.name)){
                    listItems = getCategoryBrandList(category.children, listItems, ++catLevel)
                } 
            }
        })
        return listItems
    }


    return(
       
        <div className="row">
        <div className="col-lg-2 col-xxl-2">
            {/* <ul>
                {supplier.supplierProductBrands.length > 0 ?
                    supplier.supplierProductBrands.map(brand => 
                        <li style={{ 'list-style-type':'none', padding:0, margin:0}}>
                            <a 
                            onClick={() => handleBrandClick(brand.brand)}
                            className="text-dark text-hover-primary mb-1 font-size-lg">
                                {brand.brand}
                            </a>
                        </li>
                        
                    )
                    :
                    null
                }
            </ul> */}
            <ul>
                {fullCategoryTree.length > 0 ?
                    <div>
                        <li key={"all"} style={{ 'listStyleType':'none', paddingLeft:0, margin:0}}>
                            <a 
                            href="javascript:void(0);"
                            onClick={() => handleBrandClick("All")}
                            className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {"All"}
                            </a>
                        </li>
                        {getCategoryBrandList(fullCategoryTree)}
                    </div>
                    // null
                    :
                    null
                }
            </ul>
        </div>
        <div className="col-lg-9 col-xxl-9">
        <div className="row justify-content-between mb-4">
                <Formik
                    initialValues={{searchText:""}}
                    onSubmit={(values) =>{
                        setSearchName(values.searchText)
                        getSupplierProductsNameBrand(supplier._id,accessToken,1,paginationOptions.sizePerPage,values.searchText, searchBrand, searchCategory, company._id).then(response=>{
                            setProducts(response.data.products)
                            setPaginationOptions({
                                page:1,
                                totalSize: response.data.totalCount,
                                sizePerPage: paginationOptions.sizePerPage
                            })
                        })

                    }}>
                    {({values, handleSubmit, handleBlur, handleChange, setFieldValue})=>(
                        <form onSubmit={handleSubmit} className="form form-label-right w-100">
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="searchText"
                                        placeholder="Search"
                                        onBlur={handleBlur}
                                        value={values.searchText}
                                        onChange={(e) =>{
                                            setFieldValue("searchText",e.target.value);
                                            handleSubmit();
                                        }}
                                    />
                                    <small className="form-text text-muted">
                                        <b>Search</b> by name or item id
                                    </small>
                                </div>
                                <button
                                id="addLocationButton"
                                type="button"
                                onClick={() => {
                                    setFieldValue("searchText",'')
                                    handleSubmit();
                                }}
                                // disabled={formik.isSubmitting}
                                className="btn btn-primary font-weight-bold px-4 mx-4"
                                >
                                    <span>Clear</span>
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

            <div className="row">


                {typeof(products) !== 'undefined' ? products.map((product,index) => (
                    <ProductBox key={product.name + '-' + index} product={product} company={company} supplier={supplier} location={order.location[0]} setShowSnackBar={setShowSnackBar}
                                getSupplierProductsNameBrand={getSupplierProductsNameBrand}  setProducts={setProducts} setPaginationOptions={setPaginationOptions} 
                                searchBrand={searchBrand} searchCategory={searchCategory} paginationOptions={paginationOptions} 
                                orderId={order._id} setIsOrderUpdated={setIsOrderUpdated} isOrderUpdated={isOrderUpdated} />
                ))
                    : null
                }
            </div>
            <div className="row justify-content-center">
                <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paginationOptions.page}
                    itemsCountPerPage={paginationOptions.sizePerPage}
                    totalItemsCount={paginationOptions.totalSize}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                />
            </div>
            {/* <SimpleSnackbar
                showSnackBar={showSnackBar}
                setShowSnackBar={setShowSnackBar}
            /> */}
            </div>
            </div>

    )
}
