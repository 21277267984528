import axios from "axios";
export const PRODUCTS_GET_URL = process.env.REACT_APP_API_URL + "/products/supplier";

export function fetchOrders(id,authToken,){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/orders/supplier', options)

}

export function updateStatus(id,authToken,orderId,status,date = null){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    let body = {
        status: status,
        date: date,
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/' + orderId + '/updateStatus',body,options )
}

export function getCompanyLocations(id,authToken,companyId){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    const companyIdList = [companyId];

    const companyIdListJson = {companyIdList}

    return axios.get(process.env.REACT_APP_API_URL + "/locations/company",{params: companyIdListJson}, options )
}

//
export function updateOrderProduct(id,authToken,orderId,productId,amount,price){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    const body = {
        amount: amount,
        price: price
    }

    console.log("El precio adentro",price)

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/' + orderId + '/products/' + productId, body, options);
}

export function getOrderInfo(authToken,orderId){
    const options = {
        headers: {'x-auth-token':authToken},
    }

    return axios.get(process.env.REACT_APP_API_URL + '/orders/' + orderId, options)
}

export function getSupplierOrderInfo(authToken,orderId,userId){
    const options = {
        headers: {'x-auth-token':authToken},
        params: {userId : userId}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/orders/supplier/' + orderId, options)
}

//
export function patchOrder(authToken,order){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/supplier/update', order, options)
}

export function postProductFromSupplier(authToken, location, product, isFuelFree, orderId) {

    const options = {
        headers: { 'x-auth-token': authToken },

    }

    const body = {
        product: product,
        location: location,
        isFuelFree: isFuelFree,
        orderId: orderId
    }

    return axios.post(process.env.REACT_APP_API_URL + '/orders/supplier/update/product', body, options)
}

export function fetchCategories(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/categories/suppliers/' + id, options)
}

export function getSupplierProductsNameBrand(supplierId,authToken,pageNumber,sizePerPage,searchText,brand,category, companyId) {
    const options = {
        headers: {'x-auth-token':authToken},
        validateStatus: status => status < 500,
        params: {
            page:pageNumber,
            sizePerPage:sizePerPage,
            name:searchText,
            brand:brand,
            category:category,
            companyId: companyId
        }
    }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${PRODUCTS_GET_URL}/namebrand/${supplierId}`, options);
}

export function cancelOrder(orderUpdate, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        data: orderUpdate
    }

    return axios.delete(process.env.REACT_APP_API_URL + '/orders/cancel', options)
}

export function patchRouteRecordForceQueue(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/record/force/queue', data, options);
}