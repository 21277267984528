/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import {Dropdown} from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu2, DropdownMenu1 } from "../../../../_metronic/_partials/dropdowns";

import React, {useState} from "react";
import { DropdownMenu4} from "../../../../_metronic/_partials/dropdowns";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import { getSuppliers, getSuppliersPagination, getSuppliersPaginationCategories } from '../CommonApplication/SupplierSelection/SupplierSelectionCrud'
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";

export function TopCategorySupplier({ className, suppliers, applicationList, accessToken, handleSupplierClick }){


    const initialData = {
        data: [],
        totalCount: 0,
        pageSize:5
    }
    const [data,setData] = useState(initialData)

    const fetchSuppliers = async (queryParams) => {
        let suppliersStatus = []
        await getSuppliersPaginationCategories(queryParams, accessToken).then(response => {
            // console.log("-------------------------------")
            // console.log(response)
            // console.log(applicationList)
            // console.log("~~~~~Res: ", response)
            response.data.data.map(supplier => {
                let supplierApplications = []
                applicationList.map(application => {
                    // console.log(application)
                    if(supplier._id === application.supplierId){
                        supplierApplications.push({
                            company : application.companyName,
                            status : application.status,
                        })
                    } 
                })
                if(supplierApplications.length > 0){
                    suppliersStatus.push({
                        ...supplier,
                        supplierApplications
                    })
                } else {
                    suppliersStatus.push({
                        ...supplier,
                        status : "Not Applied"
                    })
                }
            })

            // console.log("~~~~Aqui:", suppliersStatus)
            
            const data = {
                data : suppliersStatus,
                totalCount : response.data.totalCount,
                pageSize : 5
            }
            setData(data)
        }).catch((error) => {
            console.log(error)
        });
        
        // setData(data);
        // Authorization head should be fulfilled in interceptor.
        // const options = {
        //     headers: { 'x-auth-token': accessToken},
        //     validateStatus: status => status < 500,
        //     params: queryParams
        // }
        // axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + user.id + '/applications',options).then(response =>{
        //     setData(response.data)
        // });
    }

    const columns = [
        // {
        //     dataField: "orderNumber",
        //     text: "Order Number",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        //     events: {
        //         onClick: (e, column, columnIndex, row, rowIndex) => {
        //             console.log("Pepe")
        //         }
        //     },
        //     // formatter: columnFormatters.OrderNumberFormatter,
        // },
        // {
        //     dataField: "companyName",
        //     text: "Company",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
            dataField: "entityName",
            text: "Supplier",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.EntityNameFormatter,
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                handleSupplierClick(row)
              }
          },
        },
        {
            dataField: "supplierCategories",
            text: "Categories",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CategoriesFormatter,
        },
        {
            dataField: "company",
            text: "Company",
            // sort: true,
            // sortCaret: sortCaret,
            // headerSortingClasses,
            formatter: columnFormatters.CompanyFormatter,
        },
        {
            dataField: "status",
            text: "Status",
            // sort: true,
            // sortCaret: sortCaret,
            // headerSortingClasses,
            formatter: columnFormatters.StatusFormatter,
        },
    ];

    const sizePerPageList = [
        { text: "3", value: 3 },
        { text: "5", value: 5 },
        { text: "10", value: 10 }
    ]

    const initialFilter = {
        filter: {
            entityName:"",
        },
        sortOrder: "asc",
        sortField: "entityName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "entityName",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-vertical-center "

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.entityName = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>  
            <div className={`card card-custom ${className}`}>
                <Card>
                    <CardHeader title="Top Categories by Supplier">
                        <CardHeaderToolbar>
                            {/* <button type="button" className="btn btn-primary" onClick={()=>{console.log("Que la que")}} >
                                Create New Order
                            </button> */}
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchSuppliers}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}/>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}


// export function TopCategorySupplier({ className }) {
//   return (
//     <>
//       <div className={`card card-custom ${className}`}>
//         {/* Head */}
//         <div className="card-header border-0">
//           <h3 className="card-title font-weight-bolder text-dark">Top Categories by Supplier</h3>
//           <div className="card-toolbar">
//             <button type="button" className="btn btn-primary" onClick={() => console.log("filtrando")}>
//               View Products
//             </button>
//           </div>
//         </div>
//         {/* Body */}
//         <div className="card-body pt-2">
//           <div className="d-flex align-items-center mb-10">
//             <span className="bullet bullet-bar bg-success align-self-stretch"></span>

//             <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
//               <input type="checkbox" name="" onChange={() => {}} value="1" />
//               <span></span>
//             </label>

//             <div className="d-flex flex-column flex-grow-1">
//               <a
//                 href="#"
//                 className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
//               >
//                 Alcoholic Beverages
//               </a>
//               <span className="text-muted font-weight-bold">Supplier C</span>
//             </div>
//             <ItemDropdown item="" />
//           </div>

//           <div className="d-flex align-items-center mb-10">
//             <span className="bullet bullet-bar bg-primary align-self-stretch"></span>

//             <label className="checkbox checkbox-lg checkbox-light-primary checkbox-single flex-shrink-0 m-0 mx-4">
//               <input type="checkbox" onChange={() => {}} value="1" />
//               <span></span>
//             </label>

//             <div className="d-flex flex-column flex-grow-1">
//               <a
//                 href="#"
//                 className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
//               >
//                 Food Service
//               </a>
//               <span className="text-muted font-weight-bold">Supplier E</span>
//             </div>
//             <ItemDropdown item="" />
//           </div>

//           <div className="d-flex align-items-center mb-10">
//             <span className="bullet bullet-bar bg-warning align-self-stretch"></span>

//             <label className="checkbox checkbox-lg checkbox-light-warning checkbox-single flex-shrink-0 m-0 mx-4">
//               <input type="checkbox" value="1" onChange={() => {}} />
//               <span></span>
//             </label>

//             <div className="d-flex flex-column flex-grow-1">
//               <a
//                 href="#"
//                 className="text-dark-75 text-hover-primary font-size-sm font-weight-bold font-size-lg mb-1"
//               >
//                 Non-Alcoholic Beverages
//               </a>
//               <span className="text-muted font-weight-bold">Supplier B</span>
//             </div>
//             <ItemDropdown item="" />
//           </div>

//           <div className="d-flex align-items-center mb-10">
//             <span className="bullet bullet-bar bg-info align-self-stretch"></span>

//             <label className="checkbox checkbox-lg checkbox-light-info checkbox-single flex-shrink-0 m-0 mx-4">
//               <input type="checkbox" value="1" onChange={() => {}} />
//               <span></span>
//             </label>

//             <div className="d-flex flex-column flex-grow-1">
//               <a
//                 href="#"
//                 className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
//               >
//                 Household Products
//               </a>
//               <span className="text-muted font-weight-bold">Supplier C</span>
//             </div>
//             <ItemDropdown item="" />
//           </div>

//           <div className="d-flex align-items-center mb-2">
//             <span className="bullet bullet-bar bg-danger align-self-stretch"></span>

//             <label className="checkbox checkbox-lg checkbox-light-danger checkbox-single flex-shrink-0 m-0 mx-4">
//               <input type="checkbox" value="1" onChange={() => {}} />
//               <span></span>
//             </label>
//             <div className="d-flex flex-column flex-grow-1">
//               <a
//                 href="#"
//                 className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
//               >
//                 Snack & Confections
//               </a>
//               <span className="text-muted font-weight-bold">
//                 Supplier F
//               </span>
//             </div>
//             <ItemDropdown item="" />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// const ItemDropdown = ({item}) => {
//   return (<>
//   <Dropdown className="dropdown-inline" alignRight>
//     <Dropdown.Toggle
//       variant="transparent"
//       id="dropdown-toggle-top"
//       className="btn btn-hover-light-primary btn-sm btn-icon"
//       as={DropdownCustomToggler}>
//       <i className="ki ki-bold-more-hor" />
//     </Dropdown.Toggle>
//     <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
//       <DropdownMenu1 />
//     </Dropdown.Menu>
//   </Dropdown>
//   </>);
// };