import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {resendValidation} from "../_redux/authCrud";

function EmailVerification(props) {
    const history = useHistory();
    const [emailSent, setEmailSent] = useState(false)
    const email = useSelector(state => state.verificationEmail)

    const {hasEmail} = useSelector(
        ({verificationEmail}) =>({
            hasEmail: verificationEmail != null && verificationEmail !== ""
        })
    )

    if(!hasEmail){
        console.log(hasEmail)
        history.push('/auth/login')
    }else{
        console.log(hasEmail)
    }

    const submitValidation = () => {
        resendValidation(email).then(res =>{
            console.log("Validation Res: ", res)
        });
        setEmailSent(true)
    }

    return (
        <div>
            {!emailSent ? (
                <div style={{maxWidth:"600px"}}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Your email has to be verified before loggin in
                        </h3>
                        <p className="text-muted font-weight-bold">
                            When you created your account we sent you a confirmation email with a verification link. If you have not received a confirmation email please <span style={{color:"#3699FF", cursor:"pointer"}} onClick={submitValidation}>click here</span>.
                        </p>
                    </div>

                </div>
            ) : (
                <div style={{maxWidth:"600px"}}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Verification email sent.
                        </h3>
                        <p className="text-muted font-weight-bold">
                            Please follow the instructions in the email to activate your account.
                        </p>
                    </div>

                </div>
            )}
        </div>
    );
}

export default EmailVerification;
