import React from "react";
// import { connect } from "react-redux";
import { injectIntl } from "react-intl";

function AccountSettingsPage(props) {
    return (
        <h1>Account Settings Page Coming Soon!</h1>
    );
}
    
export default injectIntl(AccountSettingsPage);