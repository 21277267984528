import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../_metronic/_partials/controls";
import React, { useState, useEffect } from "react";
import {MyTextInput} from "../CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';
import { getApplicationsByLocation } from '../CommonApplication/CommonApplicationCrud'

export default function LocationDetails({ locationInformation, accessToken }) {

    const [applications, setApplications] = useState([])

    const fetchApplicationsByLocation = async (locationId, accessToken) => {
        console.log("WTF")
        getApplicationsByLocation(locationId, accessToken).then(response => {
            setApplications(response.data)
        }).catch((error) => console.error(error))
    }

    useEffect(() => {
      fetchApplicationsByLocation(locationInformation._id, accessToken)
    }, [  ])

    const getStatusLabel = (status) => {
        if(status === "submitted") {
          return (
            <span className="label label-lg label-light-primary label-inline">
              {status}
            </span> 
          )
        } else if (status === "draft") {
            return (
              <span className="label label-lg label-light-warning label-inline">
                {status}
              </span>
            )
        } else if (status === "approved") {
            return (
              <span className="label label-lg label-light-success label-inline">
                {status}
              </span>
            )
        } else if (status === "denied") {
          return (
            <span className="label label-lg label-light-danger label-inline">
              {status}
            </span>
          )
        }
    }

    return (
        <div>
            <div className="col-12">
                <Card>
                    <CardHeader title="Location Information">
                        <CardHeaderToolbar>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <Formik
                            initialValues={locationInformation}
                            onSubmit={(values) =>{
                                console.log(values)
                            }}
                        >
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="Company"
                                            name="companyName"
                                            type="text"
                                            // value={personalInformation.personalFullLegalName}
                                            disabled
                                        />
                                        <MyTextInput
                                            label="Name"
                                            name="locationName"
                                            type="text"
                                            // value={personalInformation.personalFullLegalName}
                                            disabled
                                        />
                                        <MyTextInput
                                            label="Address Line 1"
                                            name="locationAddress1"
                                            type="text"
                                            // value={personalInformation.personalSocialSecurity}
                                            disabled
                                        />
                                        <MyTextInput
                                            label="Address Line 2"
                                            name="locationAddress2"
                                            type="text"
                                            // value={personalInformation.personalSocialSecurity}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="City"
                                            name="locationCity"
                                            type="text"
                                            // value={personalInformation.personalDriversLicense}
                                            disabled
                                        />
                                        <MyTextInput
                                            label="Zipcode"
                                            name="locationZipcode"
                                            type="text"
                                            // value={personalInformation.personalPhysicalAddress}
                                            disabled
                                        />
                                        <MyTextInput
                                            label="Numer de Catastro"
                                            name="locationCatastro"
                                            type="text"
                                            // value={personalInformation.personalPhysicalCity}
                                            disabled
                                        />
                                        <MyTextInput
                                            label="Created Date"
                                            name="submissionDate"
                                            type="text"
                                            // value={personalInformation.personalPhysicalZipcode}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </div>
            <div className="col-12">
                <Card>
                    <CardHeader title={"Location Applications"} >
                        <CardHeaderToolbar>
                            {/* <button type="button" className="btn btn-primary" onClick={console.log("Que la Que!!")}>
                                Apply to Supplier
                            </button> */}
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        {/* begin::Table */}
                        <div className="table-responsive">
                            <table
                            className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                            id="kt_advance_table_widget_1"
                            >
                            <thead>
                                <tr className="text-left">
                                <th className="pr-0" style={{ minWidth: "150px" }}>
                                    Application #
                                </th>
                                <th style={{ minWidth: "150px" }}>Company</th>
                                <th style={{ minWidth: "150px" }}>Supplier</th>
                                <th style={{ minWidth: "150px" }}>Client Type</th>
                                <th style={{ minWidth: "150px" }}>Submission Date</th>
                                {/* <th style={{ minWidth: "150px" }}>Contact</th> */}
                                <th style={{ minWidth: "150px" }}>Status</th>
                                {/* <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                                    Action
                                </th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {applications.map((application, index) => (
                            <tr key={`${application._id}-${index}`}>
                                <td className="pl-0 py-8">
                                <a
                                    // onClick={() => getCommonAppFromApplication(application.companyId)}
                                    href="#"
                                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >
                                    {"#" + application.applicationNumber}
                                </a>
                                </td>
                                <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {application.companyName}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {application.companyAddress}
                                </span>
                                </td>
                                <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {application.supplierName}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {application.supplierEmail}
                                </span>
                                </td>
                                <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {(typeof(application.clientId) !== 'undefined') ? 'Existing' : 'New'}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {(typeof(application.clientId) !== 'undefined') ? application.clientId : null}
                                </span>
                                </td>
                                <td>
                                <a
                                    href="#"
                                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >
                                    {application.submissionDate}
                                </a>
                                </td>
                                {/* <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {application.contactName}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {application.contactPhone}
                                </span>
                                </td> */}
                                <td>
                                {getStatusLabel(application.status)} 
                                </td>
                                
                            </tr>
                            ))}
                        </tbody>
                            
                        </table>
                        </div>
                        {/* end::Table */}
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}