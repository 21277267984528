import React, { useState, useEffect } from "react";

export function SequenceIndexFormatter(cellContent, row, rowIndex, { rearrangeClientsList, setRearrangeClientsList }){

    const currClientIndexObject = rearrangeClientsList.find(curr => curr.clientId == row._id)

    const handleSequenceIndexChange = (productId, event) => {
        const foundClientIndex = rearrangeClientsList.findIndex(item => item.clientId == row._id)

        const parsedSequenceIndex = parseInt(event.target.value)

        if(isNaN(parsedSequenceIndex)){
            const newRearrageClientsList = rearrangeClientsList.filter(curr => curr.clientId != row._id)
            setRearrangeClientsList(newRearrageClientsList)

        } else{
            let newClientIndexObject = {
                clientId: row._id,
                sequenceIndex: parsedSequenceIndex
            }
    
            if(foundClientIndex >= 0){
                let newRearrageClientsList = [...rearrangeClientsList]
                newRearrageClientsList[foundClientIndex] = newClientIndexObject
                setRearrangeClientsList(newRearrageClientsList)
    
            }  else{
                let newRearrageClientsList = [...rearrangeClientsList, newClientIndexObject]
                setRearrangeClientsList(newRearrageClientsList)
    
            }
        }
    }

    return (
        <>
            <input 
                id={row._id + "-pl"} 
                key={row._id + "-pl"} 
                type="number" 
                value={ currClientIndexObject ? currClientIndexObject.sequenceIndex : "" }
                onChange={(event) => handleSequenceIndexChange(row._id, event)}
            />
        </>
    )
}