import React from "react";

export function LocationsCountFormatter(cellContent,row){

    return(
        <>
            <span className="text-muted font-weight-bold">
                {`${row.locations.length} Locations`}
            </span>
        </>
    )
}