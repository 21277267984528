import React from "react";
import {Link} from "react-router-dom";


export function UserInfoColumn(cellContent, row) {
    return (
        <>
            <Link to={'/userAdmin/' + row._id}>
                <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">
                    {row.fullName}
                </span>
            </Link>
        </>
    );
}

export function UserActiveColumn(cellContent, row) {
    return (
        <>
            {row.active ?
                <span className=" mb-1 font-size-md">
                    Yes
                </span> :
                <span className="  mb-1 font-size-md">
                    No
                </span>
            }
        </>
    );
}
