import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    postLocations
} from '../CommonApplication/CommonApplicationCrud'
import {injectIntl} from "react-intl";
import Grid from '@material-ui/core/Grid';
import {useFormik} from "formik";
// import {makeStyles} from '@material-ui/core/styles';
import * as Yup from "yup";
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import FormControl from "@material-ui/core/FormControl";
import {RegionDropdown} from "react-country-region-selector";
import { getLocationsByCompanyIdList } from './../../../../redux/actions/locations';

// const useStyles = makeStyles(theme => ({
//     root: {
//         width: '90%',
//     },
//     paper: {
//         padding: theme.spacing(2, 2),
//         // height: 140,
//         // width: 300,
//     },
//     modalPaper: {
//         position: 'absolute',
//         // width: 400,
//         backgroundColor: theme.palette.background.paper,
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(4),
//         outline: 'none',
//     },
//     button: {
//         marginRight: theme.spacing(1),
//     },
//     backButton: {
//         marginRight: theme.spacing(1),
//     },
//     completed: {
//         display: 'inline-block',
//     },
//     instructions: {
//         marginTop: theme.spacing(1),
//         marginBottom: theme.spacing(1),
//     },
// }));

function PhoneMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function CatastroMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function EINMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function RegistroComercianteMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function ZipcodeMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function focusInputRef(inputRef){
  // console.log("Input Ref: ", inputRef)
  inputRef.target.setSelectionRange(0, 1);
}

PhoneMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
CatastroMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
EINMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
RegistroComercianteMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
ZipcodeMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const newLocationInitialValues = {
    locationAddress1: "",
    locationAddress2: "",
    locationCity: "",
    locationZipcode: "",
    locationCatastro: "",
}

function AddCompanyPage(props) {

    const {intl} = props;
    const {handleAddCompanyClose, userId, accessToken, companyId, addLocationsToComapnyId} = props

    const [locationsList, setLocationsList] = useState([])
    const [country] = useState('Puerto Rico');
    const dispatch = useDispatch();

    const { companies } = useSelector(state => state.companyReducer);

    const LocationInformationSchema = Yup.object().shape({
      locationName: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(39, "Maximum 39 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
      locationAddress1: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols")
        .required(
                intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
      ),
      locationAddress2: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols"),
        // .required(
        //         intl.formatMessage({
        //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
        //         })
      // ),
    locationCity: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(20, "Maximum 20 symbols")
      .required(
                intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
      ),
    locationZipcode: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(12, "Maximum 12 symbols")
      .required(
                intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
      ),
    locationCatastro: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(30, "Maximum 30 symbols"),
  });

  const addLocationFormik = useFormik({
    initialValues: newLocationInitialValues,
    validationSchema: LocationInformationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      let location = {}
      if (addLocationsToComapnyId !== "") {
        location = {
          companyId: addLocationsToComapnyId,
          ...values
        }
      } else {
        location = {
          companyId,
          ...values
        }
      }

      // setLocationsList(locationsList => locationsList.concat(location))
      resetForm(newLocationInitialValues)

      const locations = {
        locations: [location]
      }
      
      postLocations(locations,accessToken).then(response => {
        console.log(response)
        const companyIdList = companies.map(item => {
            console.log(item)
            return item._id
          });
          dispatch(getLocationsByCompanyIdList({ companyIdList }));

          handleAddCompanyClose()
            
      }).catch(err => console.error(err));

      setTimeout(() => {
        // alert("Your common application has been submited.");
        setSubmitting(false);
      }, 400);
    },
  });

  const handleSaveLocations = () => {

    const locations = {
      locations: locationsList
    }

    postLocations(locations,accessToken).then(response => {
      
        const companyIdList = companies.map(item => {
          
            return item._id
          });
          
          dispatch(getLocationsByCompanyIdList({ companyIdList }));

          handleAddCompanyClose()
          
    }).catch(err => console.error(err));

}

    return (
        <div>
            {/* begin::Table */}
            <div className="table-responsive mb-10">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">Add Location</span>
                        {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">20+ new applications</span> */}
                    </h3>
                {/* <table
                    className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                    id="kt_advance_table_widget_1"
                >
                    <thead>
                    <tr className="text-left">
                        <th className="pr-0" style={{ minWidth: "150px" }}>
                        Name
                        </th>
                        <th style={{ minWidth: "150px" }}>Address 1</th>
                        <th style={{ minWidth: "150px" }}>Address 2</th>
                        <th style={{ minWidth: "150px" }}>City</th>

                        <th style={{ minWidth: "150px" }}>Zipcode</th>
                        <th style={{ minWidth: "150px" }}>Numero de Catastro</th>
                        <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                        Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {locationsList.map((location, index) => (
                            <tr key={`${location.locationCity}-${index}`}>
                            <td className="pl-0 py-2">
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {location.locationName}
                                </span>
                            </td>
                            <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {location.locationAddress1}
                                </span>
                            </td>
                            <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {location.locationAddress2}
                                </span>
                            </td>
                            <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {location.locationCity}
                                </span>
                            </td>
                            <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {location.locationZipcode}
                                </span>
                            </td>
                            <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {location.locationCatastro}
                                </span>
                            </td>

                            </tr>

                        ))}
                    </tbody>
                </table> */}
            </div>
            {/* end::Table */}
            {/* <Form> */}
            <div>
                <form
                  id="kt_login_signin_form"
                  className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                  onSubmit={addLocationFormik.handleSubmit}
                >
                  {/* begin: Alert */}
                  {addLocationFormik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{addLocationFormik.status}</div>
                    </div>
                  )}
                  {/* end: Alert */}
                  <div className="" style={{ display: "block" }}>


                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        {/* begin: Email */}
                          <div className="form-group fv-plugins-icon-container">
                            <label htmlFor={"locationName"}>Name</label>
                            <Input
                                placeholder="Name"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="locationName"
                                {...addLocationFormik.getFieldProps("locationName")}
                            />
                            {addLocationFormik.touched.locationName && addLocationFormik.errors.locationName ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{addLocationFormik.errors.locationName}</div>
                                </div>
                            ) : null}
                          </div>
                          <div className="form-group fv-plugins-icon-container">
                            <label htmlFor={"locationAddress1"}>Address Line 1</label>
                            <Input
                                placeholder="Address"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="locationAddress1"
                                {...addLocationFormik.getFieldProps("locationAddress1")}
                            />
                            {addLocationFormik.touched.locationAddress1 && addLocationFormik.errors.locationAddress1 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{addLocationFormik.errors.locationAddress1}</div>
                                </div>
                            ) : null}
                          </div>
                          <div className="form-group fv-plugins-icon-container">
                            <label htmlFor={"locationAddress2"}>Address Line 2</label>
                            <Input
                                placeholder="Address"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="locationAddress2"
                                {...addLocationFormik.getFieldProps("locationAddress2")}
                            />
                            {addLocationFormik.touched.locationAddress2 && addLocationFormik.errors.locationAddress2 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{addLocationFormik.errors.locationAddress2}</div>
                                </div>
                            ) : null}
                          </div>

                    </Grid>
                    <Grid item xs={6}>
                      {/* begin: Email */}
                      <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationCity"}>City</label>
                                        <FormControl variant="filled" style={{ width: "100%" }}>
                                            <RegionDropdown
                                            name="locationCity"
                                            classes={`form-control form-control-solid h-auto py-5 px-6`}
                                            country={country}
                                            value={addLocationFormik.values.locationCity}
                                            onChange={(_, e) => addLocationFormik.handleChange(e)}
                                            onBlur={addLocationFormik.handleBlur}
                                            defaultOptionLabel = {addLocationFormik.values.locationCity}
                                            />
                                            {addLocationFormik.touched.locationCity && addLocationFormik.errors.locationCity ? (
                                                <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{addLocationFormik.errors.locationCity}</div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                        </div>
                      <div className="form-group fv-plugins-icon-container">
                            <label htmlFor={"locationZipcode"}>Zipcode</label>
                            <Input
                                placeholder="00123"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="locationZipcode"
                                inputComponent={ZipcodeMaskCustom}
                                {...addLocationFormik.getFieldProps("locationZipcode")}
                            />
                            {addLocationFormik.touched.locationZipcode && addLocationFormik.errors.locationZipcode ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{addLocationFormik.errors.locationZipcode}</div>
                                </div>
                            ) : null}
                          </div>
                        {/* end: Email */}
                        {/* begin: Email */}
                        <div className="form-group fv-plugins-icon-container">
                            <label htmlFor={"locationCatastro"}>Numero de Catastro</label>
                            <Input
                                placeholder="###-###-##-##"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="locationCatastro"
                                inputComponent={CatastroMaskCustom}
                                {...addLocationFormik.getFieldProps("locationCatastro")}
                            />
                            {addLocationFormik.touched.locationCatastro && addLocationFormik.errors.locationCatastro ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{addLocationFormik.errors.locationCatastro}</div>
                                </div>
                            ) : null}
                          </div>
                        {/* end: Email */}


                      </Grid>
                      <div className="form-group d-flex flex-wrap flex-center">
                          <button
                          id="addLocationButton"
                          type="submit"
                          // disabled={formik.isSubmitting}
                          className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                          >
                              <span>Submit Location</span>
                          </button>

                {/* <button
                  type="button"
                  onClick={() => handleSaveLocations()}
                  disabled={formik.isSubmitting}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <span>Submit Locations</span>
                </button> */}
              </div>
            </Grid>
          </div>
        </form>
      </div>
      {/* </Form> */}
    </div>
  )

}

export default injectIntl(AddCompanyPage);

