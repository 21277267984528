import React from 'react';
import { useField } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}

function CatastroMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[ /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/ ]}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}

function EINMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[ /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}

function RegistroComercianteMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ]}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}

function ZipcodeMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[ /\d/, /\d/, /\d/, /\d/, /\d/ ]}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}

PhoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
CatastroMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
EINMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
RegistroComercianteMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
ZipcodeMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className="form-group fv-plugins-icon-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input className={`form-control form-control-solid h-auto py-5 px-6`}
            {...field} {...props} />
        {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
            </div>
        ) : null}
    </div>
  );
};

export const MyPhoneInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group fv-plugins-icon-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input
          className={`form-control form-control-solid h-auto py-5 px-6`}
          inputComponent={PhoneMaskCustom}
          {...field} {...props} 
        />
        {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
            </div>
        ) : null}
    </div>
  )
}

export const MyCatastroInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group fv-plugins-icon-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input
          className={`form-control form-control-solid h-auto py-5 px-6`}
          inputComponent={CatastroMaskCustom}
          {...field} {...props} 
        />
        {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
            </div>
        ) : null}
    </div>
  )
}

export const MyEINInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group fv-plugins-icon-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input
          className={`form-control form-control-solid h-auto py-5 px-6`}
          inputComponent={EINMaskCustom}
          {...field} {...props} 
        />
        {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
            </div>
        ) : null}
    </div>
  )
}

export const MyRegistroComercianteInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group fv-plugins-icon-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input
          className={`form-control form-control-solid h-auto py-5 px-6`}
          inputComponent={RegistroComercianteMaskCustom}
          {...field} {...props} 
        />
        {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
            </div>
        ) : null}
    </div>
  )
}

export const MyZipcodeInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group fv-plugins-icon-container">
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input
          className={`form-control form-control-solid h-auto py-5 px-6`}
          inputComponent={ZipcodeMaskCustom}
          {...field} {...props} 
        />
        {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
            </div>
        ) : null}
    </div>
  )
}

export const YesNoSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group fv-plugins-icon-container">
      <label htmlFor={props.id || props.name}>{label}</label>
        <FormControl variant="filled" style={{ width: "100%" }}>
          <Select
            input={<FilledInput 
            className={`form-control form-control-solid h-auto`}
            {...field} {...props}
            />}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          {meta.touched && meta.error ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{meta.error}</div>
              </div>
            ) : null}
        </FormControl>
      </div>
    </>
  );
};

export const BusinessTypeSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group fv-plugins-icon-container">
        <FormControl variant="filled" style={{ width: "100%" }}>
          <InputLabel >{label}</InputLabel>
          <Select
            input={<FilledInput
            className={`form-control form-control-solid h-auto`}
            {...field} {...props}
            />}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Restaurant"}>Restaurant</MenuItem>
            <MenuItem value={"Gas Station"}>Gas Station</MenuItem>
            <MenuItem value={"Pharmacy"}>Pharmacy</MenuItem>
            <MenuItem value={"Market"}>Market</MenuItem>
            <MenuItem value={"Bar"}>Bar</MenuItem>
          </Select>
          {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{meta.error}</div>
            </div>
          ) : null}
        </FormControl>
      </div>
    </>
  );
};

export const ShipViaSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group fv-plugins-icon-container">
        <FormControl variant="filled" style={{ width: "100%" }}>
          <InputLabel >{label}</InputLabel>
          <Select
            input={<FilledInput
            className={`form-control form-control-solid h-auto`}
            {...field} {...props}
            />}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Cust. Pickup"}>Cust. Pickup</MenuItem>
            <MenuItem value={"Bio-Ware Truck"}>Bio-Ware Truck</MenuItem>
          </Select>
          {meta.touched && meta.error ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{meta.error}</div>
            </div>
          ) : null}
        </FormControl>
      </div>
    </>
  );
};
