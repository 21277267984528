import React from "react";
import PersonalInformation from './PersonalInformation'
import BusinessInformation from './BusinessInformation'
import PartnerInformation from './PartnerInformation'
import BankReferenceInformation from './BankReferenceInformation'
import CommercialReferenceInformation from './CommercialReferenceInformation'
import AdditionalInformation from './AdditionalInformation'
import ApplicationsInformation from './ApplicationsInformation'
import DocumentsList from "./DocumentsList"

export default function ApplicationSummary({ commonApp, companyApplications }){
    console.log(commonApp)
    return (
        <>
            <div className="row">
                <BusinessInformation businessInformation={commonApp.businessInformation}/>
            </div>
            {typeof(commonApp.personalInformation) !== 'undefined' ?
                <div className="row">
                    <PersonalInformation personalInformation={commonApp.personalInformation}/>
                </div>
                : 
                null
            }
            { typeof(commonApp.partnerInformation) !== 'undefined' ?
                <div className="row">
                    <PartnerInformation partnerInformation={commonApp.partnerInformation}/>
                </div>
                :
                null
            }
            {typeof(commonApp.bankReferenceInformation) !== 'undefined' ?
                <div className="row">
                    <BankReferenceInformation bankReferenceInformation={commonApp.bankReferenceInformation}/>
                </div>
                :
                null
            }
            {typeof(commonApp.commercialReferenceInformation) !== 'undefined' ?
                <div className="row">
                    <CommercialReferenceInformation commercialReferenceInformation={commonApp.commercialReferenceInformation}/>
                </div>
                :
                null
            }
            {typeof(commonApp.additionalInformation) !== 'undefined' ?
                <div className="row">
                    <AdditionalInformation additionalInformation={commonApp.additionalInformation}/>
                </div>
                :
                null
            }
            {typeof(commonApp.documents) !== 'undefined' ?
                <div className="row">
                    <DocumentsList documents={commonApp.documents} />
                </div>
                :
                null
            }
            {typeof(companyApplications) !== 'undefined' ?
                <div className="row">
                    <ApplicationsInformation commonApp={commonApp} companyApplications={companyApplications}/>
                </div>
                :
                null
            }
        </>
    )
}