import React, { useState, useEffect } from "react";
import {
    MixedWidget1,
    MixedWidget14,
    ListsWidget9,
    StatsWidget11,
    StatsWidget12,
    ListsWidget1,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    ListsWidget3,
    ListsWidget4,
    ListsWidget8
} from "../../../../_metronic/_partials/widgets";
import {DashboardInfo} from "./DashboardInfo";
import {PendingApplications} from "./PendingApplications";
import ClientLocations from "./ClientLocations";
import {PendingClients} from "./PendingClients";
import {ListOfContacts} from "./ListOfContacts";
import {ClientsTableComponent} from "../ClientsTable/ClientsTableComponent";
import {ClientsTable} from "../Clients/ClientsTable";
import {useSelector} from "react-redux";
import { getSupplierByTenant } from "../SuppliersCrud"

export function SupplierDashboard() {

    const [supplier, setSupplier] = useState({})
    const [isSupplierFetched, setIsSupplierFetched] = useState(false)
    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const fetchSupplier = async () => {
        await getSupplierByTenant(accessToken).then((response) => {
            console.log("Supplier: ",response.data)
            setSupplier(response.data)
            setIsSupplierFetched(true)
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() =>{
        fetchSupplier()
    },[])

    return (<>
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <div className="row justify-content-center px-8 py-md-10 px-md-0">
                    <div className="col-md-10">
                        <div className="col-lg bg-light-primary px-6 py-4 rounded-xl mr-7">
                            {/* <div className="text-center mb-4">
                                <span className="card-label font-weight-bolder text-dark text-center font-size-lg"></span>
                            </div> */}
                            {isSupplierFetched ? 
                                <div>
                                    <div className="text-center mb-4">
                                        <span className="card-label font-weight-bolder text-dark text-center font-size-lg">{`Welcome ${user.name}`}</span>
                                    </div>
                                    <div className="text-center">
                                        <span className="text-dark-75 mt-3 font-weight-bold font-size-lg">{`From ${supplier.entityName}`}</span>
                                    </div >
                                </div>
                                :
                                null

                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-xxl-12">
                <DashboardInfo className="card-stretch gutter-b"/>
            </div>
            {/*<div className="col-lg-6 col-xxl-4">
                <ListsWidget9 className="card-stretch gutter-b"/>
            </div> */}
            {/* <div className="col-lg-12 col-xxl-12">
                <PendingApplications className="card-stretch gutter-b"/>
            </div> */}

            {/*<div className="col-lg-6 col-xxl-4">*/}
            {/*    /!*<AllOrders className="card-stretch card-stretch-half gutter-b"/>*!/*/}
            {/*    <StatsWidget12 className="card-stretch card-stretch-half gutter-b"/>*/}
            {/*</div>*/}

            {/*<div className="col-12">*/}
            {/*    <ClientLocations className="card-stretch gutter-b"/>*/}
            {/*</div>*/}
            {/* <div className="col-12">
                <ListOfContacts className="card-stretch gutter-b"/>
            </div> */}
        </div>
        <div className="row">
            <div className="col-12">
                <ClientsTable className="card-stretch gutter-b"/>
            </div>
        </div>
    </>);
}
