import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import {logout} from "../_redux/authCrud"

class Logout extends Component {



  componentDidMount() {



    setTimeout(function() {

      logout(this.props.token).then(response => {
        // console.log(response);
      })


      localStorage.setItem("loggingOut", true);


      let queryParam = '?redirectUrl=' + process.env.REACT_APP_URL
      window.location.href = process.env.REACT_APP_OAUTH_LOGOUT + queryParam;


      // this.props.logout();
    }.bind(this), 5000)
  }

  render() {
    const { hasAuthToken } = this.props;
    return(
        <>
          {hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />}
          {/*<iframe style={{display: "none"}} src={process.env.REACT_APP_OAUTH_LOGOUT}></iframe>*/}
        </>
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken), token: auth.authToken }),
  auth.actions
)(Logout);
