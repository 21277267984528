import React, { useState, useMemo } from "react";
import { postLocations } from '../CommonApplication/CommonApplicationCrud'

import { injectIntl } from "react-intl";
import Grid from '@material-ui/core/Grid';
import { useFormik } from "formik";
// import { makeStyles } from '@material-ui/core/styles';
import * as Yup from "yup";
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select,
    ListSubheader,
    TextField,
    InputAdornment} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {RegionDropdown} from "react-country-region-selector";
import SearchIcon from "@material-ui/icons/Search";

// const useStyles = makeStyles(theme => ({
//     root: {
//       width: '90%',
//     },
//     paper: {
//       padding: theme.spacing(2, 2),
//       // height: 140,
//       // width: 300,
//     },
//     modalPaper: {
//       position: 'absolute',
//       // width: 400,
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(4),
//       outline: 'none',
//     },
//     button: {
//       marginRight: theme.spacing(1),
//     },
//     backButton: {
//       marginRight: theme.spacing(1),
//     },
//     completed: {
//       display: 'inline-block',
//     },
//     instructions: {
//       marginTop: theme.spacing(1),
//       marginBottom: theme.spacing(1),
//     },
//   }));

  function PhoneMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  function CatastroMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  function EINMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  function RegistroComercianteMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
  function ZipcodeMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
PhoneMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
CatastroMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
EINMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
RegistroComercianteMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
ZipcodeMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};

function AddLocationsPage(props) {

    const { intl } = props;
    // const classes = useStyles();
    const { userId, accessToken, companies, setIsLocationsPage } = props

    const [locationsList, setLocationsList] = useState([])
    const [company, setCompany] = useState({ _id : '' })
    const [open, setOpen] = React.useState(false);
    const [country] = useState('Puerto Rico');
    // const [tenantWarehouses, setTenantWarehouses] = useState([])
    const [warehouse, setWarehouse] = useState({ id : '' })

    const [selectedOption, setSelectedOption] = useState();
    const [searchText, setSearchText] = useState("");

    const containsText = (text, searchText) => {
        // console.log(companies)
        if(text){
            return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }
        
    }

    const displayedOptions = useMemo(
        () => companies.filter((company) => containsText(company.businessName, searchText)),
        [searchText]
    );

    

    const newLocationInitialValues = {
      locationName: '',
      locationAddress1: '',
      locationAddress2: '',
      locationCity: '',
      locationZipcode: '',
      locationCatastro: '',
  }

    // useEffect(() => {
    //   const fetchTenantWarehouses = async () => {
    //     const data = await getTenantWarehouses()

    //     console.log("Got tenant warehouses: ", data.data)
    //     setTenantWarehouses(data.data)
    //   }

    //   fetchTenantWarehouses()
    // }, [  ])

    const LocationInformationSchema = Yup.object().shape({
        locationName: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(39, "Maximum 39 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationAddress1: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationAddress2: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationCity: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationZipcode: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationCatastro: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(30, "Maximum 30 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
      });



    const addLocationFormik = useFormik({
        initialValues: newLocationInitialValues,
        validationSchema: LocationInformationSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm  }) => {
            let location = {}
            if(company._id !== ''){
              if(warehouse !== ''){
                location = {
                  companyId: company._id,
                  ...values,
                  tenantWarehouseId: warehouse.id
                }
              } else {
                location = {
                  companyId: company._id,
                  ...values,
                }
              }
                

                setLocationsList(locationsList => locationsList.concat(location))
                setWarehouse({ id : '' })
                resetForm(newLocationInitialValues)

            } else {
                handleClickOpen()
            }
  
            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    // const handleWarehouseChange = (element) => {
    //   const selectedWarehouse = tenantWarehouses.find(warehouse => warehouse.id === element.target.value)
    //   const locationsWarehouse = locationsList.find(location => location.tenantWarehouseId === selectedWarehouse.id)
    //   if(locationsWarehouse){

    //   } else{
    //     setWarehouse(selectedWarehouse)
    //   }
    // }

    const handleSaveLocations = () => {

        const locations = {
            locations : locationsList
        }

        postLocations(locations, accessToken).then((response) => {
            setIsLocationsPage(false)
        }).catch(error => console.error(error));

        // handleAddCompanyClose()
        console.log(locations)
    }
    
    return (
        <div>
            <Card>
                  <CardHeader title="Add Locations">
                      <CardHeaderToolbar>
                          {/* <button type="button" className="btn btn-primary" onClick={()=>{ setIsLocationsPage(true) }} >
                              Create New Location
                          </button> */}
                        <FormControl variant="filled" style={{width: "100%", marginBottom: 5}}>
                            <InputLabel>Company</InputLabel>
                            <Select
                                MenuProps={{ autoFocus: false }}
                                value={company._id}
                                style={{minWidth:"200px"}}
                                input={<FilledInput name="company" id="company"
                                onChange={(element) =>{
                                    setCompany(companies.find(company => company._id === element.target.value))
                                }}
                                onClose={() => setSearchText("")}
                                type="text"
                                className="form-control form-control-solid h-auto"
                            />}>
                                <ListSubheader>
                                    <TextField
                                    size="small"
                                    // Autofocus on textfield
                                    autoFocus
                                    placeholder="Type to search..."
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                        }
                                    }}
                                    />
                                </ListSubheader>
                                {displayedOptions.map((companyItem,index) =>(
                                    <MenuItem key={companyItem._id} selected={index === 0 } value={companyItem._id}>{companyItem.businessName}</MenuItem>
                                ))

                                }

                            </Select>
                        </FormControl>
                        {/* <FormControl variant="filled" style={{width: "100%"}}>
                            <InputLabel>Tenant Warehouse</InputLabel>
                            <Select
                                value={warehouse.id}
                                style={{minWidth:"200px"}}
                                input={<FilledInput name="tenantWarehouses" id="tenantWarehouses"
                                onChange={(element) => handleWarehouseChange(element)}
                                type="text"
                                className="form-control form-control-solid h-auto"
                            />}>

                                {tenantWarehouses.map((warehouseItem,index) =>(
                                    <MenuItem key={warehouseItem.id} selected={index === 0 } value={warehouseItem.id}>{warehouseItem.name}</MenuItem>
                                ))

                                }

                            </Select>
                        </FormControl> */}
                      </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody>
                  <div>
                        {/* begin::Table */}
                        <div className="table-responsive mb-10">
                            <table
                                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                id="kt_advance_table_widget_1"
                            >
                                <thead>
                                <tr className="text-left">
                                    <th className="pr-0" style={{ minWidth: "150px" }}>
                                    City
                                    </th>
                                    <th style={{ minWidth: "150px" }}>Address 1</th>
                                    <th style={{ minWidth: "150px" }}>Address 2</th>
                                    <th style={{ minWidth: "150px" }}>Zipcode</th>
                                    <th style={{ minWidth: "150px" }}>Numero de Catastro</th>
                                    {/* <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                                    Action
                                    </th> */}
                                </tr>
                                </thead>
                                <tbody>
                                    {locationsList.map((location, index) => (
                                        <tr key={`${location.locationCity}-${index}`}>
                                        <td className="pl-0 py-2">
                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                {location.locationCity}
                                            </span>
                                        </td>
                                        <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                {location.locationAddress1}
                                            </span>
                                        </td>
                                        <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                {location.locationAddress2}
                                            </span>
                                        </td>
                                        <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                {location.locationZipcode}
                                            </span>
                                        </td>
                                        <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                {location.locationCatastro}
                                            </span>
                                        </td>
                                        
                                        </tr>
                                        
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* end::Table */}
                        {/* <Form> */}
                        <div>
                            <form
                            id="kt_login_signin_form"
                            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                            onSubmit={addLocationFormik.handleSubmit}
                            >
                            {/* begin: Alert */}
                            {addLocationFormik.status && (
                                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{addLocationFormik.status}</div>
                                </div>
                            )}
                            {/* end: Alert */}
                            <div className="" style={{ display: "block" }}>
                                
            
                                <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {/* begin: Email */}
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationName"}>Name</label>
                                        <Input
                                            placeholder="Name"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="locationName"
                                            {...addLocationFormik.getFieldProps("locationName")}
                                        />
                                        {addLocationFormik.touched.locationName && addLocationFormik.errors.locationName ? (
                                            <div className="fv-plugins-message-container">
                                              <div className="fv-help-block">{addLocationFormik.errors.locationName}</div>
                                            </div>
                                        ) : null}
                                      </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationAddress1"}>Address Line 1</label>
                                        <Input
                                            placeholder="Address"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="locationAddress1"
                                            {...addLocationFormik.getFieldProps("locationAddress1")}
                                        />
                                        {addLocationFormik.touched.locationAddress1 && addLocationFormik.errors.locationAddress1 ? (
                                            <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{addLocationFormik.errors.locationAddress1}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationAddress2"}>Address Line 2</label>
                                        <Input
                                            placeholder="Address"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="locationAddress2"
                                            {...addLocationFormik.getFieldProps("locationAddress2")}
                                        />
                                        {addLocationFormik.touched.locationAddress2 && addLocationFormik.errors.locationAddress2 ? (
                                            <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{addLocationFormik.errors.locationAddress2}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    
                                </Grid>    
                                <Grid item xs={6}>
                                {/* begin: Email */}
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationCity"}>City</label>
                                        <FormControl variant="filled" style={{ width: "100%" }}>
                                            <RegionDropdown
                                            name="locationCity"
                                            classes={`form-control form-control-solid h-auto py-5 px-6`}
                                            country={country}
                                            value={addLocationFormik.values.locationCity}
                                            onChange={(_, e) => addLocationFormik.handleChange(e)} 
                                            onBlur={addLocationFormik.handleBlur}
                                            defaultOptionLabel = {addLocationFormik.values.locationCity}
                                            />
                                            {addLocationFormik.touched.locationCity && addLocationFormik.errors.locationCity ? (
                                                <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{addLocationFormik.errors.locationCity}</div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationZipcode"}>Zipcode</label>
                                        <Input
                                            placeholder="00123"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="locationZipcode"
                                            inputComponent={ZipcodeMaskCustom}
                                            {...addLocationFormik.getFieldProps("locationZipcode")}
                                        />
                                        {addLocationFormik.touched.locationZipcode && addLocationFormik.errors.locationZipcode ? (
                                            <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{addLocationFormik.errors.locationZipcode}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Email */}
                                    {/* begin: Email */}
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationCatastro"}>Numero de Catastro</label>
                                        <Input
                                            placeholder="###-###-##-##"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="locationCatastro"
                                            inputComponent={CatastroMaskCustom}
                                            {...addLocationFormik.getFieldProps("locationCatastro")}
                                        />
                                        {addLocationFormik.touched.locationCatastro && addLocationFormik.errors.locationCatastro ? (
                                            <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{addLocationFormik.errors.locationCatastro}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* end: Email */}
                                    
                                
                                </Grid>  
                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                    id="addLocationButton"
                                    type="submit"
                                    // disabled={formik.isSubmitting}
                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                    >
                                        <span>Add Ship to Location</span>
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => handleSaveLocations()}
                                        // disabled={formik.isSubmitting}
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Submit Locations</span>
                                    </button>
                                </div>
                                </Grid>
                            </div>
                            </form>
                        </div>
                        {/* </Form> */}
                    </div>
                  </CardBody>
              </Card>
              <div>
              {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                  Open alert dialog
              </Button> */}
              <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">{"Choose Company"}</DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Please choose a company to add a location to.
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                      OK
                  </Button>
                  </DialogActions>
              </Dialog>
          </div>
          </div>
        
    )

}

export default injectIntl(AddLocationsPage);

