
import React, {useState} from "react";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {Card,CardBody,CardHeader,CardHeaderToolbar} from "../../../../_metronic/_partials/controls"
import * as columnFormatters from "./column-formatters";
import {useSelector} from "react-redux";
import axios from "axios";


export function ApplicationsTable() {

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 5
    }

    const [data,setData] = useState(initialData);



    const fetchApplications = (queryParams) => {
        // Authorization head should be fulfilled in interceptor.
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }
        axios.get(process.env.REACT_APP_API_URL + '/suppliers/applications',options).then(response =>{
            
            setData(response.data)
        });
    }


    const columns = [
        {
            dataField: "companyName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CompanyColumnFormatter,
        },
        {
            dataField: "applicationNumber",
            text: "Application Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ApplicationNumberColumnFormatter
        },
        {
            dataField: "submissionDate",
            text: "Submission Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.SubmissionDateColumnFormatter
        },
        {
            dataField: "contactName",
            text: "Contact",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ContactColumnFormatter,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.StatusColumnFormatter,
        },
    ];

    const sizePerPageList = [
        { text: "3", value: 3 },
        { text: "5", value: 5 },
        { text: "10", value: 10 }
    ]

    const initialFilter = {
        filter: {
            companyName:"",
            applicationNumber: "",
            submissionDate:"",
            contactName:"",
            status:""
        },
        sortOrder: "asc",
        sortField: "applicationNumber",
        pageNumber: 1,
        pageSize: 5
    }

    const sorting = [{dataField: "applicationNumber",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center table-padding"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.companyName = searchText ?? "";
        filter.applicationNumber = searchText ?? "";
        filter.submissionDate = searchText ?? "";
        filter.contactName = searchText ?? "";
        filter.status = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            <Card>
                <CardHeader title="Applications">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-primary" onClick={console.log("filtrando")}>
                            Filter
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        tableClasses={tableClasses}
                        fetcher={fetchApplications}
                        filterFunction={prepareFilter}
                        initialFilter={initialFilter}/>
                </CardBody>
            </Card>
        </>
    );
}