import React from "react";
import PersonalInformation from './PersonalInformation'
import BusinessInformation from './BusinessInformation'
import PartnerInformation from './PartnerInformation'
import BankReferenceInformation from './BankReferenceInformation'
import CommercialReferenceInformation from './CommercialReferenceInformation'
import AdditionalInformation from './AdditionalInformation'
import DocumentsList from "./DocumentsList"
import ApplicationLocations from "./ApplicationLocations"

export default function ApplicationSummary({ commonApp, applicationLocations, locationsCategories }){

    return (
        <>
            <div className="row">
                <BusinessInformation businessInformation={commonApp.businessInformation}/>
            </div>
            <div className="row">
                <ApplicationLocations applicationLocations={applicationLocations} locationsCategories={locationsCategories} />
            </div>
            <div className="row">
                <PersonalInformation personalInformation={commonApp.personalInformation}/>
            </div>
            { typeof(commonApp.partnerInformation) !== 'undefined' ?
                <div className="row">
                    <PartnerInformation partnerInformation={commonApp.partnerInformation}/>
                </div>
                :
                null
            }
            <div className="row">
                <BankReferenceInformation bankReferenceInformation={commonApp.bankReferenceInformation}/>
            </div>
            <div className="row">
                <CommercialReferenceInformation commercialReferenceInformation={commonApp.commercialReferenceInformation}/>
            </div>
            <div className="row">
                <AdditionalInformation additionalInformation={commonApp.additionalInformation}/>
            </div>
            {typeof(commonApp.documents) !== 'undefined' ?
                <div className="row">
                    <DocumentsList documents={commonApp.documents} />
                </div>
                :
                null
            }
        </>
    )
}