import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React, { Fragment } from "react";
import {MyTextInput} from "../../CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function PartnerInformation({partnerInformation}) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Partner Information">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>
                            Expand
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                {partnerInformation.map(partnerInfo => 
                    <div>
                        <CardBody>
                            <Formik
                                initialValues={partnerInfo}
                                onSubmit={(values) =>{
                                    console.log(values)
                                }}
                            >
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Full Legal Name"
                                                name="partnerFullLegalName"
                                                type="text"
                                                value={partnerInfo.partnerFullLegalName}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Social Security"
                                                name="partnerSocialSecurity"
                                                type="text"
                                                value={partnerInfo.partnerSocialSecurity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Drivers License"
                                                name="partnerDriversLicense"
                                                type="text"
                                                value={partnerInfo.partnerDriversLicense}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Birth Date"
                                                name="partnerBirthDate"
                                                type="text"
                                                value={partnerInfo.partnerBirthDate}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Phone Number"
                                                name="partnerPhone"
                                                type="text"
                                                value={partnerInfo.partnerPhone}
                                                disabled
                                            />
                                            {partnerInfo.partnerSpouseFullName !== '' ? 
                                                <Fragment>
                                                    <MyTextInput
                                                        label="Married?"
                                                        name="partnerIsMarried"
                                                        type="text"
                                                        value={"Yes"}
                                                        disabled
                                                    />
                                                    <MyTextInput
                                                        label="Spouse Full Name"
                                                        name="partnerSpouseFullName"
                                                        type="text"
                                                        value={partnerInfo.partnerSpouseFullName}
                                                        disabled
                                                    />
                                                </Fragment>
                                                :
                                                <MyTextInput
                                                    label="Married?"
                                                    name="partnerIsMarried"
                                                    type="text"
                                                    value={"No"}
                                                    disabled
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Title"
                                                name="partnerTitle                                        "
                                                type="text"
                                                value={partnerInfo.partnerTitle}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical Address"
                                                name="partnerPhysicalAddress                                        "
                                                type="text"
                                                value={partnerInfo.partnerPhysicalAddress}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical City"
                                                name="partnerPostalZipcode"
                                                type="text"
                                                value={partnerInfo.partnerPhysicalCity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical Zipcode"
                                                name="partnerPostalZipcode"
                                                type="text"
                                                value={partnerInfo.partnerPhysicalZipcode}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal Address"
                                                name="partnerPostalZipcode                                        "
                                                type="text"
                                                value={partnerInfo.partnerPostalAddress}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal City"
                                                name="partnerPostalZipcode"
                                                type="text"
                                                value={partnerInfo.partnerPostalCity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal Zipcode"
                                                name="partnerPostalZipcode"
                                                type="text"
                                                value={partnerInfo.partnerPostalZipcode}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            </Formik>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </div>
                )}
            </Card>
        </div>
        
    )
}