import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import CardUI from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
    card: {
    //   maxWidth: 250,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    header: {
      textAlign: 'center',
      paddingBottom: '8px'
    },
    textContent: {
      textAlign: 'center',
      padding: '8px'
    },
    center: {
      textAlign: 'center',
    },
    centerButton: {
      justifyContent: 'center'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));

export default function DocumentsList({ documents } ) { 
    const classes = useStyles();
    pdfjs.GlobalWorkerOptions.workerSrc =  
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null); 
    const [pageNumber, setPageNumber] = useState(1); 
    const [isDocumentClicked, setIsDocumentClicked] = useState(false)
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("")
    const [selectedDocumentExtension, setSelectedDocumentExtension] = useState("")

    const handleDocumentClicked = (documentUrl, documentName) => {
        setSelectedDocumentUrl(documentUrl)
        setSelectedDocumentExtension(documentName.split('.').pop())
        setIsDocumentClicked(true)
    }

    const handleDocumentClose = () => {
        setIsDocumentClicked(false)
        setSelectedDocumentUrl("")
    }
  
    function onDocumentLoadSuccess({ numPages }) { 
        setNumPages(numPages); 
        setPageNumber(1); 
    } 

    function changePage(offset) { 
        setPageNumber(prevPageNumber => prevPageNumber + offset); 
    } 
    
    function previousPage() { 
        changePage(-1); 
    } 
    
    function nextPage() { 
        changePage(1); 
    } 

    return (
        <div className="col-12">
            <Card>
                <CardHeader title={"Documents"} >
                    {isDocumentClicked ? 
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-light" onClick={() => handleDocumentClose()}>
                                Back
                            </button>
                        </CardHeaderToolbar>
                        :
                        null
                    }
                </CardHeader>
                <CardBody>
                {isDocumentClicked ?
                    (selectedDocumentExtension !== "pdf") ? 
                        // <div style={{display:'flex', alignItems:'center', justifyContent:'center'}} >
                        //     <img 
                        //         src={selectedDocumentUrl}
                        //         alt="new"
                        //     />
                        // </div>
                        <CardUI className={classes.card}>
        
                            <CardMedia
                            className={classes.media}
                            image={selectedDocumentUrl}
                            title="Su"
                        />
                        </CardUI>
                    :
                        <div>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <Document 
                                    file={selectedDocumentUrl} 
                                    onLoadSuccess={onDocumentLoadSuccess} 
                                    > 
                                    <Page pageNumber={pageNumber} /> 
                                </Document> 
                            </div>
                            <div > 
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}} className="mb-2"> 
                                    <span className="text-muted font-weight-bold">
                                        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'} 
                                    </span>
                                </div> 
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}> 
                                    <button 
                                        type="button"
                                        className="btn btn-primary font-weight-bolder font-size-sm mr-2"
                                        disabled={pageNumber <= 1} 
                                        onClick={() => previousPage()}
                                    >
                                        Back
                                    </button>
                                    <button 
                                        type="button"
                                        className="btn btn-primary font-weight-bolder font-size-sm ml-2"
                                        disabled={pageNumber >= numPages} 
                                        onClick={() => nextPage()}
                                    >
                                        Next
                                    </button>
                                </div> 
                            </div> 
                        </div>
                    :
                    <div className="table-responsive">
                        <table
                        className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                        id="kt_advance_table_widget_1"
                        >
                        <thead>
                            <tr className="text-left">
                                <th className="pr-0 mb-2" style={{ minWidth: "150px" }}>
                                    Document
                                </th>
                                <th style={{ minWidth: "150px" }}>Document Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((document, index) => (
                            <tr key={`${document.uploadedDocumentName}-${index}`}>
                                <td className="pr-0 py-2">
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {document.documentName}
                                    </span>
                                </td>
                                <td>
                                    <a
                                    href="javascript:void(0);"
                                    onClick={() => handleDocumentClicked(document.documentUrl, document.uploadedDocumentName)}
                                    className="text-muted font-weight-bold text-hover-primary"
                                    >
                                        {document.uploadedDocumentName}
                                    </a>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    }
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    )
}