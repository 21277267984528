
import React from "react";

export function InvoiceNumberFormatter(cellContent, row){

    return (
        <>
            <a
            href="javascript:void(0);"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
            >
                {`#${row.orderNumber}`}
            </a>
        </>
    )
}