
import React from "react";

export function LocationFormatter(cellContent, row){

    return (
        <>
            <span className="text-dark-75 font-weight-bolder d-block text-hover-primary font-size-lg">
                { row.locationAddress2 ? 
                    `${row.locationAddress1} ${row.locationAddress2}`
                    :
                    `${row.locationAddress1}`
                }
            </span>
            <span className="text-muted font-weight-bold">
                {`${row.locationCity}, ${row.locationZipcode}`}
            </span>
        </>
    )
}