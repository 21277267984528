/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { useSelector } from "react-redux";

export function SupplierMenu({ layoutProps}) {
    const location = useLocation();
    const storedUser = useSelector(state => state.auth.user)
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>

                <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                    {/*begin::1 Level*/}
                    {/* <li
                        className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/dashboard">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                            </span>
                            <span className="menu-text">Dashboard</span>
                        </NavLink>
                    </li> */}
                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                    {/* <li
                        className={`menu-item ${getMenuItemActive("/applications", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/applications">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
          </span>
                            <span className="menu-text">Applications</span>
                        </NavLink>
                    </li> */}
                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                    {/* <li
                        className={`menu-item ${getMenuItemActive("/orders", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/orders">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")}/>
          </span>
                            <span className="menu-text">Orders</span>
                        </NavLink>
                    </li> */}
                    {/*end::1 Level*/}

                    <li
                        className={`menu-item ${getMenuItemActive("/routes", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/routes">
                            <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
                            </span>
                            <span className="menu-text">Route Management</span>
                        </NavLink>
                    </li>

                    {/*begin::1 Level*/}
                    {/* <li
                        className={`menu-item ${getMenuItemActive("/myclients", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/myclients">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
          </span>
                            <span className="menu-text">My Clients</span>
                        </NavLink>
                    </li> */}
                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                        {/* <li
                            className={`menu-item ${getMenuItemActive("/analytics", false)}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/analytics">
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")}/>
                                </span>
                                <span className="menu-text">Analytics</span>
                            </NavLink>
                        </li> */}

                    <li
                        className={`menu-item ${getMenuItemActive("/supplierAdmin/", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/supplierAdmin/" + storedUser.supplierId}>
                            <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
                            </span>
                            <span className="menu-text">Admin</span>
                        </NavLink>
                    </li>

                    {/* <li
                        className={`menu-item`}
                        aria-haspopup="true"
                    >
                        <a className="menu-link" href={ process.env.REACT_APP_ADMIN_URL } target="_blank" rel="noopener noreferrer">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Box.svg")}/>
                            </span>
                            <span className="menu-text">Admin</span>
                        </a>
                    </li> */}
                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                    {/* <li
                        className={`menu-item ${getMenuItemActive("/accountsettings", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/accountsettings">
          <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}/>
          </span>
                            <span className="menu-text">Account Settings</span>
                        </NavLink>
                    </li> */}

                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                    {/* <li
              className={`menu-item ${getMenuItemActive("/builder", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Layout Builder</span>
            </NavLink>
          </li> */}
                    {/*end::1 Level*/}

                    {/* Components */}
                    {/* begin::section */}
                    {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
                    {/* end:: section */}

                    {/* Material-UI */}
                    {/*begin::1 Level*/}

                    {/*end::1 Level*/}
                </ul>
            </>
    );
}
