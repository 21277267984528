
import axios from 'axios';

import { put, takeLatest, select } from "redux-saga/effects";


export const actionTypes = {
    GetProductCount: "Get Product Count",
    SetProductCount: "Set Product Count"

};


export const cartCount = (state = 0,action) => {
        switch (action.type) {
            case actionTypes.GetProductCount:
                return state;

            case actionTypes.SetProductCount:
                return action.payload;

            default:
                return state;
        }
    }
;


export const cartCountActions = {
    getProductCount: (accessToken, id) => ({type: actionTypes.GetProductCount, payload: {accessToken, id} }),
    setProductCount: count => ({ type: actionTypes.SetProductCount, payload: count })

};

// const getToken = state => state.authToken;

export function apiGetProductCount(accessToken, id) {
    // Authorization head should be fulfilled in interceptor.
    const options = {
      headers: { 'x-auth-token': accessToken}
    }
    return axios.get(process.env.REACT_APP_API_URL + `/orders/getProductCount/${id}`, options);
  }

export function* saga() {
    yield takeLatest(actionTypes.GetProductCount, function* fetchProductCount(params) {
        //   axios.get();
        // console.log("ES ESTE" , params.payload)
        const { data } = yield apiGetProductCount(params.payload.accessToken, params.payload.id);
// console.log("DATA", data);
        yield put(cartCountActions.setProductCount(data));
            // return {type: actionTypes.GetProductCount, payload: response.data}
        

       
    });


}



