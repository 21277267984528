import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import React, { useState } from "react";

export function ProductBox({product,supplier,company,locations,setShowSnackBar}){

    // const [amount,setAmount] = useState(1);

    const [showProductModal, setShowProductModal] = useState(false);
    // const [showLocationsModal,setLocationsModal] = useState(false);
    // const [expanded2, setExpanded2] = useState(false);
    // const [locationsToOrder,setLocationsToOrder] = useState([])
    // const [locationList,setLocationList] = useState([])
    // const dispatch = useDispatch();

    // function handleExpandClick2() {
    //     setExpanded2(!expanded2);
    // }
    

    // const openLicensesModal = () => {

    //     setOpenVerificationModal({
    //         permitsModal: false,
    //         licensesModal: true,
    //         creditModal: false,
    //         otherModal: false
    //     })
    // }

    // const handleLocationChange = (e,locationParam,index,amount) => {

    //     let locationToOrder = locationsToOrder.find(location => location._id === locationParam._id)
    //     console.log(locationToOrder,locationParam,index,amount);

    //     if(!locationToOrder){
    //         console.log("Entré aqui")

    //         if(e.target.value){
    //             setLocationsToOrder(locationsToOrder =>[...locationsToOrder,locationParam])

    //         }
    //         console.log(locationParam,locationToOrder)
    //     }else{
    //         if(!e.target.value){
    //             let locationsTemp = [...locationToOrder]
    //             locationsTemp.slice(index,1)
    //             setLocationsToOrder(locationsTemp);
    //             console.log(locationsTemp)
    //         }
    //     }
    //     console.log("Valor",e.target.value)
    // }

    // const handleAmountChange = (locationParam,amount) => {
    //     let locationToOrderIndex = locationsToOrder.findIndex(location => location._id === locationParam._id)
    //     let locationInListIndex = locationList.findIndex(location => location._id === locationParam._id)
    //     let locationsTemp = [...locationsToOrder];
    //     let locationsListTemp = [...locationList]

    //     if(amount > 0) {
    //         if(locationToOrderIndex !== -1) {
    //             locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:amount}
    //             locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
    //         } else {
    //             locationsTemp.push({ ...locationParam , amount:amount })
    //             locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
    //         }
    //     } else {
    //         locationsTemp = locationsTemp.filter(location => location._id !== locationParam._id)
    //         locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
    //     }

    //     setLocationsToOrder(locationsTemp)
    //     setLocationList(locationsListTemp)

    //     // if(locationToOrderIndex !== undefined && locationToOrderIndex !== null && locationToOrderIndex !== -1){
    //     //     locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:amount}
    //     //     locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}

    //     //     setLocationsToOrder(locationsTemp)
    //     //     setLocationList(locationsListTemp)

    //     //     console.log("Llegamos",locationsTemp,locationsToOrder)
    //     // }
    // }

    // const addToCart = () =>{
    //     setLocationsModal(true)
    //     console.log("Abriendo",product.name)
    // }

    return(
        <>
        <div className="col-md-4 cursor-pointer">
            <Card classname={`d-flex align-items-center mb-9 rounded p-5`}>
                <CardBody>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center p-3">
                            <img onClick={() => setShowProductModal(true)} src={product.imagePath} alt="" style={{maxHeight:"120px",maxWidth:"120px"}}/>
                        </div>
                        <div className="col-12 text-center">
                            <h3>{product.name}</h3>
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Sku: ${product.sku}`}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Unit Size: ${product.unitSize} ${product.unitMeasurement}`}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Units: ${product.units}`}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <p className="font-size-lg font-weight-bolder"><span>${product.price}</span></p>

                        </div>
                    </div>
                    
                    
                    
                </CardBody>
            </Card>
        </div>
        
        {/* <ProductModal
            modalStatus={showProductModal}
            setShowProductModal ={setShowProductModal}
            product={product}
            locations={locations}
            company={company}
            supplier={supplier}
            setShowSnackBar={setShowSnackBar}
        /> */}



</>
        
    )
}