import React, {useState} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import axios from "axios";
import {SupplierPriceListColumn} from "../ColumnsFormatter/PriceListNameColumn";
import {RouteTabs} from "../Routes/RouteTabs";
import RouteAddEdit from "../Routes/RouteAddEdit";


function SupplierRoutes({supplierId}) {

    const [open,setOpen] = useState(false);
    const [listEdit, setListEdit] = useState({});
    const [clients, setClients] = useState([]);
    const [openInfo, setOpenInfo] = useState(false)
    const [routeId, setRouteId] = useState('');

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 10
    }

    const openNewRoute = () => {
        setOpen(true)
    }


    const [data,setData] = useState(initialData)


    const fetchRoutes = (queryParams) => {
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }
        // console.log("Search params : ", options.queryParams)
        axios.get(process.env.REACT_APP_API_URL + '/routes/supplier/' + supplierId, options).then(response =>{
            
            setData({
                data: response.data.data,
                totalCount: response.data.count,
                pageSize: response.data.pageSize
            })

        });
    }

    const columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses,
            // formatter: SupplierRouteColumn,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteId(row._id)
                    setOpenInfo(!openInfo)
                }
            }
        },

    ];

    const sizePerPageList = [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 }
    ]

    const initialFilter = {
        filter: {
            name:"",
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "name",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.name = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    const initialValues = {
        name: "",
        dayOfTheWeek: "",
        clientsList: [],
    };

    return (
        <>
            {openInfo ? <RouteTabs routeId={routeId}/> :
                open ? <RouteAddEdit route={initialValues} supplierId={supplierId} edit={false} setEdit={null}
                                           isNew={open} setIsNew={setOpen} /> :
                <Card>
                    <CardHeader title="Routes">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={openNewRoute}>
                                New Route
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchRoutes}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}
                            searchField={true}/>
                    </CardBody>
                </Card>
            }
        </>
    );
}

export default injectIntl(SupplierRoutes);