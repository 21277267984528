
import React from "react";

export function ClientFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
                {row.company[0].businessName}
            </span>
            <span className="text-muted font-weight-bold d-block text-center">
                {row.location[0].locationName}
            </span>
        </>
    )
}