import {Card, CardBody, CardFooter, CardHeader} from "../../../../../_metronic/_partials/controls";
import {FieldArray, Form, Formik} from "formik";
import {MyTextInput} from "../../../Buyer/CustomFormInputs";
import React, {useRef, useState, useEffect} from "react";
import DoneAllIconTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import {HighlightOff} from "@material-ui/icons";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import {postCreditInformation} from "../applicationsCrud";
import {useSelector} from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ApplicationDeterminationModal} from "../ApplicationsModals/ApplicationDeterminationModal";
import { PermitsModal} from "../ApplicationsModals/PermitsModal";
import {CreditModal} from "../ApplicationsModals/CreditModal";
import {LicensesModal} from "../ApplicationsModals/LicensesModal";
import {OtherModal} from "../ApplicationsModals/OtherModal";


export function AppVerification({commonApp,actualApplication,applicationId}) {

    // console.log("Common Application: ", commonApp)
    // console.log("Application: ", actualApplication)

    const initialModalConfiguration = {
        permitsModal: false,
        licensesModal: false,
        creditModal: false,
        otherModal: false
    }


    let initialCreditInformation = {
        authorizedCredit: "",
        message: "",
        creditRange: "",
        determination: "",
        creditByCategories: []
    }

    const [openVerificationModalManagement,setOpenVerificationModal] = useState(initialModalConfiguration);
    const [isCreditInfoReady, setIsCreditInfoReady] = useState(false)

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    const [showDeterminationModal,setShowDeterminationModal] = useState(false);

    const formRef = useRef();

    const [creditInformation,setCreditInformation] = useState(initialCreditInformation)

    const creditSum = (accumulator,currentValue) => accumulator + currentValue;



    useEffect(() =>{
        if(actualApplication.creditInformation){
            let unapprovedLocations = actualApplication.locationsCategories.filter(location => location.status === "submitted")
            let newCreditByCategories = [...actualApplication.creditInformation.creditByCategories]
            console.log(newCreditByCategories)
            if(unapprovedLocations.length > 0){
                unapprovedLocations.forEach(location => {
                    console.log(newCreditByCategories)
                    location.categories.forEach(category => {
                        let currentCategory = newCreditByCategories.find(cat => category === cat.name)
                        if(typeof(currentCategory) === 'undefined'){
                            // console.log("----- ", currentCategory)
                            let creditCategory = {};
                            creditCategory['name'] = category;
                            creditCategory['value'] = 0;
                            creditCategory['newLocationsCount'] = 1
                            newCreditByCategories.push(creditCategory)
                        } else {
                            console.log("~~~~~~ ", currentCategory)
                            if(typeof(currentCategory.newLocationsCount) !== 'undefined') {
                                currentCategory.newLocationsCount += 1
                            } else {
                                currentCategory.newLocationsCount = 1
                            }
                            
                        }
                    })
                })
            }
            initialCreditInformation = {
                authorizedCredit: actualApplication.creditInformation.authorizedCredit,
                message: actualApplication.creditInformation.message,
                creditRange: actualApplication.creditInformation.creditRange,
                determination: actualApplication.creditInformation.determination,
                creditByCategories: newCreditByCategories
            }
            setCreditInformation(initialCreditInformation)
            setIsCreditInfoReady(true)
        }else{
            if(actualApplication.locationsCategories){
                actualApplication.locationsCategories.forEach(location =>{
                    location.categories.forEach(category => {
                        let currentCategory = initialCreditInformation.creditByCategories.find(cat => category === cat.name)
                        if(typeof(currentCategory) === 'undefined'){
                            console.log(category)
                            let creditCategory = {};
                            creditCategory['name'] = category;
                            creditCategory['value'] = 0;
                            creditCategory['locationsCount'] = 1
                            initialCreditInformation.creditByCategories.push(creditCategory)
                        } else {
                            currentCategory.locationsCount += 1
                        }
                    })
                })
            }
            setCreditInformation(initialCreditInformation)
            setIsCreditInfoReady(true)
        }

    },[])

    const getIcon = (value) => {
        return value ?
            (<DoneAllIconTwoToneIcon color="primary" fontSize="large"/>)
            :
            (<HighlightOff color="error" fontSize="large"/> )
            ;
    }

    const handleSubmit = () => {
        if (formRef.current){
            formRef.current.handleSubmit();
        }
    }

    const openPermitsModal = () => {
        setOpenVerificationModal({
            permitsModal: true,
            licensesModal: false,
            creditModal: false,
            otherModal: false
        })
    }

    const openLicensesModal = () => {

        setOpenVerificationModal({
            permitsModal: false,
            licensesModal: true,
            creditModal: false,
            otherModal: false
        })
    }

    const openCreditModal = () => {

        setOpenVerificationModal({
            permitsModal: false,
            licensesModal: false,
            creditModal: true,
            otherModal: false
        })
    }

    const openOtherModal = () => {

        setOpenVerificationModal({
            permitsModal: false,
            licensesModal: false,
            creditModal: false,
            otherModal: true
        })
    }


    const categoryCreditString = (credit) => {
        let categoryLocations = ''
        if(typeof(credit.newLocationsCount) !== 'undefined') {
            if(typeof(credit.locationsCount) !== 'undefined'){
                categoryLocations = credit.name.charAt(0).toUpperCase() + credit.name.slice(1) + ` Credit (${credit.locationsCount} locations, ${credit.newLocationsCount} new) `
            } else {
                categoryLocations = credit.name.charAt(0).toUpperCase() + credit.name.slice(1) + ` Credit (${credit.newLocationsCount} new) `
            }
            
        } else {
            categoryLocations = credit.name.charAt(0).toUpperCase() + credit.name.slice(1) + ` Credit (${credit.locationsCount} locations)`
        }
        return categoryLocations
    }



    return (
        <>
        

        
        <div>
            {console.log(creditInformation)}
            <Card>
                <CardHeader title="Verification Engine">

                </CardHeader>
                <CardBody>
                   <div className="row justify-content-center">
                       <div className="col-12 col-md-8">
                           <div className="table-responsive">
                               <table className="table table-padding-2 table-head-light-primary table-light table-striped table-head-padding-2 table-borderless table-vertical-center">
                                   <thead>
                                   <tr className="text-left text-uppercase">
                                       <th className="pl-7" style={{minWidth: "250px"}}>Documents</th>
                                       <th style={{minWidth: "70px"}}>Status</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                        <tr style={{cursor:"pointer"}} onClick={openPermitsModal}>
                                            <td>
                                                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                    Permits
                                                </span>
                                            </td>
                                            <td>
                                                {getIcon(true)}
                                            </td>
                                        </tr>
                                        <tr style={{cursor:"pointer"}} onClick={openLicensesModal}>
                                            <td>
                                                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                    Licenses
                                                </span>
                                            </td>
                                            <td>
                                                {getIcon(false)}
                                            </td>
                                        </tr>
                                        <tr style={{cursor:"pointer"}} onClick={openCreditModal}>
                                            <td>
                                                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                    Credit
                                                </span>
                                            </td>
                                            <td>
                                                {getIcon(true)}
                                            </td>
                                        </tr>
                                        <tr style={{cursor:"pointer"}} onClick={openOtherModal}>
                                            <td>
                                                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                    Other
                                                </span>
                                            </td>
                                            <td>
                                                {getIcon(false)}
                                            </td>
                                        </tr>
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>

                </CardBody>
                <CardFooter>

                </CardFooter>
            </Card>

            <Card>
                <CardHeader title="Line of Credit">

                </CardHeader>
                {isCreditInfoReady ? 
                <CardBody>
                    <Formik
                        initialValues={creditInformation}
                        onSubmit={(values) =>{
                            values.authorizedCredit = parseFloat(values.creditByCategories.map(category =>  category.value).reduce(creditSum))
                            const parsedCreditRange = parseFloat(values.creditRange)


                            const newCreditByCategories = values.creditByCategories.map(credit => {
                                let newCredit = {}
                                if(typeof(credit.newLocationsCount) !== 'undefined') {
                                    if(typeof(credit.locationsCount) !== 'undefined'){
                                        newCredit = {
                                            name : credit.name,
                                            value : credit.value,
                                            locationsCount : credit.locationsCount + credit.newLocationsCount
                                        }
                                    } else {
                                        newCredit = {
                                            name : credit.name,
                                            value : credit.value,
                                            locationsCount : credit.newLocationsCount
                                        }
                                    }
                                } else {
                                    newCredit = {
                                        name : credit.name,
                                        value : credit.value,
                                        locationsCount : credit.locationsCount
                                    }
                                }
                                return newCredit
                            })

                            const finalCreditInfo = {
                                ...values,
                                creditRange: parsedCreditRange,
                                creditByCategories : newCreditByCategories
                            }

                            postCreditInformation(applicationId, accessToken, finalCreditInfo, commonApp.businessInformation.businessEmail, actualApplication.supplierName).then(response=>{
                                //TODO:ApplicationDialog
                                setCreditInformation(values)
                                setShowDeterminationModal(true)
                            }).catch(error =>{
                                //TODO:CatchError
                            })
                        }}
                        innerRef={formRef}
                    >
                        {(props) => (
                            <Form>
                                <div>
                                    <div className="row align-self-center">
                                        <div className="col-6">
                                            <MyTextInput
                                                label="Suggested Credit"
                                                name="suggestedCredit"
                                                type="number"
                                                placeholder="$$$$"
                                                value="8500"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-4">
                                            <FormControl variant="filled" style={{width: "100%"}}>
                                                <InputLabel>Credit Range</InputLabel>
                                                <Select
                                                    input={<FilledInput name="creditRange" id="creditRange" value={props.values.creditRange} onChange={props.handleChange} type="text" className="form-control form-control-solid h-auto"/> }>

                                                    <MenuItem value="0">COD</MenuItem>
                                                    <MenuItem value="15">Net 15 days</MenuItem>
                                                    <MenuItem value="30">Net 30 days</MenuItem>
                                                    <MenuItem value="60">Net 60 days</MenuItem>
                                                    <MenuItem value="90">Net 90 days</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <MyTextInput
                                                label={`Authorized Credit (${actualApplication.locationsCategories.length} Locations)`}
                                                name="authorizedCredit"
                                                type="number"
                                                value={props.values.creditByCategories.map(category =>  category.value).reduce(creditSum)}
                                                disabled
                                                placeholder="$$$$"
                                                onChange={props.handleChange}
                                            />
                                        </div>
                                        {actualApplication.clientId ? (
                                            <div className="col-6">
                                                <MyTextInput
                                                    label="Client Id"
                                                    name="clientId"
                                                    type="text"
                                                    value={actualApplication.clientId}
                                                    placeholder="####"
                                                    disabled
                                                    onChange={props.handleChange}
                                                />
                                            </div>
                                        ):""}
                                    </div>
                                    <div className="row">
                                        <FieldArray
                                            name="creditByCategories"
                                            render={arrayHelpers => (
                                                <>
                                                    {props.values.creditByCategories.map((credit,index) =>(
                                                            <div className="col-6">
                                                                <MyTextInput
                                                                    label={categoryCreditString(credit)} 
                                                                    name={`creditByCategories.${index}.value`}
                                                                    type="number"
                                                                    placeholder="####"
                                                                    value={credit.value}
                                                                    onChange={props.handleChange}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="row justify-content-center mb-4">
                                        <div className="col-8">
                                            <FormControl variant="filled" style={{width: "100%"}}>
                                                <label htmlFor="message">Message</label>
                                                <textarea
                                                    name="message"
                                                    rows="4"
                                                    value={props.values.message}
                                                    className="form-control form-control-solid h-auto p-2"
                                                    onChange={props.handleChange}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <FormControl variant="filled" className="row">
                                            <RadioGroup
                                                name="determination"
                                                row
                                                value={props.values.determination}
                                                onChange={props.handleChange}
                                                >
                                                <FormControlLabel value="approved" control={<Radio color="primary"/>} label="Approve"/>
                                                <FormControlLabel value="additionalInfo" control={<Radio color="primary"/>} label="Request Additional Information"/>
                                                <FormControlLabel value="denied" control={<Radio color="primary"/>} label="Deny"/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </Form>

                        )}
                    </Formik>
                </CardBody>
                :
                null
                }
                <CardFooter>
                    <div className="row justify-content-center">
                        <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                    </div>
                </CardFooter>
            </Card>

            <ApplicationDeterminationModal
                creditInformation={creditInformation}
                commonApp={commonApp}
                application={actualApplication}
                setShowDeterminationModal={setShowDeterminationModal}
                showDeterminationModal={showDeterminationModal} />

            <PermitsModal
                manageModal={setOpenVerificationModal}
                modalStatus={openVerificationModalManagement}
                permitsStatus={true}
                actualApplication={actualApplication} />

            <CreditModal
                manageModal={setOpenVerificationModal}
                modalStatus={openVerificationModalManagement}
                creditStatus={true}
                actualApplication={actualApplication} />

            <LicensesModal
                manageModal={setOpenVerificationModal}
                modalStatus={openVerificationModalManagement}
                licensesStatus={false}
                actualApplication={actualApplication} />

            <OtherModal
                manageModal={setOpenVerificationModal}
                modalStatus={openVerificationModalManagement}
                actualApplication={actualApplication} />

                
            </div>
            
            
        </>
    )
}