import React, {useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import {verificationEmailActions} from "../../../../redux/reducers/verificaionEmail";
import Oauth2Login from 'react-simple-oauth2-login';
import {useSearchParams} from "react-router-dom";

import {
  AUTHORIZATION_URL,
  CLIENT_ID,
  REDIRECT_URI,
  APP_SERVER_URL,
  OAUTH_SERVER_URL,
  SCOPE,
} from '../../../../oauth.settings';
import axios from "axios";
import OauthPopup from "../../../components/OauthPopup";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URL(currentUrl).searchParams;
    const code = searchParams.get('code');
    const loggingOut = localStorage.getItem("loggingOut");


    const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
    const scope = '*';
    let redirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI
    // redirectUri = 'http://localhost:3000/callback'
    const responseType = 'code'
    let serverUrl = process.env.REACT_APP_OAUTH_AUTHORIZE_URI

    console.log("Code", code, loggingOut, serverUrl);

    localStorage.setItem('loggingIn', true);

    if(!code){
      window.location.href = `${serverUrl}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=${responseType}`
    }
  },[])

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const logUserInfo = () => {
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("ENV Var:", process.env.REACT_APP_API_URL)
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(res => {
            if(res.status === 200){
              if(res.data.emailVerified && res.data.active){
                disableLoading();
                props.login(res.data.accessToken);
              }else if(!res.data.active && res.data.accountType === 'supplier'){
                history.push('/auth/supplierOnBoarding')
              }else{
                dispatch(verificationEmailActions.setEmail(values.email));
                history.push('/auth/emailVerification')
              }
            }else{
              disableLoading();
              setSubmitting(false);
              setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN",
                  })
              );
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  // const onSuccess = ({ code }) => axios.post(`http://localhost:8200/api/auth/oauth/login`, {
  //   code:code
  // })
  //     .then(res => res.json())
  //     .then((data) => {
  //       // setAccessToken(data.access_token);
  //       return data.access_token;
  //     })
  //     .then(token => fetch(`${OAUTH_SERVER_URL}/api/user`, {
  //       method: 'GET',
  //       headers: {
  //         accept: 'application/json',
  //         authorization: `Bearer ${token}`,
  //       },
  //     }))
  //     .then(res => res.json())
  //     // .then(setUser)
  //     .catch(err => console.log(err));

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const destinationPath = params.get('destination_path');
  // const destinationPath = localStorage.getItem('destinationPath');
  console.log(localStorage);

   // const destinationPath = qs.parse(location.search, {ignoreQueryPrefix: true}).destination_path

  // console.log("Destination Path Login: " + destinationPath)

  // const onSuccess = ({code}) => {
  //   axios.post('/auth/oauth/login',{code: code})
  //       .then(res =>{
  //         if(res.status === 200){
  //           console.log('Hello help', res.data.accessToken)
  //           disableLoading();
  //           props.login(res.data.accessToken)
  //         }
  //       });
  // }

  const onFailure = () => {
    console.log('failed')
  }

  return (
      <></>
    // <div className="login-form login-signin" id="kt_login_signin_form">
    //   {/*<Oauth2Login*/}
    //   {/*  id="auth-code-login-btn"*/}
    //   {/*  authorizationUrl={AUTHORIZATION_URL}*/}
    //   {/*  clientId={CLIENT_ID}*/}
    //   {/*  redirectUri={REDIRECT_URI}*/}
    //   {/*  responseType="code"*/}
    //   {/*  scope={SCOPE}*/}
    //   {/*  buttonText="Auth code login"*/}
    //   {/*  onSuccess={onSuccess}*/}
    //   {/*  onFailure={onFailure}*/}
    //   {/*/>*/}
    //
    //   {/* begin::Head */}
    //   <div className="text-center mb-10 mb-lg-20">
    //     <h3 className="font-size-h1">
    //       <FormattedMessage id="AUTH.LOGIN.TITLE" />
    //     </h3>
    //     <p className="text-muted font-weight-bold">
    //       Log In to your account
    //     </p>
    //   </div>
    //   {/* end::Head */}
    //
    //   {/*begin::Form*/}
    //   <form
    //     onSubmit={formik.handleSubmit}
    //     className="form fv-plugins-bootstrap fv-plugins-framework"
    //   >
    //     {formik.status ? (
    //       <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
    //         <div className="alert-text font-weight-bold">{formik.status}</div>
    //       </div>
    //     ) : (
    //       null
    //       // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
    //       //   <div className="alert-text ">
    //       //     Use account <strong>admin@demo.com</strong> and password{" "}
    //       //     <strong>demo</strong> to continue.
    //       //   </div>
    //       // </div>
    //     )}
    //
    //     <div className="form-group fv-plugins-icon-container">
    //       <input
    //         placeholder="Email"
    //         type="email"
    //         className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
    //           "email"
    //         )}`}
    //         name="email"
    //         {...formik.getFieldProps("email")}
    //       />
    //       {formik.touched.email && formik.errors.email ? (
    //         <div className="fv-plugins-message-container">
    //           <div className="fv-help-block">{formik.errors.email}</div>
    //         </div>
    //       ) : null}
    //     </div>
    //     <div className="form-group fv-plugins-icon-container">
    //       <input
    //         placeholder="Password"
    //         type="password"
    //         className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
    //           "password"
    //         )}`}
    //         name="password"
    //         {...formik.getFieldProps("password")}
    //       />
    //       {formik.touched.password && formik.errors.password ? (
    //         <div className="fv-plugins-message-container">
    //           <div className="fv-help-block">{formik.errors.password}</div>
    //         </div>
    //       ) : null}
    //     </div>
    //     <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
    //       <label className="checkbox">
    //         <input
    //           type="checkbox"
    //           name="acceptTerms"
    //           className="m-1"
    //           {...formik.getFieldProps("acceptTerms")}
    //         />
    //         Remember me
    //         <span className="m-1"/>
    //       </label>
    //       <Link
    //         to="/auth/forgot-password"
    //         className="text-dark-50 text-hover-primary my-3 mr-2"
    //         id="kt_login_forgot"
    //       >
    //         <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
    //       </Link>
    //     </div>
    //     {/* <button
    //       id="kt_login_signin_submit"
    //       type="submit"
    //       disabled={formik.isSubmitting}
    //       className={`btn btn-primary font-weight-bold py-4 my-2`}
    //       style={{ width: '80%' }}
    //     >
    //       <span>Sign In</span>
    //       {loading && <span className="ml-3 spinner spinner-white"></span>}
    //     </button> */}
    //   </form>
    //   {/*end::Form*/}
    //   <div className="form-group fv-plugins-icon-container text-center">
    //
    //     <button
    //       id="kt_login_signin_submit"
    //       disabled={formik.isSubmitting}
    //       className={`btn btn-primary font-weight-bold py-4 my-2`}
    //       style={{ width: '80%' }}
    //       onClick={formik.submitForm}
    //     >
    //       <span>Log In</span>
    //       {loading && <span className="ml-3 spinner spinner-white"></span>}
    //     </button>
    //     <OauthPopup login={props.login} destinationPath={destinationPath}/>
    //
    //
    //     {/*<button*/}
    //     {/*  id="kt_logout_signin_submit"*/}
    //     {/*  disabled={formik.isSubmitting}*/}
    //     {/*  className={`btn btn-primary font-weight-bold py-4 my-2`}*/}
    //     {/*  style={{ width: '80%' }}*/}
    //     {/*  onClick={() => logout()}*/}
    //     {/*>*/}
    //     {/*  <span>Log Out</span>*/}
    //     {/*  {loading && <span className="ml-3 spinner spinner-white"></span>}*/}
    //     {/*</button>*/}
    //
    //     <Link to="/auth/buyerRegistration">
    //       <button
    //         id="kt_login_signin_submit"
    //         type="button"
    //         //disabled={formik.isSubmitting}
    //         className={`btn btn-primary font-weight-bold py-4 my-2`}
    //         style={{ width: '80%' }}
    //         onClick={() => logUserInfo()}
    //       >
    //         <span>Buyer Registration</span>
    //         {loading && <span className="ml-3 spinner spinner-white"></span>}
    //       </button>
    //     </Link>
    //     <Link to="/auth/supplierRegistration">
    //       <button
    //         id="kt_login_signin_submit"
    //         type="submit"
    //         disabled={formik.isSubmitting}
    //         className={`btn btn-primary font-weight-bold py-4 my-2`}
    //         style={{ width: '80%',  }}
    //       >
    //         <span>Supplier Registration</span>
    //         {loading && <span className="ml-3 spinner spinner-white"></span>}
    //       </button>
    //     </Link>
    //   </div>
    // </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
