import React from "react";
import {Star} from "@material-ui/icons";

export function FavoriteFormatter(cellContent, row){

    return (
        <>
            <span className="cursor-pointer text-dark-75 font-weight-bolder d-block font-size-lg">
                <Star className={'cursor-pointer'} style={{color: row.favorite ? 'yellow' : 'gray'}}/>
                {row.favorite}
            </span>
        </>
    )
}
