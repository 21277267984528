import React from "react";

export function RemoveColumnFormatter(cellContent,row,rowIndex, {routeClients}){

    const getAction = () => {
        const clientFound = routeClients.find(client => client == row._id)
        if(!clientFound) {
            return (
                <button
                    type="button"
                    className="btn btn-primary font-weight-bolder font-size-sm mr-1"
                >
                    Removed
                </button>
            )
        } else {
            return (
                <button
                    type="button"
                    className="btn btn-primary font-weight-bolder font-size-sm mr-1"
                >
                    <i className="fa fa-trash"></i>
                </button>
            )
        }
        
    }

    return(
        <>
            {getAction()}
        </>
    )
}