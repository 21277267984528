import React from "react";
// import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersByUser } from "../../../../redux/actions/orders";
import { headerSortingClasses, sortCaret } from "../../../../_metronic/_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { PaginationTable } from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "../Orders/column-formatters";


function OrdersPage() {

    // const [queryParams, setQueryParams] = useState(initialData)

    // const { user, accessToken } = useSelector(
    //     ({ auth }) => ({
    //         user: auth.user != null ? auth.user : null,
    //         accessToken: auth.authToken,
    //     })
    // ),
    const { orderListPaginationData } = useSelector(state => state.orderReducer);

    const dispatch = useDispatch();

    const fetchOrders = async (queryParams) => {
        // setQueryParams(queryParams);
        dispatch(getOrdersByUser(queryParams));

    }


    const columns = [
        {
            dataField: "orderNumber",
            text: "Order Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.OrderNumberFormatter,
        },
        {
            dataField: "companyName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "totalCost",
            text: "Total Cost",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalCostFormatter,
        },
        {
            dataField: "totalProductQty",
            text: "Products Quantity",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalProductFormatter,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.StatusFormatter,
        },
    ];

    const sizePerPageList = [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 }
    ]

    const initialFilter = {
        filter: {
            companyName: "",
            supplierName: "",
            orderNumber: "",
            totalCost: "",
            totalProductQty: "",
            status: "",
            searchText: ""
        },
        sortOrder: "desc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{ dataField: "orderNumber", order: "desc" }]
    const pagination = { custom: true, sizePerPageList: sizePerPageList }
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const { searchText } = values;
        const newQueryParams = { ...queryParams };
        const filter = {};

        filter.companyName = searchText ?? "";
        filter.supplierName = searchText ?? "";
        filter.orderNumber = searchText ?? "";
        filter.totalCost = searchText ?? "";
        filter.totalProductQty = searchText ?? "";
        filter.status = searchText ?? "";
        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    // useEffect(() => {
    //     fetchOrders(queryParams)
    //   }, [])

    return (
        <>
            <Card>
                <CardHeader title="All Orders">
                    <CardHeaderToolbar>
                        {/* <button type="button" className="btn btn-primary" onClick={()=>{setNewOrder(true)}} >
                            Create New Order
                        </button> */}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={orderListPaginationData}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        tableClasses={tableClasses}
                        fetcher={fetchOrders}
                        filterFunction={prepareFilter}
                        searchField={false}
                        initialFilter={initialFilter} />
                </CardBody>
            </Card>

        </>
    );
}

export default injectIntl(OrdersPage);