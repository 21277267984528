/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import moment from 'moment';
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTotalAuthorizedCredit } from "../../../../../redux/actions/applications";
import { markNotificationRead } from "../../../../../redux/actions/notifications";
import { toAbsoluteUrl } from "../../../../_helpers";


export function QuickUser() {
  const history = useHistory();
  const dispatch = useDispatch();

  const markNotificationAsRead = (notificationID) => {
    dispatch(markNotificationRead(notificationID));
  }

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");

  };

  const storedUser = useSelector(state => state.auth.user)
  const { userName, userEmail, role } = useSelector(
    ({ auth }) => ({
      userName: auth.user.name,
      userEmail: auth.user.email,
      role: auth.user.type.charAt(0).toUpperCase() + auth.user.type.slice(1)
    }));

  const { unreadNotifications, notifications } = useSelector(state => state.notificationReducer),
    { applicationList, totalAuthorizedCredit } = useSelector(state => state.applicationReducer),
    { orderListPaginationData } = useSelector(state => state.orderReducer);

  const [remainingAuthorizedCredit, setRemainingAuthorizedCredit] = useState(0);
  const [remainingDays, setRemainingDays] = useState(0);

  useEffect(() => {
    dispatch(getTotalAuthorizedCredit(applicationList));
  }, [applicationList])

  useEffect(() => {
    setRemainingAuthorizedCredit(orderListPaginationData.data.map(item => item.totalCost)
      .reduce((prevAmount, currAmount) => prevAmount + currAmount, 0))
    setRemainingDays(moment().endOf('month').diff(moment(), 'days'));
  }, [orderListPaginationData])


  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
            {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div
        className="offcanvas-content pr-5 mr-n5"
      >
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
          >
            <div className="symbol-label" style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/users/blank.png"
              )})`
            }} />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {userName}
            </a>
            <div className="text-muted mt-1">{role}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {userEmail}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Sign out</button>
          </div>
        </div>

        {/*<div className="separator separator-dashed mt-8 mb-5" />*/}

        {/*<div className="navi navi-spacer-x-0 p-0">*/}
        {/*  <a href="/user/profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-success">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/General/Notification2.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Profile</div>*/}
        {/*        <div className="text-muted">*/}
        {/*          Account settings and more*/}
        {/*            {" "}*/}
        {/*          <span className="label label-light-danger label-inline font-weight-bold">*/}
        {/*            update*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </a>*/}

        {/*  <a href="/user/profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-warning">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/Shopping/Chart-bar1.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Messages</div>*/}
        {/*        <div className="text-muted">Inbox and tasks</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </a>*/}

        {/*  <a href="/user/profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-danger">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/Files/Selected-file.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Activities</div>*/}
        {/*        <div className="text-muted">Logs and notifications</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </a>*/}

        {/*  <a href="/user/profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-primary">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/Communication/Mail-opened.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Tasks</div>*/}
        {/*        <div className="text-muted">latest tasks and projects</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </a>*/}
        {/*</div>*/}

        
        <div className="separator separator-dashed my-7"></div>
        {
          storedUser.tenant == process.env.REACT_APP_BIOWARE_SUPPLIER_TENANT || process.env.REACT_APP_BIOWARE_TENANT ? 
              null
          :
          
          <div>
            <h5 className="mb-5">Monthly Credit</h5>

            <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
              <span className="svg-icon svg-icon-warning mr-5">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  className="svg-icon svg-icon-lg"
                ></SVG>
              </span>

              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {remainingAuthorizedCredit}
                </a>
                <span className="text-muted font-size-sm">Available for another {remainingDays} {remainingDays > 1 ? "days" : "day"}</span>
              </div>

              <span className="font-weight-bolder text-warning py-1 font-size-lg">
                {totalAuthorizedCredit == 0 ? 100 :
                  (remainingAuthorizedCredit / totalAuthorizedCredit) * 100}%
              </span>
            </div>
          </div>
        }

        <div className="separator separator-dashed my-7"></div>

        <div>
          <h5 className="mb-5">Recent Notifications</h5>

          {notifications.map((notification) => {
            return <div className="d-flex align-items-center bg-light-info rounded p-5 gutter-b">
              <span className="svg-icon svg-icon-info mr-5">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                  className="svg-icon svg-icon-lg"
                ></SVG>
              </span>
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {notification.message}
                </a>
                <span className="text-muted font-size-sm">{moment(notification.dateCreated).fromNow()}</span>
              </div>


              {notification.read ?
                null
                :
                <span className="font-weight-bolder text-info py-1 font-size-lg">
                  <button className="btn btn-light-primary btn-bold" onClick={() => markNotificationAsRead(notification._id)}>read</button>
                </span>}
            </div>
          })}
        </div>
      </div>
    </div >
  );
}
