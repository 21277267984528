import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {fetchSupplierClientsPagination, fetchRouteClientsPagination, updateRouteClients} from "../AdminPagesCrud";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {CardBody, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    textField: {
        width: 100,
    },
    summary: {
        width: '40%',
        position: 'center'
    }
}));

export default function RouteClientConfig({ route, currentRouteClients, setEdit }) {
    const classes = useStyles();
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [data, setData] = useState(initialData)
    const [routeClientsData, setRouteClientsData] = useState(initialData)
    
    const [routeClientsPageNumber, setRouteClientsPageNumber] = useState("")
    const [routeClients, setRouteClients] = useState(currentRouteClients)
    const [rearrangeClientsList, setRearrangeClientsList] = useState([])
    const [clientsToRemove, setClientsToRemove] = useState([])
    const {id} = useParams();

    const {user, accessToken} = useSelector(
            ({auth}) => ({
                user: auth.user != null ? auth.user : null,
                accessToken: auth.authToken,
            })
        )

    // const dispatch = useDispatch();

    useEffect(() => {
        

        // fetchSupplierClients(initialFilter)
    }, [])

    const fetchSupplierClients = async (queryParams) => {
        await fetchSupplierClientsPagination(id, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    const fetchRouteClients = async (queryParams) => {
        await fetchRouteClientsPagination(route._id, queryParams, accessToken).then(response => {
            // console.log("Route clients: ", response)
            setRouteClientsData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize,
                pageNumber: response.data.pageNumber,
                originalPageSize: response.data.originalPageSize
            })
            // setRouteClientsPageNumber(response.data.pageNumber)
        }).catch(error => console.log(error))
    }
    
    const handlePriceListClientRemove = (clientId) => {

        const item = routeClients.find(routeId => routeId == clientId)
        if(!item){
            setRouteClients(oldClientsToAdd => [...oldClientsToAdd, clientId])
        } else{
            setRouteClients(oldClientsToAdd => oldClientsToAdd.filter(curr => curr != clientId))
        }
    }

    const handleSubmit = async () => {
        
        // console.log("Routes clients list: ", routeClients)
        // console.log("Client rearrange list: ", rearrangeClientsList)
        await updateRouteClients(route._id, { routeClients, rearrangeClientsList }, accessToken).then(response => {
            setEdit(false)

        }).catch(error => console.log(error))
    }

    const columns = [
        {
            dataField: "businessName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            // formatter: columnFormatters.ClientAddFormatter,
            // formatExtraData:{
            //     clientsToAdd: clientsToAdd
            // },
        },
        {
            dataField: "businessEmail",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ActionColumnFormatter,
            formatExtraData:{
                clientsToAdd: routeClients
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    const item = routeClients.find(routeId => routeId == row._id)
                    if(!item){
                        setRouteClients(oldClientsToAdd => [...oldClientsToAdd, row._id])
                    } else{
                        setRouteClients(oldClientsToAdd => oldClientsToAdd.filter(curr => curr != row._id))
                    }
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "businessName", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    const routeClientsColumns = [
        {
            dataField: 'clientIndex',
            text: 'Client Index',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                // console.log("Page number: ", routeClientsData.pageNumber)
                // console.log("Page size: ", routeClientsData.originalPageSize)
                return ((routeClientsData.pageNumber - 1) * routeClientsData.originalPageSize) + (rowIndex + 1);
            }
        },
        {
            dataField: "sequenceIndex",
            text: "Sequence Index",
            formatter: columnFormatters.SequenceIndexFormatter,
            formatExtraData:{
                rearrangeClientsList: rearrangeClientsList,
                setRearrangeClientsList: setRearrangeClientsList
            },
        },
        {
            dataField: "businessName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            // formatter: columnFormatters.ClientFormatter,
            // formatExtraData:{
            //     clientsToRemove: clientsToRemove
            // },
        },
        {
            dataField: "businessEmail",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RemoveColumnFormatter,
            formatExtraData:{
                routeClients: routeClients
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handlePriceListClientRemove(row._id)
                }
            },
        },
    ];

    const routeSizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    let routeInitialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const routeSorting = []
    const routePagination = {custom: true, sizePerPageList: routeSizePerPageList}
    const routeTableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const routePrepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.paper}>
                <CardHeader title="New Route">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        initialFilter={initialFilter}
                        tableClasses={tableClasses}
                        fetcher={fetchSupplierClients}
                        filterFunction={prepareFilter}
                        searchField={true}/>
                   
                </CardBody>
            </Card>
            {true ?
                <Card>
                    <CardHeader title={`Route Summary: `}/>
                    <CardBody>
                        <PaginationTable
                            data={routeClientsData}
                            columns={routeClientsColumns}
                            pagination={routePagination}
                            isLoading={false}
                            sorting={routeSorting}
                            initialFilter={routeInitialFilter}
                            tableClasses={routeTableClasses}
                            fetcher={fetchRouteClients}
                            filterFunction={routePrepareFilter}
                            searchField={true}/>
                    </CardBody>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                        Submit
                    </button>
                </Card>
                
                : null
            }
        </div>
    );
}