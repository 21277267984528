import React, {useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {toAbsoluteUrl, headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SVG from "react-inlinesvg";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EditProductModal, {AddCategories} from "./SupplierComponents";
import AdminSnackbar from "../AdminSnackbar";
import { fetchProductsByCategory, fetchProducts, fetchSupplierProductsPagination } from "../AdminPagesCrud";
import * as columnFormatters from "./column-formatters";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    subNested: {
        paddingLeft: theme.spacing(8),
    },
    rootTable: {
        width: '100%',
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

}));



function SupplierCategories({products, categories, supplierId}) {

    const initialProduct = {
        name: '',
        price: 0,
        description: '',
        category: '',
        brand: '',
        units: 0,
        unitSize: 0,
        unitMeasurement: '',
        sku: '',
        supplierId: supplierId,
        priceLists: [],
        imagePath: ''
    }

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const classes = useStyles();
    const [openProductModal,setOpenProductModal] = useState(false);
    const [filteredProducts, setProducts] = useState(products);
    const [mainCategories, setMainCategories] = useState(categories);
    const [filterCategories, setFilterCategories] = useState(categories);
    const [allProducts, setAllProducts] = useState(products);
    const [subCategories1] = useState([]);
    const [subCategories2, setSubCategories2] = useState([]);
    const [subVisible] = useState(false);
    const [sub2Visible, set2SubVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [openEditCategories, setOpenEditCategories] = useState(false);
    const [productForModal, setProductForModal] = useState(initialProduct);
    const [productsCategories, setProductCategories] = useState([]);
    const [newProduct, setNew] = useState(false);
    const [snackbarCategories, setSnackbarCategories] = useState(false);
    const [snackbarProduct, setSnackbarProduct] = useState(false);
    const [warningSnackbar, setWarningSnackbar] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [data, setData] = useState(initialData)

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    const initialFilter = {
        filter: {
            searchText: "",
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    }

    async function fetchProductsData(queryParams, categoryId = null) {

        let newQueryParams = {
            ...queryParams
        }
        if(categoryId){
            newQueryParams = {
                ...queryParams,
                categoryId: categoryId
            }
        } else if(selectedCategory !== 'all'){
            newQueryParams = {
                ...queryParams,
                categoryId: selectedCategory
            }
        }

        await fetchSupplierProductsPagination( supplierId, newQueryParams, accessToken ).then((response) => {

            const data = {
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: 5
            }
            setData(data)
            console.log("Products: ", data)

        }).catch((error) => {
            console.log(error)
        });
    }

    const initialCategories = [
        {
            name: '',
            children: []
        }
    ]

    useEffect(() =>{
        setFilterCategories(getProductCategories(mainCategories))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]) 


    function editCategories(isEdit){
        if(!mainCategories || mainCategories.length === 0){
            setMainCategories(initialCategories);
        }
        setEdit(isEdit);
        setOpenEditCategories(true);
    }

    function productModal(product, isNew){

        if(!mainCategories){
            alert("Add categories first");
        }
        else {

            if (isNew) {
                setNew(true)
            }

            // let arr = getProductCategories(categories);
            setProductForModal(product);
            setProductCategories(filterCategories);
            setOpenProductModal(true)

        }
    }

    const getProductCategories  = (categories, arr= []) => {
        categories.forEach(cat => {
            if(cat.children && cat.children.length === 0){
                arr.push({
                    _id: cat._id,
                    name: cat.name
                })
            }
            else{
                arr.push({
                    _id: cat._id,
                    name: cat.name
                })
                getProductCategories(cat.children, arr);
            }
        })

        return arr;
    }


    async function handleChange(event) {
        setSelectedCategory(event.target.value)
        if(event.target.value === 'all'){
            await fetchProductsData(initialFilter)
        } else{
            await fetchProductsData(initialFilter, event.target.value)
        }
        
        
    }

    function handleChangeSub(event) {
        let arr = [];
        subCategories1.forEach(cat => {
            if (cat.name === event.target.value) {
                if (cat.childType === "category") {
                    setSubCategories2(cat.children);
                    setProducts([]);
                    set2SubVisible(true);
                } else {
                    allProducts.forEach(p => {
                        if (p.category === event.target.value) {
                            arr.push(p);
                        }
                    });
                    setProducts(arr);
                    set2SubVisible(false);
                }
            }
        });
    }

    function handleChangeLast(event){
        let arr=[]
        allProducts.forEach(p => {
            if (p.category === event.target.value) {
                arr.push(p);
            }
        });
        setProducts(arr);
    }

    const columns = [
        {
            dataField: "description",
            text: "Product Name",
            sort: true,
            sortCaret: sortCaret,
        },
        {
            dataField: "productCategory",
            text: "Category",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CategoryFormatter,
        },
        {
            dataField: "price",
            text: "Price",
            formatter: columnFormatters.PriceFormatter,
        },
        {
            dataField: "units",
            text: "Units",
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
            formatter: columnFormatters.UnitSizeFormatter,
        },
        {
            dataField: "sku",
            text: "SKU",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "",
            text: "Edit",
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.EditButtonFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    productModal(row, false)
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <Card>
            {openEditCategories ? <AddCategories supplierCategories={mainCategories} setSupplierCategories={setMainCategories} edit={edit} supplierId={supplierId}
                                                 openEditCategories={openEditCategories} setOpenEditCategories={setOpenEditCategories}
                                                 setShowSnackbar={setSnackbarCategories} showSnackbar={snackbarCategories}/> :
                <Card>
                    <CardHeader
                        title="Categories"
                    >
                        <CardHeaderToolbar>
                            {mainCategories && mainCategories.length > 0 ?
                                <button type="button" className="btn btn-primary" onClick={() => editCategories(true)}>
                                    Edit Categories
                                </button>
                                :
                                <button type="button" className="btn btn-primary" onClick={() => editCategories(false)}>
                                    Add Categories
                                </button>
                            }
                        </CardHeaderToolbar>
                    </CardHeader>

                    {mainCategories ?
                        <CardContent>
                            {mainCategories.map((cat,index) =>(
                                    <List 
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        className={classes.root}
                                        dense
                                        key={cat.name + '-' + index}
                                    >
                                        <ListItem key={cat.name + index}>
                                            <ListItemIcon>
                                                <span className="svg-icon menu-icon">
                                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}/>
                                                </span>
                                            </ListItemIcon>
                                            <ListItemText primary={cat.name} />
                                        </ListItem>

                                        {cat.children.map((child, i) => (
                                                <List key={child.name + '-' + i} component="div" dense>
                                                    <ListItem key={child.name + i} className={classes.nested}>
                                                        <ListItemIcon>
                                                            <span className="svg-icon menu-icon">
                                                                <SVG src={'/media/svg/icons/General/Thunder.svg'}/>
                                                            </span>
                                                        </ListItemIcon>
                                                        <ListItemText primary={child.name} />
                                                    </ListItem>

                                                    {child.children ?
                                                        child.children.map((c, i) =>  (
                                                            <List key={child.name + '--' + i} component="div" dense>
                                                                <ListItem key={child.name + i} className={classes.subNested}>
                                                                    <ListItemIcon>
                                                                        <span className="svg-icon menu-icon">
                                                                            <SVG src={'/media/svg/icons/General/Thunder.svg'}/>
                                                                        </span>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={c.name} />
                                                                </ListItem>
                                                            </List>
                                                        )) : null}

                                                </List>
                                            )
                                        )}
                                    </List>
                                )
                            )}
                            <AdminSnackbar
                                message={'Categories updated successfully'}
                                setShowSnackbar={setSnackbarCategories}
                                showSnackbar={snackbarCategories}
                                variant={'success'}
                            />
                            <AdminSnackbar
                                message={'You must add categories first'}
                                setShowSnackbar={setWarningSnackbar}
                                showSnackbar={warningSnackbar}
                                variant={'warning'}
                            />
                        </CardContent>
                        : null}
                </Card>
            }
            <></>

            <Card>
                <CardHeader title="Products">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-primary" onClick={() => productModal(initialProduct, true)}>
                            Add New Product
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                { mainCategories ?
                    <CardContent>
                        <form className={classes.root} autoComplete="off">
                            <FormControl  className={classes.formControl}>
                                <InputLabel htmlFor="mainCategory">Main Category</InputLabel>
                                <Select
                                    name="mainCategory"
                                    id="mainCategory"
                                    onChange={handleChange}
                                    value = {selectedCategory}
                                >
                                    <MenuItem value="all">Show All</MenuItem>
                                    {filterCategories.map((main, i) => (
                                            <MenuItem key={main._id} value={main._id}>{main.name}</MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            {/* {subVisible ?
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="subCategory1">Sub-Category</InputLabel>
                                    <Select
                                        name="subCategory1"
                                        id="subCategory1"
                                        onChange={handleChangeSub}
                                    >
                                        {subCategories1.map((main, i) => (
                                                <MenuItem value={main.name}>{main.name}</MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl> : null }
                            {sub2Visible ?
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="subCategory2">Sub-Category</InputLabel>
                                    <Select
                                        name="subCategory2"
                                        id="subCategory2"
                                        onChange={handleChangeLast}
                                    >
                                        {subCategories2.map((main, i) => (
                                                <MenuItem value={main.name}>{main.name}</MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl> : null } */}
                        </form>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchProductsData}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}/>

                        {openProductModal ?
                            <EditProductModal
                                manageModal={setOpenProductModal}
                                modalStatus={openProductModal}
                                product={productForModal}
                                allProducts={allProducts}
                                setAllProducts={setAllProducts}
                                categories={productsCategories}
                                isNew={newProduct}
                                setShowSnackbar={setSnackbarProduct}
                                showSnackbar={snackbarProduct}
                            /> : null}

                    </CardContent> : null}
            </Card>
        </Card>


    );
}

export default injectIntl(SupplierCategories);