import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
  // const top = 50 + rand();
  // const left = 50 + rand();

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
}));

export default function SubmitModal(props) {
  const [open, setOpen] = React.useState(false);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [existingClients, setExistingClients] = useState([])
  const [clients, setClients] = useState([])

  const { selectedSuppliers, setExistingClientList } = props

  useEffect(() => {
    
  }, [  ])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    console.log(existingClients)
    setExistingClientList(existingClients)
    handleClose()
  }

  const handleExistingClientChange = (supplier) => {
      if(clients.includes(supplier)){
        setClients(clients => clients.filter(client => client !== supplier))
        setExistingClients(existingClients => existingClients.filter(client => supplier !== client.name))
      } else {
        setClients(clients => clients.concat(supplier))
        setExistingClients(existingClients => existingClients.concat({ name : supplier, clientId : "" }))
      }
      console.log(existingClients)
  }

  const handleClientIdChange = (index, event) => {
    const updatedClients = [...existingClients];
    updatedClients[index][event.target.name] = event.target.value;
    setExistingClients(updatedClients);
  };

  const classes = useStyles();


  return (
    <div>
        <div className="form-group d-flex flex-wrap flex-center">
            <Typography variant="h6" id="modal-title">
                Already a client with an existing supplier?
            </Typography>
            <button
            type="button"
            onClick={handleOpen}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
                <span>{"Add Client Id"}</span>
            </button>
        </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            Selected Suppliers
          </Typography>
          {(typeof selectedSuppliers == "undefined" || selectedSuppliers.length === 0) ? "Plese select desired suppliers." : 
          <div>
          <ul className="list-unstyled">
            {selectedSuppliers.map(supplier => 
                <li key={supplier._id}>
                    <div className="d-flex align-items-center mb-10">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                            <input type="checkbox" name="" onChange={() => handleExistingClientChange(supplier.entityName)} />
                            <span></span>
                        </label>
                        <div className="d-flex flex-column flex-grow-1">
                            <a
                            href="javascript:void(0);"
                            className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                            >
                            {supplier.entityName}
                            </a>
                            {/* <span className="text-muted font-weight-bold">Due in 2 Days</span> */}
                        </div>
                    </div>
                </li>
            )}
          </ul>

            <form>
                {existingClients.map((supplier, index) => 
                    
                        <div key={index} className="form-group fv-plugins-icon-container">
                            <label>{supplier.name + " Client Id:"}</label>
                            <input
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="clientId"
                                value={supplier.clientId}
                                onChange={event => handleClientIdChange(index, event)}
                            />
                        </div>
                    
                )}
                <button
                id="submit"
                type="button"
                onClick={handleSubmit}
                // disabled={formik.isSubmitting}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                    <span>Submit</span>
                </button>    

            </form>
            </div>
            }
        </div>
      </Modal>
    </div>
  );
}