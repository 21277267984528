import React, {useState, useEffect} from "react";
// import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {useSelector} from "react-redux";
import axios from "axios";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { updateStatus, getSupplierOrderInfo } from "./ordersCrud";
import OrderDetails from "./OrderDetails";
import Invoice from "../../Buyer/Invoices/Invoice"

function OrdersPage(props) {

    const [isInvoiceClicked, setIsInvoiceClicked] = useState(false)
    const [isOrderDetails, setIsOrderDetails] = useState(false)
    const [invoiceDetails, setInvoiceDetails] = useState({})

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize:5
    }

    const [data,setData] = useState(initialData)

    const initialOrder = {
        _id:"",
        createdDate:"",
        submissionDate: new Date(),
        orderNumber:"",
        status:"",
        products:[],
        location:[],
        company:[],
        totalCost: 0,
        totalProductQty: 0,
        processedDate: new Date(),
        inTransitDate: new Date(),
        deliveredDate: new Date()
    }
    const [order,setOrder] = useState(initialOrder)
    const [isOrderFound, setIsOrderFound] = useState(false)
    const [page,setPage] = useState(1)

    const fetchOrder = async (pathId) => {
        console.log(pathId)
        await getSupplierOrderInfo(accessToken, pathId, user.id).then(order => {
            if(typeof(order.data) !== 'undefined' && order.data !== null) {
                setOrder(order.data)
                setIsOrderFound(true)
            }
            setIsOrderDetails(true)
        }).catch(error => console.log(error))
    }

    useEffect(() => {
        if(typeof(props.pathId) !== 'undefined' && props.pathId !== ''){
            fetchOrder(props.pathId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetchOrders = (queryParams) => {


        // Authorization head should be fulfilled in interceptor.
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 400,
            params: queryParams
        }
        axios.get(process.env.REACT_APP_API_URL + '/orders/supplier',options).then(response =>{
            // if(typeof(props.pathId) !== 'undefined' && props.pathId !== ''){
            //     console.log(response.data.data)
            //     console.log(props)
            //     console.log("QYGXVJKHWBIUX")
            //     setOrder(response.data.data.find(order => order._id === props.pathId))
            //     setPage(2)
            // }

            setData(response.data)
        });
    }

    const setStatus = (orderId,status) => {

        console.log("Setting Status",orderId,status)

        updateStatus(user.id, accessToken,orderId, status).then(response  =>{

        })
    }

    const setProcessed = (id) => {
        setStatus(id,"Processed");
        window.location.reload()
    }

    const setInTransit = (id) => {
        setStatus(id,"In Transit")
        window.location.reload()
    }

    const setDelivered = (id) => {
        setStatus(id,"Delivered")
        window.location.reload()
    }


    const columns = [
        {
            dataField: "orderNumber",
            text: "Order Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.OrderNumberFormatter,
            // events: {
            //     onClick: (e, column, columnIndex, row, rowIndex) => {
            //         setInvoiceDetails(row)
            //         setIsInvoiceClicked(true)
            //     }
            // },
        },
        {
            dataField: "companyName",
            text: "Client",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CompanyFormatter
            
        },
        {
            dataField: "totalCost",
            text: "Total Cost",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalCostFormatter
        },
        {
            dataField: "totalProductQty",
            text: "Total Product Quantity",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalProductFormatter
        },
        {
            dataField: "status",
            text: "Order Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.StatusFormatter
        },
        // {
        //     dataField: "action",
        //     text: "Action",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        //     formatter: columnFormatters.ActionsColumnFormatter,
        //     formatExtraData:{
        //         setProcessed: setProcessed,
        //         setInTransit: setInTransit,
        //         setDelivered: setDelivered
        //     }
        // },
        {
            dataField: "viewDetails",
            text: "View Details",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ViewDetailsFormatter,
            formatExtraData:{
                viewDetails: setOrder,
                setIsOrderDetails: setIsOrderDetails,
                setIsOrderFound: setIsOrderFound
            }
        },
    ];

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    const initialFilter = {
        filter: {
            searchText:"",
        },
        sortOrder: "desc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "orderNumber",order:"desc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center table-padding-2"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            { !isOrderDetails ? (
                isInvoiceClicked ? 
                <Invoice invoice={invoiceDetails} accessToken={accessToken} />
                :
                <Card>
                    <CardHeader title="All Orders">
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchOrders}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}
                        />
                    </CardBody>
                </Card>
                ):
                (   isOrderFound ?
                    <>
                        <OrderDetails order={order} setOrder={setOrder} setIsOrderDetails={setIsOrderDetails} isOrderDetails={isOrderDetails} />
                    </>
                    :
                    <div className="card card-custom gutter-b">
                        <div className="card-body p-0">
                            <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                <div className="col-md-10">
                                    <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                        <div className="text-center mb-4">
                                            <span className="card-label font-weight-bolder text-dark text-center font-size-lg">Order Not Found</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
}
    
export default injectIntl(OrdersPage);