
import axios from 'axios';

import { put, takeLatest, select } from "redux-saga/effects";


export const actionTypes = {
    GetOrdersCount: "Get Orders Count",
    SetOrdersCount: "Set Orders Count"
};


export const ordersCount = (state = 0,action) => {
        switch (action.type) {
            case actionTypes.GetOrdersCount:
                return state;

            case actionTypes.SetOrdersCount:
                return action.payload;

            default:
                return state;
        }
    }
;


export const ordersCountActions = {
    getOrdersCount: (accessToken, id) => ({type: actionTypes.GetOrdersCount, payload: {accessToken, id} }),
    setOrdersCount: count => ({ type: actionTypes.SetOrdersCount, payload: count })

};

// const getToken = state => state.authToken;

export function apiGetOrdersCount(accessToken, id) {
    // Authorization head should be fulfilled in interceptor.
    const options = {
      headers: { 'x-auth-token': accessToken}
    }
    return axios.get(process.env.REACT_APP_API_URL + `/orders/getOrdersCount/${id}`, options);
  }

export function* saga() {
    yield takeLatest(actionTypes.GetOrdersCount, function* fetchOrdersCount(params) {
        //   axios.get();
        // console.log("ES ESTE" , params.payload)
        const { data } = yield apiGetOrdersCount(params.payload.accessToken, params.payload.id);
// console.log("DATA", data);
        yield put(ordersCountActions.setOrdersCount(data));
            // return {type: actionTypes.GetProductCount, payload: response.data}
        

       
    });


}



