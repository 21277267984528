import {Modal} from "react-bootstrap";
import {CheckCircleOutline, HighlightOff} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {postProducts} from "../ordersCrud";
import {useDispatch, useSelector} from "react-redux";
import {cartCountActions} from "../../../../../redux/reducers/cartCount";
import SimpleSnackbar from "./SimpleSnackbar";

export function ProductModal({modalStatus,product, setShowProductModal,locations,company,supplier,setShowSnackBar}){

    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const dispatch = useDispatch();
    const [locationsToOrder,setLocationsToOrder] = useState([])
    const [locationList,setLocationList] = useState([])

    const handleLocationChange = (e,locationParam,index,amount) => {

        let locationToOrder = locationsToOrder.find(location => location._id === locationParam._id)
        console.log(locationToOrder,locationParam,index,amount);

        if(!locationToOrder){
            console.log("Entré aqui")

            if(e.target.value){
                setLocationsToOrder(locationsToOrder =>[...locationsToOrder,locationParam])

            }
            console.log(locationParam,locationToOrder)
        }else{
            if(!e.target.value){
                let locationsTemp = [...locationToOrder]
                locationsTemp.slice(index,1)
                setLocationsToOrder(locationsTemp);
                console.log(locationsTemp)
            }
        }
        console.log("Valor",e.target.value)
    }

    const handleAmountChange = (locationParam,amount) => {
        let locationToOrderIndex = locationsToOrder.findIndex(location => location._id === locationParam._id)
        let locationInListIndex = locationList.findIndex(location => location._id === locationParam._id)
        let locationsTemp = [...locationsToOrder];
        let locationsListTemp = [...locationList]

        if(amount > 0) {
            if(locationToOrderIndex !== -1) {
                locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:amount}
                locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
            } else {
                locationsTemp.push({ ...locationParam , amount:amount })
                locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
            }
        } else {
            locationsTemp = locationsTemp.filter(location => location._id !== locationParam._id)
            locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
        }

        setLocationsToOrder(locationsTemp)
        setLocationList(locationsListTemp)

        // let locationToOrderIndex = locationsToOrder.findIndex(location => location._id === locationParam._id)
        // let locationInListIndex = locationList.findIndex(location => location._id === locationParam._id)
        // let locationsTemp = [...locationsToOrder];
        // let locationsListTemp = [...locationList]

        // console.log("Ahi vamos",locationsTemp,locationToOrderIndex,amount)

        // if(locationToOrderIndex !== undefined && locationToOrderIndex !== null){
        //     locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:amount}
        //     locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}

        //     setLocationsToOrder(locationsTemp)
        //     setLocationList(locationsListTemp)

        //     console.log("Llegamos",locationsTemp,locationsToOrder)
        // }
    }

    const addToCart = () => {
        postProducts(user.id, accessToken,locationsToOrder,product,supplier._id,supplier.entityName,company._id,company.businessName).then(response =>{
            if(response.status === 200){
                dispatch(cartCountActions.getProductCount(accessToken))
                setShowSnackBar(true)
                console.log("Product Added to Cart")

                const locationListAmountZero = []
                
                locationList.map(location => {
                    locationListAmountZero.push({
                        ...location,
                        amount : 0
                    })
                })

                setLocationsToOrder([])
                setLocationList([...locationListAmountZero])
            }
        })

        setShowProductModal(false)
    }

    useEffect(() => {
        let currentLocations = []
        locations.forEach((location,index) => {
            let locationObject = {
                _id: location._id,
                locationName: location.locationName,
                locationAddress: location.locationAddress2 ? location.locationAddress1 + " " + location.locationAddress2 : location.locationAddress1,
                locationCity: location.locationCity,
                amount: 0
            }
            currentLocations.push(locationObject)
        })

        setLocationList(currentLocations)
    }, [ locations ])

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                show={modalStatus}
                onHide={() => setShowProductModal(false)}
                centered={true}
                size="lg"
            >
                <Modal.Body className="overlay overlay-block cursor-default row justify-content-center">

                    <div className="col-12 row justify-content-center">
                        <div class="col-md-7">
                            <div class="pl-12 py-10">
                                <h3 class="font-weight-bolder font-size-h2 mb-1">
                                    <a href="#" class="text-dark-75">{product.name}</a>
                                </h3>
                                <div class="text-primary font-size-h4 mb-9">${product.price}</div>
                                <div class="font-size-sm mb-8">{product.description ? product.description : product.name}</div>
                                <div class="d-flex mb-3">
                                    <span class="text-dark-50 flex-root font-weight-bold">Brand</span>
                                    <span class="text-dark flex-root font-weight-bold">{product.brand}</span>
                                </div>
                                <div class="d-flex mb-3">
                                    <span class="text-dark-50 flex-root font-weight-bold">SKU</span>
                                    <span class="text-dark flex-root font-weight-bold">{product.sku}</span>
                                </div>
                                <div class="d-flex mb-3">
                                    <span class="text-dark-50 flex-root font-weight-bold">Size Per Unit</span>
                                    <span class="text-dark flex-root font-weight-bold">{`${product.unitSize} / ${product.unitMeasurement}`}</span>
                                </div>
                                <div class="d-flex mb-3">
                                    <span class="text-dark-50 flex-root font-weight-bold">QoH</span>
                                    <span class="text-dark flex-root font-weight-bold">{product.qtyOnHand}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="row justify-content-center align-items-center h-100">

                                <img src={product.imagePath} alt="" style={{maxHeight:"250px",maxWidth:"250px"}}/>


                            </div>
                        </div>
                    </div>
                    <div className="row col-12">
                        <div className="col-12">
                            <div className="card-body">
                                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                    <thead>
                                    <tr className="text-lect">
                                        <th className="pr-0" style={{minWidth: "150px"}}>
                                            Shipping To
                                        </th>
                                        <th style={{ minWidth: "150px" }}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {locationList.map((location,index) =>(
                                        <tr key={location.locationName + '-' + index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                                    {/* <label  className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                                        <input type="checkbox" name="" onChange={(e) => handleLocationChange(e,location,index)}/>
                                                        <span></span>
                                                    </label> */}
                                                    <div className="d-flex flex-column flex-grow-1">
                                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg pl-2">
                                                    {location.locationName}
                                                </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-12 text-center">
                                                    <div className="mb-3">
                                                        <button className="btn btn-xs btn-light-success btn-icon mr-2" onClick={() => {
                                                            handleAmountChange(location,location.amount - 1)
                                                        }}>
                                                            <i className="ki ki-minus icon-xs"></i>
                                                        </button>
                                                        <span className="mr-2 font-weight-bolder">{location.amount}</span>
                                                        <button className="btn btn-xs btn-light-success btn-icon mr-2" onClick={() => {
                                                            handleAmountChange(location,location.amount + 1)
                                                        }} >
                                                            <i className="ki ki-plus icon-xs"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row col-12 justify-content-center">
                        <button type="button" className="btn btn-primary" onClick={() => addToCart()}>Add to Cart</button>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}