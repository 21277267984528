import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { put, select, takeLatest } from "redux-saga/effects";
import {getUserByToken, logoutUser} from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  ToLogout: "[To Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  GetToken: "[Get Token] Action",
  GetUserType: "[GET USER TYPE] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  destinationPath: undefined
};

export const reducer = persistReducer(
  { storage, key: "v706-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.ToLogout: {
        return state;
      }

      case actionTypes.UserLoaded: {
        // console.log("Payload: ", action.payload)
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.GetToken: {
        const { authToken } = action.payload;

        return { ...state, authToken };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  toLogout: () => ({type: actionTypes.ToLogout}),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  getToken: authToken => ({ type: actionTypes.GetToken, payload: { authToken } })
};

const getToken = state => state.authToken;

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    console.log("Aqui adentro: ");
    yield put(actions.requestUser());
  });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });

  yield takeLatest(actionTypes.Logout, function* loggedOut() {
    console.log("Paramantero");

    yield logoutUser();
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {

    console.log(yield select(getToken))
    const { data: user } = yield getUserByToken(yield select());    

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UserLoaded, function* userLoaded(){
    yield localStorage.setItem('loggingIn', false);
  })
}
