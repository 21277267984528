import React, { useState } from "react";
import { postLocations } from '../CommonApplication/CommonApplicationCrud'
import { MyTextInput } from '../CustomFormInputs'
import { injectIntl } from "react-intl";
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import { useFormik, Form, Formik } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from "yup";
import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../_metronic/_partials/controls";
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import FormControl from "@material-ui/core/FormControl";
import {RegionDropdown} from "react-country-region-selector";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
      width: '90%',
    },
    paper: {
      padding: theme.spacing(2, 2),
      // height: 140,
      // width: 300,
    },
    modalPaper: {
      position: 'absolute',
      // width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  function PhoneMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  function CatastroMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  function EINMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  function ZipcodeMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, /\d/, /\d/ ]}
        // placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
PhoneMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
CatastroMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
EINMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
ZipcodeMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};

const newLocationInitialValues = {
    locationAddress: "",
    locationCity: "",
    locationZipcode: "",
    locationCatastro: "",
}

function CompanySummary(props) {
    const { intl, businessInformation, companyLocations, setCompanyLocations, accessToken, setIsCompanySummary, user } = props;
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [country] = useState('Puerto Rico');
    const [openDiaogue, setOpenDiaogue] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const handleOpen = () => {
        if(companyLocations.length > 0 && user.tenant == process.env.REACT_APP_BIOWARE_TENANT){
            handleOpenDialogue("To add more than one location they must be added in Sage.")
        } else{
            setOpen(true);
        }
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBack = () => {
        setIsCompanySummary(false);
    };

    function handleCloseDialogue() {
        setOpenDiaogue(false);
        setDialogMessage("")
        // if(isOrdersValid){
        //     dispatch(cartCountActions.getProductCount(accessToken, user.id))
        //     props.history.push('/orders');
        // }
        
    }

    function handleOpenDialogue(message){
        setDialogMessage(message)
        setOpenDiaogue(true);
    }

    // const handleSubmit = () => {
    // props.handleSubmit()
    // handleClose()
    // }

    const LocationInformationSchema = Yup.object().shape({
        locationName: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationAddress: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationCity: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationZipcode: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
        locationCatastro: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
        intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        ),
      });
    const addLocationFormik = useFormik({
        initialValues: newLocationInitialValues,
        validationSchema: LocationInformationSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm  }) => {
            const location = {
                companyId : businessInformation._id,
                ...values
            }

            
            const locations = {
                locations : [ location ]
            }

            postLocations(locations, accessToken).then((response) => {
                setCompanyLocations(companyLocations => companyLocations.concat(location))
                resetForm(newLocationInitialValues)
            }).catch(error => console.error(error));
            
            setTimeout(() => {
                // alert("Your common application has been submited.");
                handleClose()
                setSubmitting(false);
            }, 400);
        },
    });

    return (
        <div>
            <div>
                <div className="col-12">
                    <button type="button" className="btn btn-primary mb-6" onClick={() => handleBack()} >Back</button>
                    <Card>
                        <CardHeader title="Customer Information">
                        </CardHeader>
                        <CardBody>
                            <Formik
                                initialValues={businessInformation}
                                onSubmit={(values) =>{
                                    console.log(values)
                                }}
                            >
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Corporation Name"
                                                name="corporationName"
                                                type="text"
                                                value={businessInformation.corporationName}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Business Name"
                                                name="businessName"
                                                type="text"
                                                value={businessInformation.businessName}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Business Type"
                                                name="businessType"
                                                type="text"
                                                value={businessInformation.businessType}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Phone Number"
                                                name="businessPhoneNumber"
                                                type="text"
                                                value={businessInformation.businessPhoneNumber}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Email"
                                                name="businessEmail"
                                                type="text"
                                                value={businessInformation.businessEmail}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Fax Number"
                                                name="businessFax"
                                                type="text"
                                                value={businessInformation.businessFax}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MyTextInput
                                                label="Business Establishment Date"
                                                name="businessEstablishmentDate                                        "
                                                type="text"
                                                value={businessInformation.businessEstablishmentDate}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Business Description"
                                                name="placeOfBusinessDescription"
                                                type="text"
                                                value={businessInformation.placeOfBusinessDescription}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Address"
                                                name="deliveryAddress                                        "
                                                type="text"
                                                value={businessInformation.deliveryAddress}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="City"
                                                name="deliveryCity"
                                                type="text"
                                                value={businessInformation.deliveryCity}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Zipcode"
                                                name="deliveryZipcode"
                                                type="text"
                                                value={businessInformation.deliveryZipcode}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            </Formik>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </div>

                <div className="col-12">
                    <Card>
                        <CardHeader title="Customer Locations">
                            <CardHeaderToolbar>
                                <button 
                                    className="btn btn-success font-weight-bolder font-size-sm"
                                    onClick={() => handleOpen()}
                                >
                                    Add Location
                                </button>
                                <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <div className={classes.modalPaper}>
                                    
                                    <form
                                        id="kt_login_signin_form"
                                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                                        onSubmit={addLocationFormik.handleSubmit}
                                        >
                                        {/* begin: Alert */}
                                        {addLocationFormik.status && (
                                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                            <div className="alert-text font-weight-bold">{addLocationFormik.status}</div>
                                            </div>
                                        )}
                                        {/* end: Alert */}
                                        <div className="" style={{ display: "block" }}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"locationName"}>Name</label>
                                                    <Input
                                                        placeholder="Name"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="locationName"
                                                        {...addLocationFormik.getFieldProps("locationName")}
                                                    />
                                                    {addLocationFormik.touched.locationName && addLocationFormik.errors.locationName ? (
                                                        <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{addLocationFormik.errors.locationName}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                    {/* begin: Email */}
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <label htmlFor={"locationAddress"}>Address</label>
                                                        <Input
                                                            placeholder="Address"
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                                            name="locationAddress"
                                                            {...addLocationFormik.getFieldProps("locationAddress")}
                                                        />
                                                        {addLocationFormik.touched.locationAddress && addLocationFormik.errors.locationAddress ? (
                                                            <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{addLocationFormik.errors.locationAddress}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {/* end: Email */}
                                                    {/* begin: Email */}
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <label htmlFor={"locationCity"}>City</label>
                                                        <FormControl variant="filled" style={{ width: "100%" }}>
                                                            <RegionDropdown
                                                            name="locationCity"
                                                            classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                            country={country}
                                                            value={addLocationFormik.values.locationCity}
                                                            onChange={(_, e) => addLocationFormik.handleChange(e)} 
                                                            onBlur={addLocationFormik.handleBlur}
                                                            defaultOptionLabel = {addLocationFormik.values.locationCity}
                                                            />
                                                            {addLocationFormik.touched.locationCity && addLocationFormik.errors.locationCity ? (
                                                                <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{addLocationFormik.errors.locationCity}</div>
                                                                </div>
                                                            ) : null}
                                                        </FormControl>
                                                        </div>
                                                    {/* end: Email */}
                                                    
                                                </Grid>    
                                                <Grid item xs={6}>
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                        <label htmlFor={"locationZipcode"}>Zipcode</label>
                                                        <Input
                                                            placeholder="00123"
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                                            name="locationZipcode"
                                                            inputComponent={ZipcodeMaskCustom}
                                                            {...addLocationFormik.getFieldProps("locationZipcode")}
                                                        />
                                                        {addLocationFormik.touched.locationZipcode && addLocationFormik.errors.locationZipcode ? (
                                                            <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{addLocationFormik.errors.locationZipcode}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {/* end: Email */}
                                                    {/* begin: Email */}
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <label htmlFor={"locationCatastro"}>Numero de Catastro</label>
                                                        <Input
                                                            placeholder="###-###-##-##"
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                                            name="locationCatastro"
                                                            inputComponent={CatastroMaskCustom}
                                                            {...addLocationFormik.getFieldProps("locationCatastro")}
                                                        />
                                                        {addLocationFormik.touched.locationCatastro && addLocationFormik.errors.locationCatastro ? (
                                                            <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{addLocationFormik.errors.locationCatastro}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {/* end: Email */}
                                                    
                                                
                                                </Grid>  
                                                <div className="form-group d-flex flex-wrap flex-center">
                                                    <button
                                                    id="addLocationButton"
                                                    type="submit"
                                                    // disabled={formik.isSubmitting}
                                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                                    >
                                                        <span>Save Location</span>
                                                    </button>
                                                </div>
                                                </Grid>
                                            </div>
                                        </form>
                                    </div>
                                </Modal>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table
                                    className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                    id="kt_advance_table_widget_1"
                                >
                                    <thead>
                                        <tr className="text-left">
                                            <th className="pr-0" style={{ minWidth: "150px" }}>
                                            Name
                                            </th>
                                            <th style={{ minWidth: "150px" }}>Address</th>
                                            <th style={{ minWidth: "150px" }}>City</th>
                                            <th style={{ minWidth: "150px" }}>Zipcode</th>
                                            <th style={{ minWidth: "150px" }}>Numero de Catastro</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {companyLocations.map(location => (
                                            <tr key={location._id}>
                                                <td className="pr-0 pl-2 py-2">
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationName}  
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationAddress1} {location.locationAddress2}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationCity}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationZipcode}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationCatastro}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div> 

            <Dialog
                open={openDiaogue}
                onClose={handleCloseDialogue}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialogue} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
}
    
export default injectIntl(CompanySummary);