/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BuyerBasePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route, useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BuyerBasePage from "./pages/Buyer/BuyerBasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {useCookies} from 'react-cookie';
import SupplierBasePage from "./pages/Supplier/SupplierBasePage";
import AdminBasePage from "./pages/Admin/AdminBasePage";
import { getNotifications } from './../redux/actions/notifications';
import * as auth from "../../src/app/modules/Auth/_redux/authRedux";

export function Routes() {

    const location = useLocation()

    const [pathName, setPathName] = useState("")
    const [pathId, setPathId] = useState("")
    const storedUser = useSelector(state => state.auth.user),
        storedToken = useSelector(state => state.auth.authToken)
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies();
    const history = useHistory();

    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    // console.log("API url: ", process.env.REACT_APP_API_URL)

    // Notifications useEffect
    useEffect(() => {
        if (storedUser) {
            dispatch(getNotifications(storedUser.id))
            const interval = setInterval(() => {
                dispatch(getNotifications(storedUser.id))
            }, 60000);
            return () => clearInterval(interval);
        }

    }, []);

    useEffect( () => {
        let pathComponents = location.pathname.split("/")
        const path = pathComponents[1]
        const id = pathComponents[2]
        setPathName(path)
        setPathId(id)
    }, [])

    useEffect(() => {
        let pathComponents = location.pathname.split("/")
        let path = pathComponents[1]

        if(path === '/logout' || path === '/auth/login' || '/callback' || isAuthorized){
            path = '/'
        }

        const destinationPath = localStorage.getItem('destinationPath');

        if(isAuthorized && destinationPath != '' && destinationPath != null){
            localStorage.setItem('destinationPath','');
            history.push(destinationPath);
        }

        if(path !== '/'){
            localStorage.setItem('destinationPath', path);
        }
    })

    // If no session logout
    useEffect(() => {
        const loggingIn = localStorage.getItem("loggingIn");
        // const loggingOut = localStorage.getItem('loggingOut');
        // console.log('La sesionsita', cookies['adminSession'], isAuthorized,localStorage.getItem('loggingOut'));

        if(cookies['adminSession'] === "false" && loggingIn === 'false'){
            // console.log('La sesion', cookies['adminSession'], isAuthorized,localStorage.getItem('loggingOut'));
            // console.log('Path', location.pathname);

            localStorage.setItem('loggingOut',true)
            dispatch(auth.actions.logout())
            // window.location.href = process.env.REACT_APP_OAUTH_LOGOUT;
        }else{
            // console.log("Tengo session: ", cookies['adminSession'], cookies, cookies['adminSession'] == true);
            // console.log("Tengo session 2: ", isAuthorized);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const { accountType } = useSelector(
        ({ auth }) => ({
            accountType: auth.user != null ? auth.user.type : null
        }));

    let basePage = null;

    if (accountType === "buyer") {
        basePage = <BuyerBasePage pathName={pathName} pathId={pathId} />
    } else if (accountType === "supplier") {
        basePage = <SupplierBasePage pathName={pathName} pathId={pathId} />
    } else if (accountType === "admin") {
        basePage = <AdminBasePage />
    }

    let path = location.pathname;

    return (
        <Switch>
             {/* {console.log("Authorized? ", isAuthorized)} */}
            {/*<Route path="/callback" component={Callback}/>*/}
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage destinationPath={path}/>
                </Route>

            ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to={"/auth/login?destinationPath="+location.pathname} state={{redirect: location.pathname}}/>
            ) : (
                <Layout accountType={accountType}>
                    {basePage}
                </Layout>
            )}

        </Switch>
    );
}
