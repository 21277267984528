import { ADD_LOCATION, GET_LOCATIONS_BY_COMPANY_ID_LIST } from "../constants";


const initialState = {
    locations: []
};

export default function locationReducer(state = initialState, action) {
    let locations = []
    let location = null
    switch (action.type) {
        case GET_LOCATIONS_BY_COMPANY_ID_LIST:
            locations = action.payload ? action.payload : state.locations;

            return Object.assign({}, state, {
                locations: locations
            });
        case ADD_LOCATION:
            location = action.payload ? action.payload : location;

            if (location) {
                locations = [...state.locations, location];
                return Object.assign({}, state, {
                    locations: locations
                });
            }
            return state;
        default:
            return state;
    }
}