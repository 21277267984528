import axios from "axios";

export const SUPPLIERS_URL = process.env.REACT_APP_API_URL + "/suppliers";


export function getSupplierByTenant(accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
      }
    return axios.get(`${SUPPLIERS_URL}/tenant`, config)
}