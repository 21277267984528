import React, {useState} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../../_metronic/_partials/controls";
import {AddAttachmentItem} from "./SupplierComponents";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import ListItemText from "@material-ui/core/ListItemText";
import AdminSnackbar from "../AdminSnackbar";

export function SupplierAttachments({supplierId, attachments}) {

    const [edit, setEdit] = useState(false);
    const [list, setList] = useState(attachments);
    const [snackbarAttachments, setSnackbarAttachments] = useState(false);

    function handleEdit(){
        setEdit(true);
    }
 
    return (
        <>
        <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                    <div className="col-md-10">
                                        <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                            <div className="text-center mb-4">
                                                <span className="card-label font-weight-bolder text-dark text-center font-size-lg">Coming Soon</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            {/* {edit ? <AddAttachmentItem supplierId={supplierId} edit={edit} setEdit={setEdit}
                                       attachments={list} setAttachments={setList}
                                       setShowSnackbar={setSnackbarAttachments} showSnackbar={snackbarAttachments}/> :
                <Card>
                    <CardHeader title="Attachments">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => handleEdit()}>
                                Edit Attachments
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        {list ?
                            <List component="nav">

                                {list.map((item, i) => (
                                    <ListItem>
                                        <ListItemIcon>
                                    <span className="svg-icon menu-icon">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Attachment1.svg")}/>
                                     </span>
                                        </ListItemIcon>
                                        <ListItemText primary={item}/>
                                    </ListItem>
                                ))}
                            </List> :
                            <h2>No attachments.</h2>
                        }
                    </CardBody>
                    <AdminSnackbar
                        message={'Attachments updated successfully'}
                        setShowSnackbar={setSnackbarAttachments}
                        showSnackbar={snackbarAttachments}
                        variant={'success'}
                    />

                </Card>
            } */}
        </>
    )
}