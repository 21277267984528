import React, {useState} from "react";
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import {useFormik} from "formik";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {putCommonApplication} from './CommonApplicationCrud'
import SupplierSelection from './SupplierSelection/SupplierSelectionPage'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers"
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import {RegionDropdown} from 'react-country-region-selector';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    paper: {
        padding: theme.spacing(2, 2),
        // height: 140,
        // width: 300,
    },
    modalPaper: {
        position: 'absolute',
        // width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    label: {
        fontSize: 'medium'
    },
}));

function PhoneMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
        />
    );
}

function ZipcodeMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
        />
    );
}

function SSNMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
        />
    );
}

PhoneMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
ZipcodeMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
SSNMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function GetStepContent(props) {

    const classses = useStyles();
    const {
        activeStep,
        // initValues,
        setCommonApplication,
        commonApplication,
        validationSchemas,
        handleNext,
        handleBack,
        handleComplete,
        // completedSteps,
        // totalSteps,
        handleSuppliersChange,
        selectedSuppliers,
        submitCommonApplicaton,
        postSuppliers,
        handleSelectedSuppliersChange,
        suppliers,
        buyer,
        // open,
        // handleOpen,
        // handleClose,
        // modalStyle,
        // classes,
        // companies,
        selectedCompany,
        // setSelectedCompany,
        // submitBusinessInformation,
        personalInitValues,
        partnerInitValues,
        bankInitValues,
        commercialInitValues,
        additionalInitValues,
        // userId,
        accessToken,
        // newCompanyInitialValues,
        // isCompanySelected,
        handleBackStep1,
        setExistingClientList,
        submitSuppliers,
        existingClientList,
        partnerList,
        setPartnerList,
        selectedCompanyLocations,
        locationsCategories,
        setLocationCategories,
        isPropertyOwner,
        setIsPropertyOwner,
        isInLeaseContract,
        setIsInLeaseContract,
        isBeenInvolvedInBankruptcy,
        setIsBeenInvolvedInBankruptcy,
        bankReferenceList,
        setBankReferenceList,
        commercialReferenceList,
        setCommercialReferenceList,
        files,
        setFiles,
        requiredFiles,
        selectedCompanyUnsubmittedLocations,
        newLocationsCategories,
        setNewLocationCategories,
        handleBankDialogueOpen,
        handleCommercialDialogueOpen,
        isMarried,
        setIsMarried,
        isPartnerMarried,
        setIsPartnerMarried
    } = props

    const [isPartnerRequired, setIsPartnerRequired] = useState(partnerList.length > 0)
    const [country] = useState('Puerto Rico');
    // const [personalPhysicalCity, setPersonalPhysicalCity] = useState("");
    // const [personalPostalCity, setPersonalPostalCity] = useState();

    const personalInfoFormik = useFormik({
        initialValues: personalInitValues,
        validationSchema: validationSchemas.PersonalInformationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            try {
                console.log(commonApplication)
                putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
                    setCommonApplication({
                        ...commonApplication,
                        personalInformation: values
                    })
                    // resetForm(partnerInitValues)
                    handleComplete()
                    handleNext()
                    console.log(response)
                })
            } catch (e) {
                alert("An error there has been. Your common application was not saved");
                console.log(e.message);
            }

            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });
    const partnerInfoFormik = useFormik({
        initialValues: partnerInitValues,
        validationSchema: validationSchemas.PartnerInformationSchema,
        onSubmit: (values, {setSubmitting, resetForm}) => {

            setPartnerList(partnerList => partnerList.concat(values))
            resetForm(partnerInitValues)
            setIsPartnerMarried(false)

            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });
    const bankReferenceFormik = useFormik({
        initialValues: bankInitValues,
        validationSchema: validationSchemas.BankReferencesSchema,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setBankReferenceList(bankReferenceList => bankReferenceList.concat(values))
            resetForm(bankInitValues)

            // try {
            //     putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
            //     setCommonApplication({
            //         ...commonApplication,
            //         bankReferenceInformation : values
            //     })
            //     handleComplete()
            //     handleNext()
            //     console.log(response)
            //     })
            // } catch (e) {
            //     alert("An error there has been. Your common application was not saved");
            //     console.log(e.message);
            // }

            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });
    const commercialReferenceFormik = useFormik({
        initialValues: commercialInitValues,
        validationSchema: validationSchemas.CommercialReferencesSchema,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setCommercialReferenceList(commercialReferenceList => commercialReferenceList.concat(values))
            resetForm(commercialInitValues)
            // try {
            //     putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
            //       setCommonApplication({
            //         ...commonApplication,
            //         commercialReferenceInformation : values
            //       })
            //       handleComplete()
            //       handleNext()
            //       console.log(response)
            //     })
            //     } catch (e) {
            //         alert("An error there has been. Your common application was not saved");
            //         console.log(e.message);
            //     }

            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });
    const additionalInfoFormik = useFormik({
        initialValues: additionalInitValues,
        validationSchema: validationSchemas.AdditionalInformationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            try {
                putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
                    setCommonApplication({
                        ...commonApplication,
                        additionalInformation: values
                    })
                    handleComplete()
                    handleNext()
                    console.log(response)
                })
            } catch (e) {
                alert("An error there has been. Your common application was not saved");
                console.log(e.message);
            }

            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });

    const submitPartners = () => {
        console.log("Here!!!!!!!!!!!!!")
        try {
            putCommonApplication(partnerList, selectedCompany._id, accessToken).then((response) => {
                setCommonApplication({
                    ...commonApplication,
                    partnerInformation: partnerList
                })
                handleComplete()
                handleNext()
                console.log(response)
            }).catch((error) => {
                console.log(error)
            });
        } catch (e) {
            console.log(e.message);
            alert("Add at least one partner.")
        }
    }


    const submitBankReferenceList = () => {
        if (bankReferenceList.length > 0) {
            putCommonApplication(bankReferenceList, selectedCompany._id, accessToken).then((response) => {
                setCommonApplication({
                    ...commonApplication,
                    bankReferenceInformation: bankReferenceList
                })
                handleComplete()
                handleNext()
                console.log(response)
            }).catch((error) => {
                console.log(error)
            });
        } else {
            handleBankDialogueOpen()
        }

    }

    const submitCommercialReferenceList = () => {
        if (commercialReferenceList.length > 0) {
            putCommonApplication(commercialReferenceList, selectedCompany._id, accessToken).then((response) => {
                setCommonApplication({
                    ...commonApplication,
                    commercialReferenceInformation: commercialReferenceList
                })
                handleComplete()
                handleNext()
                console.log(response)
            }).catch((error) => {
                console.log(error)
            });
        } else {
            handleCommercialDialogueOpen()
        }

    }

    const removePartner = (driversLicense) => {
        setPartnerList(partnerList => partnerList.filter(partner => partner.partnerDriversLicense !== driversLicense))
    }

    const removeBankReference = (reference) => {
        setBankReferenceList(bankReferenceList => bankReferenceList.filter(currReference => currReference.institutionName !== reference))
    }

    const removeCommercialReference = (reference) => {
        setCommercialReferenceList(commercialReferenceList => commercialReferenceList.filter(currReference => currReference.commercialCompanyName !== reference))
    }

    const handleNextNoPartner = () => {
        handleComplete()
        handleNext()
    }

    switch (activeStep) {

        case 1:
            return (
                <div>
                    {/* <Formik
                initialValues = { personalInitValues }
                validationSchema = { validationSchemas.PersonalInformationSchema }
                enableReinitialize = {true}
                onSubmit = { (values, { setSubmitting, resetForm }) => {
                    try {
                      console.log(commonApplication)
                      putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
                        setCommonApplication({
                          ...commonApplication,
                          personalInformation : values
                        }) 
                        // resetForm(partnerInitValues)
                        handleComplete()
                        handleNext()
                        console.log(response)
                      })
                    } catch (e) {
                      alert("An error there has been. Your common application was not saved");
                      console.log(e.message);
                    }
  
                    setTimeout(() => {
                      // alert("Your common application has been submited.");
                      setSubmitting(false);
                    }, 400);
                  } }
            > */}
                    {/* <Form> */}
                    <div>
                        <form
                            id="kt_login_signin_form"
                            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                            onSubmit={personalInfoFormik.handleSubmit}
                        >
                            {/* begin: Alert */}
                            {personalInfoFormik.status && (
                                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">{personalInfoFormik.status}</div>
                                </div>
                            )}
                            {/* end: Alert */}
                            <div className="" style={{display: "block"}}>
                                <div className="text-center mb-10 mb-lg-20">
                                    <h3 className="font-size-h1">
                                        Personal Information
                                        {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                                    </h3>
                                    {/* <p className="text-muted font-weight-bold">
                        Enter your details to be sent to suppliers.
                        </p> */}
                                </div>

                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalFullLegalName"}>Legal Name</label>
                                            <Input
                                                placeholder="Legal Name"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalFullLegalName"
                                                {...personalInfoFormik.getFieldProps("personalFullLegalName")}
                                            />
                                            {personalInfoFormik.touched.personalFullLegalName && personalInfoFormik.errors.personalFullLegalName ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalFullLegalName}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPhone"}>Phone Number</label>
                                            <Input
                                                placeholder="(787) 555-8888"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalPhone"
                                                inputComponent={PhoneMaskCustom}
                                                {...personalInfoFormik.getFieldProps("personalPhone")}
                                            />
                                            {personalInfoFormik.touched.personalPhone && personalInfoFormik.errors.personalPhone ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalPhone}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalSocialSecurity"}>Social Security</label>
                                            <Input
                                                placeholder="555 55 1234"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalSocialSecurity"
                                                inputComponent={SSNMaskCustom}
                                                {...personalInfoFormik.getFieldProps("personalSocialSecurity")}
                                            />
                                            {personalInfoFormik.touched.personalSocialSecurity && personalInfoFormik.errors.personalSocialSecurity ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalSocialSecurity}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPhysicalAddress"}>Physical Address</label>
                                            <Input
                                                placeholder="Physical Address"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalPhysicalAddress"
                                                {...personalInfoFormik.getFieldProps("personalPhysicalAddress")}
                                            />
                                            {personalInfoFormik.touched.personalPhysicalAddress && personalInfoFormik.errors.personalPhysicalAddress ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalPhysicalAddress}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPhysicalCity"}>Physical City</label>
                                            <FormControl variant="filled" style={{width: "100%"}}>
                                                <RegionDropdown
                                                    name="personalPhysicalCity"
                                                    classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                    country={country}
                                                    value={personalInfoFormik.values.personalPhysicalCity}
                                                    onChange={(_, e) => personalInfoFormik.handleChange(e)}
                                                    onBlur={personalInfoFormik.handleBlur}
                                                    defaultOptionLabel={personalInfoFormik.values.personalPhysicalCity}
                                                />
                                                {personalInfoFormik.touched.personalPhysicalCity && personalInfoFormik.errors.personalPhysicalCity ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{personalInfoFormik.errors.personalPhysicalCity}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>

                                        {/* begin: Email */}
                                        {/* <div className="form-group fv-plugins-icon-container">
                            <FormControl variant="filled" className={classes.formControl}>

                                <RegionDropdown
                                    {...personalInfoFormik.getFieldProps("physicalCity")}
                                    className={'form-control form-control-solid h-auto py-5 px-6'}
                                    // className={`form-control form-control-solid h-auto`}
                                    country={country}
                                    value={personalPhysicalCity}
                                    defaultOptionLabel = 'City'
                                    onChange={(val) => setPersonalPhysicalCity(val)} />

                                {personalInfoFormik.touched.physicalCity && personalInfoFormik.errors.physicalCity ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{personalInfoFormik.errors.physicalCity}</div>
                                    </div>
                                ) : null}


                            </FormControl>

                        </div> */}
                                        {/* <div className="form-group fv-plugins-icon-container">
                            <label htmlFor={"personalPhysicalCity"}>Physical City</label>
                            <Input
                                placeholder="Dorado"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="personalPhysicalCity"
                                {...personalInfoFormik.getFieldProps("personalPhysicalCity")}
                            />
                            {personalInfoFormik.touched.personalPhysicalCity && personalInfoFormik.errors.personalPhysicalCity ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{personalInfoFormik.errors.personalPhysicalCity}</div>
                                </div>
                            ) : null}
                          </div> */}
                                        {/* end: Email */}

                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPhysicalZipcode"}>Physical Zipcode</label>
                                            <Input
                                                placeholder="00646"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalPhysicalZipcode"
                                                inputComponent={ZipcodeMaskCustom}
                                                {...personalInfoFormik.getFieldProps("personalPhysicalZipcode")}
                                            />
                                            {personalInfoFormik.touched.personalPhysicalZipcode && personalInfoFormik.errors.personalPhysicalZipcode ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalPhysicalZipcode}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                        {/* <MyTextInput
                          label="Full Legal Name"
                          name="personalFullLegalName"
                          type="text"
                          placeholder="Jose Melendez"
                      />
                      
                      <MyTextInput
                          label="Phone"
                          name="personalPhone"
                          type="text"
                          placeholder="(787) 123-456"
                      />
                      
                      <MyTextInput
                          label="Social Security"
                          name="socialSecurity"
                          type="text"
                          placeholder="12338910896"
                      />
  
                      <MyTextInput
                          label="Physycal Address"
                          name="personalPhysicalAddress"
                          type="text"
                          placeholder="Por ahi"
                      />
                      
                      <MyTextInput
                          label="Physical City"
                          name="personalPhysicalCity"
                          type="text"
                          placeholder="Loiza"
                      />
                      
                      <MyTextInput
                          label="Physical Zip Code"
                          name="personalPhysicalZipcode"
                          type="text"
                          placeholder="00321"
                      /> */}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPostalAddress"}>Postal Address</label>
                                            <Input
                                                placeholder="Postal Address"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalPostalAddress"
                                                {...personalInfoFormik.getFieldProps("personalPostalAddress")}
                                            />
                                            {personalInfoFormik.touched.personalPostalAddress && personalInfoFormik.errors.personalPostalAddress ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalPostalAddress}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPostalCity"}>Postal City</label>
                                            <FormControl variant="filled" style={{width: "100%"}}>
                                                <RegionDropdown
                                                    name="personalPostalCity"
                                                    classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                    country={country}
                                                    value={personalInfoFormik.values.personalPostalCity}
                                                    onChange={(_, e) => personalInfoFormik.handleChange(e)}
                                                    onBlur={personalInfoFormik.handleBlur}
                                                    defaultOptionLabel={personalInfoFormik.values.personalPostalCity}
                                                />
                                                {personalInfoFormik.touched.personalPostalCity && personalInfoFormik.errors.personalPostalCity ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{personalInfoFormik.errors.personalPostalCity}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>

                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalPostalZipcode"}>Postal Zipcode</label>
                                            <Input
                                                placeholder="Postal Zipcode"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalPostalZipcode"
                                                inputComponent={ZipcodeMaskCustom}
                                                {...personalInfoFormik.getFieldProps("personalPostalZipcode")}
                                            />
                                            {personalInfoFormik.touched.personalPostalZipcode && personalInfoFormik.errors.personalPostalZipcode ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalPostalZipcode}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalDriversLicense"}>Drivers License Number</label>
                                            <Input
                                                placeholder="835567828363"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalDriversLicense"
                                                {...personalInfoFormik.getFieldProps("personalDriversLicense")}
                                            />
                                            {personalInfoFormik.touched.personalDriversLicense && personalInfoFormik.errors.personalDriversLicense ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalDriversLicense}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"personalBirthDate"}>Birth Date</label>
                                            <Input
                                                type="date"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="personalBirthDate"
                                                {...personalInfoFormik.getFieldProps("personalBirthDate")}
                                            />
                                            {personalInfoFormik.touched.personalBirthDate && personalInfoFormik.errors.personalBirthDate ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{personalInfoFormik.errors.personalBirthDate}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}
                                        {/* begin: Physical City */}
                                        <FormControlLabel

                                            control={
                                                <Switch checked={isMarried}
                                                        onChange={() => setIsMarried(isMarried => !isMarried)}/>
                                            }
                                            label={<Typography className={classses.label}>Are you married?</Typography>}
                                        />
                                        {/* <div className="form-group fv-plugins-icon-container">
                            <FormControl variant="filled" style={{ width: "100%" }}>
                              <InputLabel >Married?</InputLabel>
                              <Select
                                input={<FilledInput name="personalIsMarried" id="personalIsMarried"
                                type="text"
                                className={`form-control form-control-solid h-auto`}
                                {...personalInfoFormik.getFieldProps("personalIsMarried")}
                                />}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"true"}>Yes</MenuItem>
                                <MenuItem value={"false"}>No</MenuItem>
                              </Select>
                              {personalInfoFormik.touched.personalIsMarried && personalInfoFormik.errors.personalIsMarried ? (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{personalInfoFormik.errors.personalIsMarried}</div>
                                  </div>
                                ) : null}
                            </FormControl>
                          </div> */}
                                        {/* end: Physical City */}
                                        {/* begin: Email */}
                                        {isMarried ?
                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"personalSpouseFullName"}>Spouse Full Name</label>
                                                <Input
                                                    placeholder="Juanita Perez"
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="personalSpouseFullName"
                                                    {...personalInfoFormik.getFieldProps("personalSpouseFullName")}
                                                />
                                                {personalInfoFormik.touched.personalSpouseFullName && personalInfoFormik.errors.personalSpouseFullName ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{personalInfoFormik.errors.personalSpouseFullName}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            :
                                            null
                                        }
                                        {/* end: Email */}
                                        {/* <MyTextInput
                          label="Postal Address"
                          name="personalPostalAddress"
                          type="text"
                          placeholder="Por alla"
                      />
  
                      <MyTextInput
                          label="Postal City"
                          name="personalPostalCity"
                          type="text"
                          placeholder="Lares"
                      />
                      
                      <MyTextInput
                          label="Postal Zip Code"
                          name="personalPostalZipcode"
                          type="text"
                          placeholder="00333"
                      />
  
                      <MyTextInput
                          label="Drivers License"
                          name="personalDriversLicense"
                          type="text"
                          placeholder="1234557789"
                      />
                      
                      <MyTextInput
                          label="Birth Date"
                          name="birthDate"
                          type="text"
                          placeholder="01-02-2020"
                      /> */}

                                        {/* <MyTextInput
                          label="Married?"
                          name="isMarried"
                          type="text"
                          placeholder="Yes/No/On week days"
                      /> */}

                                        {/* <YesNoSelect
                          label="Married?"
                          name="isMarried"
                          type="text"
                      />
                      
                      <MyTextInput
                          label="Spouse Full Name"
                          name="spouseFullName"
                          type="text"
                          placeholder=""
                      /> */}

                                    </Grid>
                                    <div className="form-group d-flex flex-wrap flex-center">
                                        <button
                                            type="button"
                                            onClick={() => handleBackStep1()}
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Back</span>
                                        </button>

                                        <button
                                            id="personalInfoSubmit"
                                            type="submit"
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Save and Continue</span>
                                        </button>
                                    </div>
                                </Grid>
                            </div>
                        </form>
                    </div>
                    {/* </Form> */}
                    {/* </Formik> */}
                </div>
            );
        case 2:
            return (

                <div>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Partners Information
                            {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                        </h3>
                        {/* <p className="text-muted font-weight-bold">
              Enter your details to be sent to suppliers.
              </p> */}
                    </div>
                    {!isPartnerRequired ?
                        <div>
                            <FormGroup row>
                                <FormControlLabel

                                    control={
                                        <Switch checked={isPartnerRequired}
                                                onChange={() => setIsPartnerRequired(isPartnerRequired => !isPartnerRequired)}/>
                                    }
                                    label={<Typography className={classses.label}>Does the company have one or more
                                        partners?</Typography>}
                                />
                            </FormGroup>
                            <div className="form-group d-flex flex-wrap flex-center">
                                <button
                                    type="button"
                                    onClick={() => handleBack()}
                                    // disabled={formik.isSubmitting}
                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                >
                                    <span>Back</span>
                                </button>

                                <button
                                    type="button"
                                    onClick={() => handleNextNoPartner()}
                                    // disabled={formik.isSubmitting}
                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                >
                                    <span>Save and Continue</span>
                                </button>
                            </div>
                        </div>
                        :
                        <div>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch checked={isPartnerRequired}
                                                onChange={() => setIsPartnerRequired(isPartnerRequired => !isPartnerRequired)}/>
                                    }
                                    label={<Typography className={classses.label}>Does the company have one or more
                                        partners?</Typography>}
                                />
                            </FormGroup>
                            <div className="" style={{display: "block"}}>

                                <div className="table-responsive mb-10">
                                    <table
                                        className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                        id="kt_advance_table_widget_1"
                                    >
                                        <thead>
                                        <tr className="text-left">
                                            <th className="pr-0" style={{minWidth: "150px"}}>
                                                Full Name
                                            </th>
                                            <th style={{minWidth: "150px"}}>Title</th>
                                            <th style={{minWidth: "150px"}}>Phone Number</th>
                                            <th className="text-center" style={{minWidth: "150px"}}>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {partnerList.map((partner, index) => (
                                            <tr key={`${partner.partnerDriversLicense}-${index}`}>
                                                <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {partner.partnerFullLegalName}
                            </span>
                                                </td>
                                                <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {partner.partnerTitle}
                            </span>
                                                </td>
                                                <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {partner.partnerPhone}
                            </span>
                                                </td>
                                                <td className="pr-0 text-center">
                                                    <a
                                                        href="javascript:void(0);"
                                                        onClick={() => removePartner(partner.partnerDriversLicense)}
                                                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Trash.svg"
                                    )}
                                ></SVG>
                              </span>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <form
                                id="kt_login_signin_form"
                                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                                onSubmit={partnerInfoFormik.handleSubmit}
                            >
                                {/* begin: Alert */}
                                {partnerInfoFormik.status && (
                                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                        <div className="alert-text font-weight-bold">{partnerInfoFormik.status}</div>
                                    </div>
                                )}
                                {/* end: Alert */}
                                <div>
                                    <div className="" style={{display: "block"}}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerFullLegalName"}>Full Legal Name</label>
                                                    <Input
                                                        placeholder="Full Name"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerFullLegalName"
                                                        {...partnerInfoFormik.getFieldProps("partnerFullLegalName")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerFullLegalName && partnerInfoFormik.errors.partnerFullLegalName ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerFullLegalName}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* <MyTextInput
                        label="Full Legal Name"
                        name="partnerFullLegalName"
                        type="text"
                        placeholder="Jose Melendez"
                    /> */}

                                                {/* <MyTextInput
                        label="Title"
                        name="partnerTitle"
                        type="text"
                        placeholder="Title"
                    /> */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerTitle"}>Title</label>
                                                    <Input
                                                        placeholder="Title"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerTitle"
                                                        {...partnerInfoFormik.getFieldProps("partnerTitle")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerTitle && partnerInfoFormik.errors.partnerTitle ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerTitle}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPhone"}>Phone Number</label>
                                                    <Input
                                                        placeholder="(787) 555-8888"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerPhone"
                                                        inputComponent={PhoneMaskCustom}
                                                        {...partnerInfoFormik.getFieldProps("partnerPhone")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerPhone && partnerInfoFormik.errors.partnerPhone ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerPhone}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerSocialSecurity"}>Social Security</label>
                                                    <Input
                                                        placeholder="555 55 1234"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerSocialSecurity"
                                                        inputComponent={SSNMaskCustom}
                                                        {...partnerInfoFormik.getFieldProps("partnerSocialSecurity")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerSocialSecurity && partnerInfoFormik.errors.partnerSocialSecurity ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerSocialSecurity}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerDriversLicense"}>Drivers License
                                                        Number</label>
                                                    <Input
                                                        placeholder="835567828363"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerDriversLicense"
                                                        {...partnerInfoFormik.getFieldProps("partnerDriversLicense")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerDriversLicense && partnerInfoFormik.errors.partnerDriversLicense ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerDriversLicense}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerBirthDate"}>Birth Date</label>
                                                    <Input
                                                        type="date"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerBirthDate"
                                                        {...partnerInfoFormik.getFieldProps("partnerBirthDate")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerBirthDate && partnerInfoFormik.errors.partnerBirthDate ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerBirthDate}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* begin: Physical City */}
                                                <FormControlLabel

                                                    control={
                                                        <Switch checked={isPartnerMarried}
                                                                onChange={() => setIsPartnerMarried(isPartnerMarried => !isPartnerMarried)}/>
                                                    }
                                                    label={<Typography className={classses.label}>Are you
                                                        married?</Typography>}
                                                />
                                                {/* <div className="form-group fv-plugins-icon-container">
                            <FormControl variant="filled" style={{ width: "100%" }}>
                              <InputLabel >Married?</InputLabel>
                              <Select
                                Input={<FilledInput name="partnerIsMarried" id="partnerIsMarried"
                                type="text"
                                className={`form-control form-control-solid h-auto`}
                                {...partnerInfoFormik.getFieldProps("partnerIsMarried")}
                                />}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"true"}>Yes</MenuItem>
                                <MenuItem value={"false"}>No</MenuItem>
                              </Select>
                              {partnerInfoFormik.touched.partnerIsMarried && partnerInfoFormik.errors.partnerIsMarried ? (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{partnerInfoFormik.errors.partnerIsMarried}</div>
                                  </div>
                                ) : null}
                            </FormControl>
                          </div> */}
                                                {/* end: Physical City */}
                                                {/* begin: Email */}
                                                {isPartnerMarried ?
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <label htmlFor={"partnerSpouseFullName"}>Spouse Full
                                                            Name</label>
                                                        <Input
                                                            placeholder="Juanita Perez"
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                                            name="partnerSpouseFullName"
                                                            {...partnerInfoFormik.getFieldProps("partnerSpouseFullName")}
                                                        />
                                                        {partnerInfoFormik.touched.partnerSpouseFullName && partnerInfoFormik.errors.partnerSpouseFullName ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{partnerInfoFormik.errors.partnerSpouseFullName}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {/* end: Email */}

                                                {/* <MyTextInput
                        label="Physycal Address"
                        name="partnerPhysicalAddress"
                        type="text"
                        placeholder="Por ahi"
                    /> */}

                                            </Grid>
                                            <Grid item xs={6}>
                                                {/* <MyTextInput
                        label="Postal Address"
                        name="partnerPostalAddres"
                        type="text"
                        placeholder="Por alla"
                    /> */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPhysicalAddress"}>Physical Address</label>
                                                    <Input
                                                        placeholder="Address"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerPhysicalAddress"
                                                        {...partnerInfoFormik.getFieldProps("partnerPhysicalAddress")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerPhysicalAddress && partnerInfoFormik.errors.partnerPhysicalAddress ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerPhysicalAddress}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}

                                                {/* <MyTextInput
                        label="Physical City"
                        name="partnerPhysicalCity"
                        type="text"
                        placeholder="Loiza"
                    /> */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPhysicalCity"}>Physical City</label>
                                                    <FormControl variant="filled" style={{width: "100%"}}>
                                                        <RegionDropdown
                                                            name="partnerPhysicalCity"
                                                            classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                            country={country}
                                                            value={partnerInfoFormik.values.partnerPhysicalCity}
                                                            onChange={(_, e) => partnerInfoFormik.handleChange(e)}
                                                            onBlur={partnerInfoFormik.handleBlur}
                                                            defaultOptionLabel={partnerInfoFormik.values.partnerPhysicalCity}
                                                        />
                                                        {partnerInfoFormik.touched.partnerPhysicalCity && partnerInfoFormik.errors.partnerPhysicalCity ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{partnerInfoFormik.errors.partnerPhysicalCity}</div>
                                                            </div>
                                                        ) : null}
                                                    </FormControl>
                                                </div>

                                                {/* end: Email */}

                                                {/* <MyTextInput
                        label="Physical Zip Code"
                        name="partnerPhysicalZipcode"
                        type="text"
                        placeholder="00321"
                    /> */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPhysicalZipcode"}>Physical Zipcode</label>
                                                    <Input
                                                        placeholder="00646"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerPhysicalZipcode"
                                                        inputComponent={ZipcodeMaskCustom}
                                                        {...partnerInfoFormik.getFieldProps("partnerPhysicalZipcode")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerPhysicalZipcode && partnerInfoFormik.errors.partnerPhysicalZipcode ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerPhysicalZipcode}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPostalAddress"}>Postal Address</label>
                                                    <Input
                                                        placeholder="Postal"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerPostalAddress"
                                                        {...partnerInfoFormik.getFieldProps("partnerPostalAddress")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerPostalAddress && partnerInfoFormik.errors.partnerPostalAddress ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerPostalAddress}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}

                                                {/* <MyTextInput
                        label="Postal City"
                        name="partnerPostalCity"
                        type="text"
                        placeholder="Lares"
                    /> */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPostalCity"}>Postal City</label>
                                                    <FormControl variant="filled" style={{width: "100%"}}>
                                                        <RegionDropdown
                                                            name="partnerPostalCity"
                                                            classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                            country={country}
                                                            value={partnerInfoFormik.values.partnerPostalCity}
                                                            onChange={(_, e) => partnerInfoFormik.handleChange(e)}
                                                            onBlur={partnerInfoFormik.handleBlur}
                                                            defaultOptionLabel={partnerInfoFormik.values.partnerPostalCity}
                                                        />
                                                        {partnerInfoFormik.touched.partnerPostalCity && partnerInfoFormik.errors.partnerPostalCity ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{partnerInfoFormik.errors.partnerPostalCity}</div>
                                                            </div>
                                                        ) : null}
                                                    </FormControl>
                                                </div>
                                                {/* end: Email */}

                                                {/* <MyTextInput
                            label="Postal Zip Code"
                            name="partnerPostalZipcode"
                            type="text"
                            placeholder="00333"
                        /> */}
                                                {/* begin: Email */}
                                                <div className="form-group fv-plugins-icon-container">
                                                    <label htmlFor={"partnerPostalZipcode"}>Postal Zipcode</label>
                                                    <Input
                                                        placeholder="00333"
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="partnerPostalZipcode"
                                                        inputComponent={ZipcodeMaskCustom}
                                                        {...partnerInfoFormik.getFieldProps("partnerPostalZipcode")}
                                                    />
                                                    {partnerInfoFormik.touched.partnerPostalZipcode && partnerInfoFormik.errors.partnerPostalZipcode ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{partnerInfoFormik.errors.partnerPostalZipcode}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {/* end: Email */}

                                            </Grid>
                                            <div className="form-group d-flex flex-wrap flex-center">
                                                <button
                                                    type="button"
                                                    onClick={() => handleBack()}
                                                    // disabled={formik.isSubmitting}
                                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                                >
                                                    <span>Back</span>
                                                </button>

                                                <button
                                                    type="submit"
                                                    // disabled={formik.isSubmitting}
                                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                                >
                                                    <span>Add Partner</span>
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => submitPartners()}
                                                    // disabled={formik.isSubmitting}
                                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                                >
                                                    <span>Save and Continue</span>
                                                </button>
                                            </div>
                                        </Grid>

                                    </div>
                                </div>
                                {/* </Form>
            </Formik> */}
                            </form>
                        </div>
                    }
                </div>

            )
        case 3:
            return (


                //     <Formik
                //     initialValues = { bankInitValues }
                //     validationSchema = { validationSchemas.BankReferencesSchema }
                //     enableReinitialize = {true}
                //     onSubmit = { (values, { setSubmitting }) => {
                //         try {
                //           putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
                //             setCommonApplication({
                //               ...commonApplication,
                //               bankReferenceInformation : values
                //             })
                //             handleComplete()
                //             handleNext()
                //             console.log(response)
                //           })
                //         } catch (e) {
                //           alert("An error there has been. Your common application was not saved");
                //           console.log(e.message);
                //         }

                //         setTimeout(() => {
                //           // alert("Your common application has been submited.");
                //           setSubmitting(false);
                //         }, 400);
                //       } }
                //    >
                // <Form>
                <div>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Bank References
                            {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                        </h3>
                        {/* <p className="text-muted font-weight-bold">
                Enter your details to be sent to suppliers.
                </p> */}
                    </div>
                    <div className="" style={{display: "block"}}>
                        <div className="table-responsive mb-10">
                            <table
                                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                id="kt_advance_table_widget_1"
                            >
                                <thead>
                                <tr className="text-left">
                                    <th className="pr-0" style={{minWidth: "150px"}}>
                                        Intitution Name
                                    </th>
                                    <th style={{minWidth: "150px"}}>Account Number</th>
                                    <th style={{minWidth: "150px"}}>Branch</th>
                                    <th style={{minWidth: "150px"}}>Phone Number</th>
                                    <th className="text-center" style={{minWidth: "150px"}}>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {bankReferenceList.map((reference, index) => (
                                    <tr key={`${reference.bankAccountNumber}-${index}`}>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.institutionName}
                        </span>
                                        </td>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.bankAccountNumber}
                        </span>
                                        </td>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.bankBranch}
                        </span>
                                        </td>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.bankPhoneNumber}
                        </span>
                                        </td>
                                        <td className="pr-0 text-center">
                                            <a
                                                href="javascript:void(0);"
                                                onClick={() => removeBankReference(reference.institutionName)}
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                )}
                            ></SVG>
                          </span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={bankReferenceFormik.handleSubmit}
                    >
                        {/* begin: Alert */}
                        {bankReferenceFormik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{bankReferenceFormik.status}</div>
                            </div>
                        )}
                        {/* end: Alert */}
                        <div>
                            <div className="" style={{display: "block"}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"institutionName"}>Institution Name</label>
                                            <Input
                                                placeholder="Banco Boricua"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="institutionName"
                                                {...bankReferenceFormik.getFieldProps("institutionName")}
                                            />
                                            {bankReferenceFormik.touched.institutionName && bankReferenceFormik.errors.institutionName ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{bankReferenceFormik.errors.institutionName}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"bankAccountNumber"}>Account Number</label>
                                            <Input
                                                placeholder="123321213"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="bankAccountNumber"
                                                {...bankReferenceFormik.getFieldProps("bankAccountNumber")}
                                            />
                                            {bankReferenceFormik.touched.bankAccountNumber && bankReferenceFormik.errors.bankAccountNumber ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{bankReferenceFormik.errors.bankAccountNumber}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"bankBranch"}>Branch</label>
                                            <Input
                                                placeholder="Aguadilla"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="bankBranch"
                                                {...bankReferenceFormik.getFieldProps("bankBranch")}
                                            />
                                            {bankReferenceFormik.touched.bankBranch && bankReferenceFormik.errors.bankBranch ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{bankReferenceFormik.errors.bankBranch}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"bankPhoneNumber"}>Phone Number</label>
                                            <Input
                                                placeholder="(787) 123-3214"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="bankPhoneNumber"
                                                inputComponent={PhoneMaskCustom}
                                                {...bankReferenceFormik.getFieldProps("bankPhoneNumber")}
                                            />
                                            {bankReferenceFormik.touched.bankPhoneNumber && bankReferenceFormik.errors.bankPhoneNumber ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{bankReferenceFormik.errors.bankPhoneNumber}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* <div className="form-group fv-plugins-icon-container">
                          <label htmlFor={"bankPhysicalAddress"}>Physycal Address</label>
                          <Input
                              placeholder="Address"
                              type="text"
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="bankPhysicalAddress"
                              {...bankReferenceFormik.getFieldProps("bankPhysicalAddress")}
                          />
                          {bankReferenceFormik.touched.bankPhysicalAddress && bankReferenceFormik.errors.bankPhysicalAddress ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{bankReferenceFormik.errors.bankPhysicalAddress}</div>
                              </div>
                          ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                          <label htmlFor={"bankPhysicalCity"}>Physical City</label>
                          <Input
                              placeholder="Loiza"
                              type="text"
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="bankPhysicalCity"
                              {...bankReferenceFormik.getFieldProps("bankPhysicalCity")}
                          />
                          {bankReferenceFormik.touched.bankPhysicalCity && bankReferenceFormik.errors.bankPhysicalCity ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{bankReferenceFormik.errors.bankPhysicalCity}</div>
                              </div>
                          ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                          <label htmlFor={"bankPhysicalZipcode"}>Physical Zip Code</label>
                          <Input
                              placeholder="00321"
                              type="text"
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="bankPhysicalZipcode"
                              inputComponent={ZipcodeMaskCustom}
                              {...bankReferenceFormik.getFieldProps("bankPhysicalZipcode")}
                          />
                          {bankReferenceFormik.touched.bankPhysicalZipcode && bankReferenceFormik.errors.bankPhysicalZipcode ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{bankReferenceFormik.errors.bankPhysicalZipcode}</div>
                              </div>
                          ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                          <label htmlFor={"bankPostalAddress"}>Postal Address</label>
                          <Input
                              placeholder="Address"
                              type="text"
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="bankPostalAddress"
                              {...bankReferenceFormik.getFieldProps("bankPostalAddress")}
                          />
                          {bankReferenceFormik.touched.bankPostalAddress && bankReferenceFormik.errors.bankPostalAddress ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{bankReferenceFormik.errors.bankPostalAddress}</div>
                              </div>
                          ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                          <label htmlFor={"bankPostalCity"}>Postal City</label>
                          <Input
                              placeholder="Lares"
                              type="text"
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="bankPostalCity"
                              {...bankReferenceFormik.getFieldProps("bankPostalCity")}
                          />
                          {bankReferenceFormik.touched.bankPostalCity && bankReferenceFormik.errors.bankPostalCity ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{bankReferenceFormik.errors.bankPostalCity}</div>
                              </div>
                          ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                          <label htmlFor={"bankPostalZipcode"}>Postal Zip Code</label>
                          <Input
                              placeholder="00333"
                              type="text"
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              name="bankPostalZipcode"
                              inputComponent={ZipcodeMaskCustom}
                              {...bankReferenceFormik.getFieldProps("bankPostalZipcode")}
                          />
                          {bankReferenceFormik.touched.bankPostalZipcode && bankReferenceFormik.errors.bankPostalZipcode ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{bankReferenceFormik.errors.bankPostalZipcode}</div>
                              </div>
                          ) : null}
                        </div> */}
                                    </Grid>
                                    <div className="form-group d-flex flex-wrap flex-center">
                                        <button
                                            type="button"
                                            onClick={() => handleBack()}
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Back</span>
                                        </button>

                                        <button
                                            type="submit"
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Add Reference</span>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => submitBankReferenceList()}
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Save and Continue</span>
                                        </button>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </form>
                </div>
                //     </Form>
                //   </Formik>
            );
        case 4:
            return (
                //   <Formik
                //       initialValues = { commercialInitValues }
                //       validationSchema = { validationSchemas.CommercialReferencesSchema }
                //       enableReinitialize = {true}
                //       onSubmit = { (values, { setSubmitting }) => {
                //           try {
                //             putCommonApplication(values, selectedCompany._id, accessToken).then((response) => {
                //               setCommonApplication({
                //                 ...commonApplication,
                //                 commercialReferenceInformation : values
                //               })
                //               handleComplete()
                //               handleNext()
                //               console.log(response)
                //             })
                //           } catch (e) {
                //             alert("An error there has been. Your common application was not saved");
                //             console.log(e.message);
                //           }

                //           setTimeout(() => {
                //             // alert("Your common application has been submited.");
                //             setSubmitting(false);
                //           }, 400);
                //         } }
                //   >
                //     <Form>
                <div>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Commercial References
                            {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                        </h3>
                        {/* <p className="text-muted font-weight-bold">
                          Enter your details to be sent to suppliers.
                          </p> */}
                    </div>
                    <div className="" style={{display: "block"}}>
                        <div className="table-responsive mb-10">
                            <table
                                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                id="kt_advance_table_widget_1"
                            >
                                <thead>
                                <tr className="text-left">
                                    <th className="pr-0" style={{minWidth: "150px"}}>
                                        Company Name
                                    </th>
                                    <th style={{minWidth: "150px"}}>Account Number</th>
                                    <th style={{minWidth: "150px"}}>Address</th>
                                    <th className="text-center" style={{minWidth: "150px"}}>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {commercialReferenceList.map((reference, index) => (
                                    <tr key={`${reference.commercialCompanyName}-${index}`}>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.commercialCompanyName}
                        </span>
                                        </td>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.commercialAccountNumber}
                        </span>
                                        </td>
                                        <td>
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {reference.commercialPhysicalAddress}
                        </span>
                                            <span className="text-muted font-weight-bold">
                          {`${reference.commercialPhysicalCity}, PR`}
                        </span>
                                        </td>
                                        <td className="pr-0 text-center">
                                            <a
                                                href="javascript:void(0);"
                                                onClick={() => removeCommercialReference(reference.commercialCompanyName)}
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Trash.svg"
                                )}
                            ></SVG>
                          </span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={commercialReferenceFormik.handleSubmit}
                    >
                        {/* begin: Alert */}
                        {commercialReferenceFormik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{commercialReferenceFormik.status}</div>
                            </div>
                        )}
                        {/* end: Alert */}
                        <div>
                            <div className="" style={{display: "block"}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        {/* <MyTextInput
                            label="Company Name"
                            name="commercialCompanyName"
                            type="text"
                            placeholder="Puerto Rico Inc."
                        /> */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialCompanyName"}>Company Name</label>
                                            <Input
                                                placeholder="Puerto Rico Inc."
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="commercialCompanyName"
                                                {...commercialReferenceFormik.getFieldProps("commercialCompanyName")}
                                            />
                                            {commercialReferenceFormik.touched.commercialCompanyName && commercialReferenceFormik.errors.commercialCompanyName ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{commercialReferenceFormik.errors.commercialCompanyName}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                        {/* <MyTextInput
                            label="Account Number"
                            name="commercialAccountNumber"
                            type="text"
                            placeholder="123321123"
                        /> */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialAccountNumber"}>Account Number</label>
                                            <Input
                                                placeholder="123321123"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="commercialAccountNumber"
                                                {...commercialReferenceFormik.getFieldProps("commercialAccountNumber")}
                                            />
                                            {commercialReferenceFormik.touched.commercialAccountNumber && commercialReferenceFormik.errors.commercialAccountNumber ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{commercialReferenceFormik.errors.commercialAccountNumber}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                    </Grid>
                                    <Grid item xs={6}>

                                        {/* <MyTextInput
                            label="Physycal Address"
                            name="commercialPhysicalAddress"
                            type="text"
                            placeholder="Por ahi"
                        /> */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialPhysicalAddress"}>Physycal Address</label>
                                            <Input
                                                placeholder="Address"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="commercialPhysicalAddress"
                                                {...commercialReferenceFormik.getFieldProps("commercialPhysicalAddress")}
                                            />
                                            {commercialReferenceFormik.touched.commercialPhysicalAddress && commercialReferenceFormik.errors.commercialPhysicalAddress ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{commercialReferenceFormik.errors.commercialPhysicalAddress}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                        {/* <MyTextInput
                            label="Physical City"
                            name="commercialPhysicalCity"
                            type="text"
                            placeholder="Loiza"
                        /> */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialPhysicalCity"}>Physical City</label>
                                            <FormControl variant="filled" style={{width: "100%"}}>
                                                <RegionDropdown
                                                    name="commercialPhysicalCity"
                                                    classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                    country={country}
                                                    value={commercialReferenceFormik.values.commercialPhysicalCity}
                                                    onChange={(_, e) => commercialReferenceFormik.handleChange(e)}
                                                    onBlur={commercialReferenceFormik.handleBlur}
                                                    defaultOptionLabel={commercialReferenceFormik.values.commercialPhysicalCity}
                                                />
                                                {commercialReferenceFormik.touched.commercialPhysicalCity && commercialReferenceFormik.errors.commercialPhysicalCity ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{commercialReferenceFormik.errors.commercialPhysicalCity}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>

                                        {/* end: Email */}

                                        {/* <MyTextInput
                            label="Physical Zip Code"
                            name="commercialPhysicalZipcode"
                            type="text"
                            placeholder="00321"
                        />         */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialPhysicalZipcode"}>Physical Zip Code</label>
                                            <Input
                                                placeholder="00321"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="commercialPhysicalZipcode"
                                                inputComponent={ZipcodeMaskCustom}
                                                {...commercialReferenceFormik.getFieldProps("commercialPhysicalZipcode")}
                                            />
                                            {commercialReferenceFormik.touched.commercialPhysicalZipcode && commercialReferenceFormik.errors.commercialPhysicalZipcode ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{commercialReferenceFormik.errors.commercialPhysicalZipcode}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                        {/* <MyTextInput
                            label="Postal Address"
                            name="commercialPostalAddress"
                            type="text"
                            placeholder="Por alla"
                        /> */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialPostalAddress"}>Postal Address</label>
                                            <Input
                                                placeholder="Address"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="commercialPostalAddress"
                                                {...commercialReferenceFormik.getFieldProps("commercialPostalAddress")}
                                            />
                                            {commercialReferenceFormik.touched.commercialPostalAddress && commercialReferenceFormik.errors.commercialPostalAddress ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{commercialReferenceFormik.errors.commercialPostalAddress}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                        {/*
                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialPostalCity"}>Postal City</label>
                                            <FormControl variant="filled" style={{width: "100%"}}>
                                                <RegionDropdown
                                                    name="commercialPostalCity"
                                                    classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                    country={country}
                                                    value={commercialReferenceFormik.values.commercialPostalCity}
                                                    onChange={(_, e) => commercialReferenceFormik.handleChange(e)}
                                                    onBlur={commercialReferenceFormik.handleBlur}
                                                    defaultOptionLabel={commercialReferenceFormik.values.commercialPostalCity}
                                                />
                                                {commercialReferenceFormik.touched.commercialPostalCity && commercialReferenceFormik.errors.commercialPostalCity ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{commercialReferenceFormik.errors.commercialPostalCity}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>
                                        {/* end: Email */}

                                        {/* <MyTextInput
                            label="Postal Zip Code"
                            name="commercialPostalZipcode"
                            type="text"
                            placeholder="00333"
                        /> */}
                                        {/* begin: Email */}
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"commercialPostalZipcode"}>Postal Zip Code</label>
                                            <Input
                                                placeholder="00333"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="commercialPostalZipcode"
                                                inputComponent={ZipcodeMaskCustom}
                                                {...commercialReferenceFormik.getFieldProps("commercialPostalZipcode")}
                                            />
                                            {commercialReferenceFormik.touched.commercialPostalZipcode && commercialReferenceFormik.errors.commercialPostalZipcode ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{commercialReferenceFormik.errors.commercialPostalZipcode}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        {/* end: Email */}

                                    </Grid>
                                    <div className="form-group d-flex flex-wrap flex-center">
                                        <button
                                            type="button"
                                            onClick={() => handleBack()}
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Back</span>
                                        </button>
                                        <button
                                            type="submit"
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Add Reference</span>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => submitCommercialReferenceList()}
                                            // disabled={formik.isSubmitting}
                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                        >
                                            <span>Save and Continue</span>
                                        </button>
                                    </div>
                                </Grid>

                            </div>
                        </div>
                    </form>
                </div>
                // </Formik>
            );
        case 5:
            return (
                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={additionalInfoFormik.handleSubmit}
                >
                    {/* begin: Alert */}
                    {additionalInfoFormik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{additionalInfoFormik.status}</div>
                        </div>
                    )}
                    {/* end: Alert */}
                    <div>
                        <div className="" style={{display: "block"}}>
                            <div className="text-center mb-10 mb-lg-20">
                                <h3 className="font-size-h1">
                                    Additional Information
                                    {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                                </h3>
                                {/* <p className="text-muted font-weight-bold">
                          Enter your details to be sent to suppliers.
                          </p> */}
                            </div>

                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"patentName"}>Patent's Name</label>
                                        <Input
                                            placeholder="Patent's Name"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="patentName"
                                            {...additionalInfoFormik.getFieldProps("patentName")}
                                        />
                                        {additionalInfoFormik.touched.patentName && additionalInfoFormik.errors.patentName ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{additionalInfoFormik.errors.patentName}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"insuranceCompany"}>Insurance Company</label>
                                        <Input
                                            placeholder="Restaurant Assurance"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="insuranceCompany"
                                            {...additionalInfoFormik.getFieldProps("insuranceCompany")}
                                        />
                                        {additionalInfoFormik.touched.insuranceCompany && additionalInfoFormik.errors.insuranceCompany ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{additionalInfoFormik.errors.insuranceCompany}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"liquorLicenseNumber"}>Liquor License Number</label>
                                        <Input
                                            placeholder="1234566789"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="liquorLicenseNumber"
                                            {...additionalInfoFormik.getFieldProps("liquorLicenseNumber")}
                                        />
                                        {additionalInfoFormik.touched.liquorLicenseNumber && additionalInfoFormik.errors.liquorLicenseNumber ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{additionalInfoFormik.errors.liquorLicenseNumber}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"entitledPerson"}>Entitled Person</label>
                                        <Input
                                            placeholder="Entitled Person"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="entitledPerson"
                                            {...additionalInfoFormik.getFieldProps("entitledPerson")}
                                        />
                                        {additionalInfoFormik.entitledPerson && additionalInfoFormik.errors.entitledPerson ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{additionalInfoFormik.errors.entitledPerson}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                </Grid>
                            </Grid>
                            <Divider variant="middle"/>
                            <div className="mt-5">
                                <FormGroup row>
                                    <FormControlLabel

                                        control={
                                            <Switch checked={isPropertyOwner}
                                                    onChange={() => setIsPropertyOwner(isPropertyOwner => !isPropertyOwner)}/>
                                        }
                                        label={<Typography className={classses.label}>Are you the Property
                                            Owner?</Typography>}
                                    />
                                </FormGroup>
                                {isPropertyOwner ?
                                    <Grid container spacing={3}>

                                        <Grid item xs={6}>
                                            {/* <div className="form-group fv-plugins-icon-container">
                              <FormControl variant="filled" style={{ width: "100%" }}>
                                <InputLabel >Are you the Property Owner?</InputLabel>
                                <Select
                                  Input={<FilledInput name="isPropertyOwner" id="isPropertyOwner"
                                  type="text"
                                  className={`form-control form-control-solid h-auto`}
                                  {...additionalInfoFormik.getFieldProps("isPropertyOwner")}
                                  />}
                                >
                                  <MenuItem value={isPropertyOwner}>
                                    <em>{isPropertyOwner}</em>
                                  </MenuItem>
                                  <MenuItem value={"true"}>Yes</MenuItem>
                                  <MenuItem value={"false"}>No</MenuItem>
                                </Select>
                                {additionalInfoFormik.touched.isPropertyOwner && additionalInfoFormik.errors.isPropertyOwner ? (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{additionalInfoFormik.errors.isPropertyOwner}</div>
                                  </div>
                                ) : null}
                              </FormControl>
                            </div> */}
                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"dateOfPurchase"}>Date of Purchase</label>
                                                <Input
                                                    type="date"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="dateOfPurchase"
                                                    {...additionalInfoFormik.getFieldProps("dateOfPurchase")}
                                                />
                                                {additionalInfoFormik.touched.dateOfPurchase && additionalInfoFormik.errors.dateOfPurchase ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{additionalInfoFormik.errors.dateOfPurchase}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"financedBy"}>Financed By</label>
                                                <Input
                                                    placeholder="Bank of PR"
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="financedBy"
                                                    {...additionalInfoFormik.getFieldProps("financedBy")}
                                                />
                                                {additionalInfoFormik.touched.financedBy && additionalInfoFormik.errors.financedBy ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{additionalInfoFormik.errors.financedBy}</div>
                                                    </div>
                                                ) : null}
                                            </div>

                                        </Grid>
                                        <Grid item xs={6}>

                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"mortgageTerm"}>Mortgage Term (in months)</label>
                                                <Input
                                                    placeholder="4"
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="mortgageTerm"
                                                    {...additionalInfoFormik.getFieldProps("mortgageTerm")}
                                                />
                                                {additionalInfoFormik.touched.mortgageTerm && additionalInfoFormik.errors.mortgageTerm ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{additionalInfoFormik.errors.mortgageTerm}</div>
                                                    </div>
                                                ) : null}
                                            </div>

                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }
                            </div>
                            <Divider variant="middle"/>
                            <div className="mt-5">
                                <FormGroup row>
                                    <FormControlLabel

                                        control={
                                            <Switch checked={isInLeaseContract}
                                                    onChange={() => setIsInLeaseContract(isInLeaseContract => !isInLeaseContract)}/>
                                        }
                                        label={<Typography className={classses.label}>Are you in a Lease
                                            Contract?</Typography>}
                                    />
                                </FormGroup>
                                {isInLeaseContract ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            {/* <div className="form-group fv-plugins-icon-container">
                            <FormControl variant="filled" style={{ width: "100%" }}>
                              <InputLabel >Are you in a Lease Contract?</InputLabel>
                              <Select
                                Input={<FilledInput name="isInLeaseContract" id="isInLeaseContract"
                                type="text"
                                className={`form-control form-control-solid h-auto`}
                                {...additionalInfoFormik.getFieldProps("isInLeaseContract")}
                                />}
                              >
                                <MenuItem value={isInLeaseContract}>{isInLeaseContract.toString()}</MenuItem>
                                <MenuItem value={"true"}>Yes</MenuItem>
                                <MenuItem value={"false"}>No</MenuItem>
                              </Select>
                              {additionalInfoFormik.touched.isInLeaseContract && additionalInfoFormik.errors.isInLeaseContract ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{additionalInfoFormik.errors.isInLeaseContract}</div>
                                </div>
                              ) : null}
                            </FormControl>
                          </div> */}
                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"monthlyRent"}>Monthly Rent</label>
                                                <Input
                                                    placeholder="$1,500.00"
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="monthlyRent"
                                                    {...additionalInfoFormik.getFieldProps("monthlyRent")}
                                                />
                                                {additionalInfoFormik.touched.monthlyRent && additionalInfoFormik.errors.monthlyRent ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{additionalInfoFormik.errors.monthlyRent}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>

                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"contractTerm"}>Contract Term (in months)</label>
                                                <Input
                                                    placeholder="360"
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="contractTerm"
                                                    {...additionalInfoFormik.getFieldProps("contractTerm")}
                                                />
                                                {additionalInfoFormik.touched.contractTerm && additionalInfoFormik.errors.contractTerm ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{additionalInfoFormik.errors.contractTerm}</div>
                                                    </div>
                                                ) : null}
                                            </div>

                                        </Grid>
                                    </Grid>
                                    :
                                    null
                                }
                            </div>
                            <Divider variant="middle"/>
                            <div className="mt-5">
                                <FormGroup row>
                                    <FormControlLabel

                                        control={
                                            <Switch checked={isBeenInvolvedInBankruptcy}
                                                    onChange={() => setIsBeenInvolvedInBankruptcy(isBeenInvolvedInBankruptcy => !isBeenInvolvedInBankruptcy)}/>
                                        }
                                        label={<Typography className={classses.label}>Have you, or any businesses you
                                            have been associated with, been involved in any bankruptcy?</Typography>}
                                    />
                                </FormGroup>
                                {isBeenInvolvedInBankruptcy ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>


                                            <div className="form-group fv-plugins-icon-container">
                                                <label htmlFor={"bankruptcyExplanation"}>Explain: </label>
                                                <Input
                                                    placeholder="Aguadilla"
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="bankruptcyExplanation"
                                                    {...additionalInfoFormik.getFieldProps("bankruptcyExplanation")}
                                                />
                                                {additionalInfoFormik.touched.bankruptcyExplanation && additionalInfoFormik.errors.bankruptcyExplanation ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{additionalInfoFormik.errors.bankruptcyExplanation}</div>
                                                    </div>
                                                ) : null
                                                }
                                            </div>
                                        </Grid>


                                    </Grid>
                                    : null}
                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                        type="button"
                                        onClick={() => handleBack()}
                                        // disabled={formik.isSubmitting}
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                    >
                                        <span>Back</span>
                                    </button>
                                    <button
                                        type="submit"
                                        // disabled={formik.isSubmitting}
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                    >
                                        <span>Save and Continue</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                //   </Form>
                // </Formik>
            );
        case 6:
            return (
                <div>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Supplier Selection
                            {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                        </h3>
                        {/* <p className="text-muted font-weight-bold">
                            Enter your details to be sent to suppliers.
                            </p> */}
                    </div>

                    <SupplierSelection handleComplete={handleComplete} selectedSuppliers={selectedSuppliers}
                                       handleChecked={handleSuppliersChange} handleSubmit={submitCommonApplicaton}
                                       postSuppliers={postSuppliers}
                                       handleSelectedSuppliersChange={handleSelectedSuppliersChange}
                                       suppliers={suppliers} buyer={buyer} setExistingClientList={setExistingClientList}
                                       submitSuppliers={submitSuppliers} existingClientList={existingClientList}
                                       selectedCompanyLocations={selectedCompanyLocations}
                                       locationsCategories={locationsCategories}
                                       setLocationCategories={setLocationCategories} accessToken={accessToken}
                                       files={files} setFiles={setFiles} requiredFiles={requiredFiles}
                                       selectedCompanyUnsubmittedLocations={selectedCompanyUnsubmittedLocations}
                                       newLocationsCategories={newLocationsCategories}
                                       setNewLocationCategories={setNewLocationCategories}/>
                    {/* <div className="form-group d-flex flex-wrap flex-center">
                            <button
                              type="button"
                              onClick={() => handleBack()}
                              // disabled={formik.isSubmitting}
                              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                              >
                                  <span>Back</span>
                            </button>
                            <button
                            type="submit"
                            // disabled={formik.isSubmitting}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                <span>{completedSteps() === totalSteps() - 1 ? 'Submit' : 'Save'}</span>
                            </button>
  
                            <SubmitModal selectedSuppliers={selectedSuppliers} handleChecked={handleSuppliersChange} handleSubmit={submitCommonApplicaton}/>
                        </div> */}
                </div>
            );
        default:
            return <h1>Unknown step</h1>;
    }
}
