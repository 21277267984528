import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import React, {useEffect, useRef, useState} from "react";
import { useFormik } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { patchOrder, patchRouteRecordForceQueue, getOrderInfo, cancelOrder} from "./ordersCrud";
import {useSelector} from "react-redux";
import { ProductList } from "./ProductList/ProductList";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function OrderDetails({order,setOrder, setIsOrderDetails, isOrderDetails, 
    setSelectedRecordId, selectedRecordId, setIsOrderUpdated, isOrderUpdated}) {


    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    // const [isOrderUpdated,setIsOrderUpdated] = useState(false)
    const [isDateTodayFuture, setIsDateTodayFuture] = useState(false)
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [cancelOpen, setCancelOpen] = useState(false);
    const [cancelDialogMessage, setCancelDialogMessage] = useState("");

    const formRef = useRef();

    function handleBack() {
        
        setIsOrderDetails(false)
    }

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose(isRefresh = false) {
        setOpen(false);
        if(isRefresh){
            window.location.reload()
        }
    }

    function handleCancelClose() {
        setCancelOpen(false);
    }

    var isDateInFuture = function(firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) >= secondDate.setHours(0, 0, 0, 0)) {
          return true;
        }
      
        return false;
      };

    useEffect(() => {

        getOrderInfo(accessToken,order._id).then(response =>{
            setOrder(response.data)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isOrderUpdated])

    useEffect(() => {
        if(order.status !== 'Not Submitted'){
            var invoiceDate = new Date(order.shipDate.replace(/-/g, '\/'));
            var today = new Date();

            setIsDateTodayFuture(isDateInFuture(invoiceDate, today))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleProductAmountChange = (productId, productAmount) => {
        let currOrder = {...order}

        let products = [...currOrder.products]
        let productIndex = products.findIndex(product => product._id === productId)

        products[productIndex] = {
            ...products[productIndex],
            amount : productAmount,
        }

        const newOrder = {
            ...currOrder,
            products : products
        }
        
        setOrder(newOrder)
    }

    const handlePriceChange = (productId, newPrice) => {
        let currOrder = {...order}

        let products = currOrder.products

        let productIndex = products.findIndex(product => product._id === productId)
        const product = products[productIndex]

        let newPriceParsed = parseFloat(newPrice)
        let newEstimatedPrice = 0
        let oldEstimatedPrice = 0

        const oldPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        const oldTotalCost = currOrder.totalCost
        
        let newTotalCost = parseFloat(oldTotalCost)

        if(product.isCatchWeight){
            oldEstimatedPrice = oldPrice * product.avgWeight
            newEstimatedPrice = newPriceParsed * product.avgWeight

            oldEstimatedPrice = Math.round( parseFloat(oldEstimatedPrice) * 1e2 ) / 1e2
            newEstimatedPrice = Math.round( parseFloat(newEstimatedPrice) * 1e2 ) / 1e2
        }
        
        if(oldPrice == ''){
            newTotalCost -= parseFloat(product.amount * 0)
        } else{
            if(product.isCatchWeight){
                newTotalCost -= parseFloat(product.amount * oldEstimatedPrice)
            } else{
                newTotalCost -= parseFloat(product.amount * oldPrice)
            }
        }   

        if(!isNaN(newPriceParsed)){
            if(product.isCatchWeight){
                newTotalCost += parseFloat(product.amount * newEstimatedPrice)
            } else{
                newTotalCost += parseFloat(product.amount * newPriceParsed)
            }
        }  

        if(isNaN(newPriceParsed)){
            newPriceParsed = ""
        }

        if(product.listPrice || product.listPrice == ''){
            products[productIndex] = {
                ...products[productIndex],
                listPrice : newPriceParsed,
            }
        } else{
            products[productIndex] = {
                ...products[productIndex],
                price : newPriceParsed,
            }
        }

        if(product.isCatchWeight){
            products[productIndex] = {
                ...products[productIndex],
                estimatedPrice: newEstimatedPrice
            }
        }

        const newOrder = {
            ...currOrder,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        setOrder(newOrder)
    }

    function handleRemove(productId) {
        let currOrder = {...order}

        let products = currOrder.products.filter(product => product._id !== productId)

        const newOrder = {
            ...currOrder,
            products : products
        }
        
        setOrder(newOrder)
    }

    function handleBackOrderConversion() {
        
        const orderUpdate = {
            orderId: order._id,
            customerPo: order.customerPo,
            comment: order.comment,
            invoiceDate: order.invoiceDate,
            shipDate: order.shipDate,
            products: order.products,
            status: "Not Submitted"
        }

        patchOrder(orderUpdate, accessToken).then(response => {
            setIsOrderUpdated(!isOrderUpdated)
            setDialogMessage("Order has been updated successfully.")
            handleClickOpen()
       }).catch(err => {
            console.log("Error updating order.")
            setDialogMessage("There was an error updating the order. Contact Allec team for further asistance.")
            handleClickOpen()
       })
    }

    function handleOrderCancelationClick() {
        setCancelDialogMessage("Are you sure you want to cancel order? Data may be lost.")
        setCancelOpen(true);
    }

    function handleOrderCancelation() {
        
        const orderUpdate = {
            orderId: order._id,
        }

        cancelOrder(orderUpdate, accessToken).then(response => {
            setDialogMessage("Order has been cancelled successfully.")
            handleClickOpen()
       }).catch(err => {
            console.log("Error cancelling order.")
            setDialogMessage("There was an error canceling the order. Contact Allec team for further asistance.")
            handleClickOpen()
       })
    }

    const handleRouteRecordsReturnToQueue = async () => {
        const routeRecordOrders = [
            {
                routeRecordId: selectedRecordId,
                orders: [order._id]
            }
        ]

        await patchRouteRecordForceQueue({ ordersToForceQueueObjectList : routeRecordOrders }, accessToken).then(res => {
            // setIsRouting(false)
            setSelectedRecordId(null)
            setDialogMessage("Order has been sent to routing queue successfully.")
            handleClickOpen()
        }).catch(err => {
            console.log("Error: ", err)
        })
    }

    function toFixedCustom(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    const orderFormik = useFormik({
        initialValues: order,
        enableReinitialize : true,
        innerRef : {formRef},
        onSubmit : (values, { setSubmitting, resetForm }) => {

            const orderUpdate = {
                status: values.status,
                processedDate: values.processedDate,
                inTransitDate: values.inTransitDate,
                deliveredDate: values.deliveredDate,
                customerPo: values.customerPo,
                comment: values.comment,
                invoiceDate: values.invoiceDate,
                shipDate: values.shipDate,
                products: order.products,
                orderId: order._id
            }

           patchOrder(accessToken,orderUpdate).then(response => {
            setIsOrderUpdated(!isOrderUpdated)
            setIsOrderDetails(!isOrderDetails)
           })

            setTimeout(() => {
                // alert("Your common application has been submited.");
                setSubmitting(false);
            }, 400);
        },
    });

    return(
        <>
            <button type="button" className="btn btn-primary mb-6" onClick={() => handleBack()} >Back</button>
            <Card>
                <CardHeader title={`Order #${order?.orderNumber} | ${order?.companyName}`}>

                </CardHeader>
                <CardBody>
                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center table-padding">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>SKU</th>
                                <th>Avg Weight</th>
                                <th>Unit Price</th>
                                <th>Product Qty.</th>
                                <th>Item Price</th>
                                <th>Total Amount</th>
                                { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received"))) ?
                                    <th>Remove</th>
                                    :
                                    null
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {order?.products?.map((product,index) =>  (
                                <tr key={product._id}>
                                    <td className="border-top-0 pl-0">
                                        { product.imagePath ? 
                                            <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                <div className="symbol-label"
                                                    style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                            </div>
                                            :
                                            null
                                        }
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {product.description ? product.description : product.name}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                            {product.sku}
                                        </span>
                                    </td>
                                    <td className="border-top-0 text-center py-2 align-items-center">
                                        {
                                            product.isCatchWeight ? 
                                                product.avgWeight
                                            :
                                                "N/A"
                                        }
                                    </td>
                                    <td className="border-top-0 text-right py-2 align-middle">{product.listPrice || product.listPrice == '' ?                  
                                            <input
                                                value={product.listPrice}
                                                onChange={(e) => handlePriceChange(product._id, e.target.value)}
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                type="number"
                                                min="0"
                                            />
                                            :
                                            <input
                                                value={product.price}
                                                onChange={(e) => handlePriceChange(product._id, e.target.value)}
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                type="number"
                                                min="0"
                                            />
                                        }
                                    </td>
                                    <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                        { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received" || order.status == "Submitted"))) ?
                                            <div className="align-middle">
                                                <button className="btn btn-xs btn-light-primary btn-icon mr-2" disabled={product.amount === 0} onClick={() => {
                                                    handleProductAmountChange(product._id, product.amount - 1)
                                                }}>
                                                    <i className="ki ki-minus icon-xs"></i>
                                                </button>
                                                <span className="mr-2 font-weight-bolder align-middle">{product.amount}</span>
                                                <button className="btn btn-xs btn-light-primary btn-icon mr-2" onClick={() => {
                                                    handleProductAmountChange(product._id, product.amount + 1)
                                                }} >
                                                    <i className="ki ki-plus icon-xs"></i>
                                                </button>
                                            </div>
                                            :
                                            <span className="mr-2 font-weight-bolder align-middle">{product.amount}</span>
                                        }
                                    </td>
                                    <td className="border-top-0 text-center py-2 align-items-center">
                                        {
                                            product.isCatchWeight ? 
                                                product.estimatedPrice  
                                            : 
                                                product.listPrice && product.listPrice !== '' ?
                                                    product.listPrice
                                                :
                                                    product.price
                                        }
                                    </td>
                                    <td>
                                        <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                            {
                                                product.isCatchWeight ? 
                                                    <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice * product.amount), 2)}</span>
                                                :
                                                    product.listPrice?
                                                        <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice * product.amount), 2)}</span>
                                                    :
                                                        <span>${toFixedCustom((product.price * product.amount), 2)}</span>
                                            }
                                        </span>
                                    </td>
                                    { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received"))) ?
                                        <td>
                                            <InputAdornment position="end">
                                                <IconButton  onClick={() => handleRemove(product._id)}
                                                >
                                                <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                </span>
                                                </IconButton>
                                            </InputAdornment>
                                        </td>
                                        :
                                        null
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </CardBody>
            </Card>
            { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received"))) ?
                <Card>
                    <CardHeader title={`Order #${order?.orderNumber} | ${order?.companyName}`}>

                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            <p>{order.supplier[0].description}</p>
                        </div>
                    </CardBody>

                    <ProductList order={order} setIsOrderUpdated={setIsOrderUpdated} isOrderUpdated={isOrderUpdated} />
                </Card>
                :
                null
            }
            
            <Card>
            
                <CardHeader title={`Order #${order?.orderNumber} Details`}>

                </CardHeader>
                <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={orderFormik.handleSubmit}
                    >
                <CardBody>
                    
                        <div>
                            <div className="row align-self-center">
                                <div className="col-md-6">
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"companyName"}>Client</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="companyName"
                                            disabled
                                            {...orderFormik.getFieldProps("companyName")}
                                        />
                                        {orderFormik.touched.companyName && orderFormik.errors.companyName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.companyName}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"locationAddressCity"}>Location</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="locationAddressCity"
                                            disabled
                                            {...orderFormik.getFieldProps("locationAddressCity")}
                                        />
                                        {orderFormik.touched.locationAddressCity && orderFormik.errors.locationAddressCity ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.locationAddressCity}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"supplierName"}>Supplier</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="supplierName"
                                            disabled
                                            {...orderFormik.getFieldProps("supplierName")}
                                        />
                                        {orderFormik.touched.supplierName && orderFormik.errors.supplierName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.supplierName}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"totalProductQty"}>Total Products</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="totalProductQty"
                                            disabled
                                            {...orderFormik.getFieldProps("totalProductQty")}
                                        />
                                        {orderFormik.touched.totalProductQty && orderFormik.errors.totalProductQty ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.totalProductQty}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"totalCost"}>Total Cost</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="totalCost"
                                            disabled
                                            {...orderFormik.getFieldProps("totalCost")}
                                        />
                                        {orderFormik.touched.totalCost && orderFormik.errors.totalCost ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.totalCost}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <FormControl variant="filled" style={{width: "100%"}}>
                                            <InputLabel>Order Status</InputLabel>
                                            <Select
                                                input={<FilledInput name="status" id="status"
                                                type="text"
                                                disabled
                                                className={`form-control form-control-solid h-auto`}
                                                {...orderFormik.getFieldProps("status")}
                                                />}
                                            >
                                                <MenuItem value="Submitted">Submitted</MenuItem>
                                                <MenuItem value="Received">Received</MenuItem>
                                                <MenuItem value="Revised">Revised</MenuItem>
                                                <MenuItem value="Processed">Processed</MenuItem>
                                                <MenuItem value="In Transit">In Transit</MenuItem>
                                                <MenuItem value="Delivered">Delivered</MenuItem>
                                            </Select>
                                            {orderFormik.touched.status && orderFormik.errors.status ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.status}</div>
                                            </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"customerPo"}>Customer PO</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="customerPo"
                                            {...orderFormik.getFieldProps("customerPo")}
                                        />
                                        {orderFormik.touched.customerPo && orderFormik.errors.customerPo ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.customerPo}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"comment"}>Comment</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="comment"
                                            {...orderFormik.getFieldProps("comment")}
                                        />
                                        {orderFormik.touched.comment && orderFormik.errors.comment ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.comment}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"shipVia"}>Ship Via</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="shipVia"
                                            disabled
                                            {...orderFormik.getFieldProps("shipVia")}
                                        />
                                        {orderFormik.touched.shipVia && orderFormik.errors.shipVia ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.shipVia}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                        <Input
                                            type="date"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="invoiceDate"
                                            disabled
                                            {...orderFormik.getFieldProps("invoiceDate")}
                                        />
                                        {orderFormik.touched.invoiceDate && orderFormik.errors.invoiceDate ? (
                                            <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{orderFormik.errors.invoiceDate}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"shipDate"}>Ship Date</label>
                                        <Input
                                            type="date"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="shipDate"
                                            disabled
                                            {...orderFormik.getFieldProps("shipDate")}
                                        />
                                        {orderFormik.touched.shipDate && orderFormik.errors.shipDate ? (
                                            <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{orderFormik.errors.shipDate}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {typeof(order.submissionDate) !== 'undefined' ?
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"submissionDate"}>Submission Date</label>
                                            <Input
                                                type="date"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="submissionDate"
                                                disabled
                                                {...orderFormik.getFieldProps("submissionDate")}
                                            />
                                            {orderFormik.touched.submissionDate && orderFormik.errors.submissionDate ? (
                                                <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.submissionDate}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        :
                                        null
                                    }
                                    {typeof(order.processedDate) !== 'undefined' ?
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"processedDate"}>Processed Date</label>
                                            <Input
                                                type="date"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="processedDate"
                                                disabled
                                                {...orderFormik.getFieldProps("processedDate")}
                                            />
                                            {orderFormik.touched.processedDate && orderFormik.errors.processedDate ? (
                                                <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.processedDate}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        :
                                        null
                                    }
                                    {typeof(order.inTransitDate) !== 'undefined' ?
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"inTransitDate"}>In Transit Date</label>
                                            <Input
                                                type="date"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="inTransitDate"
                                                disabled
                                                {...orderFormik.getFieldProps("inTransitDate")}
                                            />
                                            {orderFormik.touched.inTransitDate && orderFormik.errors.inTransitDate ? (
                                                <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.inTransitDate}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        :
                                        null
                                    }
                                    {typeof(order.deliveredDate) !== 'undefined' ?
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"deliveredDate"}>Delivered Date</label>
                                            <Input
                                                type="date"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="deliveredDate"
                                                disabled
                                                {...orderFormik.getFieldProps("deliveredDate")}
                                            />
                                            {orderFormik.touched.deliveredDate && orderFormik.errors.deliveredDate ? (
                                                <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{orderFormik.errors.deliveredDate}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    
                </CardBody>
                <CardFooter>
                    <div className="row col-12 justify-content-center">
                        {/* { selectedRecordId ?
                            
                            <button type="button" onClick={() => handleRouteRecordsReturnToQueue()} className="btn btn-primary mr-5" >Return to Queue</button>
                            
                            :
                            null
                        } */}
                        { (order.status == "Not Submitted" || (isDateTodayFuture && (order.status == "Received" || order.status == "Submitted"))) ?
                            
                            <button type="submit" className="btn btn-primary mr-5" >Update Order</button>
                            
                            :
                            null
                        }
                        {/* { (order.status == "Back Order") ?
                            <button type="button" className="btn btn-primary mr-5" onClick={() => handleBackOrderConversion()} >Convert to Order</button>
                                
                            :
                            null
                        }
                            <button type="button" className="btn btn-primary" onClick={() => handleOrderCancelationClick()} >Cancel Order</button> */}
                    </div>
                </CardFooter>
                </form>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={cancelOpen}
                onClose={handleCancelClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {cancelDialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancelClose} color="primary" autoFocus>
                    No
                </Button>
                <Button onClick={handleOrderCancelation} color="primary" autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}