
import React, {useEffect, useState} from "react";
import {Card,CardBody,CardHeader,CardHeaderToolbar} from "../../../../_metronic/_partials/controls"
import {useSelector} from "react-redux";
import axios from "axios";
import {useParams} from "react-router-dom"
import {fetchApplication, fetchCommonApp, fetchApplicationLocations} from "./applicationsCrud";
import {PersonalInformation} from "./CommonAppInformationSections/PersonalInformation";
import {PartnerInformation} from "./CommonAppInformationSections/PartnerInformation";
import {BankReferenceInformation} from "./CommonAppInformationSections/BankReferenceInformation";
import {OtherInformation} from "./CommonAppInformationSections/OtherInformation";
// import {AppInformationSummary} from "./ApplicationProccessingPages/AppInformationSummary";
import ApplicationSummary from './ApplicationSummary/ApplicationSummary'
import {AppVerification} from "./ApplicationProccessingPages/AppVerification";
import { getSupplierByTenant } from "../SuppliersCrud"


export function ApplicationsProcessing() {

    const initialCommonApplication = {
        applicationNumber: 0,
        bankReferenceInformation: {},
        businessInformation: {},
        commercialReferenceInformation: {},
        companyId: "",
        otherInformation: {},
        partnerInformation: {},
        personalInformation: {},
        submissionDate: "",
        _id: ""
    }

    const initialApplication = {
        _id: "",
        submissionDate: "",
        applicationNumber: 0,
        companyId: "",
        companyAddress: "",
        supplierName: "",
        supplierId: "",
        contactName: "",
        contactPhone: "",
        status: "",
        creditInformation: [],
        companyName: ""
    }

    const {id} = useParams();
    const [commonApp,setCommonApp] = useState(initialCommonApplication);
    const [application,setApplication] = useState(initialApplication);
    const [activeStep,setStep] = useState(0);
    const maxStep = 1
    const [isCommonAppFetchDone, setIsCommonAppFetchDone] = useState(false)
    const [isApplicationFound, setIsApplicationFound] = useState(false)
    const [applicationLocations, setApplicationLocations] = useState([])

    useEffect(() =>{
        fetchApplicationData()
    },[])

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const fetchApplicationData = async () => {
        await getSupplierByTenant(accessToken).then((response) => {

            console.log("Supplier: ", response.data)
            const supplierId = response.data._id

            fetchApplication(accessToken, id, supplierId).then(response =>{
                const currApplication = response.data

                if(typeof(currApplication.applicationNumber) !== 'undefined'){
                    setApplication(currApplication)
                    setIsApplicationFound(true)
                
                    const locationIdList = currApplication.locationsCategories.map(location => {
                        return location.locationId
                    })
                    
                    fetchApplicationLocations(accessToken, locationIdList).then(response => {
                        setApplicationLocations(response.data)
                    })

                    fetchCommonApp(accessToken, id).then(response =>{
                        setCommonApp(response.data)
                        setIsCommonAppFetchDone(true)
                    });
                }
            })

            // console.log("Supplier: ",response.data)
            // setSupplier(response.data)
            // setIsSupplierFetched(true)
        }).catch((error) => {
            console.log(error)
        });
    }

    const renderActualComponent = (activeStep) => {
        switch(activeStep){
            case 0: return <ApplicationSummary commonApp={commonApp} applicationLocations={applicationLocations}  locationsCategories={application.locationsCategories}/>
            case 1: return <AppVerification commonApp={commonApp} actualApplication={application} applicationId={id}/>
            default: return <></>
        }
    }


    return (
        <>
            {isCommonAppFetchDone ? 
                (isApplicationFound ?
                    renderActualComponent(activeStep) 
                    :
                    <div className="card card-custom gutter-b">
                        <div className="card-body p-0">
                            <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                <div className="col-md-10">
                                    <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                        <div className="text-center mb-4">
                                            <span className="card-label font-weight-bolder text-dark text-center font-size-lg">Application Not Found</span>
                                        </div>
                                        {/* <div className="text-center">
                                            <span className="text-dark-75 mt-3 font-weight-bold font-size-lg">Shopping Cart is empty!</span>
                                        </div > */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : 
                null}
            {activeStep !== maxStep ? (
                <div className="row justify-content-center">
                    <button className="btn btn-primary mr-1" onClick={()=>{
                        if(activeStep < maxStep){
                            setStep(activeStep + 1)
                        }
                    }}>Next</button>
                    <a className="btn btn-light ml-1" href="/applications">Cancel</a>
                </div>
            ): ""}
        </>
    );
}