import { GET_ORDERS_BY_USER } from "../constants";


const initialState = {
    orderList: [],
    orderListPaginationData: {
        data: [],
        totalCount: 0,
        pageSize: 5
    }
};

export default function orderReducer(state = initialState, action) {
    let orderListPaginationData = state.orderListPaginationData;

    switch (action.type) {
        case GET_ORDERS_BY_USER:
            if (action.payload) {
                orderListPaginationData = {
                    data: action.payload.data,
                    totalCount: action.payload.totalCount,
                    pageSize: 5
                }
            }
            return Object.assign({}, state, {
                orderListPaginationData: orderListPaginationData
            });
        default:
            return state;
    }
}