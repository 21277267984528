import React from "react";

export function ActionsColumnFormatter(cellContent, row){

    return (
        <>
            <button 
            type="button"
            className="btn btn-success font-size-sm"
            >
            Add Location
            </button>
        </>
    )
}