import React, {useState} from "react";
import {Formik,Form} from "formik";
import BuyerAdminPage from "./BuyerAdminPage";
import {Card, CardBody, CardFooter, CardHeader} from "../../../_metronic/_partials/controls";
import {MyTextInput} from "./FormFields";

export function NewBuyer({buyerInformation}) {

    const [cancel, setCancel] = useState(false)

    return (
        <>
            {cancel ?
                <BuyerAdminPage/> :
                <Card>
                    <CardHeader title="Buyer Registration">
                        {/*<CardHeaderToolbar>*/}
                        {/*    <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>*/}
                        {/*        Expand*/}
                        {/*    </button>*/}
                        {/*</CardHeaderToolbar>*/}
                    </CardHeader>
                    <CardBody>
                        <Formik
                            // initialValues={buyerInformation}
                            // onSubmit={(values) =>{
                            //     console.log(values)
                            // }}

                        >
                            <Form>
                                <div>
                                    <div className="">
                                        <div className="col-12">
                                            <MyTextInput
                                                label="Buyer Type"
                                                name="Buyer Type"
                                                type="text"
                                                // value={buyerInformation.partnerFullLegalName}
                                                placeholder="Choose One"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Company Name"
                                                name="companyName"
                                                type="text"
                                                // value={buyerInformation.partnerSocialSecurity}
                                                // placeholder="###-##-####"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Owner/Name"
                                                name="ownerName"
                                                type="text"
                                                // value={buyerInformation.partnerDriversLicense}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Contact Email"
                                                name="contactEmail"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal Address"
                                                name="postalAddress"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal City"
                                                name="postalCity"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                placeholder="Select City"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Postal Zip Code"
                                                name="postalZipcode"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical Address"
                                                name="physicalAddress"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical City"
                                                name="physicalCity"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                placeholder="Select City"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Physical Zip Code"
                                                name="physicalZipcode"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Phone Number"
                                                name="phoneNumber"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                placeholder="###-###-####"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Available Credit"
                                                name="credit"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                default="0"
                                                placeholder="0"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Price List"
                                                name="priceList"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Is Active"
                                                name="active"
                                                type="text"
                                                // value={buyerInformation.partnerBirthDate}
                                                // placeholder="Name"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </CardBody>
                    <CardFooter>
                        <button type="button" className="btn btn-primary" onClick={console.log("La salvación")}>
                            Save
                        </button>
                        <></>
                        <button type="button" className="btn btn-light" onClick={() => setCancel(true)}>
                            Cancel
                        </button>
                    </CardFooter>
                </Card>
            }
        </>
    )
}