import axios from "axios";

export const SUPPLIER_GET_URL = process.env.REACT_APP_API_URL + "/suppliers";
export const PRODUCTS_GET_URL = process.env.REACT_APP_API_URL + "/products/supplier";
export const SUPPLIER_SELECTION_POST_URL = process.env.REACT_APP_API_URL + "/buyers/suppliers";
export const BUYER_GET_URL = process.env.REACT_APP_API_URL + "/buyers/";

export function getSuppliers(accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
      }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(SUPPLIER_GET_URL, config);
}

export function getSupplierProducts(id,authToken,pageNumber,sizePerPage,searchText) {
    const options = {
        headers: {'x-auth-token':authToken},
        validateStatus: status => status < 500,
        params: {
            page:pageNumber,
            sizePerPage:sizePerPage,
            name:searchText
        }
    }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${PRODUCTS_GET_URL}/${id}`, options);
}

export function getSupplierProductsNameBrand(supplierId,authToken,pageNumber,sizePerPage,searchText,brand,category, companyId) {
    const options = {
        headers: {'x-auth-token':authToken},
        validateStatus: status => status < 500,
        params: {
            page:pageNumber,
            sizePerPage:sizePerPage,
            name:searchText,
            brand:brand,
            category:category,
            companyId: companyId
        }
    }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${PRODUCTS_GET_URL}/namebrand/${supplierId}`, options);
}

export function getSuppliersCategories(accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${SUPPLIER_GET_URL}/categories`, config);
}

export function getBuyerByUserId(userId) {
    return axios.get(BUYER_GET_URL + userId)
}

export function postSelectedSuppliers(buyerId, selectedSuppliers) {
    // Authorization head should be fulfilled in interceptor.
    return axios.post(SUPPLIER_SELECTION_POST_URL, { buyerId , selectedSuppliers});
}

export function getSuppliersPagination(queryParams, accessToken) {
    const options = {
        headers: { 'x-auth-token': accessToken},
        validateStatus: status => status < 400,
        params: queryParams
    }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${SUPPLIER_GET_URL}/pagination/pag`, options);
}

export function getSuppliersPaginationCategories(queryParams, accessToken) {
    const options = {
        headers: { 'x-auth-token': accessToken},
        validateStatus: status => status < 400,
        params: queryParams
    }
    // Authorization head should be fulfilled in interceptor.
    return axios.get(`${SUPPLIER_GET_URL}/pagination/categories`, options);
}

