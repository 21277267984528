import React, {useState, useEffect} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../_metronic/_partials/controls";
import {MyTextInput} from "../../Buyer/CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';
import { getLocationsByCompanyIdList } from "../../Buyer/CommonApplication/CommonApplicationCrud"
import DoneAllIconTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import { HighlightOff} from "@material-ui/icons";


export default function ClientSummary({ clientApplication, accessToken }){
    const [isLocationsFetched, setIsLocationsFetched] = useState(false)
    const [locations, setLocations] = useState([])



    useEffect(() => {
        getLocationsByCompanyIdList({ companyIdList : [ clientApplication.companyInfo[0]._id ]}, accessToken).then(response => {
            const fetchedLocations = response.data
            console.log(fetchedLocations)
            console.log(clientApplication)

            let clientLocations = []

            fetchedLocations.forEach(location => {
                const currLocationCat = clientApplication.locationsCategories.find(locationCat => location._id === locationCat.locationId)

                if(typeof(currLocationCat) !== 'undefined'){
                    clientLocations.push({
                        ...location,
                        status : currLocationCat.status
                    })
                } else {
                    clientLocations.push({
                        ...location,
                        status : "not applied"
                    })
                }
            })

            console.log("Client Locations: ", clientLocations)

            setLocations(clientLocations)
            setIsLocationsFetched(true)

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }).catch(error => console.error(error));
    }, [  ])

    const getStatusLabel = (status) => {
        if(status === "submitted") {
            return (
                <span className="label label-lg label-light-primary label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "draft") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "in progress" || status === "pending") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "approved") {
            return (
                <span className="label label-lg label-light-success label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "denied") {
            return (
                <span className="label label-lg label-light-danger label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        }
        else if (status === "not applied") {
            return (
                <span className="label label-lg label-light label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        }
    }

    const getIcon = (value) => {
        return value ?
            (<DoneAllIconTwoToneIcon color="primary" fontSize="large"/>)
            :
            (<HighlightOff color="error" fontSize="large"/> )
            ;
    }

    return (
        <>
        <div className="row">
            <div className="col-12">
                <Card>
                    <CardHeader title="Client Information">
                        <CardHeaderToolbar>
                            {/*<button type="button" className="btn btn-light" onClick={console.log("filtrando")}>*/}
                            {/*    Expand*/}
                            {/*</button>*/}
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <Formik
                            initialValues={clientApplication.companyInfo[0]}
                            onSubmit={(values) =>{
                                console.log(values)
                            }}
                        >
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <MyTextInput
                                            label="Corporation Name"
                                            name="corporationName"
                                            type="text"
                                            value={clientApplication.companyInfo[0].corporationName}
                                        />
                                        <MyTextInput
                                            label="Business Name"
                                            name="businessName"
                                            type="text"
                                            value={clientApplication.companyInfo[0].businessName}

                                        />
                                        <MyTextInput
                                            label="Business Type"
                                            name="businessType"
                                            type="text"
                                            value={clientApplication.companyInfo[0].businessType}

                                        />
                                        <MyTextInput
                                            label="Phone Number"
                                            name="businessPhoneNumber"
                                            type="text"
                                            value={clientApplication.companyInfo[0].businessPhoneNumber}
                                        />
                                        <MyTextInput
                                            label="Registro de Comerciantes"
                                            name="registroComerciante"
                                            type="text"
                                            value={clientApplication.companyInfo[0].registroComerciante}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MyTextInput
                                            label="Business Establishment Date"
                                            name="businessEstablishmentDate                                        "
                                            type="text"
                                            value={clientApplication.companyInfo[0].businessEstablishmentDate}

                                        />
                                        <MyTextInput
                                            label="Business Description"
                                            name="placeOfBusinessDescription"
                                            type="text"
                                            value={clientApplication.companyInfo[0].placeOfBusinessDescription}

                                        />
                                        <MyTextInput
                                            label="Email"
                                            name="businessEmail"
                                            type="text"
                                            value={clientApplication.companyInfo[0].businessEmail}

                                        />
                                        <MyTextInput
                                            label="Fax Number"
                                            name="businessFax"
                                            type="text"
                                            value={clientApplication.companyInfo[0].businessFax}

                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MyTextInput
                                            label="Address"
                                            name="deliveryAddress                                        "
                                            type="text"
                                            value={clientApplication.companyInfo[0].deliveryAddress}

                                        />
                                        <MyTextInput
                                            label="City"
                                            name="deliveryCity"
                                            type="text"
                                            value={clientApplication.companyInfo[0].deliveryCity}

                                        />
                                        <MyTextInput
                                            label="Zipcode"
                                            name="deliveryZipcode"
                                            type="text"
                                            value={clientApplication.companyInfo[0].deliveryZipcode}
                                        />
                                        <MyTextInput
                                            label="EIN"
                                            name="employerIdentificationNumber"
                                            type="text"
                                            value={clientApplication.companyInfo[0].employerIdentificationNumber}
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Formik>
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </div>

            {isLocationsFetched ? 
                <div className="col-12">
                    <Card>
                        <CardHeader title="Client Locations">
                            <CardHeaderToolbar>
                                
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table
                                    className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                    id="kt_advance_table_widget_1"
                                >
                                    <thead>
                                        <tr className="text-left">
                                            <th className="pr-0" style={{ minWidth: "150px" }}>
                                            Name
                                            </th>
                                            <th style={{ minWidth: "150px" }}>Address</th>
                                            <th style={{ minWidth: "150px" }}>City</th>
                                            <th style={{ minWidth: "150px" }}>Zipcode</th>
                                            <th style={{ minWidth: "150px" }}>Numero de Catastro</th>
                                            <th style={{ minWidth: "150px" }}>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {locations.map(location => (
                                            <tr key={location._id}>
                                                <td className="pr-0 pl-2 py-2">
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationName}  
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationAddress}  
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationCity}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationZipcode}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationCatastro}
                                                    </span>
                                                </td>
                                                <td>
                                                    {getStatusLabel(location.status)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                :
                null
            }

            <div className="col-12">
            <Card>
                <CardHeader title="Verification Engine (In Compliance)">

                </CardHeader>
                <CardBody>
                   <div className="row justify-content-center">
                       <div className="col-12 col-md-8">
                           <div className="table-responsive">
                               <table className="table table-padding-2 table-head-light-primary table-light table-striped table-head-padding-2 table-borderless table-vertical-center">
                                   <thead>
                                   <tr className="text-left text-uppercase">
                                       <th className="pl-7" style={{minWidth: "250px"}}>Documents</th>
                                       <th style={{minWidth: "70px"}}>Status</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                        <tr style={{cursor:"pointer"}}>
                                            <td>
                                                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                    Permits
                                                </span>
                                            </td>
                                            <td>
                                                {getIcon(true)}
                                            </td>
                                        </tr>
                                        <tr style={{cursor:"pointer"}}>
                                            <td>
                                                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                    Licenses
                                                </span>
                                            </td>
                                            <td>
                                                {getIcon(true)}
                                            </td>
                                        </tr>
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>

                </CardBody>
                <CardFooter>

                </CardFooter>
            </Card>
            </div>
        </div>
        </>
    )
}