import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useFormik} from "formik";
import {default as React, useState} from "react";
import {RegionDropdown} from "react-country-region-selector";
import {injectIntl} from "react-intl";
import * as Yup from "yup";
import {postCompany} from '../CommonApplication/CommonApplicationCrud';
import AddLocationsPage from './AddLocationsPage';
import {useDispatch} from "react-redux";
import * as PropTypes from "prop-types";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import {FormControl, Input, MenuItem, Select} from "@material-ui/core";
import {getCompanies} from './../../../../redux/actions/companies';


const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    paper: {
        padding: theme.spacing(2, 2),
        // height: 140,
        // width: 300,
    },
    modalPaper: {
        position: 'absolute',
        // width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const newCompanyInitialValues = {
    corporationName: "",
    businessName: "",
    deliveryAddress: "",
    deliveryCity: "",
    deliveryZipcode: "",
    salesTaxCode: "",
    contactFirstName: "",
    contactLastName: "",
    contactNotes: "",
    businessPhoneNumber: "",
    businessFax: "",
    businessEmail: "",
    businessType: "",
    employerIdentificationNumber: "",
    numeroCatastro: "",
    registroComerciante: "",
    businessEstablishmentDate: "",
    placeOfBusinessDescription: "",
    shipVia: "",
    sageCustomerId: "",
    dueDays: 0
}

const newLocationInitialValues = {
    locationAddress: "",
    locationCity: "",
    locationZipcode: "",
    locationCatastro: "",
}

function PhoneMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function CatastroMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function EINMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function RegistroComercianteMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function ZipcodeMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                // console.log("Input Ref: ", inputRef)
                // console.log("Ref: ", ref)
                inputRef(ref ? ref.inputElement : null);
                // ref.inputElement.setSelectionRange(0, 0)
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            // placeholderChar={'\u2000'}
            showMask
            onFocus={(el) => focusInputRef(el)}
        />
    );
}

function focusInputRef(inputRef){
    // console.log("Input Ref: ", inputRef)
    inputRef.target.setSelectionRange(0, 1);
}

PhoneMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
CatastroMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
EINMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
RegistroComercianteMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
ZipcodeMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function AddCompanyPage(props) {

    const {intl} = props;
    const classes = useStyles();
    const {
        user,
        userId,
        accessToken,
        handleAddCompanyClose,
        isAddLocations,
        setIsAddLocations,
        addLocationsToComapnyId,
        setIsCompanySummary
    } = props


    const [country, setCountry] = useState('Puerto Rico');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    
    const [company, setCompany] = useState({})
    const [companyId, setComapnyId] = useState("")
    // const {companyId} = useSelector(state => state.companyReducer);
    const dispatch = useDispatch();

    const [physicalCity, setPhysicalCity] = useState();

    const handleBack = () => {
        setIsCompanySummary(false);
    };

    const CompanyInformationSchema = Yup.object().shape({
        corporationName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
            // .required(
            //     intl.formatMessage({
            //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
            //     })
            // ),
        businessName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(40, "Maximum 40 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        sageCustomerId: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(20, "Maximum 20 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(30, "Maximum 30 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        salesTaxCode: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        contactFirstName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(15, "Maximum 15 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        contactLastName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(20, "Maximum 20 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),  
        contactNotes: Yup.string()
            .min(1, "Minimum 1 symbols")
            .max(200, "Maximum 200 symbols"),
            // .required(
            //     intl.formatMessage({
            //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
            //     })
            // ),  
        businessPhoneNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(20, "Maximum 20 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessFax: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
        ,
        businessEmail: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(64, "Maximum 64 symbols"),
            // .required(
            //     intl.formatMessage({
            //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
            //     })
            // ),
        businessType: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        shipVia: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        employerIdentificationNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        registroComerciante: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        businessEstablishmentDate: Yup.date(),
            // .required(
            //     intl.formatMessage({
            //         id: "AUTH.VALIDATION.REQUIRED_FIELD",
            //     })
            // ),
        placeOfBusinessDescription: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        dueDays: Yup.number()
            .min(0, "minimum 0 days")
            .max(50, "Maximum 50 days")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const companyInfoFormik = useFormik({
        initialValues: newCompanyInitialValues,
        validationSchema: CompanyInformationSchema,
        onSubmit: (values, {setSubmitting}) => {
            try {
                setIsSubmitDisabled(true)
                const newCompany = {
                    userId,
                    status: "New",
                    ...values
                }
                postCompany(newCompany, accessToken).then(response => {
                    setComapnyId(response.data)
                }).catch((error) => {
                    console.log(error)
                });
                                

                setCompany(newCompany)
                dispatch(getCompanies(userId));

            } catch (e) {
                alert("An error there has been. Your common application was not saved");
                console.log(e.message);
            }

            setTimeout(() => {
                // alert("Your common application has been saved.");
                setIsSubmitDisabled(false)
                setSubmitting(false);
                setIsAddLocations(true)
            }, 400);
        },
    });

    return (
        <div>
            
            {!isAddLocations ?
                <div>
                    <form
                        id="kt_login_signin_form"
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                        onSubmit={companyInfoFormik.handleSubmit}
                    >
                        {/* begin: Alert */}
                        {companyInfoFormik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{companyInfoFormik.status}</div>
                            </div>
                        )}
                        {/* end: Alert */}
                        <div className="" style={{display: "block"}}>
                            <div className="text-center mb-10 mb-lg-20">
                                <h3 className="font-size-h1">
                                    { user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                                        "Customer Information"
                                    :
                                        "Company Information"
                                    }
                                    
                                    {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                                </h3>
                                {/* <p className="text-muted font-weight-bold">
                        Enter your details to be sent to suppliers.
                        </p> */}
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"sageCustomerId"}>
                                            { user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                                    "Customer Id "
                                                :
                                                    "Business Id "
                                            }
                                            <span className="text-warning font-weight-bold font-size-h4">*</span>
                                        </label>
                                        <Input
                                            placeholder="Business Id"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="sageCustomerId"
                                            {...companyInfoFormik.getFieldProps("sageCustomerId")}
                                        />
                                        {companyInfoFormik.touched.sageCustomerId && companyInfoFormik.errors.sageCustomerId ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.sageCustomerId}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"businessName"}>
                                            { user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                                    "Customer Name"
                                                :
                                                    "Business Name"
                                            }
                                            <span className="text-warning font-weight-bold font-size-h4">*</span>
                                        </label>
                                        <Input
                                            placeholder="Business"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="businessName"
                                            {...companyInfoFormik.getFieldProps("businessName")}
                                        />
                                        {companyInfoFormik.touched.businessName && companyInfoFormik.errors.businessName ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.businessName}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"deliveryAddress"}>Delivery Address <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <Input
                                            placeholder="Address"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="deliveryAddress"
                                            {...companyInfoFormik.getFieldProps("deliveryAddress")}
                                        />
                                        {companyInfoFormik.touched.deliveryAddress && companyInfoFormik.errors.deliveryAddress ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.deliveryAddress}</div>
                                            </div>
                                        ) : null}
                                    </div>


                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"deliveryCity"}>City <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <FormControl variant="filled" style={{width: "100%"}}>
                                            <RegionDropdown
                                                name="deliveryCity"
                                                classes={`form-control form-control-solid h-auto py-5 px-6`}
                                                country={country}
                                                value={companyInfoFormik.values.deliveryCity}
                                                onChange={(_, e) => companyInfoFormik.handleChange(e)}
                                                onBlur={companyInfoFormik.handleBlur}
                                                defaultOptionLabel={companyInfoFormik.values.deliveryCity}
                                            />
                                            {companyInfoFormik.touched.deliveryCity && companyInfoFormik.errors.deliveryCity ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{companyInfoFormik.errors.deliveryCity}</div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"deliveryZipcode"}>Zipcode <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <Input
                                            placeholder="Postal Zipcode"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="deliveryZipcode"
                                            inputComponent={ZipcodeMaskCustom}
                                            {...companyInfoFormik.getFieldProps("deliveryZipcode")}
                                            // onFocus={(el) => focusInputRef(el)}
                                        />
                                        {companyInfoFormik.touched.deliveryZipcode && companyInfoFormik.errors.deliveryZipcode ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.deliveryZipcode}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    { user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"salesTaxCode"}>Sales Tax Code <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                            <FormControl variant="filled" style={{width: "100%"}}>

                                                <Select
                                                    input={<Input
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="salesTaxCode"
                                                        {...companyInfoFormik.getFieldProps("salesTaxCode")}
                                                    />}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"IVU"}>IVU 11.5%</MenuItem>
                                                    <MenuItem value={"IVU 6%"}>IVU 10.5%</MenuItem>
                                                </Select>
                                                {companyInfoFormik.touched.salesTaxCode && companyInfoFormik.errors.salesTaxCode ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{companyInfoFormik.errors.salesTaxCode}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>
                                        :
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"salesTaxCode"}>Sales Tax Code <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                            <FormControl variant="filled" style={{width: "100%"}}>

                                                <Select
                                                    input={<Input
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="salesTaxCode"
                                                        {...companyInfoFormik.getFieldProps("salesTaxCode")}
                                                    />}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"SALES PR"}>SALES PR</MenuItem>
                                                </Select>
                                                {companyInfoFormik.touched.salesTaxCode && companyInfoFormik.errors.salesTaxCode ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{companyInfoFormik.errors.salesTaxCode}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>
                                    }
                                            

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"contactFirstName"}>Contact Name <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <Input
                                            placeholder="Name"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="contactFirstName"
                                            {...companyInfoFormik.getFieldProps("contactFirstName")}
                                        />
                                        {companyInfoFormik.touched.contactFirstName && companyInfoFormik.errors.contactFirstName ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.contactFirstName}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"contactLastName"}>Contact Last Name <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <Input
                                            placeholder="Last Name"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="contactLastName"
                                            {...companyInfoFormik.getFieldProps("contactLastName")}
                                        />
                                        {companyInfoFormik.touched.contactLastName && companyInfoFormik.errors.contactLastName ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.contactLastName}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"businessPhoneNumber"}>Phone Number <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <Input
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="businessPhoneNumber"
                                            inputComponent={PhoneMaskCustom}
                                            {...companyInfoFormik.getFieldProps("businessPhoneNumber")}
                                        />
                                        {companyInfoFormik.touched.businessPhoneNumber && companyInfoFormik.errors.businessPhoneNumber ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.businessPhoneNumber}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"corporationName"}>Corporation Name</label>
                                        <Input
                                            placeholder="Corporation"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="corporationName"
                                            {...companyInfoFormik.getFieldProps("corporationName")}
                                        />
                                        {companyInfoFormik.touched.corporationName && companyInfoFormik.errors.corporationName ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.corporationName}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                </Grid>
                                <Grid item xs={6}>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"businessType"}>Type of Business <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <FormControl variant="filled" style={{width: "100%"}}>

                                            <Select
                                                input={<Input
                                                    type="text"
                                                    className={`form-control form-control-solid h-auto py-5 px-6`}
                                                    name="businessType"
                                                    {...companyInfoFormik.getFieldProps("businessType")}
                                                />}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"Restaurant"}>Restaurant</MenuItem>
                                                <MenuItem value={"Gas Station"}>Gas Station</MenuItem>
                                                <MenuItem value={"Pharmacy"}>Pharmacy</MenuItem>
                                                <MenuItem value={"Market"}>Market</MenuItem>
                                                <MenuItem value={"Bar"}>Bar</MenuItem>
                                            </Select>
                                            {companyInfoFormik.touched.businessType && companyInfoFormik.errors.businessType ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{companyInfoFormik.errors.businessType}</div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                    { user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"shipVia"}>Ship Via <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                            <FormControl variant="filled" style={{width: "100%"}}>

                                                <Select
                                                    input={<Input
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="shipVia"
                                                        {...companyInfoFormik.getFieldProps("shipVia")}
                                                    />}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Bio-Ware Truck"}>Bio-Ware Truck</MenuItem>
                                                    <MenuItem value={"Cust. Pickup"}>Cust. Pickup</MenuItem>
                                                        
                                                </Select>
                                                {companyInfoFormik.touched.shipVia && companyInfoFormik.errors.shipVia ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{companyInfoFormik.errors.shipVia}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>
                                        :
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"shipVia"}>Ship Via <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                            <FormControl variant="filled" style={{width: "100%"}}>

                                                <Select
                                                    input={<Input
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                        name="shipVia"
                                                        {...companyInfoFormik.getFieldProps("shipVia")}
                                                    />}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"Our Truck"}>Our Truck</MenuItem>
                                                    <MenuItem value={"C.O.D."}>C.O.D.</MenuItem>
                                                    <MenuItem value={"Cust. Pickup"}>Cust. Pickup</MenuItem>
                                                    <MenuItem value={"Vendor Deliver"}>Vendor Deliver</MenuItem>
                                                       
                                                </Select>
                                                {companyInfoFormik.touched.shipVia && companyInfoFormik.errors.shipVia ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div
                                                            className="fv-help-block">{companyInfoFormik.errors.shipVia}</div>
                                                    </div>
                                                ) : null}
                                            </FormControl>
                                        </div>
                                    }

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"dueDays"}>Due Days <span className="text-warning font-weight-bold font-size-h4">*</span></label>
                                        <Input
                                            placeholder="0"
                                            type="number"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="dueDays"
                                            {...companyInfoFormik.getFieldProps("dueDays")}
                                        />
                                        {companyInfoFormik.touched.dueDays && companyInfoFormik.errors.dueDays ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.dueDays}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    { user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                        <div className="form-group fv-plugins-icon-container">
                                            <label htmlFor={"contactNotes"}>Notes</label>
                                            <Input
                                                placeholder="Notes"
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                                name="contactNotes"
                                                {...companyInfoFormik.getFieldProps("contactNotes")}
                                            />
                                            {companyInfoFormik.touched.contactNotes && companyInfoFormik.errors.contactNotes ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{companyInfoFormik.errors.contactNotes}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        :
                                        null
                                    }

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"businessEmail"}>Email</label>
                                        <Input
                                            placeholder="mail@email.com"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="businessEmail"
                                            {...companyInfoFormik.getFieldProps("businessEmail")}
                                        />
                                        {companyInfoFormik.touched.businessEmail && companyInfoFormik.errors.businessEmail ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.businessEmail}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"businessFax"}>Fax</label>
                                        <Input
                                            placeholder=""
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="businessFax"
                                            {...companyInfoFormik.getFieldProps("businessFax")}
                                        />
                                        {companyInfoFormik.touched.businessFax && companyInfoFormik.errors.businessFax ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.businessFax}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"employerIdentificationNumber"}>Employer Identification Number
                                            (EIN)</label>
                                        <Input
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="employerIdentificationNumber"
                                            inputComponent={EINMaskCustom}
                                            {...companyInfoFormik.getFieldProps("employerIdentificationNumber")}
                                        />
                                        {companyInfoFormik.touched.employerIdentificationNumber && companyInfoFormik.errors.employerIdentificationNumber ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.employerIdentificationNumber}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"registroComerciante"}>Registro de Comerciante</label>
                                        <Input
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="registroComerciante"
                                            inputComponent={RegistroComercianteMaskCustom}
                                            {...companyInfoFormik.getFieldProps("registroComerciante")}
                                        />
                                        {companyInfoFormik.touched.registroComerciante && companyInfoFormik.errors.registroComerciante ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.registroComerciante}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"businessEstablishmentDate"}>Date of Business
                                            Establishment</label>
                                        <Input
                                            type="date"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="businessEstablishmentDate"
                                            {...companyInfoFormik.getFieldProps("businessEstablishmentDate")}
                                        />
                                        {companyInfoFormik.touched.businessEstablishmentDate && companyInfoFormik.errors.businessEstablishmentDate ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.businessEstablishmentDate}</div>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="form-group fv-plugins-icon-container">
                                        <label htmlFor={"placeOfBusinessDescription"}>Description of place of
                                            Business</label>
                                        <Input
                                            placeholder="Description of Company"
                                            type="text"
                                            className={`form-control form-control-solid h-auto py-5 px-6`}
                                            name="placeOfBusinessDescription"
                                            {...companyInfoFormik.getFieldProps("placeOfBusinessDescription")}
                                        />
                                        {companyInfoFormik.touched.placeOfBusinessDescription && companyInfoFormik.errors.placeOfBusinessDescription ? (
                                            <div className="fv-plugins-message-container">
                                                <div
                                                    className="fv-help-block">{companyInfoFormik.errors.placeOfBusinessDescription}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </Grid>
                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button
                                        id="businessInfoSubmit"
                                        type="submit"
                                        // disabled={formik.isSubmitting}
                                        className="btn btn-success font-weight-bold px-9 py-4 my-3 mx-4"
                                        disable={isSubmitDisabled}
                                    >
                                        <span>Save Company</span>
                                        {
                                            isSubmitDisabled ?
                                                <span className="mr-3 spinner spinner-primary spinner-lg"></span>
                                            :
                                                null
                                        }
                                    </button>

                                </div>
                            </Grid>

                        </div>
                    </form>
                </div>

                //     </Form>
                // </Formik>
                :
                <AddLocationsPage handleAddCompanyClose={handleAddCompanyClose} userId={userId}
                                  accessToken={accessToken}
                                  companyId={companyId} addLocationsToComapnyId={addLocationsToComapnyId}/>
            }
        </div>
    )

}

export default injectIntl(AddCompanyPage);

