import React, {useState} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {PriceListClientsModal} from "./SupplierComponents";
import axios from "axios";
import AdminSnackbar from "../AdminSnackbar";
import {SupplierPriceListColumn} from "../ColumnsFormatter/PriceListNameColumn";
import {PriceListInformation} from "../PriceLists/PriceListInformation";
import EditOrAddPriceList from "../PriceLists/EditOrAddPriceList";


function SupplierPriceList({products, categories, edit, supplierId}) {

    const [open,setOpen] = useState(false);
    const [priceListSnackbar, setPriceListSnackbar] = useState(false);
    const [listEdit, setListEdit] = useState({});
    const [openClientsModal, setOpenClientsModal] = useState(false);
    const [clients, setClients] = useState([]);
    const [openInfo, setOpenInfo] = useState(false)
    const [plId, setPlId] = useState('');
    const [snackbarPL, setSnackbarPL] = useState(false);


    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const initialValues = {
        name: "",
        supplierId: "",
        products: [],
        clients: [],
    };

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 10
    }

    const openNewPriceList = () => {
        setOpen(true)
    }


    const [data,setData] = useState(initialData)


    const fetchPriceLists = (queryParams) => {

        console.log("fetching")

        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }
        // console.log("Search params : ", options.queryParams)
        axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/priceLists', options).then(response =>{
            setData(response.data)
        });
    }

    const fetchClients = () => {
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: {type: " supplier",
                filter: {
                    companyName:"",
                    ein: "",
                    merchantRegistration:"",
                    catastro: "",
                    contactName: "",
                },
                sortOrder: "asc",
                sortField: "companyName",
                pageNumber: 1,
                pageSize: 10
            }
        }
        axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/clients',options).then(response =>{
            setClients(response.data.data)
        });
    }

    const columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses,
            formatter: SupplierPriceListColumn,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setPlId(row._id)
                    setOpenInfo(!openClientsModal)
                }
            }
        },

    ];

    const sizePerPageList = [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 }
    ]

    const initialFilter = {
        filter: {
            name:"",
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "name",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.name = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {openInfo ? <PriceListInformation priceListId={plId}/> :
                open ? <EditOrAddPriceList priceList={initialValues} supplier={supplierId} edit={false} setEdit={null}
                                           isNew={open} setIsNew={setOpen} setShowSnackbar={setSnackbarPL} showSnackbar={snackbarPL}/> :
                <Card>
                    <CardHeader title="Price Lists">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={openNewPriceList}>
                                New Price List
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchPriceLists}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}
                            searchField={false}/>
                    </CardBody>
                    <AdminSnackbar
                        message={'Price List updated successfully'}
                        setShowSnackbar={setPriceListSnackbar}
                        showSnackbar={priceListSnackbar}
                        variant={'success'}
                    />
                    <PriceListClientsModal
                        manageModal={setOpenClientsModal} modalStatus={openClientsModal}
                        priceList={listEdit} clientListForPriceList={listEdit.clients}
                        allLists={data} setAllLists={setData}
                        setPriceList={setListEdit} clientsList={clients}
                        setShowSnackbar={setPriceListSnackbar} showSnackbar={priceListSnackbar}
                        supplierId={supplierId}
                    />
                </Card>
            }
            <AdminSnackbar
                message={'Price List information saved successfully'}
                setShowSnackbar={setSnackbarPL}
                showSnackbar={snackbarPL}
                variant={'success'}
            />

            {/*{open & edit ?*/}
            {/*    <EnhancedTable products={products} supplierId={supplierId}*/}
            {/*                   priceLists={data} setPriceLists={setData}*/}
            {/*                   setShowSnackbar={setPriceListSnackbar}*/}
            {/*                   showSnackbar={priceListSnackbar}*/}
            {/*                   open={open} setOpen={setOpen}*/}
            {/*    />*/}
            {/*    : null}*/}
        </>
    );
}

export default injectIntl(SupplierPriceList);