import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import { fetchSupplierProductsPagination, fetchFavoriteProductsPagination, updateSupplierProductAddFavorite, updateSupplierProductRemoveFavorite } from "../AdminPagesCrud";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {CardBody, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import {
    makeStyles,
    lighten,
    withStyles,
    useTheme
  } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    TableSortLabel,
    TablePagination,
    Switch,
    FormControlLabel,
    TableFooter
  } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    textField: {
        width: 100,
    },
    summary: {
        width: '40%',
        position: 'center'
    }
}));

export default function SupplierFavoriteItemsConfig({ supplierId, setEdit }) {
    const classes = useStyles();
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }
    let refreshFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 10
    }

    const initialRender = useRef(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isItemUpdating, setIsItemUpdating] = useState(false)
    const [data, setData] = useState(initialData)
    const [favoritesData, setFavoritesData] = useState(initialData)

    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            
            if(!isItemUpdating){
                fetchFavoriteProducts(refreshFilter, true)
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isItemUpdating])

    const fetchSupplierProducts = async (queryParams) => {
        await fetchSupplierProductsPagination(supplierId, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    const fetchFavoriteProducts = async (queryParams) => {
        await fetchFavoriteProductsPagination(supplierId, queryParams, accessToken).then(response => {
            
            setFavoritesData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    const addItemToFavorites = async (productId) => {
        await updateSupplierProductAddFavorite(productId, accessToken).then(response => {
            setIsItemUpdating(false)
            
        }).catch(error => console.log(error))
    }

    const removeItemFromFavorites = async (productId) => {
        await updateSupplierProductRemoveFavorite(productId, accessToken).then(response => {
            setIsItemUpdating(false)
            
        }).catch(error => console.log(error))
    }

    const columns = [
        {
            dataField: "name",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "units",
            text: "Units",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.UnitSizeFormatter,
        },
        {
            dataField: "price",
            text: "Original Price",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ActionColumnFormatter,
            formatExtraData:{
                isItemUpdating: isItemUpdating
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setIsItemUpdating(true)
                    addItemToFavorites(row._id)
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    const favoriteColumns = [
        {
            dataField: "name",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "units",
            text: "Units",
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.UnitSizeFormatter,
        },
        {
            dataField: "price",
            text: "Original Price",
        },
        {
            dataField: "action",
            text: "Action",
            formatter: columnFormatters.ActionColumnFormatter,
            formatExtraData:{
                isItemUpdating: isItemUpdating
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setIsItemUpdating(true)
                    removeItemFromFavorites(row._id)
                }
            },
        },
    ];

    const favoriteSizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let favoriteInitialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const favoriteSorting = [{dataField: "name", order: "asc"}]
    const favoritePagination = {custom: true, sizePerPageList: sizePerPageList}
    const favoriteTableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const favoritePrepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.paper}>
                <CardHeader title="All Items">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        initialFilter={initialFilter}
                        tableClasses={tableClasses}
                        fetcher={fetchSupplierProducts}
                        filterFunction={prepareFilter}
                        searchField={true}/>
                   
                </CardBody>
            </Card>

            <Card className={classes.paper}>
                <CardHeader title="Favorite Items">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={favoritesData}
                        columns={favoriteColumns}
                        pagination={favoritePagination}
                        isLoading={false}
                        sorting={favoriteSorting}
                        initialFilter={favoriteInitialFilter}
                        tableClasses={favoriteTableClasses}
                        fetcher={fetchFavoriteProducts}
                        filterFunction={favoritePrepareFilter}
                        searchField={true}/>
                   
                </CardBody>
            </Card>
            
        </div>
    );
}