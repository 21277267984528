import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsByCompanyIdListPagination } from '../../../../redux/actions/dashboardApplication';
import { headerSortingClasses, sortCaret } from "../../../../_metronic/_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { PaginationTable } from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "../CommonApplication/column-formatters";

export default function AllApplications() {

  // const getStatusLabel = (status) => {
  //   if (status === "submitted") {
  //     return (
  //       <span className="label label-lg label-light-primary label-inline">
  //         {status}
  //       </span>
  //     )
  //   } else if (status === "draft") {
  //     return (
  //       <span className="label label-lg label-light-warning label-inline">
  //         {status}
  //       </span>
  //     )
  //   } else if (status === "approved") {
  //     return (
  //       <span className="label label-lg label-light-success label-inline">
  //         {status}
  //       </span>
  //     )
  //   } else if (status === "Denied") {
  //     return (
  //       <span className="label label-lg label-light-danger label-inline">
  //         {status}
  //       </span>
  //     )
  //   }
  // }

  const initialData = {
    data: [],
    totalCount: 0,
    pageSize: 5,
    filter : {
      status : ''
    },
    sortOrder: "dec",
    sortField: "applicationNumber",
  }

  const [queryParams, setQueryParams] = useState(initialData)

  const { companies } = useSelector(state => state.companyReducer),
    { dashboardApplicationListPaginationData } = useSelector(state => state.dashboardApplicationReducer);

  const dispatch = useDispatch();

  const fetchApplications = async (queryParams) => {
    setQueryParams(queryParams);
    
    dispatch(getApplicationsByCompanyIdListPagination( queryParams ));
  }

  const columns = [
    {
      dataField: "applicationNumber",
      text: "Application #",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.ApplicationNumberFormatter,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.CompanyFormatter,
    },
    {
      dataField: "supplierName",
      text: "Supplier",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.SupplierFormatter,
    },
    {
      dataField: "clientType",
      text: "Client Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.ClientTypeFormatter,
    },
    {
      dataField: "submissionDate",
      text: "Submission Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.DateFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StatusFormatter,
    },
  ];

  const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
  ]

  const initialFilter = {
    filter: {
      companyName: "",
      supplierName: "",
      orderNumber: "",
      totalCost: "",
      totalProductQty: "",
      status: "",
    },
    sortOrder: "dec",
    sortField: "applicationNumber",
    pageNumber: 1,
    pageSize: 5
  }

  const sorting = [{ dataField: "orderNumber", order: "asc" }]
  const pagination = { custom: true, sizePerPageList: sizePerPageList }
  const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

  const prepareFilter = (queryParams, values) => {
    const { searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.companyName = searchText ?? "";
    filter.supplierName = searchText ?? "";
    filter.orderNumber = searchText ?? "";
    filter.totalCost = searchText ?? "";
    filter.totalProductQty = searchText ?? "";
    filter.status = searchText ?? "";

    newQueryParams.filter = filter;
    return newQueryParams;
  }

  useEffect(() => {
    fetchApplications(queryParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  return (
    <div>

      <Card>
        <CardHeader title="All Applications">
          <CardHeaderToolbar>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationTable
            data={dashboardApplicationListPaginationData}
            columns={columns}
            pagination={pagination}
            isLoading={false}
            sorting={sorting}
            tableClasses={tableClasses}
            fetcher={fetchApplications}
            filterFunction={prepareFilter}
            searchField={false}
            initialFilter={initialFilter} />
        </CardBody>
      </Card>
    </div>
  )
}