import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {SupplierSummary} from "./SupplierSummary";
import SupplierPriceLists from "./SupplierPriceLists";
import SupplierRoutes from "./SupplierRoutes";
import SupplierFavoriteItems from "./SupplierFavoriteItems";
import SupplierClientsList from "./SupplierClientsList";
import Paper from "@material-ui/core/Paper";
import SupplierCategories from "./SupplierCategories";
import {SupplierAttachments} from "./SupplierAttachments";
import SupplierConfig from "./SupplierConfig"
import { useSelector } from "react-redux";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SupplierSummarySteps({supplierInformation, categories, products, edit, setEdit }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const supplierId = supplierInformation._id

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    const { accountType } = useSelector(
        ({ auth }) => ({
            accountType: auth.user != null ? auth.user.type : null
        }));

    console.log("Account Type: ", accountType)

    return (
        <Paper className={classes.root}>
            
            {
                accountType == 'supplier' ? 
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        centered
                    >   
                        
                        {/* <Tab label="Price Lists" wrapped/> */}
                        <Tab label="General Config" wrapped/>
                        <Tab label="Routes List" wrapped/>
                        <Tab label="Favorite Products" wrapped/>
                    </Tabs>
                : 
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        centered
                    >   
                        <Tab label="Basic Information" wrapped />
                        <Tab label="Attachments" wrapped/>
                        <Tab label="Categories and Products" wrapped/>
                        <Tab label="Price Lists" wrapped/>
                        <Tab label="Clients List" wrapped/>
                        <Tab label="Routes List" wrapped/>
                        <Tab label="General Config" wrapped/>
                    </Tabs>
            }
            
        
            {
                accountType == 'supplier' ? 
                <>
                    
                    {/* {value === 0 && <TabContainer> <SupplierPriceLists  products={products} categories={categories} edit={true} supplierId={supplierId}/> </TabContainer>} */}
                    {value === 0 && <TabContainer> <SupplierConfig supplierId={supplierId} currCutOffTime={supplierInformation.cutOffTime} currIsCutOffSameDay={supplierInformation.isCutOffSameDay} /> </TabContainer>}
                    {value === 1 && <TabContainer> <SupplierRoutes supplierId={supplierId}/> </TabContainer>}
                    {value === 2 && <TabContainer> <SupplierFavoriteItems supplierId={supplierId}/> </TabContainer>}
                    
                </>
                :
                <>
                    {value === 0 && <TabContainer> <SupplierSummary supplierInformation={supplierInformation} edit={edit} setEdit={setEdit}/> </TabContainer>}
                    {value === 1 && <TabContainer> <SupplierAttachments supplierId={supplierId} attachments={supplierInformation.attachments}/> </TabContainer>}
                    {value === 2 && <TabContainer> <SupplierCategories products={products} categories={categories} supplierId={supplierId}/> </TabContainer>}
                    {value === 3 && <TabContainer> <SupplierPriceLists  products={products} categories={categories} edit={true} supplierId={supplierId}/> </TabContainer>}
                    {value === 4 && <TabContainer> <SupplierClientsList/> </TabContainer>}
                    {value === 5 && <TabContainer> <SupplierRoutes supplierId={supplierId}/> </TabContainer>}
                    {value === 6 && <TabContainer> <SupplierConfig supplierId={supplierId} currCutOffTime={supplierInformation.cutOffTime} currIsCutOffSameDay={supplierInformation.isCutOffSameDay} /> </TabContainer>}
                </>

            }

            
        </Paper>
    )
}