
import axios from 'axios';

import { put, takeLatest, select } from "redux-saga/effects";


export const actionTypes = {
    GetApplicationsCount: "Get Applications Count",
    SetApplicationsCount: "Set Applications Count"
};


export const applicationsCount = (state = 0,action) => {
        switch (action.type) {
            case actionTypes.GetApplicationsCount:
                return state;

            case actionTypes.SetApplicationsCount:
                return action.payload;

            default:
                return state;
        }
    }
;


export const applicationsCountActions = {
    getApplicationsCount: (accessToken, id) => ({type: actionTypes.GetApplicationsCount, payload: {accessToken, id} }),
    setApplicationsCount: applicationsCount => ({ type: actionTypes.SetApplicationsCount, payload: applicationsCount })

};

// const getToken = state => state.authToken;

export function apiGetApplicationsCount(accessToken, id) {
    // Authorization head should be fulfilled in interceptor.
    // console.log("!!!!!!!!!!!!")
    const options = {
      headers: { 'x-auth-token': accessToken}
    }
    return axios.get(process.env.REACT_APP_API_URL + `/applications/getApplicationsCount/${id}`, options);
  }

export function* saga() {
    yield takeLatest(actionTypes.GetApplicationsCount, function* fetchApplicationsCount(params) {
        //   axios.get();
        // console.log("ES ESTE" , params.payload)
        const { data } = yield apiGetApplicationsCount(params.payload.accessToken, params.payload.id);
        // console.log("DATA", data);
        yield put(applicationsCountActions.setApplicationsCount(data));
            // return {type: actionTypes.GetProductCount, payload: response.data}
        

       
    });


}



