import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headerSortingClasses, sortCaret } from "../../../../_metronic/_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { PaginationTable } from "../../../components/PaginationTable/PaginationTable";
import { getApplicationsByCompanyIdListPagination } from '../../../../redux/actions/applications';
import * as columnFormatters from "./column-formatters";

export default function ApplicationList({ className, handleCreateNewApplication, applicationList, getCommonAppFromApplication, submitApplicationToNewSupplierClick,
                                            inProgressCommonApplicationClick, status }) {

  const { companies } = useSelector(state => state.companyReducer),
    { applicationListPaginationData } = useSelector(state => state.applicationReducer);

  // console.log("Application List: ", applicationListPaginationData)

  const dispatch = useDispatch();

  const initialFilter = {
    filter: {
      status : status,
    },
    sortOrder: "dec",
    sortField: "applicationNumber",
    pageNumber: 1,
    pageSize: 10
  }

  const [queryParams, setQueryParams] = useState(initialFilter)

  const fetchApplications = async (queryParams) => {
    setQueryParams(queryParams);
    dispatch(getApplicationsByCompanyIdListPagination(queryParams));
  }

  useEffect(() => {
    fetchApplications(queryParams)
  }, [companies])

  const columns = [
    {
      dataField: "applicationNumber",
      text: "Application #",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          getCommonAppFromApplication(row.companyId)
        }
      },
      formatter: columnFormatters.ApplicationNumberFormatter,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.CompanyFormatter,
    },
    {
      dataField: "supplierName",
      text: "Supplier",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.SupplierFormatter,
    },
    {
      text: "Client Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.ClientTypeFormatter,
    },
    {
      dataField: "submissionDate",
      text: "Submission Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.DateFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StatusFormatter,
    },
    {
      text: "Action",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.ActionsColumnFormatter,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (row.status === 'draft') {
            inProgressCommonApplicationClick(row.companyId)
          } else {
            submitApplicationToNewSupplierClick(row.companyId)
          }
        }
      },
    },
  ];

  const sizePerPageList = [
    { text: "3", value: 3 },
    { text: "5", value: 5 },
    { text: "10", value: 10 }
  ]

  const sorting = [{ dataField: "orderNumber", order: "asc" }]
  const pagination = { custom: true, sizePerPageList: sizePerPageList }
  const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

  const prepareFilter = (queryParams, values) => {
    const { searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.status = status;
    filter.applicationNumber = searchText ?? "";
    filter.companyName = searchText ?? "";

    newQueryParams.filter = filter;
    return newQueryParams;
  }

  return (
    <div>

      <Card>
        <CardHeader title="All Applications">
          <CardHeaderToolbar>
            <button type="button" className="btn btn-primary" onClick={() => { handleCreateNewApplication() }} >
              Create New Application
                </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationTable
            data={applicationListPaginationData}
            columns={columns}
            pagination={pagination}
            isLoading={false}
            sorting={sorting}
            tableClasses={tableClasses}
            fetcher={fetchApplications}
            filterFunction={prepareFilter}
            initialFilter={initialFilter} />
        </CardBody>
      </Card>
    </div>
  )
}