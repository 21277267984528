
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import logo from './SupplierLogo.png';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 250,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  header: {
    textAlign: 'center',
    paddingBottom: '8px'
  },
  textContent: {
    textAlign: 'center',
    padding: '8px'
  },
  center: {
    textAlign: 'center',
  },
  centerButton: {
    justifyContent: 'center'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function SupplierCard(props) {
  const classes = useStyles();
  const { supplier, handleSelected, selected, isSubmited, handleClickOpen } = props

  // function handleExpandClick() {
  //   setExpanded(!expanded);
  // }

  return (
    <Paper elevation={3} variant="outlined">
      <Card className={classes.card}>
        {typeof(supplier.logoPath) !== 'undefined' ? 
          <CardMedia
          className={classes.media}
          image={supplier.logoPath}
          title="Supplier Logo"
        />
        :
        <CardMedia
          className={classes.media}
          image={logo}
          title="Supplier Logo"
        />
        }
        

        <CardHeader
          className={classes.header}
          title={supplier.entityName}
        />
        <Typography variant="body2" color="textSecondary" component="p" className={classes.textContent}>
            {'https://allec.io'}
          </Typography>

        <CardContent>
          <Typography variant="body1" color="textPrimary" component="p" className={classes.textContent}>
            {'Food distribuitor in Puerto Rico focused on food and beverages. Short description here'}
          </Typography>

          <div className={classes.root}>
            <List component="nav" aria-label="Main mailbox folders">

              {supplier.supplierCategories.map(category => 
                <ListItem button key={category._id} onClick={() => handleClickOpen(supplier, category)}>
                  <div className="symbol symbol-40 symbol-light-primary mr-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <SVG
                          className="h-75 align-self-end"
                          src={toAbsoluteUrl("/media/svg/icons/Cooking/KnifeAndFork1.svg")}
                        ></SVG>
                      </span>
                    </span>
                  </div>
                  <ListItemText primary={`${category.name}`} />
                  <span className="symbol symbol-light-success symbol-45">
                    <span className="symbol-label font-weight-bolder font-size-h6">
                      {`${category.COUNT}`}
                    </span>
                  </span>
                </ListItem>
              )}
              
            </List>
          </div>

        </CardContent>
        <CardActions className={classes.centerButton}>
          {isSubmited ? 
            <button
              type="button"
              // disabled={formik.isSubmitting}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled
            >
              <div>
                <span>Submited    </span>
              <SVG
                className="h-75 align-self-end"
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              ></SVG>
              </div>
            </button> 
          :
            <button
              type="button"
              onClick={() => handleSelected(supplier)}
              // disabled={formik.isSubmitting}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              {selected ? 
                (<div>
                  <span>Selected   </span>
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                ></SVG>
                </div>)
                : <span>Select Supplier</span>
              }
            </button> 
          }
          
          {/* <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton> */}
        </CardActions>
        
      </Card>
    </Paper>
  );
}
