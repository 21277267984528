import {makeStyles} from '@material-ui/core/styles';
import React, {useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import * as Yup from "yup";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {getCompanies, getLocationsByCompanyIdListPagination} from '../CommonApplication/CommonApplicationCrud';
import AddLocationsPage from './AddLocationsPage';
import * as columnFormatters from "./column-formatters";
import LocationDetails from './LocationDetails';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    paper: {
        padding: theme.spacing(2, 2),
        // height: 140,
        // width: 300,
    },
    modalPaper: {
        position: 'absolute',
        // width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const newCompanyInitialValues = {
    corporationName: "",
    businessName: "",
    deliveryAddress: "",
    deliveryCity: "",
    deliveryZipcode: "",
    businessPhoneNumber: "",
    businessFax: "",
    businessEmail: "",
    businessType: "",
    taxIdNumber: "",
    businessEstablishmentDate: "",
    placeOfBusinessDescription: "",
}

function MyLocationsPage(props) {
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const {intl} = props;
    const classes = useStyles();
    const [data, setData] = useState(initialData)
    const [companies, setCompanies] = useState([])
    const [isCompaniesFetched, setIsCompaniesFetched] = useState(false)
    const [locations, setLocations] = useState([])
    const [isLocationDetails, setIsLocationDetails] = useState(false)
    const [isLocationsPage, setIsLocationsPage] = useState(false)
    const [location, setLocation] = useState({})
    const [open, setOpen] = React.useState(false);

    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user,
            accessToken: auth.authToken,
        }));

        async function fetchCompaniesData(queryParams) {

            await getCompanies(user.id, accessToken).then((response) => {
                // setCompanies(response.data)
                // console.log("Companies: ", response.data)
                const companies = response.data
                setCompanies(companies)

                setIsCompaniesFetched(true)
    
            }).catch((error) => {
                console.log(error)
            });
        }

    async function fetchLocationData(queryParams) {

        await getLocationsByCompanyIdListPagination( queryParams, accessToken ).then((response) => {

            const data = {
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            }
            setData(data)
            console.log("Locations: ", response.data)

        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        fetchCompaniesData()
    }, [  ])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const BusinessInformationSchema = Yup.object().shape({
        corporationName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        numeroCatastro: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessPhoneNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessFax: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
        ,
        businessEmail: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessType: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        employerIdentificationNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        registroComerciante: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessEstablishmentDate: Yup.date()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        placeOfBusinessDescription: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    let companyColumn = {
        dataField: "companyName",
        text: "Company",
        formatter: columnFormatters.CompanyFormatter,
      }
    
      if(user.tenant == process.env.REACT_APP_BIOWARE_TENANT){
          companyColumn.text = "Customer"
      }

    const columns = [
        {
            dataField: "locationName",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.LocationNameFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setLocation(row)
                    setIsLocationDetails(true)
                }
            },
        },
        {
            dataField: "locationAddress1",
            text: "Location",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            
            formatter: columnFormatters.LocationFormatter,
        },
        companyColumn,
        {
            dataField: "locationCatastro",
            text: "Numero de Catastro",
            formatter: columnFormatters.CatastroFormatter,
        },
        {
            dataField: "submissionDate",
            text: "Created Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.DateFormatter,
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    const initialFilter = {
        filter: {
            searchText: "",
        },
        sortOrder: "asc",
        sortField: "locationName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "orderNumber", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {isLocationDetails ? <LocationDetails locationInformation={location} accessToken={accessToken}/> :
                isLocationsPage ? <AddLocationsPage userId={user.id} accessToken={accessToken} companies={companies}
                                                    setIsLocationsPage={setIsLocationsPage}/> :
                    <Card>
                        <CardHeader title="All Locations">
                            <CardHeaderToolbar>
                                {   
                                    isCompaniesFetched ?
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            setIsLocationsPage(true)
                                        }}>
                                            Add New Location
                                        </button>
                                    :
                                    <span className="mr-3 spinner spinner-primary spinner-lg"></span>
                                }
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <PaginationTable
                                data={data}
                                columns={columns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                tableClasses={tableClasses}
                                fetcher={fetchLocationData}
                                filterFunction={prepareFilter}
                                initialFilter={initialFilter}/>
                        </CardBody>
                    </Card>
            }
        </>
        // <Paper className={classes.paper}>
        //       <div>
        //         <div>
        //           <div className="text-center mb-10 mb-lg-20">
        //             <h3 className="font-size-h1">
        //             Business Information
        //             {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
        //             </h3>
        //             {/* <p className="text-muted font-weight-bold">
        //             Enter your details to be sent to suppliers.
        //             </p> */}
        //           </div>
        //           <div className="card card-custom card-stretch gutter-b">
        //             <div className="card-header border-0 py-5">
        //               <h3 className="card-title align-items-start flex-column">
        //                 <span className="card-label font-weight-bolder text-dark">Hakuna matata</span>
        //                   {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">20+ new applications</span> */}
        //               </h3>
        //               <div className="card-toolbar">
        //                 {/* <button
        //                   className="btn btn-success font-weight-bolder font-size-sm"
        //                   // onClick={handleCreateNewApplication}
        //                 >
        //                     <span className="svg-icon svg-icon-md svg-icon-white">
        //                       <SVG
        //                         src={toAbsoluteUrl(
        //                           "/media/svg/icons/Files/File.svg"
        //                         )}
        //                         className="h-50 align-self-center"
        //                       ></SVG>
        //                     </span>
        //                     Create New Company
        //                 </button> */}
        //                 <div>
        //                     <button
        //                     type="button"
        //                     onClick={handleOpen}
        //                     className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
        //                     >
        //                         <span>{"Create New Location"}</span>
        //                     </button>
        //                   <Modal
        //                     aria-labelledby="simple-modal-title"
        //                     aria-describedby="simple-modal-description"
        //                     style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        //                     open={open}
        //                     onClose={handleClose}
        //                   >
        //                     <div className={classes.modalPaper}>
        //                       <Typography variant="h6" id="modal-title">
        //                         Create New Location
        //                       </Typography>
        //                       <Formik
        //                         initialValues = { newCompanyInitialValues }
        //                         validationSchema = { BusinessInformationSchema }
        //                         onSubmit = { (values, { setSubmitting }) => {
        //                           try {
        //                             const company = {
        //                               userId,
        //                               ...values
        //                             }
        //                             postCompany(company, accessToken).then((response) => {

        //                               getCompanies(userId, accessToken).then((response) => {
        //                                 setCompanies(response.data)
        //                               })
        //                             })
        //                           } catch (e) {
        //                             alert("An error there has been. Your common application was not saved");
        //                             console.log(e.message);
        //                           }

        //                           setTimeout(() => {
        //                             // alert("Your common application has been saved.");
        //                             setSubmitting(false);
        //                             handleClose()
        //                           }, 400);
        //                         } }
        //                       >
        //                         <Form>
        //                         <div>
        //                           <div className="" style={{ display: "block" }}>

        //                           <Grid container spacing={3}>
        //                               <Grid item xs={6}>
        //                               <MyTextInput
        //                                   label="Corporation Name"
        //                                   name="corporationName"
        //                                   type="text"
        //                                   placeholder="Corporation"
        //                               />

        //                               <MyTextInput
        //                                   label="Business Name"
        //                                   name="businessName"
        //                                   type="text"
        //                                   placeholder="Business"
        //                               />

        //                               <MyTextInput
        //                                   label="Delivery Address"
        //                                   name="deliveryAddress"
        //                                   type="text"
        //                                   placeholder="Address"
        //                               />

        //                               <MyTextInput
        //                                   label="City"
        //                                   name="deliveryCity"
        //                                   type="text"
        //                                   placeholder="City"
        //                               />

        //                               <MyZipcodeInput
        //                                   label="Zip Code"
        //                                   name="deliveryZipcode"
        //                                   type="text"
        //                                   placeholder="00123"
        //                               />

        //                               <MyCatastroInput
        //                                   label="Numero de Catastro"
        //                                   name="numeroCatastro"
        //                                   type="text"
        //                                   placeholder="###-###-##-##"
        //                               />

        //                               <MyPhoneInput
        //                                   label="Phone Number"
        //                                   name="businessPhoneNumber"
        //                                   type="text"
        //                                   placeholder=""
        //                               />

        //                             </Grid>
        //                             <Grid item xs={6}>
        //                               <MyTextInput
        //                                   label="Fax"
        //                                   name="businessFax"
        //                                   type="text"
        //                                   placeholder="01293293478329"
        //                               />

        //                               <MyTextInput
        //                                   label="Email"
        //                                   name="businessEmail"
        //                                   type="text"
        //                                   placeholder="mail@email.com"
        //                               />

        //                               <MyTextInput
        //                                   label="Type of Business"
        //                                   name="businessType"
        //                                   type="text"
        //                                   placeholder="New"
        //                               />

        //                               <MyEINInput
        //                                   label="Employer Identification Number (EIN)"
        //                                   name="employerIdentificationNumber"
        //                                   type="text"
        //                                   placeholder="##-#######"
        //                               />

        //                               <MyRegistroComercianteInput
        //                                   label="Registro de Comerciante"
        //                                   name="registroComerciante"
        //                                   type="text"
        //                                   placeholder="#######-####"
        //                               />

        //                               <MyTextInput
        //                                   label="Date of Business Establishment"
        //                                   name="businessEstablishmentDate"
        //                                   type="date"
        //                               />
        //                               {/* <TextField
        //                                 id="date"
        //                                 label="Birthday"
        //                                 type="date"
        //                                 defaultValue="2017-05-24"
        //                                 className={classes.textField}
        //                                 InputLabelProps={{
        //                                   shrink: true,
        //                                 }}
        //                               /> */}

        //                               <MyTextInput
        //                                   label="Description of place of Business"
        //                                   name="placeOfBusinessDescription"
        //                                   type="text"
        //                                   placeholder="In here, your business describe you must"
        //                               />
        //                               </Grid>
        //                                 <div className="form-group d-flex flex-wrap flex-center">
        //                                     <button
        //                                     id="businessInfoSubmit"
        //                                     type="submit"
        //                                     // disabled={formik.isSubmitting}
        //                                     className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
        //                                     >
        //                                         <span>Create Company</span>
        //                                     </button>

        //                                 </div>
        //                               </Grid>
        //                           </div>
        //                         </div>
        //                         </Form>
        //                       </Formik>
        //                     </div>
        //                   </Modal>
        //                 </div>
        //               </div>
        //             </div>
        //                 {/* end::Header */}
        //                 {/* begin::Body */}
        //                 <div className="card-body py-0">
        //                 {/* begin::Table */}
        //                   <div className="table-responsive">
        //                     <table
        //                       className="table table-head-custom table-head-bg table-borderless table-vertical-center"
        //                       id="kt_advance_table_widget_1"
        //                     >
        //                       <thead>
        //                         <tr className="text-left">
        //                           <th className="pr-0" style={{ minWidth: "150px" }}>
        //                             Company
        //                           </th>
        //                           <th style={{ minWidth: "150px" }}>City</th>
        //                           <th style={{ minWidth: "150px" }}>Date Created</th>
        //                           <th style={{ minWidth: "150px" }}></th>
        //                           {/* <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
        //                             Action
        //                           </th> */}
        //                         </tr>
        //                       </thead>
        //                     <tbody>
        //                       {companies.map(company => (
        //                           <tr key={company._id}>
        //                             <td className="pl-0 py-2">
        //                               <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        //                                   {company.businessName}
        //                               </span>
        //                               <span className="text-muted font-weight-bold">
        //                                   {company.deliveryAddress}
        //                               </span>
        //                             </td>
        //                             <td>
        //                             <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
        //                                   {company.deliveryCity}
        //                               </span>
        //                               <span className="text-muted font-weight-bold">
        //                                   {"Puerto Rico"}
        //                               </span>
        //                             </td>
        //                             <td>
        //                               <a
        //                                   href="#"
        //                                   className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
        //                               >
        //                                   {company.createdDate ? company.createdDate : company.businessEstablishmentDate}
        //                               </a>
        //                             </td>

        //                           </tr>

        //                       ))}
        //                   </tbody>
        //                 </table>
        //               </div>
        //               {/* end::Table */}
        //             </div>
        //             {/* end::Body */}

        //           </div>
        //         </div>
        //     </div>
        // </Paper>
    );
}

export default injectIntl(MyLocationsPage);