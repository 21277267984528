
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "@material-ui/core/CardHeader";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import { updateSupplierCutOffTime } from "../AdminPagesCrud";

function CutOffTimeConfig({ supplierId, currCutOffTime, currIsCutOffSameDay, intl }){

    // const {id} = useParams();

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    // useEffect(() => {

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])

    const routeSchema = Yup.object().shape({
        cutOffTime: Yup.string()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        isCutOffSameDay: Yup.boolean()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    console.log("Cutoff: ", currCutOffTime, currIsCutOffSameDay)

    const formik = useFormik({
        initialValues: { 
            cutOffTime : currCutOffTime,
            isCutOffSameDay: currIsCutOffSameDay
        },
        validationSchema: routeSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            try {
                
                updateSupplierCutOffTime(values, supplierId, accessToken).then((response) => {

                })

            } catch (e) {
                alert("An error occurred");
                console.log(e.message);
            }
        },
    });


    return (
        <>
            <Card>
                <CardHeader title="Edit Cut-off Time"/>
                <CardBody>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="">
                                <div className="col-12">
                                    <Grid container>
                                        <Grid container spacing={2}>
                                            
                                            <Grid item xs={12}>
                                                <div className="form-group fv-plugins-icon-container">
                                                    <InputLabel >Cut-off Time</InputLabel>
                                                    <Select
                                                        input={<FilledInput name="cutOffTime" id="cutOffTime"
                                                                            type="text"
                                                                            className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                            {...formik.getFieldProps("cutOffTime")}
                                                        />}
                                                    >
                                                        <MenuItem value={"1:00"}>1:00</MenuItem>
                                                        <MenuItem value={"2:00"}>2:00</MenuItem>
                                                        <MenuItem value={"3:00"}>3:00</MenuItem>
                                                        <MenuItem value={"4:00"}>4:00</MenuItem>
                                                        <MenuItem value={"5:00"}>5:00</MenuItem>
                                                        <MenuItem value={"6:00"}>6:00</MenuItem>
                                                        <MenuItem value={"7:00"}>7:00</MenuItem>

                                                        <MenuItem value={"8:00"}>8:00</MenuItem>
                                                        <MenuItem value={"9:00"}>9:00</MenuItem>
                                                        <MenuItem value={"10:00"}>10:00</MenuItem>
                                                        <MenuItem value={"11:00"}>11:00</MenuItem>
                                                        <MenuItem value={"12:00"}>12:00</MenuItem>
                                                        <MenuItem value={"13:00"}>13:00</MenuItem>
                                                        <MenuItem value={"14:00"}>14:00</MenuItem>

                                                        <MenuItem value={"15:00"}>15:00</MenuItem>
                                                        <MenuItem value={"16:00"}>16:00</MenuItem>
                                                        <MenuItem value={"17:00"}>17:00</MenuItem>
                                                        <MenuItem value={"18:00"}>18:00</MenuItem>
                                                        <MenuItem value={"19:00"}>19:00</MenuItem>
                                                        <MenuItem value={"20:00"}>20:00</MenuItem>
                                                        <MenuItem value={"21:00"}>21:00</MenuItem>
                                                        <MenuItem value={"22:00"}>22:00</MenuItem>
                                                        <MenuItem value={"23:00"}>23:00</MenuItem>
                                                        <MenuItem value={"24:00"}>24:00</MenuItem>

                                                    </Select>
                                                    {formik.touched.cutOffTime && formik.errors.cutOffTime ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.cutOffTime}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="form-group fv-plugins-icon-container">
                                                    <InputLabel >Cut-off Day</InputLabel>
                                                    <Select
                                                        input={<FilledInput name="isCutOffSameDay" id="isCutOffSameDay"
                                                                            type="text"
                                                                            className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                            {...formik.getFieldProps("isCutOffSameDay")}
                                                        />}
                                                    >
                                                        <MenuItem value={true}>Yes</MenuItem>
                                                        <MenuItem value={false}>No</MenuItem>

                                                    </Select>
                                                    {formik.touched.isCutOffSameDay && formik.errors.isCutOffSameDay ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.isCutOffSameDay}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="col-12 row justify-content-center">
                                    <button className="btn btn-primary" type="submit">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default injectIntl(CutOffTimeConfig);
