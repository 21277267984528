import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";

export default function ApplicationsInformation({ commonApp, companyApplications } ) {

    const getStatusLabel = (status) => {
        if(status === "submitted") {
          return (
            <span className="label label-lg label-light-primary label-inline">
              {status}
            </span> 
          )
        } else if (status === "draft") {
            return (
              <span className="label label-lg label-light-warning label-inline">
                {status}
              </span>
            )
        } else if (status === "approved") {
            return (
              <span className="label label-lg label-light-success label-inline">
                {status}
              </span>
            )
        } else if (status === "denied") {
          return (
            <span className="label label-lg label-light-danger label-inline">
              {status}
            </span>
          )
        }
      }

    console.log(commonApp)
    console.log(companyApplications)

    return (
        <div className="col-12">
            <Card>
                <CardHeader title={"Applications Information #" + commonApp.applicationNumber} >
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>
                            Expand
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    {/* begin::Table */}
                    <div className="table-responsive">
                        <table
                        className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                        id="kt_advance_table_widget_1"
                        >
                        <thead>
                            <tr className="text-left">
                            <th className="pr-0" style={{ minWidth: "150px" }}>
                                Application #
                            </th>
                            <th style={{ minWidth: "150px" }}>Company</th>
                            <th style={{ minWidth: "150px" }}>Supplier</th>
                            <th style={{ minWidth: "150px" }}>Client Type</th>
                            <th style={{ minWidth: "150px" }}>Submission Date</th>
                            {/* <th style={{ minWidth: "150px" }}>Contact</th> */}
                            <th style={{ minWidth: "150px" }}>Status</th>
                            {/* <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                                Action
                            </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {companyApplications.map((application, index) => (
                            <tr key={`${application._id}-${index}`}>
                                <td className="pl-0 py-8">
                                <a
                                    // onClick={() => getCommonAppFromApplication(application.companyId)}
                                    href="javascript:void(0);"
                                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >
                                    {"#" + application.applicationNumber}
                                </a>
                                </td>
                                <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {application.companyName}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {application.companyAddress}
                                </span>
                                </td>
                                <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {application.supplierName}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {application.supplierEmail}
                                </span>
                                </td>
                                <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {(typeof(application.clientId) !== 'undefined') ? 'Existing' : 'New'}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {(typeof(application.clientId) !== 'undefined') ? application.clientId : null}
                                </span>
                                </td>
                                <td>
                                <a
                                    href="javascript:void(0);"
                                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >
                                    {application.submissionDate}
                                </a>
                                </td>
                                {/* <td>
                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {application.contactName}
                                </span>
                                <span className="text-muted font-weight-bold">
                                    {application.contactPhone}
                                </span>
                                </td> */}
                                <td>
                                {getStatusLabel(application.status)} 
                                </td>
                                
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    {/* end::Table */}
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    )
}