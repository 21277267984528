
import React from "react";

export function OrderNumberFormatter(cellContent, row){

    // console.log(row)
    return (
        <>
            <a 
            href="javascript:void(0);"
            className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {`#${row.orderNumber}`}
            </a>
        </>
    )
}