import axios from "axios";


export const GET_COMMON_APP = process.env.REACT_APP_API_URL + "/commonApplication/applications/";
export const POST_CREDIT_INFORMATION = process.env.REACT_APP_API_URL + "/applications/creditInformation/";
export const GET_APP = process.env.REACT_APP_API_URL + "/applications/"
export const GET_LOCATIONS = process.env.REACT_APP_API_URL + "/locations"


export function fetchCommonApp(authToken, id) {
    // Authorization head should be fulfilled in interceptor.
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.get(GET_COMMON_APP + id, options);
}

export function fetchApplication(authToken, id, supplierId) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: { supplierId }
    }
    console.log(`Fech Application ID: ${id}`);
    return axios.get(GET_APP + id, options)
}

export function fetchApplicationLocations(authToken, locationIdList){
    const options = {
        headers: {'x-auth-token': authToken},
        params: { locationIdList }
    }

    return axios.get(`${GET_LOCATIONS}/idList`, options)
}

export function postCreditInformation(id,authToken,creditInformation,buyerEmail,supplierName){
    const options = {
        headers: { 'x-auth-token': authToken },
        params: {
            supplierName,
            buyerEmail,
            id,
        }
    }

    return axios.patch(POST_CREDIT_INFORMATION + id, creditInformation, options);
}
