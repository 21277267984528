import React, { useState, useEffect } from "react";
import {injectIntl} from "react-intl";
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { getRouteRecordDetails } from "./routesCrud";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}));

function RouteRecordDetail({ accessToken, routeRecordId, setIsRouteRecordDetailsClicked }){

    const classes = useStyles();

    const [routeRecordDetails, setRouteRecordDetails] = useState(null);
    const [expanded, setExpanded] = useState("");
    const [isDataFetched, setIsDataFetched] = useState(false);

    const fetchRouteRecordDetails = async () => {
        // dispatch(getOrdersByUser(user.id, queryParams))
        getRouteRecordDetails(accessToken, routeRecordId).then(response => {
            console.log("~~~~~~", response.data)
            setRouteRecordDetails(response.data)
            setIsDataFetched(true)
        }).catch(error => console.log(error))
    }
    
    useEffect(() => {
        fetchRouteRecordDetails()

    },[ ])

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : "")
    };

    const handleBack = () => {
        setExpanded("")
        setIsRouteRecordDetailsClicked(false)
    };

    function toFixedCustom(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    return (
        <>
            <button type="button" className="btn btn-primary mb-6" onClick={() => handleBack()} >Back</button>
            {
                isDataFetched ?
                    <>
                        <Card>
                            <CardHeader title={`Route Record Detail`}>
                                
                            </CardHeader>
                            <CardBody>
                                <div className="row">
                                    <div className="col-2">
                                        <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                            Date: 
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className={'text-dark-75 d-block font-size-lg'}>
                                            {routeRecordDetails.routeDate}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                            Status: 
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className={'text-dark-75 d-block font-size-lg'}>
                                            {routeRecordDetails.status}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                            Driver: 
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className={'text-dark-75 d-block font-size-lg'}>
                                            {routeRecordDetails.driver[0].name}
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                            Truck: 
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className={'text-dark-75 d-block font-size-lg'}>
                                            {routeRecordDetails.truck[0].name}
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <div>
                            {
                                <div className="col-12 my-5">
                                    {routeRecordDetails.orders.map((order, orderIndex) => (
                                        <>
                                            <ExpansionPanel square expanded={expanded === 'panel' + orderIndex} onChange={handleAccordionChange('panel' + orderIndex)}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls={'panel-content-' + orderIndex}
                                                    id={'panel-header-' + orderIndex}
                                                    >
                                                    <Typography className={classes.heading}>
                                                        #{order.orderNumber}
                                                    </Typography>
                                                    <Typography className={classes.secondaryHeading}>{order.companyName}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <div className="col-12 my-5">
                                                        <Card>
                                                            <div className="row mt-5 ml-5">
                                                                <div className="col-2">
                                                                    <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                                                        Comment
                                                                    </span>
                                                                </div>
                                                                <div className="col-2">
                                                                    <span className={'text-dark-75 d-block font-size-lg'}>
                                                                        {order.comment}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            { order.customerPo && order.customerPo != '' ?
                                                                <div className="row mt-2 ml-5">
                                                                    <div className="col-2">
                                                                        <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                                                            Customer PO
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <span className={'text-dark-75 d-block font-size-lg'}>
                                                                            {order.customerPo}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            :
                                                                null
                                                            }
                                                            
                                                            <div className="row mt-2 ml-5">
                                                                <div className="col-2">
                                                                    <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                                                        Total Items: 
                                                                    </span>
                                                                </div>
                                                                <div className="col-2">
                                                                    <span className={'text-dark-75 d-block font-size-lg'}>
                                                                        {order.totalProductQty}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 ml-5">
                                                                <div className="col-2">
                                                                    <span className={'text-dark-75 font-weight-bolder d-block font-size-lg'}>
                                                                        Total Price: 
                                                                    </span>
                                                                </div>
                                                                <div className="col-2">
                                                                    <span className={'text-dark-75 d-block font-size-lg'}>
                                                                        ${order.totalCost}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <CardBody>
                                                                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center table-padding">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Product</th>
                                                                            <th className="text-center" >SKU</th>
                                                                            <th className="text-center" >Price</th>
                                                                            <th className="text-center" >Product Qty.</th>
                                                                            <th className="text-center" >Total Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {order?.products?.map((product,index) =>  (
                                                                            <tr key={product._id}>
                                                                                <td className="border-top-0 pl-0">
                                                                                    { product.imagePath ? 
                                                                                        <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                                                            <div className="symbol-label"
                                                                                                style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                                        {product.description ? product.description : product.name}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                                                                                        {product.sku}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="border-top-0 text-right py-2 align-middle">
                                                                                    <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                                                                                        {product.listPrice || product.listPrice == '' ?                  
                                                                                            product.listPrice
                                                                                            :
                                                                                            product.price
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                                                                    <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">{product.amount}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                                                                                        {product.listPrice?
                                                                                            <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice * product.amount), 2)}</span>
                                                                                            :
                                                                                            <span>${toFixedCustom((product.price * product.amount), 2)}</span>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>

                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </>
                                    ))}
                                </div>

                            }
                            
                        </div>
                    </>
                :
                    <div className="row justify-content-center mt-20">
                        <span className="ml-3 spinner spinner-lg spinner-primary"></span>
                    </div>

            }
        </>
    )
}

export default injectIntl(RouteRecordDetail);