import {Modal} from "react-bootstrap";
import React from "react";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";


export function OtherModal({modalStatus,manageModal,actualApplication}){

    const handleClose = () => {
        manageModal({
            permitsModal: false,
            licensesModal: false,
            creditModal: false,
            otherModal: false
        });
    };

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                show={modalStatus.otherModal}
                backdrop="static"
                centered={true}
                size="lg"
            >
                <Modal.Body className="overlay overlay-block cursor-default row justify-content-center">
                    <div className="row justify-content-center px-5">
                        <h2 className="text-center">Documents</h2>

                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Document ID</th>
                                            <th>Upload Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Registro de Comerciante - Individuo & Corporación (SC2918)
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Certificado de Revendedor - Individuo & Corporación (SC2963)
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Certificado de COmpras Exentas - Individuo & Corporación(SC2916)
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Photo ID
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Patente Municipal
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Liquor License
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Financial Statements - Corporations
                                            </td>
                                            <td>
                                                06/14/2020
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="btn btn-icon btn-light btn-sm mx-3">
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                      <SVG
                                                          src={toAbsoluteUrl(
                                                              "/media/svg/icons/Files/Download.svg"
                                                          )}
                                                      ></SVG>
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 row justify-content-center">
                        <button className="btn btn-primary" onClick={handleClose}>
                            Ok
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}