import React from "react";

export function EntityNameFormatter(cellContent,row){

    return(
        <>
            <a className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
            href="javascript:void(0);">
                {row.entityName}
            </a>
        </>
    )
}