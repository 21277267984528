import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

export function EditButtonFormatter(cellContent,row){

    return(
        <>
            <a
                href="javascript:void(0);"
                title="Edit Product"
                className="btn btn-icon btn-light btn-hover-primary btn-sm"
            >
                <span className="center svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                </span>
            </a>
        </>
    )
}