
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function DenseTable(props) {
  const classes = useStyles();
  const { selectedSuppliers, handleChecked, existingClientList, newLocationsCategories } = props
  //console.log(props.selectedSupliers)

  const [checked, setChecked] = React.useState(true);

  console.log(existingClientList)
  console.log(selectedSuppliers)

  selectedSuppliers.map(supplier => {
    console.log(supplier)
    existingClientList.map(client => {
      console.log(client.name == supplier)
    })
  })
  
  const handleChange = (supplier) => {
    handleChecked(supplier)
    // setChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="center">Supplier</TableCell>
              <TableCell align="center">Client Type</TableCell>
              <TableCell align="center">Selected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newLocationsCategories.map(supplierLocations => (
              <TableRow key={supplierLocations.supplier}>
                <TableCell align="center" component="th" scope="row">
                  {`${supplierLocations.supplier} (New Location)`}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {/* {existingClientList.map(client => 
                      (client.name == supplier) ? "Existing" : "New"
                  )} */}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    defaultChecked
                    onChange={() => handleChange(supplierLocations.supplier)}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {selectedSuppliers.map(supplier => (
              <TableRow key={supplier._id}>
                <TableCell align="center" component="th" scope="row">
                  {supplier.entityName}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {existingClientList.map(client => 
                      (client.name == supplier.entityName) ? "Existing" : "New"
                  )}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    defaultChecked
                    onChange={() => handleChange(supplier)}
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}