import {Card, CardBody, CardHeader, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";

export default function ApplicationLocations({applicationLocations, locationsCategories}) {

    console.log("App Locations: ", applicationLocations)
    console.log("Location Categories: ", locationsCategories)

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Application Locations">
                    {/*<CardHeaderToolbar>*/}
                    {/*    <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>*/}
                    {/*        Expand*/}
                    {/*    </button>*/}
                    {/*</CardHeaderToolbar>*/}
                </CardHeader>
                <CardBody>
                            <div className="table-responsive">
                                <table
                                    className="table table-head-custom table-head-bg table-vertical-center"
                                    id="kt_advance_table_widget_1"
                                >
                                    <thead>
                                        <tr className="text-left">
                                            <th className="pr-0" style={{ minWidth: "150px" }}>
                                            Location
                                            </th>
                                            <th style={{ minWidth: "150px" }}>City</th>
                                            <th style={{ minWidth: "150px" }}>Zipcode</th>
                                            <th style={{ minWidth: "150px" }}>Numero de Catastro</th>
                                            <th style={{ minWidth: "150px" }}>Categories</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {applicationLocations.map(location => (
                                            <tr key={location._id}>
                                                <td className="pl-0 py-2">
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationAddress}  
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationCity}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationZipcode}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {location.locationCatastro}
                                                    </span>
                                                </td>
                                                <td>
                                                    <ul style={{ 'listStyleType':'none', padding:0, margin:0}} >
                                                        {locationsCategories.find(locationsCategory => locationsCategory.locationId === location._id).categories.map((category, index) => 
                                                        <li key={`${location._id}_${index}`}>
                                                            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                {`${category}`}
                                                            </span>
                                                        </li>
                                                    )}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                
                <CardFooter>
                </CardFooter>
                
            </Card>
        </div>
    )
}