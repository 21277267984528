import React, { useEffect, useState} from "react";
// import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {useSelector} from "react-redux";
import {SuppliersList} from "./SuppliersList";
import {SupplierInfo} from "./SupplierInfo";
import { fetchApprovedSuppliersCategories, fetchCompanies, fetchCompaniesByEmployee } from "./ordersCrud";


function SuppliersPage({ setNewOrder }) {

    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )
    
    const [suppliers,setSuppliers] = useState([]);
    const [isSupplierSelected, setIsSupplerSelected] = useState(false)
    const [supplier,setSupplier] = useState({})
    const [isSuppliersFetched, setIsSuppliersFetched] = useState(false)
    const [isCompaniesFetched, setIsCompaniesFetched] = useState(false)
    const [companies,setCompanies] = useState([])

    const handleSupplierChange = (supplier) => {
        setSupplier(supplier);
        setIsSupplerSelected(true)

        fetchCompaniesByEmployee(user.id,accessToken,supplier._id).then(response =>{
            
            setCompanies(response.data)
            setIsCompaniesFetched(true)

        }).catch(err => {
            console.log("Error: ", err)
        })   
    }

    useEffect(() =>{
        fetchApprovedSuppliersCategories(user.id,accessToken).then(response =>{
            // console.log("response: ", response)
            if(response.data.length == 1){
                handleSupplierChange(response.data[0])
            } else{
                setSuppliers(response.data)
                setIsSuppliersFetched(true)
            }
            
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            {!isSupplierSelected ?(
                isSuppliersFetched ? 
                    suppliers.length > 0 ?
                        <SuppliersList suppliers={suppliers} setSupplier={setSupplier} setIsSupplerSelected={setIsSupplerSelected} handleSupplierChange={handleSupplierChange}/>
                        :
                        <div className="card card-custom gutter-b">
                            <div className="card-body p-0">
                                <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                    <div className="col-md-10">
                                        <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                            <div className="text-center mb-4">
                                                <span className="card-label font-weight-bolder text-dark text-center font-size-lg">No approved suppliers found.</span>
                                            </div>
                                            <div className="text-center">
                                                <span className="text-dark-75 mt-3 font-weight-bold font-size-lg">To start making orders wait for an application to be approved.</span>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    <div className="row justify-content-center mt-20">
                        <span className="ml-3 spinner spinner-lg spinner-primary"></span>
                    </div>

            ):(
                isCompaniesFetched ?
                    <SupplierInfo supplier={supplier} setIsSupplerSelected={setIsSupplerSelected} companies={companies} setNewOrder={setNewOrder}/>
                    :
                    <div className="row justify-content-center mt-20">
                        <span className="ml-3 spinner spinner-lg spinner-primary"></span>
                    </div>
            )}
        </>
    );
}
    
export default injectIntl(SuppliersPage);