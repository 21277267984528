import React, { useState } from "react";
import {Star} from "@material-ui/icons";

export function FavoritePriceFormatter({row, handleBlur}){
    const initialFavoritePrice = row.favoritePrice ? row.favoritePrice : 0
    const [favoritePrice, setFavoritePrice] = useState(initialFavoritePrice)

    return (
        <>
            <input
                value={favoritePrice}
                onChange={(e) => setFavoritePrice(e.target.value)}
                onBlur={() => handleBlur(favoritePrice, row)}
                className={`form-control form-control-solid h-auto py-2 px-6`}
                type="number"
                min="0"
            />
        </>
    )
}
