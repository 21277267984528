import React from "react";

export function ClientTypeFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                {(typeof(row.clientId) !== 'undefined') ? 'Existing' : 'New'}
            </span>
        </>
    )
}