import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

export function ActionColumnFormatter(cellContent, row, rowIndex){

    const setAction = () =>{
        return (
            <button
                type="button"
                className="btn btn-primary font-weight-bolder font-size-sm mr-1"
            >
                {"Edit"}
            </button>
        )
    }


    return (
        <>
            {setAction()}
        </>
    )
}