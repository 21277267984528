
import React from "react";

export function TotalCostFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {parseFloat(row.totalCost).toFixed(2)}
            </span>
        </>
    )
}