
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import CardHeader from "@material-ui/core/CardHeader";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import { createNewRoute, updateRoute } from "../AdminPagesCrud";

function RouteAddEdit({route, supplierId, edit, setEdit, isNew, setIsNew, intl}){

    // const {id} = useParams();

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const routeSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(30, "Maximum 30 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        dayOfTheWeek: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(20, "Maximum 20 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const formik = useFormik({
        initialValues: route,
        validationSchema: routeSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            try {
                if(isNew){

                    values.clientsList = []
                    values.supplierId = supplierId
                    createNewRoute(values, accessToken).then((response) => {
                        setIsNew(!isNew)
                        // setShowSnackbar(!showSnackbar)
                    })
                }
                else {
                    updateRoute(route._id, values, accessToken).then((response) => {
                        setEdit(!edit)
                    })
                }

            } catch (e) {
                alert("An error occurred");
                console.log(e.message);
            }
        },
    });


    return (
        <>
            <Card>
                {edit ? <CardHeader title="Edit Price List"/> : <CardHeader title="New Price List"/> }
                <CardBody>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="">
                                <div className="col-12">
                                    <Grid container>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="form-group fv-plugins-icon-container">
                                                    <InputLabel>Route Name</InputLabel>
                                                    <input
                                                        type="text"
                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                        name="name"
                                                        {...formik.getFieldProps("name")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div
                                                                className="fv-help-block">{formik.errors.name}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <div className="form-group fv-plugins-icon-container">
                                                    <InputLabel >Day of the week</InputLabel>
                                                    <Select
                                                        input={<FilledInput name="dayOfTheWeek" id="dayOfTheWeek"
                                                                            type="text"
                                                                            className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                            {...formik.getFieldProps("dayOfTheWeek")}
                                                        />}
                                                    >
                                                        <MenuItem value={"Monday"}>Monday</MenuItem>
                                                        <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                                                        <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                                                        <MenuItem value={"Thursday"}>Thursday</MenuItem>
                                                        <MenuItem value={"Friday"}>Friday</MenuItem>
                                                        <MenuItem value={"Saturday"}>Saturday</MenuItem>
                                                        <MenuItem value={"Sunday"}>Sunday</MenuItem>

                                                    </Select>
                                                    {formik.touched.dayOfTheWeek && formik.errors.dayOfTheWeek ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.dayOfTheWeek}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="col-12 row justify-content-center">
                                    <button className="btn btn-primary" type="submit">
                                        Save
                                    </button>
                                    <div className="pl-4"/>
                                    <button className="btn btn-secondary" type="button" onClick={() => setEdit(!edit)}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default injectIntl(RouteAddEdit);
