import React from "react";

export function ClientAddFormatter(cellContent, row, rowIndex, {clientsToAdd}){

    const getClientName = () => {
        const currClient = clientsToAdd.find(client => client == row._id)
        if(currClient) {
            return (
                <span className="text-success font-weight-bolder d-block font-size-lg">
                    { row.businessName }
                </span>
            )
        } else {
            return (
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    { row.businessName }
                </span>
            )
        }
        
    }

    return(
        <>
            {getClientName()}
        </>
    )
}