import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationsByCompanyIdList } from './../../../../redux/actions/applications';
import AllApplications from "./AllApplications";
import AllOrders from "./AllOrders";
import { DashboardInfo } from "./DashboardInfo";
import { SupplierList } from "./SupplierList";
import { TopCategorySupplier } from "./TopCategorySupplier";
import { getLocationsByCompanyIdList } from './../../../../redux/actions/locations';


export function ClientDashboard() {
    const { user, accessToken } = useSelector(
        ({ auth }) => ({
            user: auth.user,
            accessToken: auth.authToken,
        }));
    
    const dispatch = useDispatch();

    async function fetchCommonApplicationData() {
        
        dispatch(getApplicationsByCompanyIdList());
        dispatch(getLocationsByCompanyIdList());
    }

    useEffect(() => {
        fetchCommonApplicationData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <DashboardInfo className="card-stretch gutter-b" />
            </div>
            {user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                null
                :
                <div className="col-lg-12 col-xxl-12">
                    <AllApplications />
                </div>
            }
            <div className="col-lg-12 col-xxl-12">
                <AllOrders className="card-stretch gutter-b" />
            </div>
            {
                user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                    null
                :   
                    <><div className="col-lg-12 col-xxl-12">
                        <TopCategorySupplier />
                    </div><div className="col-lg-12 col-xxl-12">
                        <SupplierList accessToken={accessToken} />
                    </div></>
            }
            

        </div>
    </>);
}
