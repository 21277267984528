import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import React, {useEffect, useState} from "react";
import {ProductBox} from "./ProductBox";
import {useSelector} from "react-redux";
import Pagination from "react-js-pagination"
import {Formik} from "formik";
// import SimpleSnackbar from "./SimpleSnackbar";
import { getSupplierProducts } from '../CommonApplication/SupplierSelection/SupplierSelectionCrud'

export function SupplierProductsPage({supplier}){

    const [products,setProducts] = useState([]);

    const {accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    const initialPaginationOptions = {
        totalSize: 0,
        sizePerPage: 10,
        page: 1
    }

    const [paginationOptions,setPaginationOptions] = useState(initialPaginationOptions)
    const [searchName,setSearchName] = useState("")

    const handlePageChange = (pageNumber) =>{
        getSupplierProducts(supplier._id,accessToken,pageNumber,paginationOptions.sizePerPage,searchName).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:pageNumber,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    useEffect(() => {
        console.log(supplier)
        getSupplierProducts(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName).then(response=>{
            console.log("!!!!!!!!", response)
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <div className="row justify-content-between mb-4">
            <Card >
                <CardHeader title={supplier.entityName}>

                </CardHeader>
                <CardBody>
                    <div className="row">
                        <p>{supplier.description}</p>
                    </div>
                </CardBody>
            </Card>
                <Formik
                    initialValues={{searchText:""}}
                    onSubmit={(values) =>{
                        setSearchName(values.searchText)
                        getSupplierProducts(supplier._id,accessToken,1,paginationOptions.sizePerPage,values.searchText).then(response=>{
                            setProducts(response.data.products)
                            setPaginationOptions({
                                page:1,
                                totalSize: response.data.totalCount,
                                sizePerPage: paginationOptions.sizePerPage
                            })
                        })

                    }}>
                    {({values, handleSubmit, handleBlur, handleChange, setFieldValue})=>(
                        <form onSubmit={handleSubmit} className="form form-label-right w-100">
                            <div className="form-group">
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="searchText"
                                        placeholder="Search"
                                        onBlur={handleBlur}
                                        value={values.searchText}
                                        onChange={(e) =>{
                                            setFieldValue("searchText",e.target.value);
                                            handleSubmit();
                                        }}
                                    />
                                    <small className="form-text text-muted">
                                        <b>Search</b> by name
                                    </small>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            <div className="row">


                {typeof(products) !== 'undefined' ? products.map((product,index) => (
                    <ProductBox product={product}/>
                ))
                    : null
                }
            </div>
            <div className="row justify-content-center">
                <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paginationOptions.page}
                    itemsCountPerPage={paginationOptions.sizePerPage}
                    totalItemsCount={paginationOptions.totalSize}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                />
            </div>
            {/* <SimpleSnackbar
                showSnackBar={showSnackBar}
                setShowSnackBar={setShowSnackBar}
            /> */}
        </>

    )
}