// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function RouteStatusFormatter(cellContent, row) {
    
    const getStatusLabel = (routeStatus) => {
        if(row.status === "Not Submitted"){
            return (
                <span className="label label-lg label-light-primary label-inline">
                    N/A
                </span>
            )
        } else if(routeStatus === "Completed") {
            return (
                <span className="label label-lg label-light-primary label-inline">
                    Sent to Warehouse
                </span>
            )
        } else if (routeStatus === "Routing") {
            return (
                <span className="label label-lg label-light-primary label-inline">
                    {routeStatus.charAt(0).toUpperCase() + routeStatus.slice(1)}
                </span>
            )
        } else {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {routeStatus.charAt(0).toUpperCase() + routeStatus.slice(1)}
                </span>
            )
        }
    }

    return (
        <>
            {getStatusLabel(row.routeStatus)}
        </>
    );
}
