import { GET_NOTIFICATIONS, MARK_NOTIFICATION_READ } from '../constants';
import { api } from './../index';

export const NOTIFICATIONS_URL = `${process.env.REACT_APP_API_URL}/notifications`;

export function getNotifications(userID) {
    return function (dispatch) {
        api.get("/notifications", { params: { userID: userID } })
            .then(response => {
                dispatch({ type: GET_NOTIFICATIONS, payload: response.data });
            }).catch(error => {
                console.log(error)
            })
    }
}

export function markNotificationRead(notificationID) {
    return function (dispatch) {
        api.put(`/notifications/${notificationID}`, { data: { read: true } })
            .then(response => {
                dispatch({ type: MARK_NOTIFICATION_READ, payload: { notificationID: notificationID, modified: response.data.modified } })
            }).catch(error => {
                console.log(error)
            })
    }
}