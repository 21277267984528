import React from "react";

export function PriceFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                { "$" + row.price }
            </span>
        </>
    )
}