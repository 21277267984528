import React from "react";

export function DeniedDetermination({application,creditInformation}){
    console.log("Application",application)
    return (
        <div className="row justify-content-center px-5">
            <h2 className="text-center">{application.companyName} credit application has been rejected.</h2>
            <p className="font-size-lg">{application.companyName} will be notified via email.</p>
        </div>
    )
}