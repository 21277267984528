import { GET_ORDERS_BY_USER } from '../constants';
import { api } from './../index';

export function getOrdersByUser(queryParams) {
    const options = {
        validateStatus: status => status < 400,
        params: queryParams,
    }
    return function (dispatch) {
        
        api.get(`/orders/tenant/pagination`, options).then(response => {
            // console.log("Orders Inside Action", response.data)
            dispatch({ type: GET_ORDERS_BY_USER, payload: response.data });
        }).catch(e => {
            console.log("Order Action Error: ", e)
        })
    }
}
