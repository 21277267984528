import React from "react";

export function CategoryFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                {row.productCategory[0].name}
            </span>
        </>
    )
}