import React from "react";

export function CompanyFormatter(cellContent,row){

    return(
        <>
            {   row.supplierApplications ? 
                    <ul style={{ 'list-style-type':'none', padding:0, margin:0}} >
                    {row.supplierApplications.map(companyStatus => 
                        <li>
                            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                {`${companyStatus.company}`}
                            </span>
                        </li>
                    )}
                    </ul>
                : 
                    null
            }
        </>
    )
}