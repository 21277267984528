import React, { useState } from "react";
import {ListOfContacts} from "../Dashboard/ListOfContacts";
import {ClientsTable} from "./ClientsTable";


export function ClientsPage() {
    const [isClientSummary, setIsClientSummary] = useState(false)

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ClientsTable className="card-stretch gutter-b" isClientSummary={isClientSummary} setIsClientSummary={setIsClientSummary}/>
                </div>
            </div>
            {isClientSummary ? 
                null
                :
                <div className="row">
                    <div className="col-12">
                        <ListOfContacts className="card-stretch gutter-b"/>
                    </div>
                </div>
            }
            
            { /*<div className="row">
                <div className="col-12">
                    <ClientLocations className="card-stretch gutter-b"/>
                </div>
            </div> */}

    </>
    );
}
