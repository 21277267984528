import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";
import {MyTextInput} from "../../CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function BusinessInformation({businessInformation}) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Business Information">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>
                            Expand
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={businessInformation}
                        onSubmit={(values) =>{
                            console.log(values)
                        }}
                    >
                        <Form>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Corporation Name"
                                        name="corporationName"
                                        type="text"
                                        value={businessInformation.corporationName}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Business Name"
                                        name="businessName"
                                        type="text"
                                        value={businessInformation.businessName}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Business Type"
                                        name="businessType"
                                        type="text"
                                        value={businessInformation.businessType}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Phone Number"
                                        name="businessPhoneNumber"
                                        type="text"
                                        value={businessInformation.businessPhoneNumber}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Email"
                                        name="businessEmail"
                                        type="text"
                                        value={businessInformation.businessEmail}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Fax Number"
                                        name="businessFax"
                                        type="text"
                                        value={businessInformation.businessFax}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Business Establishment Date"
                                        name="businessEstablishmentDate                                        "
                                        type="text"
                                        value={businessInformation.businessEstablishmentDate}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Business Description"
                                        name="placeOfBusinessDescription"
                                        type="text"
                                        value={businessInformation.placeOfBusinessDescription}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Address"
                                        name="deliveryAddress                                        "
                                        type="text"
                                        value={businessInformation.deliveryAddress}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="City"
                                        name="deliveryCity"
                                        type="text"
                                        value={businessInformation.deliveryCity}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Zipcode"
                                        name="deliveryZipcode"
                                        type="text"
                                        value={businessInformation.deliveryZipcode}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    )
}