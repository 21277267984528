import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import Iframe from "react-iframe";
import { injectIntl } from "react-intl";
import SVG from "react-inlinesvg";
import ApexCharts from "apexcharts";
import {useHtmlClassService} from "../../../../_metronic/layout";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {KTUtil} from "../../../../_metronic/_assets/js/components/util";
import {ProgressBar} from "react-bootstrap";

function getChartOptions(layoutProps, height) {
    const options = {
      series: [74],
      chart: {
        height: height,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700",
            },
            value: {
              color: layoutProps.colorsGrayGray700,
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true
            },
          },
          track: {
            background: layoutProps.colorsThemeLightSuccess,
            strokeWidth: '100%'
          }
        }
      },
      colors: [layoutProps.colorsThemeBaseSuccess],
      stroke: {
        lineCap: "round",
      },
      labels: ["Progress"]
    };
    return options;
  }

  function getChartOptions1(layoutProps) {
    const strokeColor = "#D13647";
  
    const options = {
      series: [
        {
          name: "Net Profit",
          data: [30, 45, 32, 70, 40, 40, 40]
        }
      ],
      chart: {
        type: "area",
        height: 200,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: layoutProps.colorsThemeLightPrimary,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [layoutProps.colorsThemeBasePrimary]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: layoutProps.colorsGrayGray300,
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        },
        marker: {
          show: false
        }
      },
      colors: [layoutProps.colorsThemeLightPrimary],
      markers: {
        colors: layoutProps.colorsThemeLightPrimary,
        strokeColor: [layoutProps.colorsThemeBasePrimary],
        strokeWidth: 3
      }
    };
    return options;
  }
  
  function getChartOption2(layoutProps) {
    var options = {
      series: [
        {
          name: "Net Profit",
          data: [40, 40, 30, 30, 35, 35, 50]
        }
      ],
      chart: {
        type: "area",
        height: 150,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [layoutProps.colorsThemeBasePrimary]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: layoutProps.colorsGrayGray300,
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily
          }
        }
      },
      yaxis: {
        min: 0,
        max: 55,
        labels: {
          show: false,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        }
      },
      colors: [layoutProps.colorsThemeLightPrimary],
      markers: {
        colors: [layoutProps.colorsThemeLightPrimary],
        strokeColor: [layoutProps.colorsThemeBasePrimary],
        strokeWidth: 3
      }
    };
    return options;
  }

function AnalyticsPage(props) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
          colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
          colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
          colorsThemeBaseSuccess: objectPath.get(
              uiService.config,
              "js.colors.theme.base.success"
          ),
          colorsThemeLightSuccess: objectPath.get(
              uiService.config,
              "js.colors.theme.light.success"
          ),
          fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

      useEffect(() => {
        const element = document.getElementById("kt_mixed_widget_14_chart");
        if (!element) {
          return;
        }
    
        const height = parseInt(KTUtil.css(element, 'height'));
        const options = getChartOptions(layoutProps, height);
    
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
    }, [layoutProps]);

    const layoutProps1 = useMemo(() => {
        return {
          colorsGrayGray500: objectPath.get(
            uiService.config,
            "js.colors.gray.gray500"
          ),
          colorsGrayGray200: objectPath.get(
            uiService.config,
            "js.colors.gray.gray200"
          ),
          colorsGrayGray300: objectPath.get(
            uiService.config,
            "js.colors.gray.gray300"
          ),
          colorsThemeBaseDanger: objectPath.get(
            uiService.config,
            "js.colors.theme.base.danger"
          ),
          fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
      }, [uiService]);
    
      useEffect(() => {
        const element = document.getElementById("kt_mixed_widget_1_chart");
        if (!element) {
          return;
        }
    
        const options = getChartOptions1(layoutProps2);
    
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }, [layoutProps2]);

      const layoutProps2 = useMemo(() => {
        return {
          colorsGrayGray500: objectPath.get(
            uiService.config,
            "js.colors.gray.gray500"
          ),
          colorsGrayGray200: objectPath.get(
            uiService.config,
            "js.colors.gray.gray200"
          ),
          colorsGrayGray300: objectPath.get(
            uiService.config,
            "js.colors.gray.gray300"
          ),
          colorsThemeBasePrimary: objectPath.get(
            uiService.config,
            "js.colors.theme.base.primary"
          ),
          colorsThemeLightPrimary: objectPath.get(
            uiService.config,
            "js.colors.theme.light.primary"
          ),
          fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
      }, [uiService]);

      useEffect(() => {
        const element = document.getElementById("kt_stats_widget_12_chart");
    
        if (!element) {
          return;
        }
    
        const options = getChartOption2(layoutProps2);
        const chartnewUsers = new ApexCharts(element, options);
        chartnewUsers.render();
        return function cleanUp() {
          chartnewUsers.destroy();
        };
      }, [layoutProps2]);

    return (
        <div>
            <div className={`card card-custom card-stretch gutter-b`}>
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-weight-bolder text-dark">KPI Dashboard</span>
                    </h3>
                </div>
                <div className="">
                <div className="col-12">
                    <Iframe
                        url="https://app.powerbi.com/view?r=eyJrIjoiOGFjYjg4OTQtNzk0MC00MzE1LTgyZjgtOTAyNjZjMmU2YzcyIiwidCI6Ijk2MGI1MzA1LWRlMDgtNDk0Yy04ZDY0LTJkNGE3NjRjZTdhMCIsImMiOjJ9"
                        width="100%"
                        height="800px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                    />
                </div>
                   <div className="separator separator-dashed my-5"></div>

                  <div className="col-12">
                        <Iframe
                            url="https://app.powerbi.com/view?r=eyJrIjoiZGY0ZDdkMGItYTk4Yy00ZDBkLThkNTQtMjMyNGNhN2VjMzJiIiwidCI6Ijk2MGI1MzA1LWRlMDgtNDk0Yy04ZDY0LTJkNGE3NjRjZTdhMCIsImMiOjJ9"
                            width="100%"
                            height="800px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                        />
                    </div>
            </div>
            </div>

            {/*<div className="row">*/}
            {/*    <div className="col-md-6">*/}
            {/*        <div className={`card card-custom card-stretch gutter-b`}>*/}
            {/*            /!* Header *!/*/}
            {/*            <div className="card-header align-items-center border-0 mt-4">*/}
            {/*                <h3 className="card-title align-items-start flex-column">*/}
            {/*                    <span className="font-weight-bolder text-dark">New Businesses</span>*/}
            {/*                    <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                    8,104 Approved Applications*/}
            {/*                    </span>*/}
            {/*                </h3>*/}
            {/*                <div className="card-toolbar">*/}
            {/*                    <h3 className="card-title align-items-start flex-column">*/}
            {/*                        <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                            From:*/}
            {/*                        </span>*/}
            {/*                        <span className="label label-lg label-light-primary label-inline">*/}
            {/*                            07 May, 2020*/}
            {/*                        </span>*/}
            {/*                    </h3>*/}
            {/*                    <h3 className="card-title align-items-start flex-column">*/}
            {/*                        <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                            To*/}
            {/*                        </span>*/}
            {/*                        <span className="label label-lg label-light-danger label-inline">*/}
            {/*                            10 August, 2020*/}
            {/*                        </span>*/}
            {/*                    </h3>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            /!* Body *!/*/}
            {/*            <div className="card-body pt-2">*/}
            {/*            <div className="table-responsive">*/}
            {/*            <table className="table table-borderless table-vertical-center">*/}
            {/*                <thead>*/}
            {/*                <tr>*/}
            {/*                    <th className="p-0" style={{minWidth: "200px"}}></th>*/}
            {/*                    <th className="p-0" style={{minWidth: "70px"}}></th>*/}
            {/*                </tr>*/}
            {/*                </thead>*/}
            {/*                <tbody>*/}
            {/*                    <tr>*/}
            {/*                        <td className="pl-0">*/}
            {/*                            <div className="d-flex align-items-center mb-3">*/}
            {/*                                <div className="symbol symbol-40 symbol-light-primary mr-5">*/}
            {/*                                    <span className="symbol-label">*/}
            {/*                                        <span className="svg-icon svg-icon-lg svg-icon-primary">*/}
            {/*                                        <SVG*/}
            {/*                                            className="h-75 align-self-end"*/}
            {/*                                            src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}*/}
            {/*                                        ></SVG>*/}
            {/*                                        </span>*/}
            {/*                                    </span>*/}
            {/*                                </div>*/}

            {/*                                <div className="d-flex flex-column font-weight-bold">*/}
            {/*                                <a*/}
            {/*                                    href="#"*/}
            {/*                                    className="text-dark text-hover-primary mb-1 font-size-lg"*/}
            {/*                                >*/}
            {/*                                    Top Uses*/}
            {/*                                </a>*/}
            {/*                                <span className="text-muted">Bar, Restaurant, Cafeteria</span>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </td>*/}
            {/*                        <td>*/}
            {/*                            <span className="text-muted font-weight-light text-hover-primary mb-1 font-size-lg">*/}
            {/*                                3,290+*/}
            {/*                           </span>*/}
            {/*                        </td>*/}
            {/*                    </tr>*/}
            {/*                    <tr>*/}
            {/*                        <td className="pl-0">*/}
            {/*                            <div className="d-flex align-items-center mb-3">*/}
            {/*                                <div className="symbol symbol-40 symbol-light-warning mr-5">*/}
            {/*                                <span className="symbol-label">*/}
            {/*                                    <span className="svg-icon svg-icon-lg svg-icon-warning">*/}
            {/*                                    <SVG*/}
            {/*                                        className="h-75 align-self-end"*/}
            {/*                                        src={toAbsoluteUrl(*/}
            {/*                                        "/media/svg/icons/Communication/Write.svg"*/}
            {/*                                        )}*/}
            {/*                                    ></SVG>*/}
            {/*                                    </span>*/}
            {/*                                </span>*/}
            {/*                                </div>*/}
            {/*                                <div className="d-flex flex-column font-weight-bold">*/}
            {/*                                <a*/}
            {/*                                    href="#"*/}
            {/*                                    className="text-dark-75 text-hover-primary mb-1 font-size-lg"*/}
            {/*                                >*/}
            {/*                                    Internal Revenue Licenses*/}
            {/*                                </a>*/}
            {/*                                <span className="text-muted">Alcohol, Cigarettes</span>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </td>*/}
            {/*                        <td>*/}
            {/*                            <span className="text-muted font-weight-light text-hover-primary mb-1 font-size-lg">*/}
            {/*                                3,290+*/}
            {/*                           </span>*/}
            {/*                        </td>*/}
            {/*                    </tr>*/}
            {/*                    <tr>*/}
            {/*                        <td className="pl-0">*/}
            {/*                            <div className="d-flex align-items-center mb-3">*/}
            {/*                                <div className="symbol symbol-40 symbol-light-success mr-5">*/}
            {/*                                <span className="symbol-label">*/}
            {/*                                    <span className="svg-icon svg-icon-lg svg-icon-success">*/}
            {/*                                    <SVG*/}
            {/*                                        className="h-75 align-self-end"*/}
            {/*                                        src={toAbsoluteUrl(*/}
            {/*                                        "/media/svg/icons/Communication/Group-chat.svg"*/}
            {/*                                        )}*/}
            {/*                                    ></SVG>*/}
            {/*                                    </span>*/}
            {/*                                </span>*/}
            {/*                                </div>*/}

            {/*                                <div className="d-flex flex-column font-weight-bold">*/}
            {/*                                <a*/}
            {/*                                    href="#"*/}
            {/*                                    className="text-dark text-hover-primary mb-1 font-size-lg"*/}
            {/*                                >*/}
            {/*                                    New Business OutReach*/}
            {/*                                </a>*/}
            {/*                                <span className="text-muted">New Client Engagement</span>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </td>*/}
            {/*                        <td>*/}
            {/*                            <span className="text-muted font-weight-light text-hover-primary mb-1 font-size-lg">*/}
            {/*                                3,290+*/}
            {/*                           </span>*/}
            {/*                        </td>*/}
            {/*                    </tr>*/}
            {/*                    <tr>*/}
            {/*                        <td className="pl-0">*/}
            {/*                            <div className="d-flex align-items-center mb-3">*/}
            {/*                                <div className="symbol symbol-40 symbol-light-danger mr-5">*/}
            {/*                                <span className="symbol-label">*/}
            {/*                                    <span className="svg-icon svg-icon-lg svg-icon-danger">*/}
            {/*                                    <SVG*/}
            {/*                                        className="h-75 align-self-end"*/}
            {/*                                        src={toAbsoluteUrl(*/}
            {/*                                        "/media/svg/icons/General/Attachment2.svg"*/}
            {/*                                        )}*/}
            {/*                                    ></SVG>*/}
            {/*                                    </span>*/}
            {/*                                </span>*/}
            {/*                                </div>*/}

            {/*                                <div className="d-flex flex-column font-weight-bold">*/}
            {/*                                <a*/}
            {/*                                    href="#"*/}
            {/*                                    className="text-dark text-hover-primary mb-1 font-size-lg"*/}
            {/*                                >*/}
            {/*                                    Submitted Purchase Orders*/}
            {/*                                </a>*/}
            {/*                                /!* <span className="text-muted">DevOps</span> *!/*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </td>*/}
            {/*                        <td>*/}
            {/*                            <span className="text-muted font-weight-light text-hover-primary mb-1 font-size-lg">*/}
            {/*                                3,290+*/}
            {/*                           </span>*/}
            {/*                        </td>*/}
            {/*                    </tr>*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*        </div>*/}
            {/*            */}
            {/*            */}

            {/*            */}
            {/*            */}

            {/*            */}

            {/*            */}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="col-md-6">*/}
            {/*        <div className={`card card-custom card-stretch gutter-b`}>*/}
            {/*            /!* Header *!/*/}
            {/*            <div className="card-header align-items-center border-0 mt-4">*/}
            {/*                <h3 className="card-title align-items-start flex-column">*/}
            {/*                    <span className="font-weight-bolder text-dark">Market Presence</span>*/}
            {/*                    <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                    5,684 Clients*/}
            {/*                    </span>*/}
            {/*                </h3>*/}
            {/*                <div className="card-toolbar">*/}
            {/*                    <h3 className="card-title align-items-start flex-column">*/}
            {/*                        <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                            From:*/}
            {/*                        </span>*/}
            {/*                        <span className="label label-lg label-light-primary label-inline">*/}
            {/*                            07 May, 2020*/}
            {/*                        </span>*/}
            {/*                    </h3>*/}
            {/*                    <h3 className="card-title align-items-start flex-column">*/}
            {/*                        <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                            To*/}
            {/*                        </span>*/}
            {/*                        <span className="label label-lg label-light-danger label-inline">*/}
            {/*                            10 August, 2020*/}
            {/*                        </span>*/}
            {/*                    </h3>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            /!* Body *!/*/}
            {/*            <div className="card-body d-flex flex-column">*/}
            {/*                <div className="flex-grow-1">*/}
            {/*                    <div id="kt_mixed_widget_14_chart" style={{height: "200px"}}></div>*/}
            {/*                </div>*/}
            {/*                <div className="d-flex align-items-center mb-3">*/}
            {/*                    <div className="symbol symbol-40 symbol-light-primary mr-5">*/}
            {/*                        <span className="symbol-label">*/}
            {/*                            <span className="svg-icon svg-icon-lg svg-icon-primary">*/}
            {/*                            <SVG*/}
            {/*                                className="h-75 align-self-end"*/}
            {/*                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}*/}
            {/*                            ></SVG>*/}
            {/*                            </span>*/}
            {/*                        </span>*/}
            {/*                    </div>*/}
            {/*                    <div className="d-flex flex-column font-weight-bold">*/}
            {/*                        <a*/}
            {/*                            href="#"*/}
            {/*                            className="text-dark text-hover-primary mb-1 font-size-lg"*/}
            {/*                        >*/}
            {/*                            Market Precense Breakdown*/}
            {/*                        </a>*/}
            {/*                        <span className="text-muted">List of clients</span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="d-flex align-items-center mb-3">*/}
            {/*                    <div className="symbol symbol-40 symbol-light-primary mr-5">*/}
            {/*                        <span className="symbol-label">*/}
            {/*                            <span className="svg-icon svg-icon-lg svg-icon-primary">*/}
            {/*                            <SVG*/}
            {/*                                className="h-75 align-self-end"*/}
            {/*                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}*/}
            {/*                            ></SVG>*/}
            {/*                            </span>*/}
            {/*                        </span>*/}
            {/*                    </div>*/}
            {/*                    <div className="d-flex flex-column font-weight-bold">*/}
            {/*                        <a*/}
            {/*                            href="#"*/}
            {/*                            className="text-dark text-hover-primary mb-1 font-size-lg"*/}
            {/*                        >*/}
            {/*                            Total Sales Engagement*/}
            {/*                        </a>*/}
            {/*                        <span className="text-muted">Percentage of client outreach</span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="d-flex align-items-center mb-3">*/}
            {/*                    <div className="symbol symbol-40 symbol-light-primary mr-5">*/}
            {/*                        <span className="symbol-label">*/}
            {/*                            <span className="svg-icon svg-icon-lg svg-icon-primary">*/}
            {/*                            <SVG*/}
            {/*                                className="h-75 align-self-end"*/}
            {/*                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}*/}
            {/*                            ></SVG>*/}
            {/*                            </span>*/}
            {/*                        </span>*/}
            {/*                    </div>*/}
            {/*                    <div className="d-flex flex-column font-weight-bold">*/}
            {/*                        <a*/}
            {/*                            href="#"*/}
            {/*                            className="text-dark text-hover-primary mb-1 font-size-lg"*/}
            {/*                        >*/}
            {/*                            Bestselling Products*/}
            {/*                        </a>*/}
            {/*                        <span className="text-muted">Product Breakdown</span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                /!* <div className="pt-5">*/}
            {/*                    <p className="text-center font-weight-normal font-size-lg pb-7">*/}
            {/*                        Notes: Current sprint requires stakeholders<br/>*/}
            {/*                        to approve newly amended policies*/}
            {/*                    </p>*/}
            {/*                    <a href="#" className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3">Generate Report</a>*/}
            {/*                </div> *!/*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="row">*/}
            {/*    <div className="col-md-6">*/}
            {/*        <div className={`card card-custom card-stretch gutter-b`}>*/}
            {/*            /!* Header *!/*/}
            {/*            <div className="card-header align-items-center border-0 mt-4">*/}
            {/*                <h3 className="card-title align-items-start flex-column">*/}
            {/*                    <span className="font-weight-bolder text-dark">Sales Report</span>*/}
            {/*                    <span className="text-muted mt-3 font-weight-bold font-size-sm">*/}
            {/*                    Monthly Report - August 2020*/}
            {/*                    </span>*/}
            {/*                </h3>*/}
            {/*                <div className="card-toolbar">*/}
            {/*                    <h3 className="card-title align-items-start flex-column">*/}
            {/*                        <span className="label label-lg label-light-primary label-inline">*/}
            {/*                            $2,450.00*/}
            {/*                        </span>*/}
            {/*                    </h3>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="card-body pt-2">*/}
            {/*                <div className="d-flex align-items-center mb-2 mt-5">*/}
            {/*                    */}
            {/*                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">*/}
            {/*                    */}
            {/*                        <span className="text-muted">Sales Progress</span>*/}
            {/*                    </div>*/}
            {/*                        <span className="label label-lg label-light-primary label-inline">*/}
            {/*                            60%*/}
            {/*                        </span>*/}
            {/*                </div>*/}
            {/*                <ProgressBar now={60} />*/}

            {/*                 <div className="pt-5">*/}
            {/*                    <p className="text-center font-weight-normal font-size-lg pb-2 pt-5">*/}
            {/*                        Create KPI Report<br/>*/}
            {/*                    Generate the latest KPI Report*/}
            {/*                    </p>*/}
            {/*                    <div className="d-flex flex-wrap flex-center">*/}
            {/*                        <a href="#" className="btn btn-success btn-shadow-hover font-weight-bolder w-50 py-3 d-flex flex-wrap flex-center">Start Now</a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="card-body d-flex flex-column p-0">*/}
            {/*                <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">*/}
            {/*                    <div className="d-flex flex-column text-right">*/}
            {/*                        <span className="text-dark-75 font-weight-bolder font-size-h3">*/}
            {/*                        $19,723*/}
            {/*                        </span>*/}
            {/*                        <span className="text-muted font-weight-bold mt-2">Total Sales</span>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div*/}
            {/*                id="kt_stats_widget_12_chart"*/}
            {/*                className="card-rounded-bottom"*/}
            {/*                style={{ height: "150px" }}*/}
            {/*                ></div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="col-md-6">*/}
            {/*        <div className={`card card-custom bg-gray-100 card-stretch gutter-b`}>*/}
            {/*            /!* Header *!/*/}
            {/*            <div className="card-header border-0 bg-primary py-5">*/}
            {/*                <h3 className="card-title font-weight-bolder text-white">Unreached Clients</h3>*/}
            {/*                /!* <div className="card-toolbar">*/}
            {/*                <Dropdown className="dropdown-inline" drop="down" alignRight>*/}
            {/*                    <Dropdown.Toggle*/}
            {/*                    className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"*/}
            {/*                    variant="transparent"*/}
            {/*                    id="dropdown-toggle-top">*/}
            {/*                    Export*/}
            {/*                    </Dropdown.Toggle>*/}
            {/*                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">*/}
            {/*                    <DropdownMenu2 />*/}
            {/*                    </Dropdown.Menu>*/}
            {/*                </Dropdown>*/}
            {/*                </div> *!/*/}
            {/*            </div>*/}
            {/*            /!* Body *!/*/}
            {/*            <div className="card-body p-0 position-relative overflow-hidden">*/}
            {/*                /!* Chart *!/*/}
            {/*                <div*/}
            {/*                id="kt_mixed_widget_1_chart"*/}
            {/*                className="card-rounded-bottom bg-primary"*/}
            {/*                style={{ height: "200px" }}*/}
            {/*                ></div>*/}

            {/*                /!* Stat *!/*/}
            {/*                <div className="card-spacer mt-n25">*/}
            {/*                <div className="row m-0">*/}
            {/*                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">*/}
            {/*                    <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">*/}
            {/*                        <SVG*/}
            {/*                        src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}*/}
            {/*                        ></SVG>*/}
            {/*                    </span>*/}
            {/*                    <a*/}
            {/*                        href="#"*/}
            {/*                        className="text-warning font-weight-bold font-size-h6"*/}
            {/*                    >*/}
            {/*                        Client Orders*/}
            {/*                    </a>*/}
            {/*                    </div>*/}
            {/*                    <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">*/}
            {/*                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">*/}
            {/*                        <SVG*/}
            {/*                        src={toAbsoluteUrl(*/}
            {/*                            "/media/svg/icons/Communication/Add-user.svg"*/}
            {/*                        )}*/}
            {/*                        ></SVG>*/}
            {/*                    </span>*/}
            {/*                    <a*/}
            {/*                        href="#"*/}
            {/*                        className="text-primary font-weight-bold font-size-h6 mt-2"*/}
            {/*                    >*/}
            {/*                        New Users*/}
            {/*                    </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="row m-0">*/}
            {/*                    <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">*/}
            {/*                    <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">*/}
            {/*                        <SVG*/}
            {/*                        src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}*/}
            {/*                        ></SVG>*/}
            {/*                    </span>*/}
            {/*                    <a*/}
            {/*                        href="#"*/}
            {/*                        className="text-danger font-weight-bold font-size-h6 mt-2"*/}
            {/*                    >*/}
            {/*                        Companies*/}
            {/*                    </a>*/}
            {/*                    </div>*/}
            {/*                    <div className="col bg-light-success px-6 py-8 rounded-xl">*/}
            {/*                    <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">*/}
            {/*                        <SVG*/}
            {/*                        src={toAbsoluteUrl(*/}
            {/*                            "/media/svg/icons/Communication/Urgent-mail.svg"*/}
            {/*                        )}*/}
            {/*                        ></SVG>*/}
            {/*                    </span>*/}
            {/*                    <a*/}
            {/*                        href="#"*/}
            {/*                        className="text-success font-weight-bold font-size-h6 mt-2"*/}
            {/*                    >*/}
            {/*                        Type of Businesses*/}
            {/*                    </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                </div>*/}

            {/*                /!* Resize *!/*/}
            {/*                <div className="resize-triggers">*/}
            {/*                <div className="expand-trigger">*/}
            {/*                    <div style={{ width: "411px", height: "461px" }} />*/}
            {/*                </div>*/}
            {/*                <div className="contract-trigger" />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            
        </div>
    );
}
    
export default injectIntl(AnalyticsPage);