import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { fetchRoute } from "../AdminPagesCrud";
import {useParams} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import CutOffTimeConfig from "./CutOffTimeConfig";
import DriverConfig from "./DriverConfig"
import TruckConfig from "./TruckConfig"

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

export function ConfigTabs({ supplierId, currCutOffTime, currIsCutOffSameDay }) {

    const {id} = useParams();

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    // useEffect(() => {

    // },[])


    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                >
                    <Tab label="Cut-Off Time" wrapped />
                    <Tab label="Driver Config" wrapped />
                    <Tab label="Truck Config" wrapped />
                </Tabs>
                {value === 0 && <TabContainer> <CutOffTimeConfig supplierId={supplierId} currCutOffTime={currCutOffTime} currIsCutOffSameDay={currIsCutOffSameDay}/> </TabContainer>}
                {value === 1 && <TabContainer> <DriverConfig supplierId={supplierId} /> </TabContainer>}
                {value === 2 && <TabContainer> <TruckConfig supplierId={supplierId} /> </TabContainer>}
            </Paper>
        </>
    )
}