import React, { useState } from "react";
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { supplierRegister } from "../_redux/authCrud";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {RegionDropdown} from "react-country-region-selector";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140
    // width: 300,
  },
  control: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  }
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholderChar={'\u2000'}
          showMask
      />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const initialValues = {
  entityName: "",
  pointOfContact: "",
  email: "",
  phoneNumber: "",
  physicalAddress: "",
  physicalCity: "",
  physicalZipcode: "",
  postalAddress: "",
  postalCity: "",
  postalZipcode: "",
  // distributionCategories: "",
  password: "",
  changePassword: ""
};

function Registration(props) {
  //console.log(props)
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  let history = useHistory();
  const [country] = useState('Puerto Rico');
  const [physicalCity, setPhysicalCity] = useState();
  const [postalCity, setpostalCity] = useState();

  // React.useEffect(() => {
  //   setLabelWidth(inputLabel.current.offsetWidth);
  // }, []);

  const RegistrationSchema = Yup.object().shape({
    entityName: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    pointOfContact: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    phoneNumber: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    physicalAddress: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    postalAddress: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    physicalCity: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    postalCity: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    physicalZipcode: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    postalZipcode: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
            intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD",
            })
        ),
    // distributionCategories: Yup.string()
    //     .min(3, "Minimum 3 symbols")
    //     .max(50, "Maximum 50 symbols")
    //     .required(
    //         intl.formatMessage({
    //           id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //         })
    //     ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const supplier = {
        entityName: values.entityName,
        pointOfContact: values.pointOfContact,
        email: values.email,
        phoneNumber: values.phoneNumber,
        physicalAddress: values.physicalAddress,
        physicalCity: values.physicalCity,
        physicalZipcode: values.physicalZipcode,
        postalAddress: values.postalAddress,
        postalCity: values.postalCity,
        postalZipcode: values.postalZipcode,
        // distributionCategories: values.distributionCategories,
        password: values.password,
      }
      supplierRegister(supplier)
        .then((response) => {
          // props.register(accessToken);
          disableLoading();
          history.push('/auth/supplierOnBoarding')
        })
        .catch((error) => {
          console.log(error)
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
      <div className="" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            Supplier Registration
            {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
          </h3>
          <p className="text-muted font-weight-bold">
            Enter your details to create your account
          </p>
        </div>

        <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
        >
          {/* begin: Alert */}
          {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
          )}
          {/* end: Alert */}

          <div className="mb-4 mb-lg-6">
            <h6>
              Account Credentials
              {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
            </h6>
          </div>

          <Grid container spacing={1}>
            {/* begin: Email */}
            <Grid item xs={12}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="email"
                    {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Email */}

            {/* begin: Password */}
            <Grid item xs={6}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <Grid item xs={6}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Confirm Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "changePassword"
                    )}`}
                    name="changePassword"
                    {...formik.getFieldProps("changePassword")}
                />
                {formik.touched.changePassword && formik.errors.changePassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.changePassword}
                      </div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Confirm Password */}
          </Grid>


          <div className="mb-4 mb-lg-6">
            <h6>
              Personal Information
              {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
            </h6>
          </div>

          <Grid container spacing={1}>

            {/* begin: Entity Name */}
            <Grid item xs={12}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Entity Name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="entityName"
                    {...formik.getFieldProps("entityName")}
                />
                {formik.touched.entityName && formik.errors.entityName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.entityName}</div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Entity Name */}

            {/* begin: Point of Contact */}
            <Grid item xs={12}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Point of Contact"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="pointOfContact"
                    {...formik.getFieldProps("pointOfContact")}
                />
                {formik.touched.pointOfContact && formik.errors.pointOfContact ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.pointOfContact}</div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Point of Contact */}

            {/* begin: Email */}
            <Grid item xs={9}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="email"
                    {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Email */}

            {/* begin: Phone Number */}
            <Grid item xs={3}>
              <div className="form-group fv-plugins-icon-container">
                <FormControl>
                  <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
                  <Input
                      name="phoneNumber"
                      id="phoneNumber"
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      inputComponent={TextMaskCustom}
                      {...formik.getFieldProps("phoneNumber")}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.phoneNumber}
                        </div>
                      </div>
                  ) : null}
                </FormControl>
              </div>
            </Grid>
            {/* end: Phone Number */}
          </Grid>

          <div className="mb-4 mb-lg-6">
            <h7>
              Physical Address
              {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
            </h7>
          </div>

          <Grid container spacing={1}>
            {/* begin: Physical Address */}
            <Grid item xs={12}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Physical Address"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="physicalAddress"
                    {...formik.getFieldProps("physicalAddress")}
                />
                {formik.touched.physicalAddress && formik.errors.physicalAddress ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.physicalAddress}
                      </div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Physical Address */}

            {/* begin: Physical City */}
            <Grid item xs={6}>
              <div className="form-group fv-plugins-icon-container">
                <FormControl variant="filled" className={classes.formControl}>
                  {/*<InputLabel >City</InputLabel>*/}
                  {/*<Select*/}
                  {/*    input={<FilledInput name="physicalCity" id="physicalCity"*/}
                  {/*                        type="text"*/}
                  {/*                        className={`form-control form-control-solid h-auto py-5 px-6`}*/}
                  {/*                        {...formik.getFieldProps("physicalCity")}*/}
                  {/*    />}*/}
                  {/*>*/}
                  {/*  <MenuItem value="">*/}
                  {/*    <em>None</em>*/}
                  {/*  </MenuItem>*/}
                  {/*  <MenuItem value={"Dorado"}>Dorado</MenuItem>*/}
                  {/*  <MenuItem value={"San Juan"}>San Juan</MenuItem>*/}
                  {/*</Select>*/}
                  <RegionDropdown
                      {...formik.getFieldProps("physicalCity")}
                      className={'form-control form-control-solid h-auto py-5 px-6'}
                      // className={`form-control form-control-solid h-auto`}
                      country={country}
                      value={physicalCity}
                      defaultOptionLabel = 'City'
                      onChange={(val) => setPhysicalCity(val)} />

                  {formik.touched.physicalCity && formik.errors.physicalCity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.physicalCity}</div>
                      </div>
                  ) : null}
                </FormControl>
              </div>
            </Grid>
            {/* end: Physical City */}

            {/* begin: Physical Zipcode */}
            <Grid item xs={6}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Zipcode"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="physicalZipcode"
                    {...formik.getFieldProps("physicalZipcode")}
                />
                {formik.touched.physicalZipcode && formik.errors.physicalZipcode ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.physicalZipcode}
                      </div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Physical Zipcode */}
          </Grid>
          <div className="mb-4 mb-lg-6">
            <h7>
              Postal Address
              {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
            </h7>
          </div>

          <Grid container spacing={1}>
            {/* begin: Postal Address */}
            <Grid item xs={12}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Postal Address"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="postalAddress"
                    {...formik.getFieldProps("postalAddress")}
                />
                {formik.touched.postalAddress && formik.errors.postalAddress ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.postalAddress}
                      </div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Postal Address */}

            {/* begin: Postal City */}
            <Grid item xs={6}>
              <div className="form-group fv-plugins-icon-container">
                <FormControl variant="filled" className={classes.formControl}>
                  {/*<InputLabel >City</InputLabel>*/}
                  {/*<Select*/}
                  {/*    input={<FilledInput name="postalCity" id="postalCity"*/}
                  {/*                        type="text"*/}
                  {/*                        className={`form-control form-control-solid h-auto`}*/}
                  {/*                        {...formik.getFieldProps("postalCity")}*/}
                  {/*    />}*/}
                  {/*>*/}
                  {/*  <MenuItem value="">*/}
                  {/*    <em>None</em>*/}
                  {/*  </MenuItem>*/}
                  {/*  <MenuItem value={"Dorado"}>Dorado</MenuItem>*/}
                  {/*  <MenuItem value={"San Juan"}>San Juan</MenuItem>*/}
                  {/*</Select>*/}
                  <RegionDropdown
                      {...formik.getFieldProps("physicalCity")}
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      // className={`form-control form-control-solid h-auto`}
                      country={country}
                      value={postalCity}
                      defaultOptionLabel = 'City'
                      onChange={(val) => setpostalCity(val)} />

                  {formik.touched.postalCity && formik.errors.postalCity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.postalCity}</div>
                      </div>
                  ) : null}
                </FormControl>
              </div>
            </Grid>
            {/* end: Postal City */}

            {/* begin: Postal Zipcode */}
            <Grid item xs={6}>
              <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Zipcode"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="postalZipcode"
                    {...formik.getFieldProps("postalZipcode")}
                />
                {formik.touched.postalZipcode && formik.errors.postalZipcode ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.postalZipcode}
                      </div>
                    </div>
                ) : null}
              </div>
            </Grid>
            {/* end: Postal Zipcode */}
          </Grid>
          {/* begin: Distribution Categories */}
            {/* <div className="form-group fv-plugins-icon-container">
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel >Distribution Categories</InputLabel>
                <Select
                    input={<FilledInput name="distributionCategories" id="distributionCategories"
                                        type="text"
                                        className={`form-control form-control-solid h-auto`}
                                        {...formik.getFieldProps("distributionCategories")}
                    />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Non-Food Products"}>Non-Food Products</MenuItem>
                  <MenuItem value={"Food Products"}>Food Products</MenuItem>
                </Select>
                {formik.touched.distributionCategories && formik.errors.distributionCategories ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.distributionCategories}</div>
                    </div>
                ) : null}
              </FormControl>
            </div> */}
            {/* end: Distribution Categories */}

            {/* begin: Terms and Conditions */}
            {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link to="/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
            I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div> */}
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Submit</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>

              <Link to="/auth/login">
                <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
        </form>
      </div>
);
}

export default injectIntl(connect(null, auth.actions)(Registration));
