
import React, {useState} from "react";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {Card,CardBody,CardHeader,CardHeaderToolbar} from "../../../../_metronic/_partials/controls"
import {useSelector} from "react-redux";
import axios from "axios";
import * as columnFormatters from "./column-formatters";
import ClientSummary from "./ClientSummary"


export function ClientsTable({ isClientSummary, setIsClientSummary }) {

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize:5
    }

    const [data,setData] = useState(initialData);
    const [selectedApplication, setSelctedApplication] = useState({})

    const fetchClients = async (queryParams) => {
        // Authorization head should be fulfilled in interceptor.
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }

        console.log("Fetching Clients")
        await axios.get(process.env.REACT_APP_API_URL + '/suppliers/clients',options).then(response =>{
            console.log("~~~~ Clients: ", response.data)
            let dataList = []
            response.data.data.map(element => {
                dataList.push({
                    ...element,
                    compliance : "In Compliance",
                    transactionsOverTenThousandInCash : "3 Transactions"
                })
            })
            setData({ data : dataList, totalCount: response.data.totalCount, pageSize : response.data.pageSize })
        }).catch((error) => {
            console.log(error)
          });
    }

    const handleClientClick = (clientApplication) => {
        setSelctedApplication(clientApplication)
        setIsClientSummary(true)
    }

    const columns = [
        {
            dataField: "companyName",
            text: "Client",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CompanyColumnFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                  handleClientClick(row)
                }
            },
        },
        {
            dataField: "locations",
            text: "Locations",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.LocationsColumnFormatter,
        },
        {
            dataField: "deliveryAddress",
            text: "Shipping Address",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CompanyAddressColumnFormatter,
        },
        {
            dataField: "creditInformation.creditRange",
            text: "Credit Due Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CreditDueDateColumnFormatter
        },
        {
            dataField: "creditInformation.authorizedCredit",
            text: "Total Credit",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalCreditColumnFormatter
        },
        {
            dataField: "balance",
            text: "Balance",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.BalanceColumnFormatter,
        },
        {
            dataField: "compliance",
            text: "Compliance",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ComplianceColumnFormatter,
        },
        // {
        //     dataField: "action",
        //     text:"Actions",
        //     formatter: columnFormatters.ActionsColumnFormatter,
        //     formatExtraData: {
        //         openEditCustomerDialog: fetchClients,
        //         openDeleteCustomerDialog: fetchClients,
        //     },
        //     classes: "text-right pr-0",
        //     headerClasses: "text-right pr-3",
        //     style: {
        //         minWidth: "100px",
        //     },
        // },
    ];

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    const initialFilter = {
        filter: {
            companyName:"",
            'deliveryAddress': "",
            'creditRange':"",
            'authorizedCredit':"",
        },
        sortOrder: "asc",
        sortField: "companyName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "companyName",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-vertical-center overflow-hidden table-padding no-header"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.companyName = searchText ?? "";
        filter.deliveryAddress = searchText ?? "";
        filter.creditRange = searchText ?? "";
        filter.authorizedCredit = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {isClientSummary ? 
                    <ClientSummary clientApplication={selectedApplication} accessToken={accessToken} />
                :
                <Card>
                <CardHeader title="My Clients">
                    <CardHeaderToolbar>
                        {/* <button type="button" className="btn btn-primary" onClick={console.log("filtrando")}>
                            Filter
                        </button> */}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        tableClasses={tableClasses}
                        fetcher={fetchClients}
                        filterFunction={prepareFilter}
                        initialFilter={initialFilter}/>
                </CardBody>
            </Card>
            }
            
        </>
    );
}