import React, {useState, useEffect} from 'react';
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import { fetchRouteClients, getSupplierDriversPagination } from "../AdminPagesCrud";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";
import DriverAddEdit from './DriverAddEdit'

function DriversConfig({ supplierId }) {
    const [edit, setEdit] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [driver, setDriver] = useState({ name: "" })

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3

    }

    const [data, setData] = useState(initialData)
    const {accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    const fetchSupplierDriversPagination = async (queryParams) => {
        await getSupplierDriversPagination(supplierId, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }


    // useEffect(() => {
        

    //     // getRouteClients()

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [edit])

    // const getRouteClients = async () => {
    //     await fetchRouteClients(route._id, accessToken).then(response => {
            
    //         setRouteClients(response.data)
    //     }).catch(error => console.log(error))
    // }

    const columns = [
        {
            dataField: "name",
            text: "Driver",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ActionColumnFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setDriver(row)
                    setEdit(true)
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            name: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {edit || isNew ? <DriverAddEdit driver={driver} setDriver={setDriver} supplierId={supplierId} 
                                     isNew={isNew} setIsNew={setIsNew} edit={edit} setEdit={setEdit}/>
             :
                <Card>
                    <CardHeader title="Supplier Drivers">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setIsNew(true)}>
                                Add New Driver
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            initialFilter={initialFilter}
                            tableClasses={tableClasses}
                            fetcher={fetchSupplierDriversPagination}
                            filterFunction={prepareFilter}
                            searchField={true}/>
                    </CardBody>
                </Card>
            }
        </>
    );
}

export default injectIntl(DriversConfig);