import React, { useState } from "react";
import {injectIntl} from "react-intl";
import {Card} from "react-bootstrap";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { patchRouteRecordOrderAmounts } from "./routesCrud";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}));

function RouteCasesDetail({ routeCasesPerCategoryWithProducts, setRouteCasesPerCategoryWithProducts, routeCasesFavoriteProducts, 
    setRouteCasesFavoriteProducts, setIsRouteCasesDetail, 
    isRouteCasesDetail, setSelectedRouteRecords, selectedCasesRoute, accessToken,
    setSelectedReviewRouteIndex, setSelectedCasesRoute, setIsCasesReviewUpdated, isCasesReviewUpdated }){

    console.log("This is the way! ", routeCasesFavoriteProducts)
    const classes = useStyles();

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [expanded, setExpanded] = useState("panel0");

    const [infoAlertTitle, setInfoAlertTitle] = useState("");
    const [infoAlertOpen, setInfoAlertOpen] = useState(false);
    const [infoAlertMessage, setInfoAlertMessage] = useState("");

    const handleDialogClose = () => {
        setAlertOpen(false);
        setSelectedProduct(null)
    }

    const handleInfoAlertClose = () => {
        setInfoAlertOpen(false);
        setInfoAlertTitle("")
        setInfoAlertMessage("")
    }

    const handleInfoAlertOpen = (title, message) => {
        setInfoAlertOpen(true);
        setInfoAlertTitle(title)
        setInfoAlertMessage(message)
    }

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : "")
    };

    const handleBack = () => {
        setExpanded("")
        setSelectedRouteRecords([])
        setSelectedReviewRouteIndex("")
        setSelectedCasesRoute("")
        setRouteCasesPerCategoryWithProducts([])
        setIsRouteCasesDetail(!isRouteCasesDetail)
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product)
        setAlertOpen(true)
    }

    const handleCustomerProductAmountChange = (orderId, product, newAmount) => {

        let newAmountParsed = parseFloat(newAmount)
        if(isNaN(newAmountParsed)){
            newAmountParsed = ''
        }

        let newRouteCasesPerCategoryWithProducts = [...routeCasesPerCategoryWithProducts]
        
        const categoryItemFoundIndex = routeCasesPerCategoryWithProducts.findIndex(curr => curr.categoryId == product.categoryId)
        let categoryItemFound = {...routeCasesPerCategoryWithProducts[categoryItemFoundIndex]}
        const categoryItemFoundProductIndex = categoryItemFound.products.findIndex(curr => curr._id == product._id)
        const customerTotalsListItemIndex = categoryItemFound.products[categoryItemFoundProductIndex].customerTotalsList.findIndex(curr => curr.orderId == orderId)

        categoryItemFound.products[categoryItemFoundProductIndex].customerTotalsList[customerTotalsListItemIndex].amount = newAmountParsed

        if(product.isFavorite){
            let newRouteCasesFavoriteProducts = [...routeCasesFavoriteProducts]
            const itemFoundIndex = newRouteCasesFavoriteProducts.findIndex(curr => curr._id == product._id)
            const customerTotalsListItemIndex = newRouteCasesFavoriteProducts[itemFoundIndex].customerTotalsList.findIndex(curr => curr.orderId == orderId)
            newRouteCasesFavoriteProducts[itemFoundIndex].customerTotalsList[customerTotalsListItemIndex].amount = newAmountParsed

            setRouteCasesFavoriteProducts(newRouteCasesFavoriteProducts)
        }

        newRouteCasesPerCategoryWithProducts[categoryItemFoundIndex] = categoryItemFound
        setRouteCasesPerCategoryWithProducts(newRouteCasesPerCategoryWithProducts)
    }

    const handleDialogSubmit = async (product) => {
        
        const categoryItemFoundIndex = routeCasesPerCategoryWithProducts.findIndex(curr => curr.categoryId == product.categoryId)
        const categoryItemFoundProductIndex = routeCasesPerCategoryWithProducts[categoryItemFoundIndex].products.findIndex(curr => curr._id == product._id)
        const customerTotalsListItems = [...routeCasesPerCategoryWithProducts[categoryItemFoundIndex].products[categoryItemFoundProductIndex].customerTotalsList]

        const finalSubmit = customerTotalsListItems.map(item => {
            if(item.amount == ''){
                return {
                    ...item,
                    productId: product._id,
                    amount: 0
                }
            } else {
                return {
                    ...item,
                    productId: product._id,
                }
            }
        })

        await patchRouteRecordOrderAmounts(finalSubmit, accessToken).then(res => {
            setAlertOpen(false);
            setSelectedProduct(null)
            setIsCasesReviewUpdated(!isCasesReviewUpdated)
            handleInfoAlertOpen("Success", "Changes submitted successfully.")
            // alert("Changes submitted successfully.")
        }).catch(err => {
            console.log("Error: ", err)
            setAlertOpen(false);
            setSelectedProduct(null)
            handleInfoAlertOpen("Error", "An error occured submitting changes! Refresh and try again or contact our team for more details.")
            // alert("An error occured submitting changes! Refresh and try again or contact our team for more details.")
        })
    }

    return (
        <>
            <div>
                <div className="row justify-content-center">
                    <div className="col-12 mb-5">
                        <div className="row justify-content-between">
                            <h1 className={'title'}>{"Route Categories (" + selectedCasesRoute + ")"} </h1>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 mb-5">
                        <div className="row justify-content-between">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={() => handleBack()}>
                                back
                            </button>
                        </div>
                    </div>
                </div>
                {
                    <div className="col-12 my-5">
                        {routeCasesFavoriteProducts != null ?
                            <ExpansionPanel square expanded={expanded === 'panel' + 0} onChange={handleAccordionChange('panel' + 0)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={'panel-content-' + 0}
                                    id={'panel-header-' + 0}
                                    >
                                    <Typography className={classes.heading}>
                                        Favorite Items Review
                                    </Typography>
                                    {/* <Typography className={classes.secondaryHeading}>{categoryObject.amount}</Typography> */}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="col-12 my-5">
                                        <Card>
                                            <Card.Body>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                    Name
                                                                </span>
                                                            </TableCell>
                                                            {/* <TableCell>
                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                    Avg Weight
                                                                </span>
                                                            </TableCell> */}
                                                            <TableCell>
                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                    Unit Price
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                    Quantity
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                    Item Price
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                    Total Amount
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            (routeCasesFavoriteProducts.map((product, productIndex) => (
                                                                <>
                                                                    <TableRow className="btn btn-hover-light">
                                                                        <TableCell onClick={() => handleProductClick(product)} >
                                                                            <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                                {product.name}
                                                                            </span>
                                                                            <span className={'text-muted font-weight-bold'}>
                                                                                {product.sku}
                                                                            </span>
                                                                        </TableCell>
                                                                        {/* <TableCell onClick={() => handleProductClick(product)} >
                                                                            {
                                                                                product.isCatchWeight ? 
                                                                                    product.avgWeight
                                                                                :
                                                                                    "N/A"
                                                                            }
                                                                        </TableCell> */}
                                                                        <TableCell onClick={() => handleProductClick(product)} >
                                                                            {product.listPrice ?
                                                                                <span className="label label-lg label-light-primary label-inline">${product.listPrice}</span>
                                                                                :
                                                                                <span>${product.price}</span>
                                                                            }
                                                                        </TableCell>
                                                                        {/* <TableCell>{product.qtyOnHand}</TableCell> */}
                                                                        <TableCell onClick={() => handleProductClick(product)} >
                                                                            {product.totalAmount}
                                                                        </TableCell>
                                                                        <TableCell onClick={() => handleProductClick(product)} >
                                                                            {
                                                                                product.isCatchWeight ? 
                                                                                    product.estimatedPrice  
                                                                                : 
                                                                                    product.listPrice && product.listPrice !== '' ?
                                                                                        product.listPrice
                                                                                    :
                                                                                        product.price
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell onClick={() => handleProductClick(product)} >
                                                                            {
                                                                                product.isCatchWeight ? 
                                                                                    product.estimatedPrice * product.totalAmount
                                                                                : 
                                                                                    product.listPrice && product.listPrice !== '' ?
                                                                                        product.listPrice * product.totalAmount
                                                                                    :
                                                                                        product.price * product.totalAmount
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        :
                            null
                            }
                        {routeCasesPerCategoryWithProducts.map((categoryObject, categoryIndex) => (
                            <>
                                <ExpansionPanel square expanded={expanded === 'panel' + (categoryIndex + 1)} onChange={handleAccordionChange('panel' + (categoryIndex + 1))}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls={'panel-content-' + (categoryIndex + 1)}
                                        id={'panel-header-' + (categoryIndex + 1)}
                                        >
                                        <Typography className={classes.heading}>
                                            {categoryObject.name}
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>{categoryObject.amount}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div className="col-12 my-5">
                                            <Card>
                                                <Card.Body>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        Name
                                                                    </span>
                                                                </TableCell>
                                                                {/* <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        Avg Weight
                                                                    </span>
                                                                </TableCell> */}
                                                                <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        Unit Price
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        Quantity
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        Item Price
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        Total Amount
                                                                    </span>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {categoryObject.products != null ?
                                                                (categoryObject.products.map((product, productIndex) => (
                                                                    <>
                                                                        <TableRow className="btn btn-hover-light">
                                                                            <TableCell onClick={() => handleProductClick(product)} >
                                                                                <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                                    {product.name}
                                                                                </span>
                                                                                <span className={'text-muted font-weight-bold'}>
                                                                                    {product.sku}
                                                                                </span>
                                                                            </TableCell>
                                                                            {/* <TableCell onClick={() => handleProductClick(product)} >
                                                                                {
                                                                                    product.isCatchWeight ? 
                                                                                        product.avgWeight
                                                                                    :
                                                                                        "N/A"
                                                                                }
                                                                            </TableCell> */}
                                                                            <TableCell onClick={() => handleProductClick(product)} >
                                                                            {product.listPrice ?
                                                                                <span className="label label-lg label-light-primary label-inline">${product.listPrice}</span>
                                                                                :
                                                                                <span>${product.price}</span>
                                                                            }
                                                                            </TableCell>
                                                                            {/* <TableCell>{product.qtyOnHand}</TableCell> */}
                                                                            <TableCell onClick={() => handleProductClick(product)} >
                                                                                {product.totalAmount}
                                                                            </TableCell>
                                                                            <TableCell onClick={() => handleProductClick(product)} >
                                                                                {
                                                                                    product.isCatchWeight ? 
                                                                                        product.estimatedPrice  
                                                                                    : 
                                                                                        product.listPrice && product.listPrice !== '' ?
                                                                                            product.listPrice
                                                                                        :
                                                                                            product.price
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell onClick={() => handleProductClick(product)} >
                                                                                {
                                                                                    product.isCatchWeight ? 
                                                                                        product.estimatedPrice * product.totalAmount
                                                                                    : 
                                                                                        product.listPrice && product.listPrice !== '' ?
                                                                                            product.listPrice * product.totalAmount
                                                                                        :
                                                                                            product.price * product.totalAmount
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                )))
                                                                :
                                                                null}
                                                        </TableBody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </>
                        ))}
                    </div>

                }
                
            </div>
            <div>
                <Dialog
                    open={alertOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{selectedProduct ? selectedProduct.name : "No product selected"}</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                            Customer
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                            Amount
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                            QoH
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedProduct ? selectedProduct.customerTotalsList.map(currCustomer => (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        {currCustomer.companyName}
                                                    </span>
                                                    <span className={'text-muted font-weight-bold'}>
                                                        {currCustomer.locationName}
                                                    </span>
                                                </TableCell>
                                                <TableCell >
                                                    <input
                                                        value={currCustomer.amount}
                                                        onChange={(e) => handleCustomerProductAmountChange(currCustomer.orderId, selectedProduct, e.target.value)}
                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                        type="number"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                        {selectedProduct.qtyOnHand}
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))
                                :
                                    null
                            }
                                
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            Close
                        </Button>
                        <Button onClick={() => handleDialogSubmit(selectedProduct)} color="primary" autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={infoAlertOpen}
                    // onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{infoAlertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {infoAlertMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleInfoAlertClose()} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default injectIntl(RouteCasesDetail);