import React from "react";
import {Link} from "react-router-dom";


export function PriceListNameColumn(cellContent, row) {
    return (
        <>
            <Link to={'/priceLists/' + row._id}>
                <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">
                    {row.name}
                </span>
            </Link>
        </>
    );
}

export function SupplierPriceListColumn(cellContent, row) {
    return (
        <>
                <span className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg">
                    {row.name}
                </span>
        </>
    );
}

