import React, {useState} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {useSelector} from "react-redux";
import { injectIntl } from "react-intl";
import { fetchOrdersInvoiceByTenant } from "../Orders/ordersCrud"
import Invoice from "./Invoice"
import * as columnFormatters from "./column-formatters";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";



function InvoicesPage(props) {

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize:3
    }

    const [isInvoiceClicked, setIsInvoiceClicked] = useState(false)
    const [data,setData] = useState(initialData)
    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [staticTextFilter, setStaticTextFilter] = useState("")

    const fetchOrders = async (queryParams) => {
        await fetchOrdersInvoiceByTenant(queryParams, accessToken).then(response => {
            
            const data = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : 5
            }
            setData(data)
        }).catch((error) => {
            console.log(error)
        });
    }

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const columns = [
        {
            dataField: "orderNumber",
            text: "Order Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setInvoiceDetails(row)
                    setIsInvoiceClicked(true)
                }
            },
            formatter: columnFormatters.InvoiceNumberFormatter,
        },
        {
            dataField: "invoiceDate",
            text: "Invoice Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setInvoiceDetails(row)
                    setIsInvoiceClicked(true)
                }
            },
        },
        {
            dataField: "companyName",
            text: "Customer",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setInvoiceDetails(row)
                    setIsInvoiceClicked(true)
                }
            },
            formatter: columnFormatters.CompanyFormatter,
        },
        // {
        //     dataField: "supplierName",
        //     text: "Supplier",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        //     events: {
        //         onClick: (e, column, columnIndex, row, rowIndex) => {
        //             setInvoiceDetails(row)
        //             setIsInvoiceClicked(true)
        //         }
        //     },
        //     formatter: columnFormatters.SupplierFormatter,
        // },
        {
            dataField: "totalCost",
            text: "Total Cost",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setInvoiceDetails(row)
                    setIsInvoiceClicked(true)
                }
            },
            formatter: columnFormatters.TotalCostFormatter,
        },
        {
            dataField: "totalProductQty",
            text: "Products Quantity",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setInvoiceDetails(row)
                    setIsInvoiceClicked(true)
                }
            },
            formatter: columnFormatters.TotalProductFormatter,
        },
    ];

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    const initialFilter = {
        filter: {
            companyName: staticTextFilter ?? "",
            supplierName: staticTextFilter ?? "",
            orderNumber: staticTextFilter ?? "",
            totalCost:"",
            totalProductQty:"",
        },
        sortOrder: "desc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "orderNumber",order:"desc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};
        
        filter.companyName = searchText ?? "";
        filter.supplierName = searchText ?? "";
        filter.orderNumber = searchText ?? "";
        filter.totalCost = searchText ?? "";
        filter.totalProductQty = searchText ?? "";

        setStaticTextFilter(searchText ?? "")

        newQueryParams.filter = filter;
        return newQueryParams;
    }



    return (
        <>
            {!isInvoiceClicked ?
                <Card>
                    <CardHeader title="All Invoices">
                        {/* <CardHeaderToolbar>
                        </CardHeaderToolbar> */}
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchOrders}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}
                            initialSearchText={staticTextFilter}/>
                    </CardBody>
                </Card>
                : 
                <Invoice invoice={invoiceDetails} user={user} accessToken={accessToken} setIsInvoiceClicked={setIsInvoiceClicked} />
            }
        </>
    );
}
    
export default injectIntl(InvoicesPage);