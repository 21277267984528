import { GET_NOTIFICATIONS, GET_TOTAL_AUTHORIZED_CREDIT, MARK_NOTIFICATION_READ } from "../constants";

const initialState = {
    notifications: [],
    unreadNotifications: [],
    totalAuthorizedCredit: 0
};

export default function notificationReducer(state = initialState, action) {
    let notifications = [];
    let unreadNotifications = [];
    let notification = null;
    switch (action.type) {
        case GET_NOTIFICATIONS:
            notifications = action.payload ? action.payload.data : state.notifications
            unreadNotifications = notifications.filter(notification => !notification.read)

            return Object.assign({}, state, {
                notifications: notifications,
                unreadNotifications: unreadNotifications
            });
        case MARK_NOTIFICATION_READ:
            notification = state.notifications.find(notification => {
                return notification._id === action.payload.notificationID
            });
            notification.read = action.payload.modified ? !notification.read : notification.read;

            notifications = state.notifications.filter(notification => {
                return notification._id !== action.payload.notificationID
            });
            notifications = [...notifications, notification];

            unreadNotifications = notifications.filter(notification => !notification.read)

            return Object.assign({}, state, {
                notifications: notifications,
                unreadNotifications: unreadNotifications
            });
        case GET_TOTAL_AUTHORIZED_CREDIT:
            return Object.assign({}, state, {
                totalAuthorizedCredit: 0
            });
        default:
            return state;
    }
}