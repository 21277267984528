import {Modal, Table} from "react-bootstrap";
import React, {useState} from "react";
import {useFormik} from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
// import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FilledInput from "@material-ui/core/FilledInput";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Image from "react-bootstrap/Image";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import {
    categoriesUpdate, fetchCategories, fetchProducts, fetchSuppliers, insertCategories, newAttachment,
    newSupplierProduct, updateAttachment, updatePriceList,
    updateSupplierProduct
} from "../AdminPagesCrud";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import {CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import axios from "axios";


const useStyles = makeStyles(theme => ({

    rootProduct: {
        flexGrow: 1,
    },
    paper: {
        height: 140
        // width: 300,
    },
    control: {
        padding: theme.spacing(2),
    },
    formControlProduct: {
        width: "100%",
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    overflow: {
        maxHeight: 200,
        overflow: 'auto'
    },
    modalTable: {
        maxHeight: 200,
        overflow: 'auto'
    },
    tableHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'lightGray',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        paddingLeft: theme.spacing(4),
        marginTop: theme.spacing(2)
    },
    textFieldCat: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        paddingLeft: theme.spacing(4),
    },
    textFieldNested: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 'auto',
        paddingLeft: theme.spacing(8),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textFieldAttachment: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    subNested: {
        paddingLeft: theme.spacing(8),
    },
    button: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));

export const SupplierLogo = () => {
    return (
        <>
            <Card>
                <CardHeader title="Logo"/>
                <CardContent>
                    <Image src="/media/stock-600x400/img-1.jpg"
                           fluid
                           rounded />
                </CardContent>
            </Card>
        </>
    )
}

function EditProductModal({modalStatus,manageModal, product, allProducts, setAllProducts, categories, isNew,
                              setShowSnackbar, showSnackbar, intl}){

    // const classes = useStyles();
    const {id} = useParams();
    const productId = product._id
    // const [loading, setLoading] = useState(false);
    // const [spacing, setSpacing] = useState(10);

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )


    const handleClose = () => {
        manageModal(false)
    }


    // const initialValues = {
    //     name: "",
    //     products: {
    //         name: "",
    //         price: "",
    //     },
    // }

    const productInfoSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        sku: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        unitSize: Yup.string()
            .min(1, "Minimum 1 symbol")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        brand: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        categoryId: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        units: Yup.string()
            .min(1, "Minimum 1 symbol")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        description: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        price: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        unitMeasurement: Yup.string()
            .min(1, "Minimum 1 symbol")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const formik = useFormik({
        initialValues: product,
        validationSchema: productInfoSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            try {
                if(isNew){
                    newSupplierProduct(id, values, accessToken).then((response) => {
                        fetchProducts(id,accessToken).then(response => {
                            setAllProducts(response.data.data)
                        })
                        setShowSnackbar(!showSnackbar)
                        manageModal(false)
                    })
                }
                else{
                    updateSupplierProduct(productId, values, accessToken).then((response) => {
                        fetchProducts(id,accessToken).then(response => {
                            setAllProducts(response.data.data)
                        })
                        setShowSnackbar(!showSnackbar)
                        manageModal(false)
                    })
                }
            } catch (e) {
                alert("An error occurred");
                console.log(e.message);
            }

        },
    });

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                show={modalStatus}
                backdrop="static"
                centered={true}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default row justify-content-center">
                    <div className="col-10">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel>Product Name</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="name"
                                                {...formik.getFieldProps("name")}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className="fv-plugins-message-container">
                                                    <div
                                                        className="fv-help-block">{formik.errors.name}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="sku">SKU (Supplier Item Number)</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="sku"
                                                {...formik.getFieldProps("sku")}
                                            />
                                            {formik.touched.sku && formik.errors.sku ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.sku}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="unitSize">Unit Size</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="unitSize"
                                                {...formik.getFieldProps("unitSize")}
                                            />
                                            {formik.touched.unitSize && formik.errors.unitSize ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.unitSize}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>


                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="brand">Brand</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="brand"
                                                {...formik.getFieldProps("brand")}
                                            />
                                            {formik.touched.brand && formik.errors.brand ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.brand}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel >Category</InputLabel>
                                            <Select
                                                input={<FilledInput name="categoryId" id="categoryId"
                                                                    type="text"
                                                                    className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                    {...formik.getFieldProps("categoryId")}
                                                />}
                                            >
                                                {categories.map(c => {
                                                        return (
                                                            <MenuItem value={c._id}>{c.name}</MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                            {formik.touched.categoryId && formik.errors.categoryId ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.categoryId}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="unitSize">Units</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="units"
                                                {...formik.getFieldProps("units")}
                                            />
                                            {formik.touched.units && formik.errors.units ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.units}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="description">Product Description</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="description"
                                                {...formik.getFieldProps("description")}
                                            />
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.description}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="price">Price</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="price"
                                                {...formik.getFieldProps("price")}
                                            />
                                            {formik.touched.price && formik.errors.price ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.price}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel >Unit Measurement</InputLabel>
                                            <Select
                                                input={<FilledInput name="unitMeasurement" id="unitMeasurement"
                                                                    type="text"
                                                                    className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                    {...formik.getFieldProps("unitMeasurement")}
                                                />}
                                            >
                                                <MenuItem value={"EA"}>EA</MenuItem>
                                                <MenuItem value={"LB"}>LB</MenuItem>
                                                <MenuItem value={"GAL"}>GAL</MenuItem>
                                                <MenuItem value={"OZ"}>OZ</MenuItem>
                                            </Select>
                                            {formik.touched.unitMeasurement && formik.errors.unitMeasurement ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.unitMeasurement}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-9">
                                        <div className="form-group fv-plugins-icon-container">
                                            <InputLabel htmlFor="unitSize">Image Path</InputLabel>
                                            <input
                                                type="text"
                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                name="units"
                                                {...formik.getFieldProps("imagePath")}
                                            />
                                            {formik.touched.imagePath && formik.errors.imagePath ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{formik.errors.imagePath}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <Image src={product.imagePath} fluid rounded/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 row justify-content-center">
                                <button className="btn btn-primary" type="submit">
                                    Save
                                </button>
                                <div className="pl-4"/>
                                <button className="btn btn-secondary" type="button" onClick={handleClose}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}


export function AddAttachmentItem({supplierId, edit, setEdit, attachments, setAttachments, setShowSnackbar, showSnackbar}) {

    const classes = useStyles();
    const {id} = useParams();

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const [fields, setFields] = useState(attachments);

    function handleChange(i, event) {
        const values = [...fields];
        values[i] = event.target.value;
        setFields(values);
    }

    function handleAdd() {
        const values = [...fields];
        values.push("");
        setFields(values);
    }

    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }

    function submitAttachments(){
        try {

            console.log("values: ", fields);

            let data = {
                supplierId: supplierId,
                attachments: fields,
                updateType: 'attachments'
            }
            updateAttachment(supplierId, data, accessToken).then((response) => {
                fetchSuppliers(id,accessToken).then(response =>{
                    setAttachments(response.data.attachments)
                });
                setShowSnackbar(!showSnackbar);
                setEdit(!edit)
            })
        } catch (e) {
            alert("An error occurred.");
            console.log(e.message);
        }
    }

    return (
        <div>
            <Button variant="outlined" color="secondary"  onClick={() => handleAdd()} className={classes.button}>
                Add Attachment
                <span className="center svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
            </span>
            </Button>

            {fields ?
                fields.map((field, idx) => {
                    return (
                        <div key={`${field}-${idx}`}>
                            <FormGroup row>
                                <Input
                                    id={`attachment${idx}`}
                                    label="Attachment Name"
                                    className={classes.textField}
                                    onChange={event => handleChange(idx, event)}
                                    value={field || ""}
                                    margin="dense"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton  onClick={() => handleRemove(idx)}
                                            >
                                        <span  className="center svg-icon svg-icon-sm svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormGroup>
                        </div>
                    );
                })
                : setFields([''])}
            <div className="col-12 row justify-content-center py-3">
                <button className="btn btn-primary mr-3" type="button" onClick={() => submitAttachments()}>
                    Save
                </button>
                <></>
                <button type="button" className="btn btn-light" onClick={() => setEdit(!edit)}>
                    Cancel
                </button>
            </div>
        </div>
    );
}


export function AddCategories({supplierCategories, setSupplierCategories, edit, supplierId, openEditCategories, setOpenEditCategories,
                                  setShowSnackbar, showSnackbar}) {

    const classes = useStyles();
    // const [spacing, setSpacing] = useState(2);
    // const [message, setMessage] = useState('Categories updated succesfully.');

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const [categories, setCategories] = useState(supplierCategories);


    function handleChangeName(i, event) {
        const values = [...categories];
        values[i].name = event.target.value;
        setCategories(values);
    }

    function handleChangeDescription(i, event) {
        const values = [...categories];
        values[i].description = event.target.value;
        setCategories(values);
    }

    function handleAdd() {
        const values = [...categories];
        values.push({name: '', children: [], description: ''});
        setCategories(values);
    }

    function handleAddSub(i) {
        const values = [...categories];
        const child = {name: '', children: [] };
        values[i].children.push(child);
        setCategories(values);
    }

    function handleAddSubZ(i, j) {
        const values = [...categories];
        const child = {name: '',  children: [] };
        if(values[i].children[j].children){
            values[i].children[j].children.push(child);
        }
        else{
            values[i].children[j].children = [];
            values[i].children[j].children.push(child);
        }

        setCategories(values);
    }

    function handleRemove(i) {
        const values = [...categories];
        values.splice(i, 1);
        setCategories(values);
    }

    function handleChangeSub(i, j, event) {
        const values = [...categories];
        values[i].children[j].name = event.target.value;
        setCategories(values);
    }

    function handleRemoveChild(i, j) {
        const values = [...categories];
        values[i].children.splice(j, 1);
        setCategories(values);
    }

    function handleChangeSubZ(i, j, k, event) {
        const values = [...categories];
        values[i].children[j].children[k].name = event.target.value;
        setCategories(values);
    }

    function handleRemoveChildZ(i, j, k) {
        const values = [...categories];
        values[i].children[j].children.splice(k, 1);
        setCategories(values);
    }



    function submitCategories(){
        try {

            console.log("values: ", categories);

            if(edit){
                let data = {
                    supplierId: supplierId,
                    categories: categories
                }
                categoriesUpdate(data, accessToken).then((response) => {
                    fetchCategories(supplierId,accessToken).then(response =>{
                        console.log('New cats: ', response.data)
                        setSupplierCategories(response.data)

                    })
                    setShowSnackbar(!showSnackbar);
                    setOpenEditCategories(!openEditCategories);
                })
            }
            else{
                let data = {
                    supplierId: supplierId,
                    categories: categories,
                }
                insertCategories(data, accessToken).then((response) => {
                    setSupplierCategories(response.data)
                    setShowSnackbar(!showSnackbar);
                    setOpenEditCategories(!openEditCategories);
                })
            }
        } catch (e) {
            alert("An error occurred.");
            console.log(e.message);
        }
    }

    return (
        <div>
            <Button variant="outlined" color="secondary"  onClick={() => handleAdd()} className={classes.button}>
                Add Main Category
                <span className="center svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
            </span>
            </Button>

            {categories.map((field, idx) => {
                    return (
                        <Card>
                            <CardHeader title={`Main Category ${idx+1}`}/>
                            <CardContent>
                                <div key={`${field}-${idx}`}>
                                    <Grid container spacing={3}>

                                        <Grid item xs={4}>
                                            <InputLabel htmlFor={`category${idx}`}>Main Category Name</InputLabel>
                                            <Input
                                                id={`category${idx}`}
                                                label="Main Category Name"
                                                className={classes.textFieldCat}
                                                onChange={event => handleChangeName(idx, event)}
                                                value={field.name || ""}
                                                margin="dense"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton  onClick={() => handleRemove(idx)}
                                                        >
                                                        <span  className="center svg-icon svg-icon-sm svg-icon-primary">
                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                        </span>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                </div>

                                <Grid container>
                                    <Button variant="outlined" color="secondary"  onClick={() => handleAddSub(idx)} className={classes.button}>
                                        Add Sub Category
                                        <span className="center svg-icon svg-icon-md svg-icon-primary">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
                                        </span>
                                    </Button>

                                </Grid>


                                <Grid container>
                                    <Table  size="small" title="Sub Categories">
                                        <TableBody>
                                            {field.children.map((child,idy) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>
                                                                <List
                                                                    component="nav"
                                                                    aria-labelledby="nested-list-subheader"
                                                                    className={classes.root}
                                                                    dense
                                                                >
                                                                    <ListItem>
                                                                        <FormGroup row>
                                                                            <Input
                                                                                id={`subCat${idy}`}
                                                                                // label="Sub-Category"
                                                                                className={classes.textField}
                                                                                onChange={event => handleChangeSub(idx, idy, event)}
                                                                                value={child.name || ""}
                                                                                margin="dense"
                                                                                endAdornment={
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton  onClick={() => handleRemoveChild(idx, idy)}
                                                                                        >
                                                                                        <span  className="center svg-icon svg-icon-sm svg-icon-primary">
                                                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                                                        </span>
                                                                                        </IconButton>
                                                                                        <IconButton onClick={() => handleAddSubZ(idx, idy)}>
                                                                                            <span  className="center svg-icon svg-icon-sm svg-icon-primary">
                                                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
                                                                                            </span>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </ListItem>
                                                                    {child.children ?
                                                                        child.children.map((subChild,idz) => {
                                                                            return (
                                                                                <List
                                                                                    component="nav"
                                                                                    aria-labelledby="nested-list-subheader"
                                                                                    className={classes.root}
                                                                                    dense
                                                                                >
                                                                                    <ListItem>
                                                                                        <FormGroup row>

                                                                                            <Input
                                                                                                id={`catDescription${idz}`}
                                                                                                // label="Sub-Category"
                                                                                                className={classes.textFieldNested}
                                                                                                onChange={event => handleChangeSubZ(idx, idy, idz, event)}
                                                                                                value={subChild.name || ""}
                                                                                                margin="dense"
                                                                                                endAdornment={
                                                                                                    <InputAdornment position="end">
                                                                                                        <IconButton  onClick={() => handleRemoveChildZ(idx, idy, idz)}
                                                                                                        >
                                                                                                        <span  className="center svg-icon svg-icon-sm svg-icon-primary">
                                                                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                                                                        </span>
                                                                                                        </IconButton>
                                                                                                    </InputAdornment>
                                                                                                }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </ListItem>
                                                                                </List>
                                                                            )
                                                                        })
                                                                        : null}
                                                                </List>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                }
            )}

            <div className="col-12 row justify-content-center py-3">
                <button className="btn btn-primary mr-3" type="button" onClick={() => submitCategories()}>
                    Save
                </button>
                <></>
                <button type="button" className="btn btn-light" onClick={() => setOpenEditCategories(!openEditCategories)}>
                    Cancel
                </button>
            </div>

        </div>
    );
}


export function PriceListClientsModal({modalStatus,manageModal, priceList, clientListForPriceList, allLists, setAllLists, clientsList, setShowSnackbar, showSnackbar, supplierId}){

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const handleClose = () => {
        setChecked([]);
        manageModal(false)
    }
    const fetchPriceLists = () => {

        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: { filter: {
                    name:"",
                },
                sortOrder: "asc",
                sortField: "name",
                pageNumber: 1,
                pageSize: 10
            }
        }
        axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/priceLists', options).then(response =>{
            setAllLists(response.data)
        });
    }

    const classes = useStyles();
    const [checked, setChecked] = useState(clientListForPriceList || []);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    function updateList(){
        try {

            console.log("values: ", checked);

            let data = {
                clients: checked,
                name: priceList.name,
                products: priceList.products
            }
            updatePriceList(priceList._id, data, accessToken).then((response) => {
                fetchPriceLists();
                setShowSnackbar(!showSnackbar);
                manageModal(false);
            })
        } catch (e) {
            alert("An error occurred.");
            console.log(e.message);
        }
    }



    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                show={modalStatus}
                backdrop="static"
                centered={true}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Clients for Price list
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="overlay overlay-block cursor-default row justify-content-center">
                    <div className="col-10">

                        <List  className={classes.root} >
                            {clientsList.map(value => {
                                return (
                                    <ListItem key={value._id}  dense button onClick={handleToggle(value._id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(value._id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={value.companyName} />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <div className="col-12 row justify-content-center">
                            <button className="btn btn-primary" type="button" onClick={() => updateList()}>
                                Save
                            </button>
                            <div className="pl-4"/>
                            <button className="btn btn-secondary" type="button" onClick={handleClose}>
                                Cancel
                            </button>
                        </div>
                        {/*</form>*/}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

// export function ImageUpload({ accessToken, imgSource, setImageSource }) {
//
//     const fileInput = createRef();
//     // const file2Input = createRef();
//
//     const processFiles = (e, fileName) => {
//         // var request = new XMLHttpRequest();
//         // setProgress("0%");
//         console.log(e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/))
//         console.log(e.target.files[0].name)
//         if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/)) {
//             e.target.value = null
//         } else {
//             console.log(e.target.files[0])
//             const file = e.target.files[0]
//             let isFileNew = true
//
//             if(imgSource.length === 0) {
//                 imgSource(files => files.concat({ name: fileName, file }))
//             } else {
//                 isFileNew = typeof(imgSource.find(currFile => currFile.name === fileName)) === 'undefined'
//                 if(isFileNew){
//                     console.log("NUEVO")
//                     setImageSource(files => files.concat({ name: fileName, file }))
//                 } else {
//                     console.log("VIEJO")
//                     const newFiles = imgSource.filter(currFile => currFile.name !== fileName)
//                     setImageSource(newFiles.concat({ name: fileName, file }))
//                 }
//             }
//         }
//     }
//
//     const findFile = (currentFile) => {
//         const foundFile = imgSource.find(thisFile => thisFile.name === currentFile)
//         if(typeof(foundFile) === "undefined") {
//             return null
//         } else {
//             return foundFile.file.name
//         }
//     }
//
//     return (
//         <div className="card-body py-0">
//             <div className="table-responsive">
//
//
//                 <input type="file" id="productImage"
//                        style={{ display: 'none' }} onChange={(e) => processFiles(e, currentFile)}/>
//                 <button className="btn btn-success font-weight-bolder font-size-sm"
//                         type="button" onClick={() => { document.getElementById("productImage").click();}}
//                 >
//                     Change Image
//                 </button>
//                 <span className="text-muted font-weight-bold px-4">
//                           {findFile(imgSource)}
//                       </span>
//
//             </div>
//         </div>
//     )
//
// }

export default injectIntl(EditProductModal);


