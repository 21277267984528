import React, { useState } from "react";
import {injectIntl} from "react-intl";
import {Card} from "react-bootstrap";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import OrdersFavoritesNumberField from "./OrderFavoritesNumberField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}));

function CategoryCollapseItemList({ category, categoryPoducts, listIndex, handlePriceChange, handleChangeQuantity }){

    // console.log("Category products: ", categoryPoducts)

    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <div>
                <>
                    <ExpansionPanel square expanded={isExpanded} onChange={() => setIsExpanded(oldIsExpanded => !oldIsExpanded)}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls={'panel-content-' + category.name}
                            id={'panel-header-' + category.name}
                            >
                            <Typography className={classes.heading}>
                                {category.name}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="col-12 my-5">
                                <Card>
                                    <Card.Body>
                                        {/* <Table className="table-responsive">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                            Name
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                            Description
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                            Category
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                            Avg Weight
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                           Unit Price
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                           Item Price
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                            QoH
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                            Quantity
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {categoryPoducts != null ?
                                                    (categoryPoducts.map((product, productIndex) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                        {product.name}
                                                                    </span>
                                                                    <span className={'text-muted font-weight-bold'}>
                                                                        {product.sku}
                                                                    </span>
                                                                </TableCell>
                                                                <TableCell>{product.description ? product.description : product.name}</TableCell>
                                                                <TableCell>{product.productCategory[0].name}</TableCell>
                                                                <TableCell>{product.isCatchWeight ? product.avgWeight : "N/A"}</TableCell>
                                                                <TableCell>
                                                                    {
                                                                        product.listPrice || product.listPrice == '' ?                  
                                                                        <input
                                                                            value={product.listPrice}
                                                                            onChange={(e) => handlePriceChange(listIndex, category._id, product._id, productIndex, e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            type="number"
                                                                            min="0"
                                                                        />
                                                                        :
                                                                        <input
                                                                            value={product.price}
                                                                            onChange={(e) => handlePriceChange(listIndex, category._id, product._id, productIndex, e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            type="number"
                                                                            min="0"
                                                                        />
                                                                    }
                                                                </TableCell>
                                                                <TableCell>{
                                                                        product.listPrice ?
                                                                            product.isCatchWeight ?
                                                                                (product.listPrice * product.avgWeight).toFixed(2)
                                                                            :
                                                                                product.listPrice
                                                                        :
                                                                            product.isCatchWeight ?
                                                                                (product.price * product.avgWeight).toFixed(2)
                                                                            :
                                                                                product.price
                                                                    }
                                                                </TableCell>
                                                                <TableCell>{product.qtyOnHand}</TableCell>
                                                                <TableCell>
                                                                    <input
                                                                            value={product.quantity}
                                                                            onChange={(e) => handleChangeQuantity(listIndex, category._id, product._id, productIndex, e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            type="number"
                                                                            min="0"
                                                                        />
                                                                </TableCell>
                                                                
                                                            </TableRow>
                                                        </>
                                                    )))
                                                    :
                                                    null
                                                }
                                            </TableBody>
                                        </Table> */}
                                        <div className="table-responsive">
                                            <table
                                                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                                                id="kt_advance_table_widget_1"
                                            >
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="pr-0" style={{ minWidth: "150px" }}>
                                                        Name
                                                        </th>
                                                        {/* <th style={{ minWidth: "150px" }}>Description</th> */}
                                                        {/* <th style={{ minWidth: "150px" }}>Category</th> */}
                                                        {/* <th style={{ minWidth: "150px" }}>Avg Weight</th> */}
                                                        <th style={{ minWidth: "125px" }}>Unit Price</th>
                                                        {/* <th style={{ minWidth: "150px" }}>Item Price</th> */}
                                                        <th style={{ }}>QoH</th>
                                                        <th style={{ minWidth: "125px" }}>Quantity</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {categoryPoducts != null ?
                                                        (categoryPoducts.map((product, productIndex) => (
                                                            <>
                                                                <tr key={product._id}>
                                                                    <td className="pr-0 pl-2 py-2">
                                                                        <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                                                                            {product.name}
                                                                        </span>
                                                                        <span className={'text-muted font-weight-bold'}>
                                                                            {product.sku}
                                                                        </span>
                                                                    </td>
                                                                    {/* <td>
                                                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                            {product.description ? product.description : product.name}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                            {product.productCategory[0].name}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                            {product.isCatchWeight ? product.avgWeight : "N/A"}
                                                                        </span>
                                                                    </td> */}
                                                                    <td>
                                                                        {
                                                                            product.listPrice || product.listPrice == '' ?                  
                                                                            <input
                                                                                value={product.listPrice}
                                                                                onChange={(e) => handlePriceChange(listIndex, category._id, product._id, productIndex, e.target.value)}
                                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                type="number"
                                                                                min="0"
                                                                            />
                                                                            :
                                                                            <input
                                                                                value={product.price}
                                                                                onChange={(e) => handlePriceChange(listIndex, category._id, product._id, productIndex, e.target.value)}
                                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                type="number"
                                                                                min="0"
                                                                            />
                                                                        }
                                                                    </td>
                                                                    {/* <td>
                                                                        {
                                                                            product.listPrice ?
                                                                                product.isCatchWeight ?
                                                                                    (product.listPrice * product.avgWeight).toFixed(2)
                                                                                :
                                                                                    product.listPrice
                                                                            :
                                                                                product.isCatchWeight ?
                                                                                    (product.price * product.avgWeight).toFixed(2)
                                                                                :
                                                                                    product.price
                                                                        }
                                                                    </td> */}
                                                                    <td>
                                                                        {product.qtyOnHand}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            value={product.quantity}
                                                                            onChange={(e) => handleChangeQuantity(listIndex, category._id, product._id, productIndex, e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            type="number"
                                                                            min="0"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                </>
                                                        )))
                                                        :
                                                        null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </>
            </div>
        </>
    )
}

export default injectIntl(CategoryCollapseItemList);