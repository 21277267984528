import {Card, CardBody, CardHeader, CardFooter} from "../../../../../_metronic/_partials/controls";
import React from "react";
import {MyTextInput} from "../../../Buyer/CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function PersonalInformation({personalInformation}) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Personal Information">
                    {/*<CardHeaderToolbar>*/}
                    {/*    <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>*/}
                    {/*        Expand*/}
                    {/*    </button>*/}
                    {/*</CardHeaderToolbar>*/}
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={personalInformation}
                        onSubmit={(values) =>{
                            console.log(values)
                        }}
                    >
                        <Form>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <MyTextInput
                                        label="Full Legal Name"
                                        name="personalFullLegalName"
                                        type="text"
                                        value={personalInformation.personalFullLegalName}

                                    />
                                    <MyTextInput
                                        label="Social Security Number"
                                        name="personalSocialSecurity"
                                        type="text"
                                        value={personalInformation.personalSocialSecurity}

                                    />
                                    <MyTextInput
                                        label="Driver's License"
                                        name="personalDriverLicense"
                                        type="text"
                                        value={personalInformation.personalDriversLicense}

                                    />
                                    <MyTextInput
                                        label="Birth Date"
                                        name="personalBirthDate"
                                        type="text"
                                        value={personalInformation.personalBirthDate}

                                    />
                                    <MyTextInput
                                        label="Phone Number"
                                        name="personalPhone"
                                        type="text"
                                        value={personalInformation.personalPhone}

                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <MyTextInput
                                        label="IsMarried"
                                        name="personalIsMarried"
                                        type="text"
                                        value={personalInformation.personalIsMarried}

                                    />
                                    <MyTextInput
                                        label="Spouse Full Name"
                                        name="personalSpouseFullName"
                                        type="text"
                                        value={personalInformation.personalSpouseFullName}
                                        placeholder="N/A"

                                    />
                                    <MyTextInput
                                        label="Physical Address"
                                        name="personalPhysicalAddress                                        "
                                        type="text"
                                        value={personalInformation.personalPhysicalAddress}

                                    />
                                    <MyTextInput
                                        label="Physical City"
                                        name="personalPhysicalCity"
                                        type="text"
                                        value={personalInformation.personalPhysicalCity}

                                    />
                                    <MyTextInput
                                        label="Physical Zipcode"
                                        name="personalPhysicalAddress"
                                        type="text"
                                        value={personalInformation.personalPhysicalZipcode}

                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <MyTextInput
                                        label="Postal Address"
                                        name="personalPostalAddress                                        "
                                        type="text"
                                        value={personalInformation.personalPostalAddress}

                                    />
                                    <MyTextInput
                                        label="Postal City"
                                        name="personalPostalCity"
                                        type="text"
                                        value={personalInformation.personalPostalCity}

                                    />
                                    <MyTextInput
                                        label="Postal Zipcode"
                                        name="personalPostalZipcode"
                                        type="text"
                                        value={personalInformation.personalPostalZipcode}

                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    )
}