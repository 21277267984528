import React, {useState, useEffect} from 'react';
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import useTheme from "@material-ui/core/styles/useTheme";
import PropTypes from 'prop-types';
import PriceListClientConfig from "./PriceListClientConfig";
import { fetchPriceListClients, fetchPriceListClientsPagination, fetchSupplierClientsPagination } from "../AdminPagesCrud";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "../Suppliers/column-formatters";

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// function createData(name, calories, fat) {
//     return { name, calories, fat };
// }


// const useStyles2 = makeStyles(theme => ({
//     root: {
//         width: '100%',
//         marginTop: theme.spacing(3),
//     },
//     table: {
//         minWidth: 500,
//     },
//     tableWrapper: {
//         overflowX: 'auto',
//     },
// }));


function PriceListClients({priceList, supplier, clients}) {
    // const classes = useStyles2();
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [edit, setEdit] = useState(false)
    const [priceListClients, setPriceListClients] = useState([])

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, clients.length - page * rowsPerPage);

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [data, setData] = useState(initialData)
    const {accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    const fetchSupplierClients = async (queryParams) => {
        await fetchPriceListClientsPagination(priceList._id, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }


    useEffect(() => {
        

        getPriceListClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    const getPriceListClients = async () => {
        await fetchPriceListClients(priceList._id, accessToken).then(response => {
            
            setPriceListClients(response.data)
        }).catch(error => console.log(error))
    }
    
    // function handleChangePage(event, newPage) {
    //     setPage(newPage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    // }

    const columns = [
        {
            dataField: "businessName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "businessEmail",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {edit ?  <PriceListClientConfig priceListId={priceList._id} currentPriceListClients={priceListClients} setEdit={setEdit}
                    /> :
                <Card>
                    <CardHeader title="Price List Clients">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setEdit(true)}>
                                Edit
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            initialFilter={initialFilter}
                            tableClasses={tableClasses}
                            fetcher={fetchSupplierClients}
                            filterFunction={prepareFilter}
                            searchField={true}/>
                    </CardBody>
                        

                {/* <Paper className={classes.root}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="center">Entity Name</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                    <TableRow key={row.fullName}>
                                        <TableCell component="th" scope="row">
                                            {row.fullName}
                                        </TableCell>
                                        <TableCell align="center">{row.entityName}</TableCell>
                                        <TableCell align="center">{row.email}</TableCell>
                                        <TableCell align="center">{row.newPrice}</TableCell>
                                    </TableRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 48 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30]}
                                        colSpan={3}
                                        count={clients.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'Rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper> */}
                </Card>
            }
        </>
    );
}

export default injectIntl(PriceListClients);