import axios from "axios";

export function getOrdersToRoute(queryParams, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/orders/pagination', options);
}

export function getOrdersInQueue(queryParams, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/orders/queue/pagination', options);
}

export function getOrdersRouting(queryParams, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/orders/routing/pagination', options);
}

export function getOrdersCompleted(queryParams, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/orders/completed/pagination', options);
}

export function getActiveRouteRecords(queryParams, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/routeRecords/active', options);
}

export function getRoutinSupplierInfo(authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/routeRecords/info', options);
}

export function getRouteRecordDetails(authToken, routeRecordId){
    const options = {
        headers: {'x-auth-token':authToken},
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/record/' + routeRecordId, options)
}

export function postOrdersToRoute(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.post(process.env.REACT_APP_API_URL + '/routes/record', data, options);
}

export function postAllOrdersToRoute(authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.post(process.env.REACT_APP_API_URL + '/routes/record/all', {}, options);
}

export function postSpecialRouteRecord(authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.post(process.env.REACT_APP_API_URL + '/routes/record/special', {}, options);
}

export function patchDisableSpecialRouteRecord(authToken, data) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/record/special', data, options);
}

export function patchRouteRecords(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/record/save', data, options);
}

export function submitRouteRecords(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/record/submit', data, options);
}

export function patchRouteRecordForceQueue(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/record/force/queue', data, options);
}

export function forceOrdersToRoute(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/force/route', data, options);
}

export function forceOrdersInQueue(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/routes/force/queue', data, options);
}

export function patchRouteRecordOrderAmounts(data, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/route/updateOrderAmounts', data, options);
}

export function deleteRouteRecord(authToken, recordId) {
    const options = {
        headers: { 'x-auth-token': authToken },
    }

    return axios.delete(process.env.REACT_APP_API_URL + '/routes/record/' + recordId, options);
}