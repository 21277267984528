import {Redirect, Switch, useRouteMatch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../../../../_metronic/layout"
import React, {Suspense, useState} from "react";
import OrderFavoritesTable from "./OrderFavoritesTable"
import OrderFavoritesNew from "./OrderFavoritesNew"
import {injectIntl} from "react-intl";
import OrderFavoritesEdit from "./OrderFavoritesEdit";

function OrderFavoritesBasePage(props){
   const {path, url} = useRouteMatch();

   return (
       <>
           <Suspense fallback={<LayoutSplashScreen/>}>
               <Switch>
                   <ContentRoute exact path={`${path}/new`} component={OrderFavoritesNew}/>
                   <ContentRoute exact path={`${path}/:id/edit`} component={OrderFavoritesEdit}/>
                   <ContentRoute exact path={`${path}`} component={OrderFavoritesTable}/>
                   <Redirect to="error/error-v1"/>
               </Switch>
           </Suspense>
       </>
   )
}

export default injectIntl(OrderFavoritesBasePage);