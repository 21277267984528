import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../../../_metronic/_partials/dropdowns";
import { fetchDashboardInfo } from "./dashboardCrud";


export function DashboardInfo({ className }) {

    const { user, accessToken } = useSelector(
        ({ auth }) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )
    const {applicationsCount} = useSelector(
        ({applicationsCount}) =>({
            applicationsCount: applicationsCount
        })
    )

    const initialDashboardInfo = {
        purchaseOrders: 0,
        applications: 0,
        suppliers: 0,
        totalSpending: 0
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

    const [dashboardInfo, setDashboardInfo] = useState(initialDashboardInfo);

    // console.log("ApplicationsCount Here: ", applicationsCount)
    useEffect(() => {
        fetchDashboardInfo(user.id, accessToken).then(response => {
            console.log(response.data)
            setDashboardInfo(response.data)
        })
    }, [])

    return (
        <>
            <div className={`card card-custom ${className}`}>
                {/* Header */}
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                        My Dashboard
                    </h3>
                    <div className="card-toolbar">
                        <Dropdown className="dropdown-inline" alignRight>
                            <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                variant="transparent"
                                id="dropdown-toggle-top"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-hor" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <DropdownMenu4 />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                {/* Body */}
                <div className="card-spacer">
                    <div className="row m-0">
                        {/*Applications*/}
                        {user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                            null
                            :
                            <div className="col-sm mb-2 mb-sm-0 mb-sm-1 bg-light-success px-6 py-8 rounded-xl mr-7">

                                <div className="row  justify-content-between align-items-end pr-2">
                                    <div>
                                        <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Communication/Urgent-mail.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                        <a
                                            href="#"
                                            className="text-success font-weight-bold font-size-h6 mt-2"
                                        >
                                            Applications
                                        </a>
                                    </div>
                                    <h1 className="ml-3 text-success">{ applicationsCount ? applicationsCount.submittedCount + applicationsCount.approvedCount
                                                                        + applicationsCount.pendingCount + applicationsCount.deniedCount : 0}</h1>

                                </div>
                            </div>
                        }
                        {/*End Applications*/}
                        {/*Purchase Orders*/}
                        <div className="col-sm mb-2 mb-sm-0 bg-light-danger px-6 py-8 rounded-xl mr-7">
                            <div className="row  justify-content-between align-items-end pr-2">
                                <div>
                                    <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                                        ></SVG>
                                    </span>
                                    <a
                                        href="#"
                                        className="text-danger font-weight-bold font-size-h6 mt-2"
                                    >
                                        Orders
                                  </a>
                                </div>
                                <h1 className="ml-3 text-danger">{dashboardInfo.purchaseOrders}</h1>
                            </div>
                        </div>
                        {/*End Purchase Orders*/}

                        {/*Clients*/}
                        {user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                            null
                            :
                            <div className="col-sm mb-2 mb-sm-0 mb-xl-1 bg-light-primary px-6 py-8 rounded-xl mr-7">
                                <div className="row  justify-content-between align-items-end pr-2">
                                    <div>
                                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Communication/Add-user.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                        <a
                                            href="#"
                                            className="text-primary font-weight-bold font-size-h6 mt-2"
                                        >
                                            Suppliers
                                        </a>
                                    </div>
                                    <h1 className="ml-3 text-primary">{dashboardInfo.suppliers}</h1>
                                </div>
                            </div>
                        }
                        {/*End Clients*/}
                        {/*Invoices*/}
                        {user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                            null
                            :
                            <div className="col-sm mb-2 mb-sm-0 mb-xl-1 bg-light-warning px-6 py-8 rounded-xl mr-7">
                                <div className="row  justify-content-between align-items-end pr-2">
                                    <div>
                                        <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                                            ></SVG>
                                        </span>
                                        <a
                                            href="#"
                                            className="text-warning font-weight-bold font-size-h6"
                                        >
                                            Total Spending
                                        </a>
                                    </div>
                                                <h1 className="ml-3 text-warning">{`${formatter.format(dashboardInfo.totalSpending)}`}</h1>

                                </div>
                            </div>
                        }
                        {/*End Invoices*/}
                    </div>
                </div>
            </div>
        </>
    )
}