import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import React, {useEffect} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom";
import * as auth from "../_redux/authRedux";
import {LayoutSplashScreen} from "../../../../_metronic/layout";



function Callback(props){

    const history = useHistory();

    useEffect(() => {

        const currentUrl = window.location.href;
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get('code');

        // const clientId = 1;
        // const scope = "*";
        // const redirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI;
        // const responseType = 'code';
        // const serverUrl = process.env.REACT_APP_OAUTH_AUTHORIZE_URI;

        console.log("This is the code", code);

        if(!code){
            history.push('/auth/login');
        }else{
            axios.post(process.env.REACT_APP_API_URL + '/auth/oauth/login', {code: code})
                .then(res => {
                    console.log("Res: ", res);
                    if(res.status === 200){
                        props.login(res.data.accessToken)
                    }
                    // history.push('/');
                }).catch(error =>{
                  history.push('/auth/login');
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <LayoutSplashScreen/>
        </>
    )
}

export default injectIntl(connect(null, auth.actions)(Callback))