import React from "react";

import {SuppliersCard} from "./SuppliersCard";

export function SuppliersList({suppliers, setSupplier, setIsSupplerSelected, handleSupplierChange}){

    return (
        <>
            <div className="row">
                {suppliers.map((supplier,index) => (
                    <div key={index} className="col-lg-4">
                        <SuppliersCard className="card-stretch gutter-b" setSupplier={setSupplier} supplier={supplier} setIsSupplerSelected={setIsSupplerSelected} 
                            handleSupplierChange={handleSupplierChange}/>
                    </div>
                ))}

            </div>
        </>
    )
}