import axios from "axios";


export const GET_DASHBOARD_INFO = process.env.REACT_APP_API_URL + "/buyers/dashboardInfo/";


export function fetchDashboardInfo(id,authToken) {
    // Authorization head should be fulfilled in interceptor.
    const options = {
        headers: { 'x-auth-token': authToken}
    }

    return axios.get(GET_DASHBOARD_INFO + id,options);
}

export function fetchApplications (id,queryParams,accessToken){
    // Authorization head should be fulfilled in interceptor.
    const options = {
        headers: { 'x-auth-token': accessToken},
        validateStatus: status => status < 500,
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + id + '/applications',options);
}