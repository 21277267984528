import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {fetchSupplierProductsPagination, fetchPriceListProductsPagination, updatePriceListProducts} from "../AdminPagesCrud";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {CardBody, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PropTypes from "prop-types";
import {
    makeStyles,
    lighten,
    withStyles,
    useTheme
  } from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    TableSortLabel,
    TablePagination,
    Switch,
    FormControlLabel,
    TableFooter
  } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    textField: {
        width: 100,
    },
    summary: {
        width: '40%',
        position: 'center'
    }
}));

const useStyles1 = makeStyles(theme => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto"
    },
    table: {
      minWidth: 650
    }
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

export default function PriceListProductConfig({ priceListId, currentPriceListProducts, setEdit }) {
    const classes = useStyles();
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const pagClasses = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [data, setData] = useState(initialData)
    const [plData, setPlData] = useState(initialData)
    const [itemsToAdd, setItemsToAdd] = useState([])
    const [itemsToRemove, setItemsToRemove] = useState([])
    const [priceListProducts, setPriceListProducts] = useState(currentPriceListProducts)
    const {id} = useParams();

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, priceListProducts.length - page * rowsPerPage);

    const {user, accessToken} = useSelector(
            ({auth}) => ({
                user: auth.user != null ? auth.user : null,
                accessToken: auth.authToken,
            })
        )

    // const dispatch = useDispatch();

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
    }

    useEffect(() => {
        

        fetchSupplierProducts(initialFilter)
    }, [])

    const fetchSupplierProducts = async (queryParams) => {
        await fetchSupplierProductsPagination(id, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    const handleListPriceChangeData = (productId, event) => {
        const productIndex = data.data.findIndex(item => item._id == productId)
        let product = data.data[productIndex]

        let productListPrice = {
            ...product,
            listPrice: event.target.value
        }

        let products = [...data.data]

        products[productIndex] = productListPrice 

        const newData = {
            ...data,
            data: products
        }
        setData(newData)
    }

    const handleListPriceChangePlData = (productId, event) => {
        const productIndex = priceListProducts.findIndex(item => item._id == productId)
        const itemToAddIndex = itemsToAdd.findIndex(item => item._id == productId)
        const itemToRemoveIndex = itemsToRemove.findIndex(item => item._id == productId)

        let product = priceListProducts[productIndex]

        let productListPrice = {
            ...product,
            listPrice: event.target.value
        }

        let products = [...priceListProducts]
        products[productIndex] = productListPrice 

        let newItemsToAdd = []
        
        if(itemToAddIndex >= 0){
            newItemsToAdd = [...itemsToAdd]
            newItemsToAdd[itemToAddIndex] = productListPrice
        } else{
            newItemsToAdd = [...itemsToAdd, productListPrice]
        }
        
        if(itemToRemoveIndex){
            const newItemsToRemove = itemsToRemove.filter(item => item._id != productId)
            setItemsToRemove(newItemsToRemove)
        }

        setPriceListProducts(products)
        setItemsToAdd(newItemsToAdd)
    }

    
    const handlePriceListProductRemove = (productId) => {
        let product = priceListProducts.find(item => item._id == productId)

        const newItemsToAdd = itemsToAdd.filter(item => item._id != productId)
        setItemsToAdd(newItemsToAdd)

        const newPriceListProducts = priceListProducts.filter(item => item._id != productId)
        setPriceListProducts(newPriceListProducts)

        const newItemsToRemove = itemsToRemove.filter(item => item._id != productId)
        setItemsToRemove([...newItemsToRemove, product])
    }

    const handleSubmit = async () => {

        const itemsToAddRemove = {
            itemsToAdd: itemsToAdd,
            itemsToRemove: itemsToRemove
        }

        await updatePriceListProducts(priceListId, itemsToAddRemove, accessToken).then(response => {
            setEdit(false)

        }).catch(error => console.log(error))
    }

    const columns = [
        {
            dataField: "name",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "units",
            text: "Units",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.UnitSizeFormatter,
        },
        {
            dataField: "price",
            text: "Original Price",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        // {
        //     dataField: "listPrice",
        //     text: "List Price",
        //     formatter: columnFormatters.ListPriceFormatter,
        //     formatExtraData:{
        //         getListPrice: getListPrice
        //     },
        // },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ActionColumnFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    const item = priceListProducts.find(prod => prod._id == row._id)
                    if(!item){
                        setPriceListProducts(oldPriceListProducts => [{...row, listPrice: 0}, ...oldPriceListProducts])
                        setItemsToAdd(oldItemsToAdd => [{...row, listPrice: 0}, ...oldItemsToAdd])
                    }
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    const fetchPriceListProductsPaginationTable = async (queryParams) => {
        await fetchPriceListProductsPagination(priceListId, queryParams, accessToken).then(response => {
            
            setPlData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    const getListPricePl = (row) => {
        return (
            <input id={row._id + "-pl"} key={row._id + "-pl"} type="number" value={row.listPrice} onChange={(event) => handleListPriceChangePlData(row._id, event)}/>
        )
    }

    const plColumns = [
        {
            dataField: "description",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        }, 
        {
            dataField: "units",
            text: "Units",
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.UnitSizeFormatter,
        },
        {
            dataField: "price",
            text: "Original Price",
        },
        {
            dataField: "listPrice",
            text: "List Price",
            formatter: columnFormatters.ListPriceFormatter,
            formatExtraData:{
                getListPrice: getListPricePl
            },
        },
        {
            dataField: "action",
            text: "Action",
            formatter: columnFormatters.ActionColumnFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    
                }
            },
        },
    ];

    const plSizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let plInitialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const plSorting = [{dataField: "name", order: "asc"}]
    const plPagination = {custom: true, sizePerPageList: sizePerPageList}
    const plTableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const plPrepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.paper}>
                <CardHeader title="New Price List">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        initialFilter={initialFilter}
                        tableClasses={tableClasses}
                        fetcher={fetchSupplierProducts}
                        filterFunction={prepareFilter}
                        searchField={true}/>
                   
                </CardBody>
            </Card>
            {true ?
                <Card>
                    <CardHeader title={`Price List Summary: `}/>
                    <CardBody>
                        {/* <PaginationTable
                            data={plData}
                            columns={plColumns}
                            pagination={plPagination}
                            isLoading={false}
                            sorting={plSorting}
                            initialFilter={plInitialFilter}
                            tableClasses={plTableClasses}
                            fetcher={fetchPriceListProductsPaginationTable}
                            filterFunction={plPrepareFilter}
                            searchField={true}/> */}
                        <div className={pagClasses.tableWrapper}>
                            <Table className={plTableClasses}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell>Units</TableCell>
                                        <TableCell>Unit Size</TableCell>
                                        <TableCell>Original Price</TableCell>
                                        <TableCell>List Price</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {priceListProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row.description}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.units}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {`${row.unitSize} / ${row.unitMeasurement}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.price}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <input id={row._id} key={row._id} type="number" value={row.listPrice} onChange={(event) => handleListPriceChangePlData(row._id, event)}/>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary font-weight-bolder font-size-sm mr-1"
                                                    onClick={() => handlePriceListProductRemove(row._id)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 48 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20, 30]}
                                            colSpan={3}
                                            count={priceListProducts.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'Rows per page' },
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </CardBody>
                    
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                        Submit
                    </button>
                </Card>
                
                : null
            }
        </div>
    );
}