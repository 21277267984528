import React from "react";
import {Formik} from "formik";
import Input from '@material-ui/core/Input';

export function PaginationTableFilter({applyFilter, initialSearchText, searchFieldName, dateRangeField, dateRangeName, searchFieldTwo, searchFieldTwoName, searchFieldThree, searchFieldThreeName}){

    return(
        <>
            <Formik
                initialValues={{
                    searchText: initialSearchText ?? "",
                    searchTextTwo: "",
                    searchTextThree: "",
                    fromDate: "",
                    toDate: ""
                }}
                onSubmit={(values) =>{
                    applyFilter(values)
                }}>
                {({
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue
                })=>(
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            <div className="col-lg-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Search"
                                    onBlur={handleBlur}
                                    value={values.searchText}
                                    onChange={(e) => {
                                        setFieldValue("searchText", e.target.value);
                                        handleSubmit();
                                    }}
                                />
                                {
                                    searchFieldName !== '' ?
                                        <small className="form-text text-muted">
                                            <b>Search</b> {searchFieldName}
                                        </small>
                                    :
                                        // <small className="form-text text-muted">
                                        //     <b>Search</b> in all fields
                                        // </small>
                                        null
                                }
                                
                            </div>
                            {
                                searchFieldTwo ?
                                    <div className="col-lg-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="searchTextTwo"
                                            placeholder="Search"
                                            onBlur={handleBlur}
                                            value={values.searchTextTwo}
                                            onChange={(e) => {
                                                setFieldValue("searchTextTwo", e.target.value);
                                                handleSubmit();
                                            }}
                                        />
                                        <small className="form-text text-muted">
                                            <b>Search</b> {searchFieldTwoName}
                                        </small>
                                    </div>
                                :
                                    null
                            }
                            {
                                searchFieldThree ?
                                    <div className="col-lg-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="searchTextThree"
                                            placeholder="Search"
                                            onBlur={handleBlur}
                                            value={values.searchTextThree}
                                            onChange={(e) => {
                                                setFieldValue("searchTextThree", e.target.value);
                                                handleSubmit();
                                            }}
                                        />
                                        <small className="form-text text-muted">
                                            <b>Search</b> {searchFieldThreeName}
                                        </small>
                                    </div>
                                :
                                    null
                            }
                            {
                                dateRangeField ? 
                                    <>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label style={{ display: "block" }} htmlFor={"fromDate"}>From {dateRangeName} Date</label>
                                            <Input
                                                type="date"
                                                className={`h-auto py-1 px-6`}
                                                name="fromDate"
                                                value={values.fromDate}
                                                onChange={(e) => {
                                                    setFieldValue("fromDate", e.target.value);
                                                    handleSubmit();
                                                }}
                                            />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label style={{ display: "block" }} htmlFor={"toDate"}>To {dateRangeName} Date</label>
                                            <Input
                                                type="date"
                                                className={`h-auto py-1 px-6`}
                                                name="toDate"
                                                value={values.toDate}
                                                onChange={(e) => {
                                                    setFieldValue("toDate", e.target.value);
                                                    handleSubmit();
                                                }}
                                            />
                                        </div>
                                    </>
                                :
                                    null
                            }
                            
                        </div>
                        
                    </form>
                )}
            </Formik>
        </>
    )
}