
import React from "react";

export function LocationNameFormatter(cellContent, row){

    return (
        <>
            <a
                className="text-hover-primary"
                href="javascript:void(0);"
            >
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    { row.locationName ?
                        `${row.locationName}`
                        :
                        'N/A'
                    }
                </span>
            </a>
        </>
    )
}