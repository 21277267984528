import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getApplicationsByCompanyIdList, getCompanies } from '../CommonApplication/CommonApplicationCrud';
import { getSuppliers } from '../CommonApplication/SupplierSelection/SupplierSelectionCrud';
import { SupplierList } from "./SupplierList";
import { SupplierProductsPage } from './SupplierProductsPage';
import { TopCategorySupplier } from "./TopCategorySupplier";

function SuppliersPage(props) {

  const [applicationList, setApplicationList] = useState([])
  const [isApplicationFetched, setIsApplicationFetched] = useState(false)
  const [isSupplierCategoryClicked, setIsSupplierCategoryClicked] = useState(false)
  const [selectedSupplierCategory, setSelectedSupplierCategory] = useState({})
  const [suppliers, setSuppliers] = useState([])
  const { userId, accessToken } = useSelector(
    ({ auth }) => ({
      userId: auth.user.id,
      accessToken: auth.authToken,
    }));

  async function fetchSupplierSelectionData() {
    await Promise.all([getSuppliers(accessToken), getCompanies(userId, accessToken)]).then((values) => {
      const supplierResponse = values[0]
      const companyResponse = values[1]

      let companyIdList = []
      let suppliersStatus = []

      companyResponse.data.map(company => {
        companyIdList.push(company._id)
      })

      if(companyIdList.length > 0){
  
        getApplicationsByCompanyIdList(accessToken).then((response) => {
          setApplicationList(response.data)
          setIsApplicationFetched(true)
  
          supplierResponse.data.map(supplier => {
            const application = response.data.find(application => application.supplierId == supplier._id)
            if (typeof (application) !== 'undefined') {
              suppliersStatus.push({
                ...supplier,
                status: application.status
              })
            } else {
              suppliersStatus.push({
                ...supplier,
                status: "Not Applied"
              })
            }
          })
  
          setSuppliers(suppliersStatus)
        }).catch((error) => {
          console.log(error)
        });
      } else {
        supplierResponse.data.map(supplier => {
          
            suppliersStatus.push({
              ...supplier,
              status: "Not Applied"
            })

            setSuppliers(suppliersStatus)
            setIsApplicationFetched(true)
        })
      }
      
      console.log("Suppliers with status: ", supplierResponse.data)

      setSuppliers()
    }).catch((error) => {
      console.log(error)
    });
  }

  // async function fetchCommonApplicationData() {
  //   let applications = []
  //   let companyIdList = []

  //   await getCompanies(userId, accessToken).then((response) => {

  //     response.data.map(company => {
  //       companyIdList.push(company._id)
  //     })

  //     const companyIdListJson = {
  //       companyIdList
  //     }

  //     getApplicationsByCompanyIdList(accessToken).then((response) => {
  //       console.log(response.data)
  //       setApplicationList(response.data)
  //     })

  //     // setApplicationList(applications)
  //   }).catch((error) => {
  //     console.log(error)
  //   });
  // }

  useEffect(() => {
    // fetchCommonApplicationData()
    fetchSupplierSelectionData()
  }, [])

  const handleSupplierCategoryClicked = async (supplier) => {
    setSelectedSupplierCategory(supplier)
    setIsSupplierCategoryClicked(true)
  }

  return (
    <>

      {isSupplierCategoryClicked ? <SupplierProductsPage supplier={selectedSupplierCategory} />
        :




        isApplicationFetched ?
          <div className="row">
            <div>
              <div className="col-lg-12 col-xxl-12">
                <TopCategorySupplier className="card-stretch gutter-b" suppliers={suppliers} applicationList={applicationList} accessToken={accessToken} handleSupplierClick={handleSupplierCategoryClicked} />
              </div>
              <div className="col-lg-12 col-xxl-12">
                <SupplierList className="card-stretch gutter-b" suppliers={suppliers} applicationList={applicationList} accessToken={accessToken} />
              </div>
            </div>
          </div>
          :
          null

      }

    </>
  );
}

export default injectIntl(SuppliersPage);