import React, { useState } from "react";
import {validateAccount} from "../_redux/authCrud";
import qs from 'qs';

function EmailVerification(props) {
    const [loading, setLoading] = useState(false);
    const token = qs.parse(props.location.search, {ignoreQueryPrefix: true}).token;



    validateAccount(token).then(res =>
        setLoading(true)
    );

    return (
        <div>
            {!loading ? (
                <div style={{maxWidth:"600px"}}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Verifying your account...
                        </h3>
                    </div>

                </div>
            ) : (
                <div style={{maxWidth:"600px"}}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Your account has been verified!
                        </h3>
                        <p className="text-muted font-weight-bold">
                            You can now <a href="/auth/login">login</a> to your account.
                        </p>
                    </div>

                </div>
            )}
        </div>
    );
}

export default EmailVerification;
