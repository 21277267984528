/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {SupplierMenu} from "./SupplierMenu";
import {BuyerMenu} from "./BuyerMenu";
import {AdminMenu} from "./AdminMenu";

export function AsideMenuList({ layoutProps,accountType}) {
  // const location = useLocation();
  // const getMenuItemActive = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  //       ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
  //       : "";
  // };

  let menuList = null;

  if(accountType === "buyer"){
    menuList = <BuyerMenu layoutProps={layoutProps}/>
  }else if(accountType === "supplier"){
    menuList = <SupplierMenu layoutProps={layoutProps}/>
  }else if (accountType === "admin"){
    menuList = <AdminMenu layoutProps={layoutProps}/>
  }
  return (
      <>
        {menuList}
      </>
  );
}
