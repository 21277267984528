import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(cellContent, row){

    return (
        <>
            <a
            href="javascript:void(0);"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                    src={toAbsoluteUrl(
                    "/media/svg/icons/General/Edit.svg"
                    )}
                ></SVG>
                </span>
            </a>
        </>
    )
}