import axios from "axios";

export const GET_SUPPLIER = process.env.REACT_APP_API_URL + "/suppliers/";


export function fetchSuppliers(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken}
    }

    return axios.get(GET_SUPPLIER + id,options);
}

export function fetchCategories(id,authToken){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/categories/suppliers/' + id, options)
}


export function fetchProducts(id, authToken){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/products/suppliers/' + id, options)
}

export function fetchFavoriteProductsPagination(supplierId, queryParams, authToken){
    const options = {
        headers: {'x-auth-token':authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/products/favorites/suppliers/' + supplierId, options)
}

export function fetchProductsByCategory(supplierId, categoryId, authToken){
    const options = {
        headers: {'x-auth-token':authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/products/suppliers/' + supplierId + '/categories/' + categoryId, options)
}

export function fetchSupplierProductsPagination(supplierId, queryParams, authToken){
    const options = {
        headers: {'x-auth-token':authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/products/supplier/pagination/' + supplierId, options)
}

export function fetchSupplierClientsPagination(supplierId, queryParams, authToken){
    const options = {
        headers: {'x-auth-token':authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/company/supplier/' + supplierId + '/pagination', options)
}

export function updateSupplierInformation(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/suppliers/' + id, data, config);
}

export function categoriesUpdate(data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/categories/', data, config);
}

export function insertCategories(data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/categories/', data, config);
}

export function newSupplierProduct(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/products/', data, config);
}

export function updateSupplierProduct(productId, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/products/', data, config);
}

export function newPriceList( data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/priceLists/', data, config);
}

export function updatePriceListProducts(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/priceLists/' + id + "/products", data, config);
}

export function updatePriceListClients(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/priceLists/' + id + "/clients", data, config);
}

export function newSupplier(data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/suppliers', data, config);
}

export function updateAttachment(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/suppliers/' + id, data, config);
}

export function updatePriceList(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/priceLists/' + id, data, config);
}

export function fetchUser(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/users/' +  id,options);
}

export function updateUserInformation(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/users/' + id, data, config);
}

export function fetchProduct(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/products/' +  id,options);
}

export function fetchPriceList(id, authToken) {
    const options = {
        headers: {'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/priceLists/' + id, options);
}
export function fetchPriceListProducts(id, authToken) {
    const options = {
        headers: {'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/priceLists/' + id + '/products', options);
}

export function fetchPriceListProductsPagination(id, queryParams, authToken) {
    const options = {
        headers: {'x-auth-token': authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/priceLists/' + id + '/products/pagination', options);
}

export function fetchPriceListClients(id, authToken) {
    const options = {
        headers: {'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/priceLists/' + id + '/clients', options);
}

export function fetchPriceListClientsPagination(id, queryParams, authToken) {
    const options = {
        headers: {'x-auth-token': authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/priceLists/' + id + '/clients/pagination', options);
}

export function fetchAllSuppliers(authToken) {
    const options = {
        headers: {'x-auth-token': authToken},
    }

    return axios.get(process.env.REACT_APP_API_URL + '/suppliers/', options);
}

export function updateSupplierProductAddFavorite(productId, accessToken) {
    const config = {
        headers: {'x-auth-token':accessToken},
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/products/favorites/add/' + productId, {}, config);
}

export function updateSupplierProductRemoveFavorite(productId, accessToken) {
    const config = {
        headers: {'x-auth-token':accessToken},
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/products/favorites/remove/' + productId, {}, config);
}

// ---------------- ROUTE routes -----------------

export function fetchRoute( id, authToken ) {
    const options = {
        headers: {'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/' + id, options);
}


export function fetchRouteClients( id, authToken ) {
    const options = {
        headers: {'x-auth-token': authToken}
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/clients/' + id, options);
}

export function fetchRouteClientsPagination( id, queryParams, authToken ) {
    const options = {
        headers: {'x-auth-token': authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/routes/clients/pagination/' + id, options);
}

export function createNewRoute( data, accessToken ) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/routes', data, config);
}

export function updateRoute(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/routes/' + id, data, config);
}

export function updateRouteClients(id, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/routes/' + id + "/clients", data, config);
}

// ---------------- SUPPLIER CONFIG routes -----------------
export function updateSupplierCutOffTime(data, supplierId, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + "/cutofftime", data, config);
}

export function getSupplierDriversPagination( supplierId, queryParams, authToken ) {
    const options = {
        headers: {'x-auth-token': authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/drivers', options);
}

export function createNewDriver( data, supplierId, accessToken ) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/drivers', data, config);
}

export function updateDriver(driverId, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/suppliers/drivers/' + driverId, data, config);
}

export function getSupplierTrucksPagination( supplierId, queryParams, authToken ) {
    const options = {
        headers: {'x-auth-token': authToken},
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/trucks', options);
}

export function createNewTruck( data, supplierId, accessToken ) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.post(process.env.REACT_APP_API_URL + '/suppliers/' + supplierId + '/trucks', data, config);
}

export function updateTruck(truckId, data, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    return axios.patch(process.env.REACT_APP_API_URL + '/suppliers/trucks/' + truckId, data, config);
}