import React, {useEffect, useState} from "react";
import {ProductBox} from "./ProductBox";
import {useSelector} from "react-redux";
import Pagination from "react-js-pagination"
import {Formik} from "formik";
import { getSupplierProductsNameBrand } from '../../CommonApplication/SupplierSelection/SupplierSelectionCrud'
import FormControl from "@material-ui/core/FormControl";
import {Select} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";


export function ProductsList({filterCategory,supplier,categories,company,locations,isOrderSummary = false, setIsCartUpdated, isCartUpdated,
                                orderId, currentOrders, setCurrentOrders, defaultInvoiceDate, defaultShipDate , order}){
    const {accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    // console.log("Locations: ", locations)
    // console.log("Company: ", company)

    const initialPaginationOptions = {
        totalSize: 0,
        sizePerPage: 12,
        page: 1
    }

    const [products,setProducts] = useState([]);
    const [paginationOptions,setPaginationOptions] = useState(initialPaginationOptions)
    const [searchName,setSearchName] = useState("")
    const [searchBrand, setSearchBrand] = useState("All")
    const [searchCategory, setSearchCategory] = useState({})
    const [filteredCategoryList, setFilteredCategoryList] = useState([])
    const [showSnackBar, setShowSnackBar] = useState(false);

    useEffect(() => {

        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, searchBrand, filterCategory, company._id).then(response=>{
            
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[company])

    const handlePageChange = (pageNumber) =>{
        getSupplierProductsNameBrand(supplier._id,accessToken,pageNumber,paginationOptions.sizePerPage,searchName, searchBrand, searchCategory, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:pageNumber,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const handlePageSizeChange = (pageSize) =>{
        console.log("Element: ", pageSize)
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,pageSize,searchName, searchBrand, searchCategory, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: pageSize
            })
        })
    }

    const handleBrandClick = (brand) =>{
        if(brand === 'All'){
            setFilteredCategoryList([])
        }
        setSearchCategory({})
        setSearchBrand(brand)
        setSearchName("")
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,"", brand, {}, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const handleCategoryClick = (category, catLevel) =>{
        console.log("Category CLicked: ", category)
        setSearchCategory(category)
        setSearchBrand("")
        setSearchName("")
        console.log(`${category.name}: ${catLevel}`)

        setFilteredCategoryList([...filteredCategoryList.slice(0, catLevel), category.name])
        
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,"", "", category, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const getCategoryBrandList = (categories, listItems = [], catLvl) => {
        
        categories.forEach((category, index) => {
            let catLevel = 0
            if(typeof(catLvl) !== 'undefined'){
                catLevel = catLvl
            }
            
            // console.log("Local category lvl: ", catLevel, category.name)
            if(category.children.length === 0){
                //Product Categoriess
                listItems.push(<li key={index + '-' + category.name} style={{ 'listStyleType':'none', paddingLeft: catLevel * 15, margin:0}}>
                    <div className="d-flex align-items-center mb-1">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        {
                            searchCategory && searchCategory._id == category._id ?
                            <span 
                                onClick={() => handleCategoryClick(category, catLevel)}
                                className={"text-danger font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}
                                style={{ cursor: 'pointer' }}>
                                    {category.name}
                            </span>
                            :
                            <span 
                                onClick={() => handleCategoryClick(category, catLevel)}
                                className={"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}
                                style={{ cursor: 'pointer' }}>
                                    {category.name}
                            </span>
                        }
                        
                    </div>
                </li>)
                
            } else {
                //Top level categories
                if(category.parent === 'root'){
                    listItems.push(<li key={index + '-' + category.name} style={{ 'listStyleType':'none', padding:0, margin:0}}>
                        {
                            searchCategory && searchCategory._id == category._id ?
                                <span 
                                    onClick={() => handleCategoryClick(category, catLevel)}
                                    className="text-danger font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                    style={{ cursor: 'pointer' }}>
                                        {category.name}
                                </span>
                                :
                                <span 
                                    onClick={() => handleCategoryClick(category, catLevel)}
                                    className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                    style={{ cursor: 'pointer' }}>
                                        {category.name}
                                </span>
                        }
                    </li>)
                } else {
                    listItems.push(<li key={index + '-' + category.name} style={{ 'listStyleType':'none', paddingLeft: catLevel * 15, margin:0}}>
                    <div className="d-flex align-items-center mb-1">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        {
                            searchCategory && searchCategory._id == category._id ?
                            <span 
                                onClick={() => handleCategoryClick(category, catLevel)}
                                className={"text-danger font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}
                                style={{ cursor: 'pointer' }}>
                                    {category.name}
                            </span>
                            :
                            <span 
                                onClick={() => handleCategoryClick(category, catLevel)}
                                className={"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}
                                style={{ cursor: 'pointer' }}>
                                    {category.name}
                            </span>
                        }

                        
                    </div>
                </li>)
                }
                
                // console.log(filteredCategory, filteredCategory === "" || filteredCategory === category.name)
                if(filteredCategoryList.length > 0 && filteredCategoryList.includes(category.name)){
                    listItems = getCategoryBrandList(category.children, listItems, ++catLevel)
                } 
            }
        })
        return listItems
    }

    return(
       
        <div className="row">
        <div className="col-lg-2 col-xxl-2">
            {/* <ul>
                {supplier.supplierProductBrands.length > 0 ?
                    supplier.supplierProductBrands.map(brand => 
                        <li style={{ 'list-style-type':'none', padding:0, margin:0}}>
                            <a 
                            onClick={() => handleBrandClick(brand.brand)}
                            className="text-dark text-hover-primary mb-1 font-size-lg">
                                {brand.brand}
                            </a>
                        </li>
                        
                    )
                    :
                    null
                }
            </ul> */}
            <ul>
                {categories.length > 0 ?
                    <div>
                        <li key={"all"} style={{ 'listStyleType':'none', paddingLeft:0, margin:0}}>
                            {
                                searchBrand === 'All' ?
                                    <span 
                                        onClick={() => handleBrandClick("All")}
                                        style={{ cursor: 'pointer' }}
                                        className="text-danger font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                            {"All"}
                                    </span>
                                    :
                                    <span 
                                        onClick={() => handleBrandClick("All")}
                                        style={{ cursor: 'pointer' }}
                                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                            {"All"}
                                    </span>
                            }
                            
                        </li>
                        {getCategoryBrandList(categories)}
                    </div>
                    // null
                    :
                    null
                }
            </ul>
        </div>
        <div className="col-lg-9 col-xxl-9">
            <div className="row justify-content-between mb-4">
                <Formik
                    initialValues={{searchText:""}}
                    onSubmit={(values) =>{
                        setSearchName(values.searchText)
                        getSupplierProductsNameBrand(supplier._id,accessToken,1,paginationOptions.sizePerPage,values.searchText, searchBrand, searchCategory, company._id).then(response=>{
                            console.log('Result: response.data.products', response.data.products)
                            setProducts(response.data.products)
                            setPaginationOptions({
                                page:1,
                                totalSize: response.data.totalCount,
                                sizePerPage: paginationOptions.sizePerPage
                            })
                        })

                    }}>
                    {({values, handleSubmit, handleBlur, handleChange, setFieldValue})=>(
                        <form onSubmit={handleSubmit} className="form form-label-right w-100">
                            <div className="form-group row">
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="searchText"
                                        placeholder="Search"
                                        onBlur={handleBlur}
                                        value={values.searchText}
                                        onChange={(e) =>{
                                            setFieldValue("searchText",e.target.value);
                                            handleSubmit();
                                        }}
                                    />
                                    <small className="form-text text-muted">
                                        <b>Search</b> by name or item id
                                    </small>
                                </div>
                                <button
                                    id="addLocationButton"
                                    type="button"
                                    onClick={() => {
                                        setFieldValue("searchText",'')
                                        handleSubmit();
                                    }}
                                    // disabled={formik.isSubmitting}
                                    className="btn btn-primary font-weight-bold px-4 mx-4"
                                    >
                                        <span>Clear</span>
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

            <div className="row">


                {typeof(products) !== 'undefined' ? products.map((product,index) => (
                    <ProductBox key={product.name + '-' + index} product={product} company={company} supplier={supplier} locations={locations} setShowSnackBar={setShowSnackBar}
                                getSupplierProductsNameBrand={getSupplierProductsNameBrand}  setProducts={setProducts} setPaginationOptions={setPaginationOptions} 
                                searchBrand={searchBrand} searchCategory={searchCategory} paginationOptions={paginationOptions} 
                                isOrderSummary={isOrderSummary} setIsCartUpdated={setIsCartUpdated} isCartUpdated={isCartUpdated} orderId={orderId} currentOrders={currentOrders} 
                                setCurrentOrders={setCurrentOrders} defaultInvoiceDate={defaultInvoiceDate} defaultShipDate={defaultShipDate} order={order}
                                />
                ))
                    : null
                }
            </div>
            <div className="row justify-content-center">
                <div className="col-12">
                    <FormControl variant="filled" style={{ width: "100%" }}>
                        <Select
                            MenuProps={{ autoFocus: false }}
                            value={paginationOptions.sizePerPage}
                            style={{ minWidth: "200px" }}
                            input={<FilledInput name="pageSize" id="pageSize"
                                onChange={(element) => {
                                    handlePageSizeChange(element.target.value);
                                } }
                                type="text"
                                className="form-control form-control-solid h-auto" 
                            />}
                        >
                            <MenuItem key={"pageSize12"} value={12}>{"12"}</MenuItem>
                            <MenuItem key={"pageSize24"} value={24}>{"24"}</MenuItem>
                            <MenuItem key={"pageSize60"} value={60}>{"60"}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={paginationOptions.page}
                    itemsCountPerPage={paginationOptions.sizePerPage}
                    totalItemsCount={paginationOptions.totalSize}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange.bind(this)}
                />
            </div>
            {/* <SimpleSnackbar
                showSnackBar={showSnackBar}
                setShowSnackBar={setShowSnackBar}
            /> */}
            </div>
            </div>

    )
}
