/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {Dropdown} from "react-bootstrap";
import {useHtmlClassService} from "../../../../_metronic/layout";
import {DropdownMenu4, DropdownCustomToggler} from "../../../../_metronic/_partials/dropdowns";
import {KTUtil} from "../../../../_metronic/_assets/js/components/util";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import logo from '../CommonApplication/SupplierSelection/SupplierLogo.png';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 250,
    maxHeight: 300
  },
  media: {
    // maxHeight: '150px',
    // width: "100%",
    objectFit: "contain",
    // paddingTop: '56.25%', // 16:9
  },
  header: {
    textAlign: 'center',
    paddingBottom: '8px'
  },
  textContent: {
    textAlign: 'center',
    padding: '8px'
  },
  center: {
    textAlign: 'center',
  },
  centerButton: {
    justifyContent: 'center'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export function SuppliersCard({ className, supplier, setSupplier, setIsSupplerSelected, handleSupplierChange }) {
  const classes = useStyles();
  const uiService = useHtmlClassService();


  return (
      <div>
        <Card className={classes.card} >
          <CardActionArea>
            {typeof (supplier.logoPath) !== 'undefined' ?
                <CardMedia
                    component="img"
                    className={classes.media}
                    image={supplier.logoPath}
                    title={supplier.entityName}
                />
                :
                <CardMedia
                    className={classes.media}
                    image={logo}
                    title={supplier.entityName}
                />
            }
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {supplier.entityName}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {supplier.physicalAddress + ', ' + supplier.physicalCity + ', ' + supplier.physicalZipcode}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <button type="button" className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"
                    onClick={()=>{
                        handleSupplierChange(supplier)
                        // setSupplier(supplier);
                        // setIsSupplerSelected(true)
                        // console.log(supplier);
                    }}>Order</button>
            {/*<Button size="small" color="primary">*/}
            {/*  Share*/}
            {/*</Button>*/}
            {/*<Button size="small" color="primary">*/}
            {/*  Learn More*/}
            {/*</Button>*/}
          </CardActions>
        </Card>


        {/*<div className={`card card-custom ${className}`}>*/}
        {/*  /!* Header *!/*/}
        {/*  <div className="card-header border-0 pt-5">*/}
        {/*    <h3 className="card-title font-weight-bolder ">{supplier.entityName}</h3>*/}
        {/*    /!*<div className="card-toolbar">*!/*/}
        {/*    /!*  <Dropdown className="dropdown-inline" drop="down" alignRight>*!/*/}
        {/*    /!*    <Dropdown.Toggle*!/*/}
        {/*    /!*        as={DropdownCustomToggler}*!/*/}
        {/*    /!*    id="dropdown-toggle-top">*!/*/}
        {/*    /!*      <i className="ki ki-bold-more-hor" />*!/*/}
        {/*    /!*    </Dropdown.Toggle>*!/*/}
        {/*    /!*    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">*!/*/}
        {/*    /!*      <DropdownMenu4 />*!/*/}
        {/*    /!*    </Dropdown.Menu>*!/*/}
        {/*    /!*  </Dropdown>*!/*/}
        {/*    /!*</div>*!/*/}
        {/*  </div>*/}
        {/*  /!* Body *!/*/}

        {/*  <div className="card-body d-flex flex-column">*/}
        {/*    <Card className={classes.card}>*/}

        {/*    {typeof(supplier.logoPath) !== 'undefined' ?*/}
        {/*      <CardMedia*/}
        {/*      className={classes.media}*/}
        {/*      image={supplier.logoPath}*/}
        {/*      title="Supplier Logo"*/}
        {/*    />*/}
        {/*    :*/}
        {/*    <CardMedia*/}
        {/*      className={classes.media}*/}
        {/*      image={logo}*/}
        {/*      title="Supplier Logo"*/}
        {/*    />*/}
        {/*    }*/}
        {/*    </Card>*/}
        {/*    /!* <div className="flex-grow-1">*!/*/}
        {/*    /!*  /!*<div id="kt_mixed_widget_14_chart" style={{height: "200px"}}></div>*!/*!/*/}
        {/*    /!*  <Col xs={6} md={4}>*!/*/}
        {/*    /!*    <Image src="https://sourcingbro.com/wp-content/uploads/2017/05/how_to_find_suppliers_in_China-1024x443.jpg" rounded />*!/*/}
        {/*    /!*  </Col>*!/*/}
        {/*    /!*</div> *!/*/}
        {/*    <div className="pt-5">*/}
        {/*      <p className="text-center font-weight-normal font-size-lg pb-7">*/}
        {/*        {supplier.physicalAddress + ', ' + supplier.physicalCity + ', ' + supplier.physicalZipcode}*/}
        {/*      </p>*/}
        {/*      <button type="button" className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"*/}
        {/*              onClick={()=>{*/}
        {/*                setSupplier(supplier);*/}
        {/*                console.log(supplier);*/}
        {/*              }}>Order</button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
  );
}
