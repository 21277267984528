import { ADD_COMPANY, GET_COMPANIES } from "../constants";


const initialState = {
    companies: [],
    companyId: ''
};

export default function companyReducer(state = initialState, action) {
    let companies = [];
    let company = null;
    switch (action.type) {
        case GET_COMPANIES:
            companies = action.payload ? action.payload : state.companies;
            return Object.assign({}, state, {
                companies: companies
            });
        case ADD_COMPANY:
            company = action.payload ? action.payload : company;
            if (company) {
                companies = [...state.companies, company]

                return Object.assign({}, state, {
                    companies: companies,
                    companyId: company._id
                });
            }
            return state;

        default:
            return state;
    }
}