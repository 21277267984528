import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState, useMemo} from "react";
import {injectIntl} from "react-intl";
import {Button, Card, Table} from "react-bootstrap";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OrderFavoritesInputField from "./OrderFavoritesInputField"
import {
    getProducts,
    fetchApprovedSuppliersCategories,
    getFavorite,
    fetchCompanies,
    updateFavorites,
    updateFavoritesSequence
} from "./crud/ordersFavoritesCrud";
import {
    FilledInput,
    FormControl,
    InputLabel, MenuItem,
    Select,
    ListSubheader,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./columnFormatters";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import OrderFavoritesProductSequenceEdit from "./OrderFavoritesProductSequenceEdit";
import Link from "@material-ui/core/Link";

const useStyles2 = makeStyles(theme => ({
   root: {
       width: '100%',
       marginTop: theme.spacing(3)
   },
    table: {
       minWidth: 500,
    },
    tableWrapper: {
       overflowX: 'auto'
    }
}));

const initialData = {
    data: [],
    totalCount: 0,
    pageSize: 5
}

const sizePerPageList = [
    {text: "10", value: 10},
    {text: "20", value: 20},
    {text: "50", value: 50}
]

function OrderFavoritesEdit(props){

    const classes = useStyles2();

    const {userId, accessToken, isSeller} = useSelector(
        ({auth}) => ({
            userId: auth.user.id,
            accessToken: auth.authToken,
            isSeller: auth.isSeller,
        })
    );

    const history = useHistory();
    const {id} = useParams();

    const [data, setData] = useState(initialData);

    const [products]  = useState([]);
    const [favoriteList, setFavoriteList] = useState({});
    const [favorites, setFavorites] = useState([]);
    const [flProducts, setFlProducts] = useState([]);
    const [favoritesMap, setFavoritesMap] = useState(new Map());
    const [name, setName] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [fetchingProducts, setFetchingProducts] = useState(false);
    const [isFetchingCompanies, setIsFetchingCompanies] = useState(true);
    const [isEditSequence, setIsEditSequence] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [locations, setLocations] = useState([]);
    const [defaultCompanyId, setDefaultCompanyId] = useState("");
    const [defaultLocationId, setDefaultLocationId] = useState("");
    const [searchText, setSearchText] = useState("");

    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            formatter: columnFormatters.ProductNameFormatter
        },
        {
            dataField: "description",
            text: "Description",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.DescriptionFormatter
        },
        {
            dataField: "category",
            text: "Category",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CategoryFormatter
        },
        // {
        //     dataField: "supplier.name",
        //     text: "Supplier",
        //     sort: true,
        //     sortCaret,
        //     headerSortingClasses,
        //     formatter: columnFormatters.SupplierFormatter
        // },
        {
            dataField: "favorites",
            text: "Favorite",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.FavoriteFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    addToFavorites(row);
                }
            }
        },
        {
            dataField: "favoritePrice",
            text: "Favorite Price",
            sort: false,
            // sortCaret,
            headerSortingClasses,
            formatter: (cellContent, row) => ( 
                <columnFormatters.FavoritePriceFormatter
                    row = {row}
                    handleBlur = {handleFavoritePriceChange}
                />
            )
        }
    ];

    const pagination = {custom: true, sizePerPageList: sizePerPageList};
    const sorting = [{dataField: "name", order: "asc"}];
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center";

    const fetchProducts = async (queryParams) => {

        if(selectedSupplier._id == null){
            return;
        }


        getProducts(queryParams, accessToken, selectedSupplier._id).then(response => {
            console.log("Response: ", response)
            
            if(response.status === 200){
                const data = {
                    data: response.data.data,
                    totalCount: response.data.totalCount,
                    pageSize: 5
                };

                data.data.forEach(d => {
                    const foundItem = favoritesMap.get(d._id)
                    if(foundItem != null){
                        d.favorite = true
                        d.favoritePrice = foundItem.favoritePrice
                    } else{
                        d.favorite = false
                    }
                });


                setData(data);
                setFetchingProducts(false);
            }
        });
    }

    const fetchFlCompanies = async (supplierId, fl) => {
        await fetchCompanies(accessToken, supplierId).then(response =>{
            // console.log("Companies: ", response)
            const fetchedCompanies = response.data
            setCompanies(fetchedCompanies)

            if(fl.defaultCompanyId && fl.defaultCompanyId !== ""){
                const foundCompany = fetchedCompanies.find(company => company._id == fl.defaultCompanyId);

                if(foundCompany){
                    setDefaultCompanyId(foundCompany._id)
                    setLocations(foundCompany.locations)
                }

                if(fl.defaultLocationId && fl.defaultLocationId !== ""){
                    const foundLocation = foundCompany.locations.find(loc => loc._id == fl.defaultLocationId);
                    if(foundLocation){
                        setDefaultLocationId(foundLocation._id)
                    }
                }

                
            }

            setIsFetchingCompanies(false)
            
        }).catch(e=>{console.log(e)})
    }

    // const fetchSuppliers = () => {
    //     fetchApprovedSuppliersCategories(userId, accessToken).then(response =>{
    //         setSuppliers(response.data)
    //     })
    // }

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.name = searchText ?? "";


        newQueryParams.filter = filter;
        newQueryParams.supplierId = selectedSupplier._id ?? "";
        newQueryParams.pageSize = filter.pageSize ?? 10;

        return newQueryParams;
    }

    const initialFilter = {
        filter: {
            name: "",
            supplierId: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    }

    // 2. Add products to favorite list
    const addToFavorites = (product) => {
        const tempData = data;
        let favoriteFlag = null;
        tempData.data.forEach(p => {
            if(p.favorite == null){
                p.favorite = false
            }

            if(product._id === p._id){
                p.favorite = !p.favorite;
                favoriteFlag = p.favorite;
                return;
            }
        });
        setData({...data, data: [...tempData.data]})


        const tempFavorites = favorites;
        const tempFavoritesMap = favoritesMap;

        const index = tempFavorites.findIndex(p => p._id === product._id);
        if(favoriteFlag){
            tempFavorites.push(product);
            tempFavoritesMap.set(product._id, product);
        }else{
            const out = tempFavorites.splice(index, 1);
            tempFavoritesMap.delete(product._id);
        }

        console.log(tempFavorites);

        setFavorites([...tempFavorites]);
        setFavoritesMap(tempFavoritesMap);
    }

    const handleFavoritePriceChange = (newValue, product) => {

        const newFavoritePrice = parseFloat(newValue)
        const favoritesMapArr = Array.from(favoritesMap.values());

        let tempFavorites = favoritesMapArr.filter(currProd => currProd._id != product._id);
        tempFavorites.push({...product, favoritePrice: newFavoritePrice});
        setFavorites([...tempFavorites]);

        const tempFavoritesMap = favoritesMap;
        tempFavoritesMap.set(product._id, {...product, favoritePrice: newFavoritePrice});
        setFavoritesMap(tempFavoritesMap);

        const tempData = data;
        tempData.data.forEach(p => {
            if(product._id === p._id){
               p.favorite = true
               p.favoritePrice = newFavoritePrice
            }
        });
        setData({...data, data: [...tempData.data]})
    }
    // 3. Post List with all the products on the list
    const handleSaveList = async () => {
        const favorite = {
            name: name,
            products: favorites,
            defaultCompanyId: defaultCompanyId,
            defaultLocationId: defaultLocationId
        }

        if (favorite.name === null || favorite.name === ""){
            //TODO: This can be moved to a generic Context API Component
           fireAlert("Validation Error", "The name field is required");
           return;
        }else if(favorite.products.length <= 0){
            fireAlert("Validation Error", "You need to select at least one product");
            return;
        }else if(selectedSupplier._id == null){
            fireAlert("Validation Error", "The supplier field is required");
        }

        const response = await updateFavoritesSequence(id, favorite, accessToken)

        console.log("Updated", response.status);
        if(response.status !== 200){
            fireAlert("Server Error", response.data)
            return;
        }

        history.push('/orders-favorites');
    }

    const handleSaveListSequence = async () => {
        const favorite = {
            name: name,
            products: [...flProducts],
            defaultCompanyId: defaultCompanyId,
            defaultLocationId: defaultLocationId
        }

        if (favorite.name === null || favorite.name === ""){
            //TODO: This can be moved to a generic Context API Component
           fireAlert("Validation Error", "The name field is required");
           return;
        }else if(favorite.products.length <= 0){
            fireAlert("Validation Error", "You need to select at least one product");
            return;
        }else if(selectedSupplier._id == null){
            fireAlert("Validation Error", "The supplier field is required");
        }

        const response = await updateFavorites(id, favorite, accessToken)

        if(response.status !== 200){
            fireAlert("Server Error", response.data)
            return;
        }

        setFlProducts([])

        history.push('/orders-favorites');
    }

    useEffect(() => {
        const queryParams = prepareFilter(initialFilter, {});
        // setFetchingProducts(true);
        fetchProducts(queryParams).then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSupplier])

    useEffect(() => {

        getFavorite(id, accessToken).then(response => {
            
            const fl = {
                id: response.data._id,
                name: response.data.name,
                products: response.data.products,
                defaultCompanyId: response.data.defaultCompanyId ? response.data.defaultCompanyId : "",
                defaultLocationId: response.data.defaultLocationId ? response.data.defaultLocationId : "",
            }
            setFavoriteList(fl);

            console.log("Fav: ", fl)

            setFavorites(response.data.products);

            const favoritesTemp = new Map();

            response.data.products.forEach(p => {
                favoritesTemp.set(p._id, p)
            })

            setFavoritesMap(favoritesTemp);

            setSelectedSupplier(response.data.supplier[0]);
            setName(response.data.name);

            const queryParams = prepareFilter({}, {});
            setFetchingProducts(true);
            
            fetchFlCompanies(response.data.supplier[0]._id, fl)
            fetchProducts(queryParams).then();
        });

        // fetchApprovedSuppliersCategories(userId, accessToken).then(response => {
        //     setSuppliers(response.data);
        // })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const tempProducts = products;
       tempProducts.forEach((p, index) => {
          if(favoritesMap.get(p._id) !== null){
              tempProducts[index].favorite = true;
          }
       });

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favoritesMap])

    const handleDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle("");
        setAlertText("");
    }

    const fireAlert = (title, text) => {
       setAlertTitle(title);
       setAlertText(text);
       setAlertOpen(true)
    }

    const containsText = (text, searchText) => {
        // console.log(companies)
        if(text){
            return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }
        
    }

    const displayedOptions = useMemo(
        () => companies.filter((company) => containsText(company.businessName, searchText) || containsText(company.sageCustomerId, searchText)),
        [searchText, companies]
    );

    const handleCompanyChange = async (element) => {
        const clickedCompany = companies.find(company => company._id == element.target.value);
        const clickedCompanyLocations = clickedCompany.locations;

        setLocations(clickedCompanyLocations)
        setDefaultCompanyId(clickedCompany._id)

        if(clickedCompanyLocations.length == 1){
            console.log("Location: ", clickedCompanyLocations)
            setDefaultLocationId(clickedCompanyLocations[0]._id)
        } else {
            setDefaultLocationId("")
        }
    }

    const handleLocationChange = (element) => {
        const clickedLocation = locations.find(location => location._id == element.target.value);

        setDefaultLocationId(clickedLocation._id)
    }

    return (
       <>
            <Link className="btn btn-primary mb-4" href="/orders-favorites">Back</Link>
            {
                isFetchingCompanies ? 
                    <div className="row justify-content-center">
                        <span className="spinner spinner-primary spinner-lg"></span>
                    </div>
                :
                <Card>
                    <Card.Header title="Add to Favorites List">
                        <h1>Favorites List</h1>
                        <OrderFavoritesInputField
                            data={name}
                            setData={setName}
                            placeholder={'Name'}
                            name={'name'}
                        />
                        <h5 className={'my-4'}>Products Selected: {favorites.length}</h5>
                        <h5 className={'my-4'}>Supplier: {selectedSupplier.entityName}</h5>
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <FormControl variant="filled" style={{width: "100%"}}>
                                        {
                                            isSeller ? 
                                                <InputLabel>Customer</InputLabel>
                                                :
                                                <InputLabel>Company</InputLabel>
                                        }
                                        <Select
                                            MenuProps={{ autoFocus: false }}
                                            value={defaultCompanyId}
                                            style={{minWidth:"200px"}}
                                            input={<FilledInput name="company" id="company"
                                                onChange={(element) =>{
                                                    handleCompanyChange(element)
                                                }}
                                                type="text"
                                                className="form-control form-control-solid h-auto mt-3"
                                            />}>
                                                <ListSubheader>
                                                    <TextField
                                                    size="small"
                                                    // Autofocus on textfield
                                                    autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== "Escape") {
                                                        // Prevents autoselecting item while typing (default Select behaviour)
                                                        e.stopPropagation();
                                                        }
                                                    }}
                                                    />
                                                </ListSubheader>
                                                {displayedOptions.map((companyItem,index) =>(
                                                    <MenuItem key={companyItem._id} selected={index === 0 } value={companyItem._id}>{companyItem.businessName}</MenuItem>
                                                ))
    
                                                }
    
                                            {/* {fl?.companies?.map((companyItem,index) =>(
                                                <MenuItem selected={index === 0 } value={companyItem._id}>{companyItem.businessName}</MenuItem>
                                            ))} */}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="my-3">
                                    <FormControl variant="filled" style={{width: "100%"}}>
                                        <InputLabel>Location</InputLabel>
                                        <Select
                                            value={defaultLocationId}
                                            style={{minWidth:"200px"}}
                                            input={<FilledInput name="location" id="location"
                                                onChange={(element) =>{
                                                    handleLocationChange(element)
                                                }}
                                                type="text"
                                                className="form-control form-control-solid h-auto"
                                            />}>
                                            {locations.map((locationItem,index) =>(
                                                <MenuItem selected={index === 0 } value={locationItem._id}>{locationItem.locationName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-between mb-3">
                            <div className="d-flex flex-wrap">
                            {
                                isEditSequence ?
                                    <button className="btn btn-primary" onClick={() => setIsEditSequence(false)}>Add Products</button>
                                :
                                    <button className="btn btn-primary" onClick={() => setIsEditSequence(true)}>Edit Sequence</button>
                            }
                            </div>
                            
                            <div className="d-flex">
                                {
                                    isEditSequence ?
                                        <button className="btn btn-primary" onClick={() => handleSaveListSequence()}>Update List</button>
                                    :
                                        <button className="btn btn-primary" onClick={() => handleSaveList()}>Update List</button>
                                }
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {
                            isEditSequence ? 
                                <OrderFavoritesProductSequenceEdit 
                                    accessToken={accessToken}
                                    favoriteListId={favoriteList.id}
                                    flProducts={flProducts}
                                    setFlProducts={setFlProducts}
                                />
                            :
                                <PaginationTable
                                    data={data}
                                    columns={columns}
                                    pagination={pagination}
                                    isLoading={false}
                                    sorting={sorting}
                                    tableClasses={tableClasses}
                                    fetcher={fetchProducts}
                                    filterFunction={prepareFilter}
                                    initialFilter={initialFilter}
                                />
                        }
                        
    
                    </Card.Body>
                </Card>

            }
           

           <div>
               <Dialog
                   open={alertOpen}
                   onClose={handleDialogClose}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
               >
                   <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                   <DialogContent>
                       <DialogContentText id='alert-dialog-description'>
                           {alertText}
                       </DialogContentText>
                   </DialogContent>
                   <DialogActions>
                      <Button onClick={handleDialogClose} color="primary" autoFocus>
                          OK
                      </Button>
                   </DialogActions>
               </Dialog>
           </div>
       </>
    )
}

export default injectIntl(OrderFavoritesEdit);