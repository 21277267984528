import { GET_APPLICATIONS_BY_COMPANY_ID_LIST, GET_DASHBOARD_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION, GET_TOTAL_AUTHORIZED_CREDIT } from '../constants';
import { api } from './../index';

export function getTotalAuthorizedCredit(dashboardApplicationList) {
    return function (dispatch) {
        dispatch({ type: GET_TOTAL_AUTHORIZED_CREDIT, payload: dashboardApplicationList });
    }
}

export function getApplicationsByCompanyIdList() {
    // console.log(companyIdList)
    return function (dispatch) {
        api.get("/applications/company")
            .then(response => {
                dispatch({ type: GET_APPLICATIONS_BY_COMPANY_ID_LIST, payload: response.data });
            })
    }
}

export function getApplicationsByCompanyIdListPagination(queryParams) {
    const options = {
        validateStatus: status => status < 400,
        params: { queryParams }
    }
    return function (dispatch) {
        api.get(`/applications/company/pagination`, options)
            .then(response => {
                // console.log("Res actions: ", response)
                dispatch({ type: GET_DASHBOARD_APPLICATIONS_BY_COMPANY_ID_LIST_PAGINATION, payload: response.data });
            })
    }
}
