import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

export function ViewDetailsFormatter(cellContent, row, rowIndex, {viewDetails,setIsOrderDetails, setIsOrderFound}){


    return (
        <>
            <a title="Action" className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            href="javascript:void(0);"
               onClick={() =>{
                   viewDetails(row)
                   setIsOrderDetails(true)
                   setIsOrderFound(true)
               }}
            >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Archive.svg")}/>
                    </span>
            </a>
        </>
    )
}