import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";

export function ActionColumnFormatter(cellContent, row, rowIndex, {isItemUpdating}){

    const setAction = () =>{
        if(isItemUpdating){
            return (
                <div className="row justify-content-center mt-20">
                    <span className="ml-3 spinner spinner-lg spinner-primary"></span>
                </div>
            )
        } 
        return (
            <a title="Add To Favorites" className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            href="javascript:void(0);"
            >
                <span title="isItemUpdating" className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG title="isItemUpdating" src={toAbsoluteUrl("/media/svg/icons/Media/Forward.svg")}/>
                </span>
            </a>
        )
    }


    return (
        <>
            {setAction()}
        </>
    )
}