import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {fetchPriceList, fetchProduct, fetchSuppliers, fetchUser} from "../AdminPagesCrud";
import {useParams} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PriceListProducts from "./PriceListProducts";
import PriceListClients from "./PriceListClients";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import PriceListBasic from "./PriceListBasic";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

export function PriceListInformation({priceListId}) {

    const initialValues = {
        name: "",
        supplierId: "",
        supplierName: "",
        products: [],
        clients: [],
    };

    const {id} = useParams();
    const [priceList, setPriceList] = useState(initialValues);
    const [supplierName, setSupplierName] = useState('')
    const [products, setProducts] = useState([])
    const [clients, setClients] = useState([])
    const [edit, setEdit] = useState(false);


    function handleEdit(){
        setEdit(true);
    }

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )


    useEffect(() => {
        let ID = id;
        if(priceListId){
            ID = priceListId
        }
        fetchPriceList(ID, accessToken).then(response => {
            console.log("This is the response", response)
            setPriceList(response.data)
            
            fetchSuppliers(response.data.supplierId, accessToken).then(response => {
                setSupplierName(response.data.entityName)
            });

            // let productsTemp =[]
            // let clientsTemp = []

            // response.data.products.forEach(p => {
            //     fetchProduct(p.productId, accessToken).then(response => {
            //         let product = response.data
            //         product.newPrice = p.newPrice
            //         productsTemp.push(product)
            //     })
            // })
            // setProducts(productsTemp);

            // response.data.clients.forEach(c => {
            //     console.log("client id: ", c)
            //     fetchUser(c, accessToken).then(response => {
            //         clientsTemp.push(response.data)
            //     })
            // })
            // setClients(clientsTemp);
        });



    },[])


    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <Paper className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                centered
            >
                <Tab label="About" wrapped />
                <Tab label="Products" wrapped />
                <Tab label="Clients" wrapped/>
            </Tabs>
            {value === 0 && <TabContainer> <PriceListBasic priceList={priceList} setPriceList={setPriceList} supplier={supplierName} setSupplierName={setSupplierName} setClients={setClients} setProducts={setProducts}/> </TabContainer>}
            {value === 1 && <TabContainer> <PriceListProducts priceList={priceList} supplier={supplierName} products={products}/> </TabContainer>}
            {value === 2 && <TabContainer> <PriceListClients priceList={priceList} supplier={supplierName} clients={clients}/> </TabContainer>}
        </Paper>
    )
}