

export const actionTypes ={
    CatchError: "Error Action",
    StartCall: "Start Call Action",
    ClientsFetched: "Clients Fetched Action",
    ClientFetched: "Client Fetched Action",
    ClientCreated: "Client Created Action",
    ClientUpdated: "Client Updated Action",
    ClientDeleted: "Client Deleted Action",
    ClientsDeleted: "Clients Deleted Action",
    ClientsStatusUpdated: "Clients Status Updated"
}

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    clientsForEdit: undefined,
    lastError: null,
}

export const callTypes = {
    list: "list",
    action: "action"
}


export const supplierClients = (state = initialState,action) => {
    switch(action.type){
        case actionTypes.CatchError: {
            const error = `${action.type}: ${action.payload.error}`;
            let listLoading = state.listLoading;
            let actionsLoading = state.actionsLoading;

            if(action.payload.callType === callTypes.list){
                listLoading = false;
            }else{
                actionsLoading = false;
            }

            return {...state,listLoading,actionsLoading,error}
        }

        case actionTypes.StartCall: {
            const error = null;
            let listLoading = state.listLoading;
            let actionsLoading = state.actionsLoading;
            // console.log("Payload",action.type)

            if(action.payload.callType === callTypes.list){
                listLoading = true;
            }else{
                actionsLoading = true;
            }

            return {...state,listLoading,actionsLoading,error}
        }

        case actionTypes.ClientsFetched:{
            // console.log("Payload",action.type);
            const {totalCount, entities} = action.payload;
            const listLoading = false;
            const error = null;

            return {...state,listLoading,error,totalCount,entities}
        }
        default:{
            // console.log("Payload",action.type);
            return state;
        }
    }
};


export const supplierClientsActions = {
    startCall: () =>({type: actionTypes.StartCall,payload:{callType: callTypes.action}}),
    fetchClients: (query) => {

        const entities = [
            {
                id: 123,
                name: "Company A",
                locations: "San Juan, PR",
                shippingAddress: "1 Calle A, 00901",
                creditDueDate: "Weekly",
                totalCredit: "$1500",
                balance: "Payment Due",
            },
            {
                id: 124,
                name: "Company B",
                locations: "Guaynabo, PR",
                shippingAddress: "2 Calle B, 00969",
                creditDueDate: "Quarterly",
                totalCredit: "$40,000",
                balance: "Submitted",
            },
            {
                id: 123,
                name: "Company A",
                locations: "San Juan, PR",
                shippingAddress: "1 Calle A, 00901",
                creditDueDate: "Weekly",
                totalCredit: "$1500",
                balance: "Deposited",
            },
            {
                id: 124,
                name: "Company B",
                locations: "Guaynabo, PR",
                shippingAddress: "2 Calle B, 00969",
                creditDueDate: "Quarterly",
                totalCredit: "$40,000",
                balance: "Submitted",
            },
            {
                id: 123,
                name: "Company A",
                locations: "San Juan, PR",
                shippingAddress: "1 Calle A, 00901",
                creditDueDate: "Weekly",
                totalCredit: "$1500",
                balance: "Payment Due",
            },
            {
                id: 124,
                name: "Company B",
                locations: "Guaynabo, PR",
                shippingAddress: "2 Calle B, 00969",
                creditDueDate: "Quarterly",
                totalCredit: "$40,000",
                balance: "Submitted",
            },
            {
                id: 123,
                name: "Company A",
                locations: "San Juan, PR",
                shippingAddress: "1 Calle A, 00901",
                creditDueDate: "Weekly",
                totalCredit: "$1500",
                balance: "Payment Due",
            },
            {
                id: 124,
                name: "Company B",
                locations: "Guaynabo, PR",
                shippingAddress: "2 Calle B, 00969",
                creditDueDate: "Quarterly",
                totalCredit: "$40,000",
                balance: "Submitted",
            },
            {
                id: 123,
                name: "Company A",
                locations: "San Juan, PR",
                shippingAddress: "1 Calle A, 00901",
                creditDueDate: "Weekly",
                totalCredit: "$1500",
                balance: "Payment Due",
            },
            {
                id: 124,
                name: "Company B",
                locations: "Guaynabo, PR",
                shippingAddress: "2 Calle B, 00969",
                creditDueDate: "Quarterly",
                totalCredit: "$40,000",
                balance: "Submitted",
            },
        ]

        const totalCount = 20;


        return {type: actionTypes.ClientsFetched, payload: {entities,totalCount}}
    }
}

export default supplierClients;

