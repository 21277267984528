// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function StatusFormatter(cellContent, row) {
    const getStatusLabel = (status) => {
        // console.log(status)
        if(status === "Processed") {
            return (
                <span className="label label-lg label-light-primary label-inline">
                    {status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
            )
        } else if (status === "Submitted") {
            // console.log("here")
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "In Transit") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
            )
        } else if (status === "Delivered") {
            return (
                <span className="label label-lg label-light-success label-inline">
                    {status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
            )
        } else if (status === "Denied") {
            return (
                <span className="label label-lg label-light-danger label-inline">
                    {status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
            )
        } else{
            return (
                <span className="label label-lg label-light-info label-inline">
                    {status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
            )
        }
    }

    return (
        <>
            {getStatusLabel(row.status)}
        </>
    );
}
