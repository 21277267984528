
import React from "react";

export function SelectFormatter(cellContent, row, rowIndex, {ordersToRouteSelected, setOrdersToRouteSelected}){

    const orderFound = ordersToRouteSelected.find(order => order == row._id)
    const isChecked = orderFound ? true : false

    return(
        <>
            <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">
                <input type="checkbox" checked={isChecked} name="" onClick={() => {

                    const orderFound = ordersToRouteSelected.find(order => order == row._id)
                    if(orderFound){
                        const newOrdersToRouteSelected = ordersToRouteSelected.filter(order => order != row._id)
                        setOrdersToRouteSelected(newOrdersToRouteSelected)
                    } else{
                        setOrdersToRouteSelected(oldOrdersToRouteSelected => [...oldOrdersToRouteSelected, row._id])
                    }

                }} />
                <span></span>
            </label>
        </>
    )
}