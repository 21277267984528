/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function BuyerMenu({ layoutProps }) {
  const { count } = useSelector(({ ordersCount }) => ({
    count: ordersCount,
  }));
  const { applicationsCount } = useSelector(({ applicationsCount }) => ({
    applicationsCount: applicationsCount,
  }));
  const storedUser = useSelector(state => state.auth.user)

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {
          storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
            null
          :
          <li
            className={`menu-item ${getMenuItemActive(
              "/commonApplication",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/commonApplication">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
              <span className="menu-text">Applications</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                {/*begin::3 Level*/}
                <li
                  className={`menu-item  ${getMenuItemActive(
                    "/commonApplication/draft"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/commonApplication/draft">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">{`Draft `}</span>

                    <span className="menu-label">
                      <span className="label label-lg label-light-primary label-inline">
                        {applicationsCount.draftCount}
                      </span>
                    </span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}

                {/*begin::3 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/commonApplication/submitted"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/commonApplication/submitted"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Submitted</span>
                    <span className="menu-label">
                      <span className="label label-lg label-light-primary label-inline">
                        {applicationsCount.submittedCount}
                      </span>
                    </span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}

                {/*begin::3 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/commonApplication/approved"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/commonApplication/approved">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Approved</span>
                    <span className="menu-label">
                      <span className="label label-lg label-light-primary label-inline">
                        {applicationsCount.approvedCount}
                      </span>
                    </span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}

                {/*begin::3 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/commonApplication/pending"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/commonApplication/pending">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Pending</span>
                    <span className="menu-label">
                      <span className="label label-lg label-light-primary label-inline">
                        {applicationsCount.pendingCount}
                      </span>
                    </span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}

                {/*begin::3 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/commonApplication/denied"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/commonApplication/denied">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Denied</span>
                    <span className="menu-label">
                      <span className="label label-lg label-light-primary label-inline">
                        {applicationsCount.deniedCount}
                      </span>
                    </span>
                  </NavLink>
                </li>
                {/*end::3 Level*/}
              </ul>
            </div>
          </li>
        }
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/orders",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
            </span>
            <span className="menu-text">Orders</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/*begin::3 Level*/}
              <li
                className={`menu-item  ${getMenuItemActive(
                  "/orders/notsubmitted"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/orders/notsubmitted">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{`Not Submitted `}</span>

                  <span className="menu-label">
                    <span className="label label-lg label-light-primary label-inline">
                      {count.notSubmittedCount}
                    </span>
                  </span>
                </NavLink>
              </li>
              {/*end::3 Level*/}

              

              <li
                className={`menu-item ${getMenuItemActive(
                  "/orders/submitted"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/orders/submitted">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Submitted</span>
                  <span className="menu-label">
                    <span className="label label-lg label-light-primary label-inline">
                      {count.submittedCount}
                    </span>
                  </span>
                </NavLink>
              </li>
              {/*begin::3 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/orders/received"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/orders/received">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Received</span>
                  <span className="menu-label">
                    <span className="label label-lg label-light-primary label-inline">
                      {count.receivedCount}
                    </span>
                  </span>
                </NavLink>
              </li>
              {/*end::3 Level*/}
              {/*begin::3 Level*/}
              {
                storedUser.tenant == process.env.REACT_APP_BIOWARE_TENANT || storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                  null
                :
                <>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/orders/processed"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/orders/processed">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Processed</span>
                      <span className="menu-label">
                        <span className="label label-lg label-light-primary label-inline">
                          {count.processedCount}
                        </span>
                      </span>
                    </NavLink>
                  </li>
                  
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/orders/intransit"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/orders/intransit">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">In Transit</span>
                      <span className="menu-label">
                        <span className="label label-lg label-light-primary label-inline">
                          {count.inTransitCount}
                        </span>
                      </span>
                    </NavLink>
                  </li>
                  
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/orders/delivered"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/orders/delivered">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Delivered</span>
                      <span className="menu-label">
                        <span className="label label-lg label-light-primary label-inline">
                          {count.deliveredCount}
                        </span>
                      </span>
                    </NavLink>
                  </li>
                </>
              }
              {/*end::3 Level*/}
              {
                storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                  null
                :
                <>
                
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/orders/quote"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/orders/quote">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Quote</span>
                      <span className="menu-label">
                        <span className="label label-lg label-light-primary label-inline">
                          {count.quoteCount}
                        </span>
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/orders/backorder"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/orders/backorder">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Back Order</span>
                      <span className="menu-label">
                        <span className="label label-lg label-light-primary label-inline">
                          {count.backOrderCount}
                        </span>
                      </span>
                    </NavLink>
                  </li>
                </>
              }
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/orders-favorites")}`}
          aria-haspopup={"true"}
          data-menu-toggle={"hover"}
        >
          <NavLink to={"/orders-favorites"} className={"menu-link"}>
            <span className={'svg-icon menu-icon'}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
            </span>
            <span className={'menu-text'}>Favorite Orders</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/invoices", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/invoices">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
            </span>
            <span className="menu-text">Invoices</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {
          storedUser.tenant == process.env.REACT_APP_BIOWARE_TENANT || storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
            null
          :
            <li
              className={`menu-item ${getMenuItemActive("/suppliers", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/suppliers">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box3.svg")} />
                </span>
                <span className="menu-text">Suppliers</span>
              </NavLink>
            </li>
        }

        {/*begin::1 Level*/}
        {
          storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ?
            null
          :
            <li
              className={`menu-item ${getMenuItemActive("/mycompanies", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/mycompanies">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")} />
                </span>
                {
                  storedUser.tenant == process.env.REACT_APP_BIOWARE_TENANT || storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
                    <span className="menu-text">My Customers</span>
                  :
                    <span className="menu-text">My Companies</span>
                }
                
              </NavLink>
            </li>
        }
        
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {
          storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
            null
          :
          <li
            className={`menu-item ${getMenuItemActive("/mylocations", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/mylocations">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
              </span>
              <span className="menu-text">My Locations</span>
            </NavLink>
          </li>
        }

        {
          storedUser.tenant == process.env.REACT_APP_WAHMEY_TENANT ? 
            null
          :
          <li
            className={`menu-item ${getMenuItemActive("/reports", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/reports">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
              </span>
              <span className="menu-text">My Reports</span>
            </NavLink>
          </li>
        }
        {/* <li className={`menu-item`} aria-haspopup="true">
          <a
            className="menu-link"
            href={ process.env.REACT_APP_HTTP + storedUser.tenant + process.env.REACT_APP_INVENTORY_URL }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Box.svg")} />
            </span>
            <span className="menu-text">My Inventory</span>
          </a>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
                    className={`menu-item ${getMenuItemActive("/accountsettings", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/accountsettings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}/>
            </span>
                        <span className="menu-text">Account Settings</span>
                    </NavLink>
                </li> */}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
              className={`menu-item ${getMenuItemActive("/builder", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Layout Builder</span>
            </NavLink>
          </li> */}
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
