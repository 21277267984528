
import React from "react";

export function CompanyFormatter(cellContent, row){

    return (
        <>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                {`${row.companyName}`}
            </span>
            <span className="text-muted font-weight-bold">
                {`${row.locationAddressCity}`}
            </span>
        </>
    )
}