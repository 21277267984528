import React, {useState, useEffect} from 'react';
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import useTheme from "@material-ui/core/styles/useTheme";
import PropTypes from 'prop-types';
import SupplierFavoriteItemsConfig from "../Suppliers/SupplierFavoriteItemsConfig";
import { fetchFavoriteProductsPagination } from "../AdminPagesCrud";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";

function SupplierFavoriteItems({ supplierId }) {
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [edit, setEdit] = useState(false)
    const [favoriteProductsData, setFavoriteProductsData] = useState([])
    const [data, setData] = useState(initialData)

    const { accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    // useEffect(() => {
    //     getPriceListProducts()

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ edit ])

    // const getFavoriteProducts = async () => {
    //     await fetchFavoriteProducts(priceList._id, accessToken).then(response => {
            
    //         setPriceListProducts(response.data)
    //     }).catch(error => console.log(error))
    // }

    const fetchFavoriteProductsPaginationTable = async (queryParams) => {
        await fetchFavoriteProductsPagination(supplierId, queryParams, accessToken).then(response => {

            console.log("~~~~~~~~DATA: ", response)
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    // function handleChangePage(event, newPage) {
    //     setPage(newPage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    // }

    const columns = [
        {
            dataField: "name",
            text: "Product",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        
        {
            dataField: "units",
            text: "Units",
        },
        {
            dataField: "unitSize",
            text: "Unit Size",
        },
        {
            dataField: "price",
            text: "Original Price",
        },
        {
            dataField: "price",
            text: "Price",
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {edit ?  <SupplierFavoriteItemsConfig supplierId={supplierId} setEdit={setEdit}
                    /> :
                <Card>
                    <CardHeader title="Favorite Products">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setEdit(true)}>
                                Edit
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            initialFilter={initialFilter}
                            tableClasses={tableClasses}
                            fetcher={fetchFavoriteProductsPaginationTable}
                            filterFunction={prepareFilter}
                            searchField={true}/>
                        
                    </CardBody>
                    {/*<AdminSnackbar*/}
                    {/*    message={'Price List  updated successfully'}*/}
                    {/*    setShowSnackbar={setSnackBarPL}*/}
                    {/*    showSnackbar={snackbarPL}*/}
                    {/*    variant={'success'}*/}
                    {/*/>*/}
                </Card>
            } 
        </>
    );
}

export default injectIntl(SupplierFavoriteItems);