import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import SupplierReportTable from './SupplierSelection/SupplierReportTable'

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
}));

export default function SubmitModal(props) {
  const [open, setOpen] = React.useState(false);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  console.log(props)

  const handleOpen = () => {
    let isSubmitApplications = true
    props.locationsCategories.forEach(suppliersCategories => {
      suppliersCategories.locations.forEach(location => {
        if(location.categories.length < 1){
          isSubmitApplications = false
        }
      })
    })
    props.newLocationsCategories.forEach(suppliersCategories => {
      suppliersCategories.locations.forEach(location => {
        if(location.categories.length < 1){
          isSubmitApplications = false
        }
      })
    })
    if(isSubmitApplications){
      setOpen(true);
    } else {
      console.log("Que lo que?")
      props.handleClickOpenCategoriesDialogue(true)
    }
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    props.handleSubmit()
    handleClose()
  }

  const classes = useStyles();

  return (
    <div>
        <button
        type="button"
        onClick={handleOpen}
        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
        >
            <span>{"Review & Submit"}</span>
        </button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            Submit common application to the following suppliers?
          </Typography>
          <SupplierReportTable selectedSuppliers={props.selectedSuppliers} handleChecked={props.handleChecked} existingClientList={props.existingClientList} newLocationsCategories={props.newLocationsCategories}/> 
          <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
              <span>{"Submit"}</span>
          </button>       
        </div>
      </Modal>
    </div>
  );
}