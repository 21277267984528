import React from "react";

export function ProductNameFormatter(cellContent, row){
    return (
        <>
            <span className={'text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg'}>
                {row.name}
            </span>
            <span className={'text-muted font-weight-bold'}>
                {row.sku}
            </span>
        </>
    )
}