import React from "react";

export function ApplicationsPageActionsColumnFormatter(cellContent, row, rowIndex, {selectedCompany}){

    return (
        <>
            <button 
            type="button"
            className="btn btn-success font-size-sm"
            >
                {selectedCompany._id !== row._id ? "Select" : "Selected"}
            </button>
        </>
    )
}