import React, {useEffect, useState} from "react";
// import { connect } from "react-redux";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";
import { getOrdersToRoute, getOrdersInQueue, getOrdersRouting, getOrdersCompleted, postOrdersToRoute, forceOrdersToRoute, forceOrdersInQueue, postAllOrdersToRoute } from "./routesCrud";
import RoutingPage from "./RoutingPage";
import Input from '@material-ui/core/Input';
import RouteRecordDetail from "./RouteRecordDetail";

function RoutesPage(props) {

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [data, setData] = useState(initialData)
    const [queueData, setQueueData] = useState(initialData)
    const [routingData, setRoutingData] = useState(initialData)
    const [completedData, setCompletedData] = useState(initialData)
    const [ordersToRouteSelected, setOrdersToRouteSelected] = useState([])
    const [ordersInQueueSelected, setOrdersInQueueSelected] = useState([])
    const [isOrdersForced, setIsOrdersForced] = useState(false)
    const [isRouting, setIsRouting] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [fromInvoiceDate, setFromInvoiceDate] = useState("")
    const [toInvoiceDate, setToInvoiceDate] = useState("")

    const [isRouteRecordDetailsClicked, setIsRouteRecordDetailsClicked] = useState(false)
    const [routeRecordDetails, setRouteRecordDetails] = useState({})

    const [isAllOrdersToRouteSelected, setIsAllOrdersToRouteSelected] = useState(false)
    const [isAllOrdersInQueueSelected, setIsAllOrdersInQueueSelected] = useState(false)

    const orderStatus = props.status

    let initialFilter = {
        filter: {
            
        },
        sortOrder: "desc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 50,
        fromInvoiceDate: fromInvoiceDate,
        toInvoiceDate: toInvoiceDate,
    }

    let initialInQueueFilter = {
        filter: {
            
        },
        sortOrder: "desc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 50,
        fromInvoiceDate: fromInvoiceDate,
        toInvoiceDate: toInvoiceDate,
    }

    const {user, accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const fetchOrdersToRoute = async (queryParams) => {
        // dispatch(getOrdersByUser(user.id, queryParams))
        getOrdersToRoute(queryParams, accessToken).then(response => {
            
            const data = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setData(data)

            if(isAllOrdersToRouteSelected){
                const newSelectedOrders = data.data.map(order => order._id)
                
                setOrdersToRouteSelected([
                    ...newSelectedOrders
                ])
            }
        }).catch(error => console.log(error))
    }

    const fetchOrdersInQueue = async (queryParams) => {
        // dispatch(getOrdersByUser(user.id, queryParams))
        getOrdersInQueue(queryParams, accessToken).then(response => {

            const queueData = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setQueueData(queueData)

            if(isAllOrdersInQueueSelected){
                const newSelectedOrders = queueData.data.map(order => order._id)
                
                setOrdersInQueueSelected([
                    ...newSelectedOrders
                ])
            }
        }).catch(error => console.log(error))
    }

    const fetchOrdersRouting = async (queryParams) => {
        // dispatch(getOrdersByUser(user.id, queryParams))
        
        getOrdersRouting(queryParams, accessToken).then(response => {
            
            const routingData = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setRoutingData(routingData)
        }).catch(error => console.log(error))
    }

    const fetchOrdersCompleted = async (queryParams) => {
        // dispatch(getOrdersByUser(user.id, queryParams))
        getOrdersCompleted(queryParams, accessToken).then(response => {
            console.log("~~~~~~", response.data)
            const completedData = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setCompletedData(completedData)
        }).catch(error => console.log(error))
    }

    useEffect(() => {

        getOrdersToRoute(initialFilter, accessToken).then(response=>{
            
            const data = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setData(data)
        }).catch(err => {
            console.log(err)
        })

        getOrdersInQueue(initialInQueueFilter, accessToken).then(response=>{
            
            const data = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setQueueData(data)
        }).catch(err => {
            console.log(err)
        })

        getOrdersRouting(initialFilter, accessToken).then(response => {

            const routingData = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setRoutingData(routingData)
        }).catch(error => console.log(error))

        getOrdersCompleted(initialFilter, accessToken).then(response => {

            const completedData = {
                data : response.data.data,
                totalCount : response.data.totalCount,
                pageSize : response.data.pageSize
            }
            setCompletedData(completedData)
        }).catch(error => console.log(error))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isOrdersForced, isRouting, fromInvoiceDate, toInvoiceDate])

    const handleRoutingAllClick = async () => {
        setIsSubmitDisabled(true)
        
        await postAllOrdersToRoute(accessToken).then(res => {
            setIsSubmitDisabled(false)
            setOrdersToRouteSelected([])
            setOrdersInQueueSelected([])
            setIsAllOrdersToRouteSelected(false)
            setIsAllOrdersInQueueSelected(false)
            setIsOrdersForced(!isOrdersForced)
            setIsRouting(true)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleRoutingClick = async () => {
        setIsSubmitDisabled(true)
        const data = {
            ordersToRoute: ordersToRouteSelected
        }
        
        await postOrdersToRoute(data, accessToken).then(res => {
            setIsSubmitDisabled(false)
            setOrdersToRouteSelected([])
            setOrdersInQueueSelected([])
            setIsAllOrdersToRouteSelected(false)
            setIsAllOrdersInQueueSelected(false)
            setIsOrdersForced(!isOrdersForced)
            setIsRouting(true)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleReturnToQueueClick = async () => {
        setIsSubmitDisabled(true)
        const data = {
            ordersToForceQueue: ordersToRouteSelected
        }
        
        await forceOrdersInQueue(data, accessToken).then(res => {
            setIsSubmitDisabled(false)
            setIsOrdersForced(!isOrdersForced)

            setOrdersToRouteSelected([])
            setOrdersInQueueSelected([])
            setIsAllOrdersToRouteSelected(false)
            setIsAllOrdersInQueueSelected(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleSendToRouteClick = async () => {
        setIsSubmitDisabled(true)
        const data = {
            ordersToForceRoute: ordersInQueueSelected
        }
        
        await forceOrdersToRoute(data, accessToken).then(res => {
            setIsSubmitDisabled(false)
            setIsOrdersForced(!isOrdersForced)

            setOrdersToRouteSelected([])
            setOrdersInQueueSelected([])
            setIsAllOrdersToRouteSelected(false)
            setIsAllOrdersInQueueSelected(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleContinueRoutingClick = async () => {
        
        setIsRouting(true)
    }

    const handleSelectAllToRouteClick = async (element) => {
        
        // console.log("Value: ", element.target.checked)
        const isSelectAll = element.target.checked

        if(isSelectAll){
            let currRouteOrders = [...ordersToRouteSelected]
            data.data.forEach(order => {
                currRouteOrders = currRouteOrders.filter(item => item != order._id)
            })
            const newSelectedOrders = data.data.map(order => order._id)
            
            setOrdersToRouteSelected([
                ...currRouteOrders,
                ...newSelectedOrders
            ])
            setIsAllOrdersToRouteSelected(true)
        } else{
            let currRouteOrders = [...ordersToRouteSelected]
            data.data.forEach(order => {
                currRouteOrders = currRouteOrders.filter(item => item != order._id)
            })
            
            setOrdersToRouteSelected([
                ...currRouteOrders
            ])
            setIsAllOrdersToRouteSelected(false)
        }
    }

    const handleSelectAllInQueueClick = async (element) => {
        
        const isSelectAll = element.target.checked

        if(isSelectAll){
            let currRouteOrders = [...ordersInQueueSelected]
            queueData.data.forEach(order => {
                currRouteOrders = currRouteOrders.filter(item => item != order._id)
            })
            const newSelectedOrders = queueData.data.map(order => order._id)
            
            setOrdersInQueueSelected([
                ...currRouteOrders,
                ...newSelectedOrders
            ])

            setIsAllOrdersInQueueSelected(true)
        } else{
            let currRouteOrders = [...ordersInQueueSelected]
            queueData.data.forEach(order => {
                currRouteOrders = currRouteOrders.filter(item => item != order._id)
            })
            
            setOrdersInQueueSelected([
                ...currRouteOrders
            ])

            setIsAllOrdersInQueueSelected(false)
        }
    }

    const columns = [
        {
            headerClasses: 'text-center',
            text: "Select",
            sort: false,
            formatter: columnFormatters.SelectFormatter,
            formatExtraData:{
                ordersToRouteSelected: ordersToRouteSelected,
                setOrdersToRouteSelected: setOrdersToRouteSelected
            }
        },
        {
            headerClasses: 'text-center',
            dataField: "routeName",
            text: "Route",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "orderNumber",
            text: "Order Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.OrderNumberFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "client",
            text: "Client",
            sort: false,
            formatter: columnFormatters.ClientFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "vendor",
            text: "Vendor",
            sort: false,
            formatter: columnFormatters.VendorFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "totalProductQty",
            text: "Qty of Cases",
            sort: false,
            formatter: columnFormatters.TotalProductQuantityFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "submissionDate",
            text: "Created On",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.SubmissionDateFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "shipDate",
            text: "Ship Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ShipDateFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "routeStatus",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteStatusFormatter
        },
    ];

    const queueColumns = [
        {
            headerClasses: 'text-center',
            text: "Select",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.SelectFormatter,
            formatExtraData:{
                ordersToRouteSelected: ordersInQueueSelected,
                setOrdersToRouteSelected: setOrdersInQueueSelected,
            }
        },
        {
            headerClasses: 'text-center',
            dataField: "routeName",
            text: "Route",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "orderNumber",
            text: "Order Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.OrderNumberFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "client",
            text: "Client",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ClientFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "vendor",
            text: "Vendor",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.VendorFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "totalProductQty",
            text: "Qty of Cases",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalProductQuantityFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "submissionDate",
            text: "Created On",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.SubmissionDateFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "shipDate",
            text: "Ship Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ShipDateFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "routeStatus",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteStatusFormatter
        },
    ];

    const routingColumns = [
        {
            headerClasses: 'text-center',
            dataField: "routeName",
            text: "Route",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "orderNumber",
            text: "Order Number",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.OrderNumberFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "client",
            text: "Client",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ClientFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "vendor",
            text: "Vendor",
            sort: false,
            formatter: columnFormatters.VendorFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "totalProductQty",
            text: "Qty of Cases",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.TotalProductQuantityFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "submissionDate",
            text: "Created On",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.SubmissionDateFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "shipDate",
            text: "Ship Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ShipDateFormatter
        },
        {
            headerClasses: 'text-center',
            dataField: "routeStatus",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteStatusFormatter
        },
    ];

    const completedColumns = [
        {
            headerClasses: 'text-center',
            dataField: "routeName",
            text: "Route",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteRecordDetails(row)
                    setIsRouteRecordDetailsClicked(true)
                }
            },
        },
        {
            headerClasses: 'text-center',
            dataField: "routeDate",
            text: "Date",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RouteDateFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteRecordDetails(row)
                    setIsRouteRecordDetailsClicked(true)
                }
            },
        },
        {
            headerClasses: 'text-center',
            dataField: "ordersCount",
            text: "Orders Count",
            sort: false,
            formatter: columnFormatters.OrderCountFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteRecordDetails(row)
                    setIsRouteRecordDetailsClicked(true)
                }
            },
        },
        {
            headerClasses: 'text-center',
            dataField: "driver",
            text: "Driver",
            sort: false,
            formatter: columnFormatters.DriverFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteRecordDetails(row)
                    setIsRouteRecordDetailsClicked(true)
                }
            },
        },
        {
            headerClasses: 'text-center',
            dataField: "truck",
            text: "Truck",
            sort: false,
            formatter: columnFormatters.TruckFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteRecordDetails(row)
                    setIsRouteRecordDetailsClicked(true)
                }
            },
        },
        {
            headerClasses: 'text-center',
            dataField: "status",
            text: "Status",
            sort: false,
            formatter: columnFormatters.StatusFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setRouteRecordDetails(row)
                    setIsRouteRecordDetailsClicked(true)
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    const sorting = [{dataField: "orderNumber", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText, searchTextTwo, fromDate, toDate} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.status = orderStatus;
        filter.searchText = searchText ?? "";
        filter.routeName = searchTextTwo ?? "";
        filter.fromInvoiceDate = fromDate ?? "";
        filter.toInvoiceDate = toDate ?? "";

        newQueryParams.filter = filter;
        newQueryParams.status = orderStatus
        return newQueryParams;
    }

    const prepareQueueFilter = (queryParams, values) => {
        const {searchText, searchTextTwo, fromDate, toDate} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.status = orderStatus;
        filter.searchText = searchText ?? "";
        filter.routeName = searchTextTwo ?? "";
        filter.fromInvoiceDate = fromDate ?? "";
        filter.toInvoiceDate = toDate ?? "";

        newQueryParams.filter = filter;
        newQueryParams.status = orderStatus
        return newQueryParams;
    }

    const prepareRoutingFilter = (queryParams, values) => {
        const {searchText, searchTextTwo, fromDate, toDate} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.status = orderStatus;
        filter.searchText = searchText ?? "";
        filter.routeName = searchTextTwo ?? "";
        filter.fromInvoiceDate = fromDate ?? "";
        filter.toInvoiceDate = toDate ?? "";

        newQueryParams.filter = filter;
        newQueryParams.status = orderStatus
        return newQueryParams;
    }

    const prepareCompletedFilter = (queryParams, values) => {
        const {searchText, searchTextTwo, searchTextThree, fromDate, toDate} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.status = orderStatus;
        filter.routeName = searchText ?? "";
        filter.driver = searchTextTwo ?? "";
        filter.truck = searchTextThree ?? "";
        filter.fromRouteDate = fromDate ?? "";
        filter.toRouteDate = toDate ?? "";

        newQueryParams.filter = filter;
        newQueryParams.status = orderStatus
        return newQueryParams;
    }

    // console.log("Orders : ", completedData)
    console.log("Is all orders to route selected: ", isAllOrdersToRouteSelected)
    console.log("Is all orders in queue selected: ", isAllOrdersInQueueSelected)

    return (
        <>
            {
                isRouting ? 
                    <RoutingPage setIsRouting={setIsRouting} />
                :
                isRouteRecordDetailsClicked ?
                    <RouteRecordDetail accessToken={accessToken} routeRecordId={routeRecordDetails._id} setIsRouteRecordDetailsClicked={setIsRouteRecordDetailsClicked} />
                :

                <div>
                    <Card>
                        <CardHeader title="Orders to Route">
                            <CardHeaderToolbar>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            {/* <div className="row justify-content-between">
                                <div className="d-flex col-8">
                                    <div className="row mr-8">
                                        <div className="form-group fv-plugins-icon-container mr-5">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>From Invoice Date</label>
                                            <Input
                                                type="date"
                                                className={`h-auto py-1 px-6`}
                                                style={{ display: "block" }}
                                                name="fromInvoiceDate"
                                                value={fromInvoiceDate}
                                                onChange={(e) => setFromInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>To Invoice Date</label>
                                            <Input
                                                type="date"
                                                style={{ display: "block" }}
                                                className={`h-auto py-1 px-6`}
                                                name="fromInvoiceDate"
                                                value={toInvoiceDate}
                                                onChange={(e) => setToInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <PaginationTable
                                data={data}
                                columns={columns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                initialFilter={initialFilter}
                                tableClasses={tableClasses}
                                fetcher={fetchOrdersToRoute}
                                filterFunction={prepareFilter}
                                searchField={true}
                                dateRangeField={true}
                                dateRangeName={"Invoice"}
                                searchFieldTwo={true}
                                searchFieldTwoName={"route"}/>
                        </CardBody>
                        <CardFooter>
                            <div className="row">
                                <div className="row col-6">
                                    <div>
                                        <span className="font-weight-bolder font-size-lg">
                                            Select All
                                        </span>
                                        <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">
                                            <input type="checkbox" checked={isAllOrdersToRouteSelected} name="" onClick={(el) => handleSelectAllToRouteClick(el)} />
                                            <span></span>
                                        </label>
                                    </div>

                                    <button
                                        type="button" 
                                        className="btn btn-success ml-8" 
                                        onClick={() => handleReturnToQueueClick()} 
                                        disabled={(isSubmitDisabled || ordersToRouteSelected.length < 1)}
                                    >
                                        Return to Queue
                                        {
                                            isSubmitDisabled ?
                                                <span className="mr-3 spinner spinner-primary"></span>
                                            :
                                                null
                                        }
                                    </button>
                                </div>
                                <div className="col-6 justify-content-end d-flex">
                                    <button 
                                        type="button" 
                                        className="btn btn-success ml-5" 
                                        onClick={() => handleRoutingClick()} 
                                        disabled={(isSubmitDisabled || ordersToRouteSelected.length < 1)}
                                    >
                                        Start Routing
                                        {
                                            isSubmitDisabled ?
                                                <span className="mr-3 spinner spinner-primary"></span>
                                            :
                                                null
                                        }
                                    </button>

                                    <button 
                                        type="button" 
                                        className="btn btn-success ml-5" 
                                        onClick={() => handleRoutingAllClick()} 
                                        disabled={(isSubmitDisabled || data.totalCount.length > 0)}
                                    >
                                        Route All
                                        {
                                            isSubmitDisabled ?
                                                <span className="mr-3 spinner spinner-primary"></span>
                                            :
                                                null
                                        }
                                    </button>
                                </div>
                                
                            </div>
                        </CardFooter>
                    </Card>

                    <Card>
                        <CardHeader title="Orders in Queue">
                            <CardHeaderToolbar>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            {/* <div className="row justify-content-between">
                                <div className="d-flex col-8">
                                    <div className="row mr-8">
                                        <div className="form-group fv-plugins-icon-container mr-5">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>From Invoice Date</label>
                                            <Input
                                                type="date"
                                                className={`h-auto py-1 px-6`}
                                                style={{ display: "block" }}
                                                name="fromInvoiceDate"
                                                value={fromInvoiceDate}
                                                onChange={(e) => setFromInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>To Invoice Date</label>
                                            <Input
                                                type="date"
                                                style={{ display: "block" }}
                                                className={`h-auto py-1 px-6`}
                                                name="fromInvoiceDate"
                                                value={toInvoiceDate}
                                                onChange={(e) => setToInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <PaginationTable
                                data={queueData}
                                columns={queueColumns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                initialFilter={initialInQueueFilter}
                                tableClasses={tableClasses}
                                fetcher={fetchOrdersInQueue}
                                filterFunction={prepareQueueFilter}
                                searchField={true}
                                dateRangeField={true}
                                dateRangeName={"Invoice"}
                                searchFieldTwo={true}
                                searchFieldTwoName={"route"}/>
                        </CardBody>
                        <CardFooter>
                            <div className="row">
                                <div className="col-6">
                                    <span className="font-weight-bolder font-size-lg">
                                        Select All
                                    </span>
                                    <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">
                                        <input type="checkbox" checked={isAllOrdersInQueueSelected} name="" onClick={(el) => handleSelectAllInQueueClick(el)} />
                                        <span></span>
                                    </label>
                                </div>
                                <div className="row col-6 justify-content-end">
                                    <button 
                                        type="button" 
                                        className="btn btn-success ml-5" 
                                        onClick={() => handleSendToRouteClick()} 
                                        disabled={(isSubmitDisabled || ordersInQueueSelected.length < 1)}
                                    >
                                        Send to Route
                                        {
                                            isSubmitDisabled ?
                                                <span className="mr-3 spinner spinner-primary"></span>
                                            :
                                                null
                                        }
                                    </button>
                                </div>
                                
                            </div>
                        </CardFooter>
                    </Card>

                    <Card>
                        <CardHeader title="Routing in Progress">
                            <CardHeaderToolbar>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            {/* <div className="row justify-content-between">
                                <div className="d-flex col-8">
                                    <div className="row mr-8">
                                        <div className="form-group fv-plugins-icon-container mr-5">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>From Invoice Date</label>
                                            <Input
                                                type="date"
                                                className={`h-auto py-1 px-6`}
                                                style={{ display: "block" }}
                                                name="fromInvoiceDate"
                                                value={fromInvoiceDate}
                                                onChange={(e) => setFromInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>To Invoice Date</label>
                                            <Input
                                                type="date"
                                                style={{ display: "block" }}
                                                className={`h-auto py-1 px-6`}
                                                name="fromInvoiceDate"
                                                value={toInvoiceDate}
                                                onChange={(e) => setToInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <PaginationTable
                                data={routingData}
                                columns={routingColumns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                initialFilter={initialFilter}
                                tableClasses={tableClasses}
                                fetcher={fetchOrdersRouting}
                                filterFunction={prepareRoutingFilter}
                                searchField={true}
                                dateRangeField={true}
                                dateRangeName={"Invoice"}
                                searchFieldTwo={true}
                                searchFieldTwoName={"route"}/>
                        </CardBody>
                        <CardFooter>
                            <div className="row col-12 justify-content-end">
                                <button 
                                    type="button" 
                                    className="btn btn-success" 
                                    onClick={() => handleContinueRoutingClick()}
                                    disabled={routingData.data.length < 1}
                                >
                                    Continue Processing
                                    {
                                        isSubmitDisabled ?
                                            <span className="mr-3 spinner spinner-primary"></span>
                                        :
                                            null
                                    }
                                </button>
                            </div>
                        </CardFooter>
                    </Card>

                    <Card>
                        <CardHeader title="Routes Sent To Warehouse">
                            <CardHeaderToolbar>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            {/* <div className="row justify-content-between">
                                <div className="d-flex col-8">
                                    <div className="row mr-8">
                                        <div className="form-group fv-plugins-icon-container mr-5">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>From Invoice Date</label>
                                            <Input
                                                type="date"
                                                className={`h-auto py-1 px-6`}
                                                style={{ display: "block" }}
                                                name="fromInvoiceDate"
                                                value={fromInvoiceDate}
                                                onChange={(e) => setFromInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group fv-plugins-icon-container">
                                            <label style={{ display: "block" }} htmlFor={"invoiceDate"}>To Invoice Date</label>
                                            <Input
                                                type="date"
                                                style={{ display: "block" }}
                                                className={`h-auto py-1 px-6`}
                                                name="fromInvoiceDate"
                                                value={toInvoiceDate}
                                                onChange={(e) => setToInvoiceDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <PaginationTable
                                data={completedData}
                                columns={completedColumns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                initialFilter={initialFilter}
                                tableClasses={tableClasses}
                                fetcher={fetchOrdersCompleted}
                                filterFunction={prepareCompletedFilter}
                                searchField={true}
                                searchFieldName={"route"}
                                dateRangeField={true}
                                dateRangeName={"Route"}
                                searchFieldTwo={true}
                                searchFieldTwoName={"driver"}
                                searchFieldThree={true}
                                searchFieldThreeName={"truck"}/>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </div>
            }
            
        </>
    );
}

export default injectIntl(RoutesPage);