import { ADD_LOCATION, GET_LOCATIONS_BY_COMPANY_ID_LIST } from './../constants';
import { api } from './../index';

export const LOCATIONS_URL = `${process.env.REACT_APP_API_URL}/locations`;

export function getLocationsByCompanyIdList() {
    return function (dispatch) {
        api.get("/locations/company")
            .then(response => {
                dispatch({ type: GET_LOCATIONS_BY_COMPANY_ID_LIST, payload: response.data })
            });
    }
}


export function postLocations(locationsList) {
    return function (dispatch) {
        api.post("/locations", locationsList)
            .then(response => {
                dispatch({ type: ADD_LOCATION, payload: response.data });
            })
    }
}