import React, {useState} from "react";
import {useFormik} from "formik";
import InputLabel from '@material-ui/core/InputLabel';
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import CardHeader from "@material-ui/core/CardHeader";
import {RegionDropdown} from "react-country-region-selector";
import SupplierAdminPage from "./SupplierAdminPage";
import {useSelector} from "react-redux";
import {newSupplier, updateSupplierInformation} from "../AdminPagesCrud";
import {useParams} from "react-router-dom";
import Input from "@material-ui/core/Input";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Image from "react-bootstrap/Image";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};




function EditOrAddSupplier({ supplierInformation, edit, setEdit, setShowSnackbar, showSnackbar, isNewSupplier, setIsNewSupplier, intl}) {

    // const { intl } = props;
    const {id} = useParams();
    const [cancel, setCancel] = useState(false);
    const [country, setCountry] = useState('Puerto Rico');
    const [physicalCity, setPhysicalCity] = useState(supplierInformation.physicalCity);
    const [postalCity, setPostalCity] = useState(supplierInformation.postalCity);
    const [logo, setLogo] = useState('');

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const supplierInformationSchema = Yup.object().shape({
        entityName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        pointOfContact: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        postalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        // postalCity: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(50, "Maximum 50 symbols")
        //     .required(
        //         intl.formatMessage({
        //             id: "AUTH.VALIDATION.REQUIRED_FIELD",
        //         })
        //     ),
        postalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(5, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        physicalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        // physicalCity: Yup.string()
        //     .min(3, "Minimum 3 symbols")
        //     .max(50, "Maximum 50 symbols")
        //     .required(
        //         intl.formatMessage({
        //             id: "AUTH.VALIDATION.REQUIRED_FIELD",
        //         })
        //     ),
        physicalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(5, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        description: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(500, "Maximum 500 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });


    const supplierFormik = useFormik({
        initialValues: supplierInformation,
        validationSchema: supplierInformationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            try {
                if(edit){
                    values.physicalCity = physicalCity;
                    values.postalCity = postalCity;
                    values.updateType = 'supplier';
                    updateSupplierInformation(id, values, accessToken).then((response) => {
                        setShowSnackbar(!showSnackbar);
                        setEdit(!edit);
                    })
                }
                else{
                    values.businesstype = 'supplier'
                    let data = {
                        values: values,
                        type: 'admin'
                    }
                    newSupplier(data, accessToken).then((response) => {
                        setShowSnackbar(true);
                        setIsNewSupplier(false);

                    })
                }
            } catch (e) {
                alert("An error occurred");
                console.log(e.message);
            }
        }
    });


    return (
        <>
            {cancel ?
                <SupplierAdminPage/> :
                <Card>
                    {edit ? <CardHeader title="Edit Distribution Company"/> : <CardHeader title="New Distribution Company"/> }
                    <CardBody>
                        <form onSubmit={supplierFormik.handleSubmit}>
                            <div>
                                <div className="">
                                    <div className="col-12">
                                        <Grid container>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Entity Name</InputLabel>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="entityName"
                                                            {...supplierFormik.getFieldProps("entityName")}
                                                        />
                                                        {supplierFormik.touched.entityName && supplierFormik.errors.entityName ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.entityName}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Point of Contact</InputLabel>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="pointOfContact"
                                                            {...supplierFormik.getFieldProps("pointOfContact")}
                                                        />
                                                        {supplierFormik.touched.pointOfContact && supplierFormik.errors.pointOfContact ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.pointOfContact}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Phone Number</InputLabel>
                                                        <Input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="phoneNumber"
                                                            inputComponent={TextMaskCustom}
                                                            {...supplierFormik.getFieldProps("phoneNumber")}
                                                        />
                                                        {supplierFormik.touched.phoneNumber && supplierFormik.errors.phoneNumber ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.phoneNumber}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Email</InputLabel>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="email"
                                                            {...supplierFormik.getFieldProps("email")}
                                                        />
                                                        {supplierFormik.touched.email && supplierFormik.errors.email ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.email}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Description of Services</InputLabel>
                                                        <input
                                                            type="text"
                                                            multiline
                                                            className={`form-control form-control-solid h-auto py-4 px-6`}
                                                            name="description"
                                                            {...supplierFormik.getFieldProps("description")}
                                                        />
                                                        {supplierFormik.touched.description && supplierFormik.errors.description ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.description}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Postal Address</InputLabel>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="postalAddress"
                                                            {...supplierFormik.getFieldProps("postalAddress")}
                                                        />
                                                        {supplierFormik.touched.postalAddress && supplierFormik.errors.postalAddress ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.postalAddress}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Physical Address</InputLabel>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="physicalAddress"
                                                            {...supplierFormik.getFieldProps("physicalAddress")}
                                                        />
                                                        {supplierFormik.touched.physicalAddress && supplierFormik.errors.physicalAddress ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.physicalAddress}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <FormControl variant="filled" className={'w-100'}>
                                                            <RegionDropdown
                                                                {...supplierFormik.getFieldProps("postalCity")}
                                                                className={'form-control form-control-solid h-auto py-2 px-6'}
                                                                country={country}
                                                                value={postalCity}
                                                                defaultOptionLabel = 'City'
                                                                onChange={(val) => setPostalCity(val)} />

                                                            {supplierFormik.touched.postalCity && supplierFormik.errors.postalCity ? (
                                                                <div className="fv-plugins-message-container">
                                                                    <div className="fv-help-block">{supplierFormik.errors.postalCity}</div>
                                                                </div>
                                                            ) : null}
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <FormControl variant="filled" className={'w-100'}>
                                                            <RegionDropdown
                                                                {...supplierFormik.getFieldProps("physicalCity")}
                                                                className={'form-control form-control-solid h-auto py-2 px-6'}
                                                                country={country}
                                                                value={physicalCity}
                                                                defaultOptionLabel = 'City'
                                                                onChange={(val) => setPhysicalCity(val)} />

                                                            {supplierFormik.touched.physicalCity && supplierFormik.errors.physicalCity ? (
                                                                <div className="fv-plugins-message-container">
                                                                    <div className="fv-help-block">{supplierFormik.errors.physicalCity}</div>
                                                                </div>
                                                            ) : null}
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Postal Zip Code</InputLabel>
                                                        <input
                                                            type="text"
                                                            placeholder="00000"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="postalZipcode"
                                                            {...supplierFormik.getFieldProps("postalZipcode")}
                                                        />
                                                        {supplierFormik.touched.postalZipcode && supplierFormik.errors.postalZipcode ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.postalZipcode}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel>Physical Zip Code</InputLabel>
                                                        <input
                                                            type="text"
                                                            placeholder="00000"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="physicalZipcode"
                                                            {...supplierFormik.getFieldProps("physicalZipcode")}
                                                        />
                                                        {supplierFormik.touched.physicalZipcode && supplierFormik.errors.physicalZipcode ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div
                                                                    className="fv-help-block">{supplierFormik.errors.physicalZipcode}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel htmlFor="unitSize">Logo Path</InputLabel>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                            name="units"
                                                            {...supplierFormik.getFieldProps("logoPath")}
                                                        />
                                                        {supplierFormik.touched.logoPath && supplierFormik.errors.logoPath ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{supplierFormik.errors.logoPath}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <Image src={supplierInformation.logoPath} fluid rounded/>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel >Is Official</InputLabel>
                                                        <Select
                                                            input={<FilledInput name="isOfficial" id="isOfficial"
                                                                                type="text"
                                                                                className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                                {...supplierFormik.getFieldProps("isOfficial")}
                                                            />}
                                                        >
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>

                                                        </Select>
                                                        {supplierFormik.touched.isOfficial && supplierFormik.errors.isOfficial ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{supplierFormik.errors.isOfficial}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div className="form-group fv-plugins-icon-container">
                                                        <InputLabel >Is Active</InputLabel>
                                                        <Select
                                                            input={<FilledInput name="active" id="active"
                                                                                type="text"
                                                                                className={`form-control form-control-solid h-2 py-2 px-6`}
                                                                                {...supplierFormik.getFieldProps("active")}
                                                            />}
                                                        >
                                                            <MenuItem value={true}>Yes</MenuItem>
                                                            <MenuItem value={false}>No</MenuItem>

                                                        </Select>
                                                        {supplierFormik.touched.active && supplierFormik.errors.active ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{supplierFormik.errors.active}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3">
                                <button type="submit" className="btn btn-primary mr-3">
                                    Save
                                </button>
                                <></>
                                {edit ?
                                    <button type="button" className="btn btn-light" onClick={() => setCancel(true)}>
                                        Cancel
                                    </button> : null }
                            </div>
                        </form>
                    </CardBody>
                </Card>
            }
        </>
    );
}

export default injectIntl(EditOrAddSupplier);
