import axios from "axios";
import { api } from './../../../../redux/index';

export const LOGIN_URL = process.env.REACT_APP_API_URL + "/auth/login";
export const BUYER_REGISTER_URL = process.env.REACT_APP_API_URL + "/buyers";
export const SUPPLIER_REGISTER_URL = process.env.REACT_APP_API_URL + "/suppliers";
export const REQUEST_PASSWORD_URL = process.env.REACT_APP_API_URL + "/auth/forgot_password";
export const CHANGE_PASSWORD_URL = process.env.REACT_APP_API_URL + "/auth/change_password";

export const ME_URL = process.env.REACT_APP_API_URL + "/auth/user";
export const USER_TYPE_URL = process.env.REACT_APP_API_URL + "/auth/user_type";
export const LOGOUT_URL = process.env.REACT_APP_API_URL + "/auth/logout";
export const RESEND_URL = process.env.REACT_APP_API_URL + "/auth/resend_email";
export const CONFIRMATION_EMAIL = process.env.REACT_APP_API_URL + "/auth/confirmation"
export const LOGOUT_ALLEC_URL = process.env.REACT_APP_API_URL + "/auth/allec_logout"

axios.defaults.withCredentials = true;

export function login(email, password) {
  console.log(process.env.REACT_APP_API_URL);
  return api.post(LOGIN_URL, { email, password }, { headers: { crossDomain: true, 'Content-Type': 'application/json' }, validateStatus: status => status < 500 });
}

export function supplierRegister(values) {
  return axios.post(SUPPLIER_REGISTER_URL, values);
}

export function register(businessType, entityName, fullName, phoneNumber,
  physicalAddress, physicalCity, physicalZipcode, postalAddress, postalCity, postalZipcode, email, password) {

  const config = {
  };

  return axios.post(BUYER_REGISTER_URL, {
    businessType, entityName, fullName, phoneNumber,
    physicalAddress, physicalCity, physicalZipcode, postalAddress, postalCity, postalZipcode, email, password
  }, config);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(state) {
  // Authorization head should be fulfilled in interceptor.
  const options = {
    headers: { 'x-auth-token': state.auth.authToken }
  }

  // console.log('Options', options);

  // console.log("DENTRO DEL BOOM!",state.auth.authToken, ME_URL);
  return axios.get(process.env.REACT_APP_API_URL + '/auth/user', options);
}

export function logout(token) {

  // console.log("Deslogueando", token);

  const options = {
    headers: {
      'x-auth-token': token
    }
  }

  return axios.post(LOGOUT_URL,[],options);
}

export function resendValidation(email) {
  return axios.post(RESEND_URL, email);
}

export function validateAccount(token) {
  return axios.post(CONFIRMATION_EMAIL, { token: token })
}

export function changePassword(password, token) {
  return axios.post(CHANGE_PASSWORD_URL, { password, token });
}

export function logoutUser() {
  console.log("Going to the beninging")

  localStorage.setItem("loggingOut", false);
  window.location.href = process.env.REACT_APP_OAUTH_LOGOUT;
}
