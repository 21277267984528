import React, {useState} from "react";
import {Dropdown} from "react-bootstrap";
import {DropdownCustomToggler, DropdownMenu4} from "../../../../_metronic/_partials/dropdowns";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import { getSuppliers, getSuppliersPagination } from '../CommonApplication/SupplierSelection/SupplierSelectionCrud'
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";

export function SupplierList({ className, suppliers, applicationList, accessToken }){
    console.log("AQUI")

    const getStatusLabel = (status) => {
        if(status === "submitted") {
            return (
                <span className="label label-lg label-light-primary label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "in progress") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "approved") {
            return (
                <span className="label label-lg label-light-success label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "denied") {
            return (
                <span className="label label-lg label-light-danger label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } else if (status === "Not Applied") {
            return (
                <span className="label label-lg label-light-warning label-inline">
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
            )
        } 
    }

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize:3
    }
    const [data,setData] = useState(initialData)

    const fetchSuppliers = async (queryParams) => {
        let suppliersStatus = []
        await getSuppliersPagination(queryParams, accessToken).then(response => {
            console.log("-------------------------------")
            console.log(response)
            console.log(applicationList)
            response.data.data.map(supplier => {
                let supplierApplications = []
                applicationList.map(application => {
                    // console.log(application)
                    if(supplier._id === application.supplierId){
                        supplierApplications.push({
                            company : application.companyName,
                            status : application.status,
                        })
                    } 
                })
                if(supplierApplications.length > 0){
                    suppliersStatus.push({
                        ...supplier,
                        supplierApplications
                    })
                } else {
                    suppliersStatus.push({
                        ...supplier,
                        status : "Not Applied"
                    })
                }
            })

            // console.log("~~~~Aqui:", suppliersStatus)
            
            const data = {
                data : suppliersStatus,
                totalCount : response.data.totalCount,
                pageSize : 5
            }
            setData(data)
        }).catch((error) => {
            console.log(error)
        });
        
        // setData(data);
        // Authorization head should be fulfilled in interceptor.
        // const options = {
        //     headers: { 'x-auth-token': accessToken},
        //     validateStatus: status => status < 500,
        //     params: queryParams
        // }
        // axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + user.id + '/applications',options).then(response =>{
        //     setData(response.data)
        // });
    }

    const columns = [
        // {
        //     dataField: "orderNumber",
        //     text: "Order Number",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        //     events: {
        //         onClick: (e, column, columnIndex, row, rowIndex) => {
        //             console.log("Pepe")
        //         }
        //     },
        //     // formatter: columnFormatters.OrderNumberFormatter,
        // },
        // {
        //     dataField: "companyName",
        //     text: "Company",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
            dataField: "entityName",
            text: "Supplier",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.EntityNameFormatter,
        },
        {
            dataField: "physicalAddress",
            text: "Address",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.AddressFormatter,
        },
        {
            dataField: "email",
            text: "Supplier Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.EmailFormatter,
        },
        {
            dataField: "company",
            text: "Company",
            // sort: true,
            // sortCaret: sortCaret,
            // headerSortingClasses,
            formatter: columnFormatters.CompanyFormatter,
        },
        {
            dataField: "status",
            text: "Status",
            // sort: true,
            // sortCaret: sortCaret,
            // headerSortingClasses,
            formatter: columnFormatters.StatusFormatter,
        },
    ];

    const sizePerPageList = [
        { text: "3", value: 3 },
        { text: "5", value: 5 },
        { text: "10", value: 10 }
    ]

    const initialFilter = {
        filter: {
            entityName:"",
        },
        sortOrder: "asc",
        sortField: "entityName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "entityName",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-vertical-center "

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.entityName = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            <div className={`card card-custom ${className}`}>
                <Card>
                    <CardHeader title="All Suppliers">
                        <CardHeaderToolbar>
                            {/* <button type="button" className="btn btn-primary" onClick={()=>{console.log("Que la que")}} >
                                Create New Order
                            </button> */}
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchSuppliers}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}/>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}