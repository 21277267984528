import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { fetchRoute } from "../AdminPagesCrud";
import {useParams} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import RouteInformation from "./RouteInformation";
import RouteClients from "./RouteClients"

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

export function RouteTabs({routeId}) {

    const initialValues = {
        name: "Route 2",
        dayOfTheWeek: "Th",
        cutOffTime: "17:00",
    };

    const {id} = useParams();
    const [route, setRoute] = useState(initialValues);
    const [supplierName, setSupplierName] = useState('')
    const [products, setProducts] = useState([])
    const [clients, setClients] = useState([])
    const [edit, setEdit] = useState(false);


    function handleEdit(){
        setEdit(true);
    }

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )


    useEffect(() => {

        fetchRoute(routeId, accessToken).then(response => {
            console.log("This is the Route response", response)
            setRoute(response.data)
        });

    },[])


    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <Paper className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                centered
            >
                <Tab label="About" wrapped />
                <Tab label="Clients" wrapped/>
            </Tabs>
            {value === 0 && <TabContainer> <RouteInformation route={route} /> </TabContainer>}
            {value === 1 && <TabContainer> <RouteClients route={route} /> </TabContainer>}
        </Paper>
    )
}