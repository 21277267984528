import React from "react";
// import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import SupplierCard from "./SupplierCard"
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import SubmitModal from '../SubmitModal'
import ExistingClientModal from '../ExistingClientModal'
import FileUpload from './FileUpload'

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import SupplierProductsModal from './SupplierProductsModal'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingBottom: 10
  },
}));

// function createData(supplierName, address, zipcode) {
//     return { supplierName, address, zipcode };
// }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SupplierSelectionPage(props) {
  const classes = useStyles();
  const { handleComplete, selectedSuppliers, handleChecked, handleSubmit,
    postSuppliers, handleSelectedSuppliersChange , suppliers, buyer, setExistingClientList, submitSuppliers, existingClientList, selectedCompanyLocations,
    locationsCategories, setLocationCategories, accessToken, files, setFiles, requiredFiles, selectedCompanyUnsubmittedLocations, newLocationsCategories, setNewLocationCategories } = props

  // useEffect(() => {
    
  //   selectedSuppliers.map(supplier => {
  //     const locationsApp = {
  //       supplier,
  //       locations
  //     }

  //   })
  // }, [  ])

  const [open, setOpen] = React.useState(false);
  const [categoriesDialogueOpen, setCategoriesDialogueOpen] = React.useState(false);
  const [scroll] = React.useState('paper');
  const [supplierClicked, setSupplierClicked] = React.useState({})
  const [filterCategory, setFilterCategory] = React.useState("")

  console.log("Locations: ", selectedCompanyLocations)
  console.log(selectedCompanyUnsubmittedLocations)

  const handleClickOpen = (supplier, category) => {
    console.log("clicked")
    console.log(supplier)
    console.log(category)
    setSupplierClicked(supplier)
    setFilterCategory(category)
    setOpen(true);
  };

  function handleCloseCategoriesDialogue() {
    setCategoriesDialogueOpen(false);
  }

  function handleClickOpenCategoriesDialogue() {
    setCategoriesDialogueOpen(true);
  }

  function handleClose() {
      setOpen(false);
  }

  function handleChange(event, supplier, location) {
    const locationCategoriesList = event.target.value
    const currentSupplier = locationsCategories.find(element => element.supplier === supplier)
    let newLocationCategories = {}

    if(typeof(currentSupplier) !== 'undefined'){
      const currentLocations = currentSupplier.locations.filter(currLocation => currLocation.locationId !== location._id)
      const oldLocationCategories = currentSupplier.locations.find(currLocation => currLocation.locationId === location._id)

      newLocationCategories = {
        locationId : oldLocationCategories.locationId,
        categories: locationCategoriesList,
        status: "submitted"
      }

      const supplierLocations = {
        supplier,
        locations : [ ...currentLocations, newLocationCategories]
      }
      
      setLocationCategories(locationsCategories => 
        [
          ...locationsCategories.filter(element => element.supplier !== supplier),
          supplierLocations
        ]
      )
    } 
  }

  const handleLocationChange = (supplier, location) => {
    const currentSupplier = locationsCategories.find(element => element.supplier === supplier)
    let currentLocations = []
    // console.log(currentSupplier)
    if(typeof(currentSupplier) !== 'undefined'){
      // console.log(currentSupplier.locations)
      // console.log(currentSupplier.locations.find(currLocation => currLocation.locationId === location._id))
      if(typeof(currentSupplier.locations.find(currLocation => currLocation.locationId === location._id)) !== 'undefined'){
        currentLocations = currentSupplier.locations.filter(currLocation => currLocation.locationId !== location._id)
      }
      else{
        currentLocations = [
          ...currentSupplier.locations,
          {
            locationId : location._id,
            categories : []
          }
        ]
      }
    } else {
      currentLocations.push({locationId : location._id, categories : []})
    }
    
    const supplierLocations = {
      supplier,
      locations : [ ...currentLocations ]
    }
    setLocationCategories(locationsCategories => 
      [
        ...locationsCategories.filter(element => element.supplier !== supplier),
        supplierLocations
      ]
    )
  }

  const isLocation = (supplier, location) => {
    const currentSupplier = locationsCategories.find(element => element.supplier === supplier)
    if(typeof(currentSupplier) !== 'undefined'){
      return !(typeof(currentSupplier.locations.find(currLocation => currLocation.locationId === location._id)) !== 'undefined')
    }
    else {
      return true
    }
  }

  function handleNewLocationCategoriesChange(event, supplier, location, applicationId) {
    console.log(newLocationsCategories)
    const locationCategoriesList = event.target.value
    const currentSupplier = newLocationsCategories.find(element => element.supplier === supplier)
    let newLocationCategories = {}
  
    if(typeof(currentSupplier) !== 'undefined'){
      const currentLocations = currentSupplier.locations.filter(currLocation => currLocation.locationId !== location._id)
      const oldLocationCategories = currentSupplier.locations.find(currLocation => currLocation.locationId === location._id)
  
      newLocationCategories = {
        locationId : oldLocationCategories.locationId,
        categories: locationCategoriesList,
        status: "submitted"
      }
  
      const supplierLocations = {
        applicationId: applicationId,
        supplier,
        locations : [ ...currentLocations, newLocationCategories]
      }
      
      setNewLocationCategories(newLocationsCategories => 
        [
          ...newLocationsCategories.filter(element => element.supplier !== supplier),
          supplierLocations
        ]
      )
    } 
  }
  
  const handleNewLocationChange = (supplier, location, applicationId) => {
    console.log("Unsubnitted Location: ", location)
    // console.log(newLocationsCategories)
    const currentSupplier = newLocationsCategories.find(element => element.supplier === supplier)
    let currentLocations = []
    // console.log(currentSupplier)
    if(typeof(currentSupplier) !== 'undefined'){
      // console.log(currentSupplier.locations)
      // console.log(currentSupplier.locations.find(currLocation => currLocation.locationId === location._id))
      if(typeof(currentSupplier.locations.find(currLocation => currLocation.locationId === location._id)) !== 'undefined'){
        currentLocations = currentSupplier.locations.filter(currLocation => currLocation.locationId !== location._id)
      }
      else{
        currentLocations = [
          ...currentSupplier.locations,
          {
            locationId : location._id,
            categories : []
          }
        ]
      }
    } else {
      currentLocations.push({locationId : location._id, categories : []})
    }
    
    const supplierLocations = {
      applicationId: applicationId,
      supplier,
      locations : [ ...currentLocations ]
    }
    setNewLocationCategories(newLocationsCategories => 
      [
        ...newLocationsCategories.filter(element => element.supplier !== supplier),
        supplierLocations
      ]
    )
  }
  
    const isNewLocation = (supplier, location) => {
      const currentSupplier = newLocationsCategories.find(element => element.supplier === supplier)
      console.log(currentSupplier)
      if(typeof(currentSupplier) !== 'undefined'){
        return !(typeof(currentSupplier.locations.find(currLocation => currLocation.locationId === location._id)) !== 'undefined')
      }
      else {
        return true
      }
    }

  // const handleCategoriesClick = () => {
  //   locationsCategories.map(element => {
  //     console.log(`${element.supplier} Locations: `)
  //     element.locations.map(location => {
  //       console.log(`   ${location.locationId} Categories: `)
  //       console.log(`       ${location.categories}`)
  //     })
  //   })
  // }

    return (
        <div>
                {/* <TextField
                    id="outlined-search"
                    label="Search Supplier"
                    type="search"
                    className={classes.textField}
                    variant="outlined"
                /> */}

                <div className={classes.root}>
                    <GridList cellHeight={'auto'} cols={3} spacing={10} className={classes.gridList}>
                    {/* <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                        <ListSubheader component="div">December</ListSubheader>
                    </GridListTile> */}

                        {suppliers.map(supplier => (
                        <GridListTile key={supplier.entityName}>
                            <SupplierCard supplier={supplier} handleSelected={handleSelectedSuppliersChange} selected={selectedSuppliers.includes(curr => curr._id === supplier._id)} 
                              isSubmited={submitSuppliers.includes(supplier.entityName)} handleClickOpen={handleClickOpen} />
                        </GridListTile>
                        ))}
                    </GridList>

                    <ExistingClientModal selectedSuppliers={selectedSuppliers} setExistingClientList={setExistingClientList}/>
                </div>
                {/* begin::Table */}
                <div>
                  <div className="card-body py-0">
                    <div className="table-responsive">
                      {selectedCompanyUnsubmittedLocations.map(location => 
                            <table
                            className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                            key={location.submitedSupplier.entityName}
                           >
                             <thead>
                             <tr className="text-left">
                               <th className="pr-0" style={{ minWidth: "150px" }}>
                                 {`${location.submitedSupplier.entityName} (Apply for new location)`}
                               </th>
                               <th style={{ minWidth: "150px" }}></th>
                             </tr>
                             </thead>
                             <tbody>
                               {location.unsubmittedCompanyLocations.map((unsubmittedLocation, index) => 
                                 <tr key={`${unsubmittedLocation._id}-${index}`}>
                                   <td>
                                     <div className="d-flex align-items-center">
                                       <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                       <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                           <input type="checkbox" name="" onChange={() => handleNewLocationChange(location.submitedSupplier.entityName, unsubmittedLocation, location.applicationId)} />
                                           <span></span>
                                       </label>
                                       <div className="d-flex flex-column flex-grow-1">
                                         <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                           {unsubmittedLocation.locationAddress1} {unsubmittedLocation.locationAddress2}
                                         </span>
                                       </div>
                                     </div>
                                   </td>
                                   {
                                     isNewLocation(location.submitedSupplier.entityName, unsubmittedLocation) ? null :
                                     <td>
                                       <FormControl className={classes.formControl}>
                                         <InputLabel htmlFor="select-multiple-checkbox">Categories</InputLabel>
                                         <Select
                                           multiple
                                           value={newLocationsCategories.find(element => element.supplier === location.submitedSupplier.entityName).locations.find(currLocation => currLocation.locationId === unsubmittedLocation._id).categories}
                                           onChange={(e) => handleNewLocationCategoriesChange(e, location.submitedSupplier.entityName, unsubmittedLocation, location.applicationId)}
                                           input={<Input id="select-multiple-checkbox" />}
                                           renderValue={selected => selected.join(', ')}
                                           MenuProps={MenuProps}
                                         >
                                           {suppliers.find(currSupplier => currSupplier.entityName === location.submitedSupplier.entityName).supplierCategories.map(category => (
                                             <MenuItem key={category.name} value={category.name}>
                                               <Checkbox checked={newLocationsCategories.find(element => element.supplier === location.submitedSupplier.entityName).locations.find(currLocation => currLocation.locationId === unsubmittedLocation._id).categories.indexOf(category.name) > -1} />
                                               <ListItemText primary={category.name} />
                                             </MenuItem>
                                           ))}
                                         </Select>
                                       </FormControl>
                                     </td>
                                   }
                                 </tr>
                               )}
                             </tbody>
                           </table>
                        )}
                        {selectedSuppliers.map(supplier => 
                           <table
                           className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                           key={supplier._id}
                          >
                            <thead>
                            <tr className="text-left">
                              <th className="pr-0" style={{ minWidth: "150px" }}>
                                {supplier.entityName}
                              </th>
                              <th style={{ minWidth: "150px" }}></th>
                            </tr>
                            </thead>
                            <tbody>
                              {selectedCompanyLocations.map((location, index) => 
                                <tr key={`${location._id}-${index}`}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                          <input type="checkbox" name="" onChange={() => handleLocationChange(supplier.entityName, location)} />
                                          <span></span>
                                      </label>
                                      <div className="d-flex flex-column flex-grow-1">
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                          {location.locationAddress1} {location.locationAddress2}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  {
                                    isLocation(supplier.entityName, location) ? null :
                                    <td>
                                      <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="select-multiple-checkbox">Categories</InputLabel>
                                        <Select
                                          multiple
                                          value={locationsCategories.find(element => element.supplier === supplier.entityName).locations.find(currLocation => currLocation.locationId === location._id).categories}
                                          onChange={(e) => handleChange(e, supplier.entityName, location)}
                                          input={<Input id="select-multiple-checkbox" />}
                                          renderValue={selected => selected.join(', ')}
                                          MenuProps={MenuProps}
                                        >
                                          {suppliers.find(currSupplier => currSupplier.entityName === supplier.entityName).supplierCategories.map(category => (
                                            <MenuItem key={category.name} value={category.name}>
                                              <Checkbox checked={locationsCategories.find(element => element.supplier === supplier.entityName).locations.find(currLocation => currLocation.locationId === location._id).categories.indexOf(category.name) > -1} />
                                              <ListItemText primary={category.name} />
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </td>
                                  }
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                    </div>
                  </div>
                  {/* end::Table */}
                </div>
                    
                <div>
                  {/* <FileUpload/>  */}
                  <FileUpload accessToken={accessToken} files={files} setFiles={setFiles} requiredFiles={requiredFiles}/>
                </div>

                {/* <h1>Pre-Selection Report</h1> */}

                {/* <SupplierReportTable selectedSuppliers={selectedSuppliers} handleChecked={handleSelectedSuppliersChange}/> */}

                    <div className="form-group d-flex flex-wrap flex-center">
                        {/* <button
                        id="locationCategoriesButton"
                        type="button"
                        onClick={() => handleCategoriesClick()}
                        // disabled={formik.isSubmitting}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            <span>Categories Test</span>
                        </button> */}
                        <SubmitModal selectedSuppliers={selectedSuppliers} handleChecked={handleChecked} handleSubmit={handleSubmit} existingClientList={existingClientList}
                                      handleClickOpenCategoriesDialogue={handleClickOpenCategoriesDialogue} locationsCategories={locationsCategories} newLocationsCategories={newLocationsCategories} />
                        
                    </div>

          {open ? <SupplierProductsModal open={open} scroll={scroll} handleClose={handleClose} supplier={supplierClicked} filterCategory={filterCategory}/> : null}
          <Dialog
                  open={categoriesDialogueOpen}
                  onClose={handleCloseCategoriesDialogue}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">{"Missing Categories"}</DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Before submitting please choose at least 
                      one category per selected location.
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleCloseCategoriesDialogue} color="primary" autoFocus>
                      OK
                  </Button>
                  </DialogActions>
              </Dialog>
        </div>
    );
}
    
export default injectIntl(SupplierSelectionPage);