import axios from "axios";
import {APPLICATIONS_GET_BY_USER_COMPANIES_URL} from "../../Buyer/CommonApplication/CommonApplicationCrud";


export const GET_DASHBOARD_INFO = process.env.REACT_APP_API_URL + "/suppliers/dashboardInfo/";


export function fetchDashboardInfo(id,authToken) {
    // Authorization head should be fulfilled in interceptor.
    const options = {
        headers: { 'x-auth-token': authToken}
    }

    return axios.get(GET_DASHBOARD_INFO + id,options);
}

export function fetchApplications (id,queryParams,accessToken){
    // Authorization head should be fulfilled in interceptor.
    const options = {
        headers: { 'x-auth-token': accessToken},
        validateStatus: status => status < 500,
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + '/suppliers/applications',options);
}

export function getApplicationsByCompanyIdList(companyIdList, accessToken) {
    const config = {
        headers: { 'x-auth-token': accessToken}
    }
    console.log(companyIdList)
    return axios.get(APPLICATIONS_GET_BY_USER_COMPANIES_URL, {params : companyIdList}, config);
}