import React, {useState} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import axios from "axios";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {useParams} from "react-router-dom";


function SupplierClientsList() {


    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 10
    }

    const {id} = useParams();
    const [data,setData] = useState(initialData)

    const fetchClients = (queryParams) => {
        queryParams.type = "supplier"
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }
        console.log("Search params : ", options.queryParams)
        axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + id + '/clients',options).then(response =>{
            setData(response.data)
            console.log("apps: " , response.data)
        });
    }


    const columns = [
        {
            dataField: "companyName",
            text: "Company Name",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "ein",
            text: "EIN",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "registroComerciante",
            text: "Merchant Registration",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "numeroCatastro",
            text: "Catastro",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "contactName",
            text: "Contact Name",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },

    ];

    const sizePerPageList = [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 }
    ]

    const initialFilter = {
        filter: {
            companyName:"",
            ein: "",
            merchantRegistration:"",
            catastro: "",
            contactName: "",
        },
        sortOrder: "asc",
        sortField: "companyName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "companyName",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.companyName = searchText ?? "";
        filter.contactName = searchText ?? "";


        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
                <Card>
                    <CardHeader title="Client Lists">
                        {/*<CardHeaderToolbar>*/}
                        {/*    <button type="button" className="btn btn-primary" onClick={console.log("wuju")}>*/}
                        {/*        New Client*/}
                        {/*    </button>*/}
                        {/*</CardHeaderToolbar>*/}
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchClients}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}
                            searchField={false}/>
                    </CardBody>
                </Card>
        </>
    );
}

export default injectIntl(SupplierClientsList);