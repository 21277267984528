/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AdminMenu({ layoutProps}) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                {/* <li
                    className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                        </span>
                        <span className="menu-text">Admin Dashboard</span>
                    </NavLink>
                </li> */}
                {/* <li
                    className={`menu-item ${getMenuItemActive("/buyerAdmin", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/buyerAdmin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")}/>
            </span>
                        <span className="menu-text">Buyer Admin Portal</span>
                    </NavLink>
                </li> */}
                <li
                    className={`menu-item ${getMenuItemActive("/supplierAdmin", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/supplierAdmin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box3.svg")}/>
            </span>
                        <span className="menu-text">Supplier Admin Portal</span>
                    </NavLink>
                </li>
                {/* <li
                    className={`menu-item ${getMenuItemActive("/userAdmin", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/userAdmin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
                        <span className="menu-text">User Admin</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/productsAdmin", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/productsAdmin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}/>
            </span>
                        <span className="menu-text">Products Admin</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/priceLists", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/priceLists">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Price1.svg")}/>
            </span>
                        <span className="menu-text">Price Lists Admin</span>
                    </NavLink>
                </li> */}
            </ul>
        </>
    );
}
