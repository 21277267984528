import {combineReducers} from "redux";
import {all} from "redux-saga/effects";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import applicationReducer from './reducers/application';
import dashboardApplicationReducer from './reducers/dashboardApplication';
import * as cartCount from "./reducers/cartCount";
import companyReducer from "./reducers/company";
import locationReducer from './reducers/location';
import notificationReducer from "./reducers/notification";
import orderReducer from './reducers/order';
import * as ordersCount from "./reducers/ordersCount";
import supplierClients from "./reducers/supplierClients";
import verificationEmail from "./reducers/verificaionEmail";

import * as applicationsCount from "./reducers/applicationsCount";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    verificationEmail: verificationEmail,
    supplierClients: supplierClients,
    cartCount: cartCount.cartCount,
    ordersCount: ordersCount.ordersCount,
    notificationReducer: notificationReducer,
    companyReducer: companyReducer,
    applicationReducer: applicationReducer,
    dashboardApplicationReducer: dashboardApplicationReducer,
    locationReducer: locationReducer,
    orderReducer: orderReducer,
    applicationsCount: applicationsCount.applicationsCount


    // customers: customersSlice.reducer,
    // products: productsSlice.reducer,
    // remarks: remarksSlice.reducer,
    // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
    yield all([auth.saga(), cartCount.saga(), ordersCount.saga(), applicationsCount.saga()]);
}
