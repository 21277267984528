import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {Form, Formik} from "formik";
import CardContent from "@material-ui/core/CardContent";
import {MyTextInput} from "../FormFields";
import AdminSnackbar from "../AdminSnackbar";
import Grid from "@material-ui/core/Grid";
import RouteAddEdit from "./RouteAddEdit";
// import {fetchPriceList, fetchProduct, fetchSuppliers, fetchUser} from "../AdminPagesCrud";


function RouteInformation({route}) {

    const [edit, setEdit] = useState(false)
    const [snackbarPL, setSnackBarPL] = useState(false)

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {
        
    },[])


    return (
        <>
            {edit ? <RouteAddEdit route={route} supplierId={route.supplierId} 
                                     isNew={false} setIsNew={null} edit={edit} setEdit={setEdit}/> :
                <Card>
                    <CardHeader title="Price List Information">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setEdit(true)}>
                                Edit
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <Formik
                            initialValues={route}
                            onSubmit={(values) => {
                                console.log(values)
                            }}
                        >
                            <Form>
                                <div>
                                    <div className="">
                                        <div className="col-12">
                                            <Card>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <MyTextInput
                                                                    label="Name"
                                                                    name="name"
                                                                    type="text"
                                                                    value={route.name}
                                                                    placeholder="Name"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <MyTextInput
                                                                    label="Day of The Week"
                                                                    name="dayOfTheWeek"
                                                                    type="text"
                                                                    value={route.dayOfTheWeek}
                                                                    placeholder="Some Day"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            {/* <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Amount of products"
                                                                    name="products"
                                                                    type="number"
                                                                    value={priceList?.products?.length}
                                                                    placeholder="0"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Amount of clients"
                                                                    name="email"
                                                                    type="number"
                                                                    value={priceList?.clients?.length}
                                                                    placeholder="0"
                                                                    disabled
                                                                />
                                                            </Grid> */}
                                                            
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </CardBody>
                    <AdminSnackbar
                        message={'Price List  updated successfully'}
                        setShowSnackbar={setSnackBarPL}
                        showSnackbar={snackbarPL}
                        variant={'success'}
                    />
                </Card>
            }
        </>
    );
}

export default injectIntl(RouteInformation);