import React, {useState} from "react";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import axios from "axios";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import {SupplierInfoColumnFormatter} from "../ColumnsFormatter/SupplierInfoColumnFormatter";
import EditOrAddSupplier from "./EditOrAddSupplier";
import AdminSnackbar from "../AdminSnackbar";
import {UserActiveColumn} from "../ColumnsFormatter/UserInfoColumn";


function SupplierAdminPage(props) {

    const initialValues = {
        entityName: "",
        pointOfContact: "",
        phoneNumber: "",
        postalAddress: "",
        postalCity: "",
        postalZipcode: "",
        physicalAddress: "",
        physicalCity: "",
        physicalZipcode: "",
        email: "",
        description: "",
        logoPath: "",
        active: "",
        isOfficial: ""
    };

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 10
    }

    const [data,setData] = useState(initialData)
    const [edit, setEdit] = useState(false)
    const [isNewSupplier, setIsNewSupplier] = useState(false)
    const [snackbarSupplier, setSnackbarSupplier] = useState(false);


    const fetchSuppliers = (queryParams) => {
        // Authorization head should be fulfilled in interceptor.
        console.log("fetching suppliers for table")
        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }
        axios.get(process.env.REACT_APP_API_URL + '/suppliers/', options).then(response =>{
            setData(response.data)
            console.log("Suppliers: ", response.data);
        });
    }


    const columns = [
        {
            dataField: "entityName",
            text: "Name",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses,
            formatter: SupplierInfoColumnFormatter

        },
        {
            dataField: "postalAddress",
            text: "Postal Address",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "postalCity",
            text: "City",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "postalZipcode",
            text: "Zip Code",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "active",
            text: "Is Active",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses,
            formatter: UserActiveColumn
        },
    ];

    const sizePerPageList = [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 }
    ]

    const initialFilter = {
        filter: {
            entityName:"",
            postalAddress: "",
            postalCity:"",
            postalZipcode: ""
        },
        sortOrder: "asc",
        sortField: "entityName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "entityName",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.entityName = searchText ?? "";
        filter.postalAddress = searchText ?? "";
        filter.postalCity = searchText ?? "";
        filter.postalZipcode = searchText ?? "";


        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {isNewSupplier ?
                <EditOrAddSupplier supplierInformation={initialValues} edit={edit} setEdit={setEdit}
                                   setShowSnackbar={setSnackbarSupplier} showSnackbar={snackbarSupplier}
                                   isNewSupplier={isNewSupplier} setIsNewSupplier={setIsNewSupplier}/>
                :
                <Card>
                    <CardHeader title="Distribution Companies Admin">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setIsNewSupplier(true)}>
                                New Distribution Company
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchSuppliers}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}/>
                    </CardBody>
                    <AdminSnackbar
                        message={'Supplier information saved successfully'}
                        setShowSnackbar={setSnackbarSupplier}
                        showSnackbar={snackbarSupplier}
                        variant={'success'}
                    />
                </Card>
            }
        </>
    );
}

export default injectIntl(SupplierAdminPage);