import React, {useState} from "react";
import {Dropdown} from "react-bootstrap";
import {DropdownCustomToggler, DropdownMenu4} from "../../../../_metronic/_partials/dropdowns";
import SVG from "react-inlinesvg";
import {headerSortingClasses, sortCaret, toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {useSelector} from "react-redux";
import axios from "axios";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";


export function ListOfContacts({className}){

    const {user,accessToken} = useSelector (
        ({auth}) =>({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 10
    }

    const [data,setData] = useState(initialData);

    const fetchContacts = (queryParams) => {
        const options = {
            headers: {'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams
        }

        axios.get(process.env.REACT_APP_API_URL + '/suppliers/' + user.id + '/listOfContacts',options).then(response =>{
            setData(response.data)
        })
    }

    const columns = [
        {
            dataField:"companyName",
            text:"Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: CompanyColumnFormatter
        },
        {
            dataField: "contactName",
            text:"Contact Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: ContactNameFormatter
        },
        {
            dataField:"contactPhone",
            text: "Contact Phone",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: ContactPhoneNumberFormatter
        },
        {
            dataField:"businessType",
            text: "Business Type",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: BusinessTypeFormatter
        },
    ]

    const sizePerPageList = [
        {text: "10", value: 10},
        {text: "20", value: 20},
        {text: "50", value: 50}
    ]

    const initialFilter = {
        filter: {
            companyName:"",
            contactName:"",
            contactPhone: "",
            businessType: ""
        },
        sortOrder:"asc",
        sortField: "companyName",
        pageNumber:1,
        pageSize: 10
    }

    const sorting = [{dataField:"companyName",order:"asc"}];
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center table-padding"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.companyName = searchText ?? "";
        filter.contactName = searchText ?? "";
        filter.contactphone = searchText ?? "";
        filter.businessType = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            <Card>
                <CardHeader title="List of Contacts">
                    <CardHeaderToolbar>

                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        tableClasses={tableClasses}
                        fetcher={fetchContacts}
                        filterFunction={prepareFilter}
                        initialFilter={initialFilter}/>
                </CardBody>
            </Card>
        </>
    )
}

//Column Formatters
function CompanyColumnFormatter(cellContent,row){
    return (
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {row.companyName}
            </span>
            <p><span className="text-muted mt-3 font-weight-bold font-size-sm">{row.companyInfo[0].businessEmail}</span></p>
        </>
    )
}

function ContactNameFormatter(cellContent,row){
    return (
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {row.contactName}
            </span>
        </>
    )
}

function ContactPhoneNumberFormatter(cellContent,row){
    return (
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {row.contactPhone}
            </span>
        </>
    )
}

function BusinessTypeFormatter(cellContent,row){
    return (
        <>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {row.companyInfo[0].businessType}
            </span>
        </>
    )
}