import React, {useState} from "react";
import {Form, Formik} from "formik";
import {Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../../_metronic/_partials/controls";
import {MyTextInput} from "../FormFields";
import CardContent from "@material-ui/core/CardContent";
import EditOrAddSupplier from "./EditOrAddSupplier";
import AdminSnackbar from "../AdminSnackbar";
import Image from "react-bootstrap/Image";
import Grid from "@material-ui/core/Grid";

export function SupplierSummary({supplierInformation, edit, setEdit}) {

    
    const [snackbarSupplier, setSnackbarSupplier] = useState(false);

    function handleEdit(){
        setEdit(true);
    }

    return (
        <>
            {edit ? <EditOrAddSupplier supplierInformation={supplierInformation} edit={true}  setEdit={setEdit}
                                       setShowSnackbar={setSnackbarSupplier} showSnackbar={snackbarSupplier}/> :
                <Card>
                    <CardHeader title="Supplier Information">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={handleEdit}>
                                Edit
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <Formik
                            initialValues={supplierInformation}
                            onSubmit={(values) => {
                                console.log(values)
                            }}

                        >
                            <Form>
                                <div>
                                    <div className="">
                                        <div className="col-12">
                                            <Card>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Entity Name"
                                                                    name="entityName"
                                                                    type="text"
                                                                    value={supplierInformation.entityName}
                                                                    placeholder="Name"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Point of Contact"
                                                                    name="pointOfContact"
                                                                    type="text"
                                                                    value={supplierInformation.pointOfContact}
                                                                    placeholder="Contact"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Phone Number"
                                                                    name="phoneNumber"
                                                                    type="text"
                                                                    value={supplierInformation.phoneNumber}
                                                                    placeholder="###-###-####"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Email"
                                                                    name="email"
                                                                    type="text"
                                                                    value={supplierInformation.email}
                                                                    placeholder="Email"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <MyTextInput
                                                                    label="Description of Services"
                                                                    name="description"
                                                                    type="text"
                                                                    multiline
                                                                    value={supplierInformation.description}
                                                                    placeholder="Description"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Postal Address"
                                                                    name="postalAddress"
                                                                    type="text"
                                                                    value={supplierInformation.postalAddress}
                                                                    placeholder="Postal Address"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Physical Address"
                                                                    name="physicalAddress"
                                                                    type="text"
                                                                    value={supplierInformation.physicalAddress}
                                                                    placeholder="Physical Address"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Postal City"
                                                                    name="postalCity"
                                                                    type="text"
                                                                    value={supplierInformation.postalCity}
                                                                    placeholder="Postal City"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Physical City"
                                                                    name="physicalCity"
                                                                    type="text"
                                                                    value={supplierInformation.physicalCity}
                                                                    placeholder="Physical City"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Postal Zip Code"
                                                                    name="postalZipCode"
                                                                    type="text"
                                                                    value={supplierInformation.postalZipcode}
                                                                    placeholder="#####"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Physical Zip Code"
                                                                    name="physicalZipCode"
                                                                    type="text"
                                                                    value={supplierInformation.physicalZipcode}
                                                                    placeholder="#####"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MyTextInput
                                                                    label="Image Path"
                                                                    name="imagePath"
                                                                    type="text"
                                                                    value={supplierInformation.logoPath}
                                                                    placeholder="Image Path"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                {supplierInformation.isOfficial ?
                                                                    <MyTextInput
                                                                        label="Is Official"
                                                                        name="isOfficial"
                                                                        type="text"
                                                                        value="Yes"
                                                                        placeholder="Official"
                                                                        disabled
                                                                    /> :
                                                                    <MyTextInput
                                                                        label="Is Official"
                                                                        name="isOfficial"
                                                                        type="text"
                                                                        value="No"
                                                                        placeholder="Official"
                                                                        disabled
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                {supplierInformation.active ?
                                                                    <MyTextInput
                                                                        label="Is Active"
                                                                        name="active"
                                                                        type="text"
                                                                        value="Yes"
                                                                        placeholder="Active"
                                                                        disabled
                                                                    /> :
                                                                    <MyTextInput
                                                                        label="Is Active"
                                                                        name="active"
                                                                        type="text"
                                                                        value="No"
                                                                        placeholder="Active"
                                                                        disabled
                                                                    />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Image src={supplierInformation.logoPath} fluid rounded/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </CardBody>
                    <AdminSnackbar
                        message={'Supplier information updated successfully'}
                        setShowSnackbar={setSnackbarSupplier}
                        showSnackbar={snackbarSupplier}
                        variant={'success'}
                    />
                </Card>
            }
        </>
    )
}