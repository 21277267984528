// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";


export function ComplianceColumnFormatter(cellContent, row) {
    let label = 'primary';
    console.log("Balance: ", row.companyInfo[0].balance)
    console.log("Credit: ", row.creditInformation.authorizedCredit)
    if(row.companyInfo[0].balance < row.creditInformation.authorizedCredit && row.companyInfo[0].balance >= 0){
        row.compliance = 'In Compliance'
    } else {
        row.compliance = 'Noncompliant'
    }
    

    if(row.compliance === "In Compliance"){
        label = 'success'
    }else if(row.compliance === "Noncompliant"){
        label = 'danger'
    }else if(row.compliance === "Limited Compliance"){
        label = 'warning'
    }

    // const getLabelCssClasses = () => {
    //     return `label label-lg label-light-${label} label-inline`;
    // };
    return (
        <>
        {/* <span
            className={`label label-dot label-${label} mr-2`}
        ></span>
            <span className={`font-bold font-${label}`}>
        {row.compliance}
      </span> */}
            <span className={`label label-lg label-light-${label} label-inline`} >
            {row.compliance}
          </span>
        </>
    );
}
