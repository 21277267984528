import {Modal} from "react-bootstrap";
import {CheckCircleOutline, HighlightOff} from "@material-ui/icons";
import React from "react";

export function CreditModal({modalStatus,manageModal, creditStatus,actualApplication}){


    const getValidationMessage = () => {
        if(creditStatus){
            return (
                <>
                    <div className="col-6 row justify-content-center">
                        <CheckCircleOutline color="primary" fontSize="large" style={{width: "70px", height:"70px"}}/>
                    </div>
                    <div className="row justify-content-center px-5">
                        <h2 className="text-center">{actualApplication.companyName} credit has been validated by TransUnion.</h2>
                        <p className="font-size-lg text-center">The validation was carried out on 07/15/2020</p>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <div className="col-6 row justify-content-center">
                        <HighlightOff color="error" fontSize="large" style={{width: "70px", height:"70px"}}/>
                    </div>
                    <div className="row justify-content-center px-5">
                        <h2 className="text-center">{actualApplication.companyName}'s credit could not be validated by TransUnion.</h2>
                        <p className="font-size-lg text-center">The SSN of the owner of the corporation did not match any records.</p>
                    </div>
                </>
            )
        }
    }

    const handleClose = () => {
        manageModal({
            permitsModal: false,
            licensesModal: false,
            creditModal: false,
            otherModal: false
        });
    };

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                show={modalStatus.creditModal}
                backdrop="static"
                centered={true}
                size="lg"
            >
                <Modal.Body className="overlay overlay-block cursor-default row justify-content-center">
                    {getValidationMessage()}
                    <div className="col-12 row justify-content-center">
                        <button className="btn btn-primary" onClick={handleClose}>
                            Ok
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}