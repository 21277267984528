import React from "react";

export function ApprovedDetermination({application,creditInformation}){
    console.log("Application",application)
    return (
        <div className="row justify-content-center px-5">
            <h2>Application has been approved!</h2>
            <p className="font-size-lg">The credit application for {application.companyName} has been approved with a maximum credit of ${creditInformation.authorizedCredit} due {creditInformation.creditRange}. {application.companyName} has been notified and is now eligible to order products.</p>
        </div>
    )
}