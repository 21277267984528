import React, { useCallback, useEffect, useState } from "react";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { isEqual, isFunction } from "lodash";
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from "../../../_metronic/_helpers";
import { Pagination } from "../../../_metronic/_partials/controls";
import { PaginationTableFilter } from "./PaginationTableFilter";

export function PaginationTable({ data, columns, pagination, isLoading, sorting, initialFilter, tableClasses, fetcher, filterFunction, 
                                    searchField = true, searchFieldName="" , dateRangeField = false, dateRangeName = "", searchFieldTwo = false, searchFieldTwoName = "",
                                    searchFieldThree = false, searchFieldThreeName = "", initialSearchText = "" } ) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, [])

    const paginationOptions = {
        custom: pagination.custom,
        totalSize: data.totalCount,
        sizePerPageList: pagination.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber
    }

    useEffect(() => {
        let mounted = true

        if (mounted) {
            fetcher(queryParams)
        }

        return function cleanup() {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    const applyFilter = (values) => {
        const newQueryParams = filterFunction(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1
            //update list by queryParams
            setQueryParamsBase(newQueryParams);
        }
    };

    // applyFilter({searchText : ""})

    return (
        <>
            {searchField ? <PaginationTableFilter applyFilter={applyFilter} initialSearchText={initialSearchText} searchFieldName={searchFieldName} dateRangeField={dateRangeField} dateRangeName={dateRangeName}
                searchFieldTwo={searchFieldTwo} searchFieldTwoName={searchFieldTwoName} searchFieldThree={searchFieldThree} searchFieldThreeName={searchFieldThreeName} /> : ""}
            <div className="row">
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination
                                isLoading={isLoading}
                                paginationProps={paginationProps}>

                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    bordered={false}
                                    classes={tableClasses}
                                    bootstrap4
                                    remote
                                    keyField="_id"
                                    data={data.data == null ? [] : data.data}
                                    columns={columns}
                                    defaultSorted={sorting}
                                    onTableChange={getHandlerTableChange(setQueryParams)}
                                    {...paginationTableProps}>
                                    <PleaseWaitMessage entities={data.data} />
                                    <NoRecordsFoundMessage entities={data.data} />
                                </BootstrapTable>
                            </Pagination>
                        )
                    }}
                </PaginationProvider>
            </div>
        </>
    )
}