import React from 'react';
 
function FileUploader({ accessToken, files, setFiles, requiredFiles }) {
    
    // const fileInput = createRef();
    // const file2Input = createRef();

    const processFiles = (e, fileName) => {
      // var request = new XMLHttpRequest();
      // setProgress("0%");
      console.log(e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/))
      console.log(e.target.files[0].name)
      if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/)) {
        e.target.value = null
      } else {
        console.log(e.target.files[0])
        const file = e.target.files[0]
        let isFileNew = true

        if(files.length === 0) {
          setFiles(files => files.concat({ name: fileName, file }))
        } else {
          isFileNew = typeof(files.find(currFile => currFile.name === fileName)) === 'undefined'
          if(isFileNew){
            console.log("NUEVO")
            setFiles(files => files.concat({ name: fileName, file }))
          } else {
            console.log("VIEJO")
            const newFiles = files.filter(currFile => currFile.name !== fileName)
            setFiles(newFiles.concat({ name: fileName, file }))
          }
        }
      }
    }
    
    const findFile = (currentFile) => {
      const foundFile = files.find(thisFile => thisFile.name === currentFile)
      if(typeof(foundFile) === "undefined") {
        return null
      } else {
        return foundFile.file.name
      }
    }

    return (
        <div className="card-body py-0">
        <div className="table-responsive">
        <form>
            {/* begin::Table */}
                <table
                  className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                  id="kt_advance_table_widget_1"
                >
                  <thead>
                    <tr className="text-left">
                      <th className="pr-0" style={{ minWidth: "150px" }}>
                        Requiered Documents
                      </th>
                      <th style={{ minWidth: "150px" }}>Upload Document</th>
                      {/* <th className="pr-0 text-right" style={{ minWidth: "150px" }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                <tbody>
                  {requiredFiles.map(currentFile => 
                    <tr key={currentFile}>
                      <td className="pr-0 py-2">
                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {currentFile}
                        </span>
                        {/* <span className="text-muted font-weight-bold">
                            {company.deliveryAddress}
                        </span> */}
                      </td>
                      <td className="pr-0">
                        <input type="file" id={currentFile}
                        style={{ display: 'none' }} onChange={(e) => processFiles(e, currentFile)}/>
                        <button className="btn btn-success font-weight-bolder font-size-sm"
                            type="button" onClick={() => { document.getElementById(currentFile).click();}}
                        >
                            Browse
                        </button>
                        <span className="text-muted font-weight-bold px-4">
                          {findFile(currentFile)}
                      </span>
                    </td>
                  </tr>
                  )}
              </tbody>
            </table>
      </form>
      </div>
      </div>
    )
 
}
 
export default FileUploader;