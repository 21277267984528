import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../../../../_metronic/layout";
import {ApplicationsTable} from "./ApplicationsTable";
import {ApplicationsProcessing} from "./ApplicationProcessing";

export default function ApplicationsPage() {


    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <ContentRoute path="/applications/:id" component={ApplicationsProcessing}/>
                <ContentRoute path="/applications/" component={ApplicationsTable}/>

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
