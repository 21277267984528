import axios from "axios";

export function fetchSuppliers(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/buyers/' + id + '/suppliers', options);
}

export function fetchApprovedSuppliersCategories(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/buyers/' + id + '/suppliers/categories', options);
}

export function fetchCategories(id, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/categories/suppliers/' + id, options)
}

export function fetchProducts(id, authToken, category, pageNumber, sizePerPage, searchText) {

    const options = {
        headers: { 'x-auth-token': authToken },
        validateStatus: status => status < 500,
        params: {
            page: pageNumber,
            sizePerPage: sizePerPage,
            name: searchText
        }
    }


    return axios.get(process.env.REACT_APP_API_URL + '/products/suppliers/' + id + '/categories/' + category, options)
}

export function postProducts(id, authToken, locations, product, supplierId, entityName, companyId, businessName, isFuelFree, shipVia) {

    const options = {
        headers: { 'x-auth-token': authToken },

    }

    const body = {
        product: product,
        locations: locations,
        supplierId: supplierId,
        companyId: companyId,
        supplierName: entityName,
        companyName: businessName,
        isFuelFree: isFuelFree,
        shipVia: shipVia
    }

    return axios.post(process.env.REACT_APP_API_URL + '/orders/addProducts', body, options)
}

export function postProductsToOrder(id, authToken, locations, product, supplierId, entityName, companyId, businessName, isFuelFree, shipVia, orderId) {

    const options = {
        headers: { 'x-auth-token': authToken },

    }

    const body = {
        product: product,
        locations: locations,
        supplierId: supplierId,
        companyId: companyId,
        supplierName: entityName,
        companyName: businessName,
        isFuelFree: isFuelFree,
        shipVia: shipVia,
        orderId: orderId
    }

    return axios.post(process.env.REACT_APP_API_URL + '/orders/addProductsToOrder', body, options)
}

export function fetchCompanies(id, authToken, supplierId) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/company/supplier/' + supplierId, options)
}

export function fetchCompaniesByEmployee(id, authToken, supplierId) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/company/employee/supplier/' + supplierId, options)
}


export function fetchorders(id, authToken, category) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: { userId: id }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/orders/confirmation/getOrders', options)

}

export function fetchOrdersInvoiceByUser(id, queryParams, accessToken) {
    const options = {
        headers: { 'x-auth-token': accessToken },
        validateStatus: status => status < 400,
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + `/orders/user/invoice/${id}`, options)

}

export function fetchOrdersInvoiceByTenant(queryParams, accessToken) {
    const options = {
        headers: { 'x-auth-token': accessToken },
        validateStatus: status => status < 400,
        params: queryParams
    }

    return axios.get(process.env.REACT_APP_API_URL + `/orders/tenant/invoice`, options)

}

export function submitOrders(id, ordersToSubmit, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/submitOrder', ordersToSubmit, options)
}

export function saveOrders(ordersToSubmit, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/saveOrder', ordersToSubmit, options)
}

export function patchOrderSummary(ordersToSubmit, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.patch(process.env.REACT_APP_API_URL + '/orders/updateOrder', ordersToSubmit, options)
}

export function getCompanyLocations(id, authToken, companyId) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    const companyIdList = [companyId];

    const companyIdListJson = { companyIdList }

    return axios.get(process.env.REACT_APP_API_URL + "/locations/company", { params: companyIdListJson }, options)
}

export function getOrdersTenantPagination(authToken, queryParams) {
    const options = {
        headers: { 'x-auth-token': authToken },
        params: queryParams,
    }

    return axios.get(process.env.REACT_APP_API_URL + '/orders/tenant/pagination', options)
}

export function getOrderInfo(authToken, orderId) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/orders/' + orderId, options)
}

export function fetchTenantUsers(authToken) {
    const options = {
        headers: { 'x-auth-token': authToken }
    }

    return axios.get(process.env.REACT_APP_API_URL + '/users/tenant', options)
}

export function cancelOrder(orderUpdate, authToken) {
    const options = {
        headers: { 'x-auth-token': authToken },
        data: orderUpdate
    }

    return axios.delete(process.env.REACT_APP_API_URL + '/orders/cancel', options)
}