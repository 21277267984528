import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import React, { useState, useEffect } from "react";
import {postProductFromSupplier} from "../ordersCrud";
import {useDispatch, useSelector} from "react-redux";
import {cartCountActions} from "../../../../../redux/reducers/cartCount";
import {
    Collapse
  } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export function ProductBox({product,supplier,company,location,setShowSnackBar, 
                        getSupplierProductsNameBrand,  setProducts, setPaginationOptions, 
                        searchBrand, searchCategory, paginationOptions,
                        orderId, setIsOrderUpdated, isOrderUpdated }){

    const [showProductModal, setShowProductModal] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [locationToOrder,setLocationToOrder] = useState({})
    const [open, setOpen] = React.useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const dispatch = useDispatch();

    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {
        
        let locationObject = {
            _id: location._id,
            locationName: location.locationName,
            locationAddress: location.locationAddress2 ? location.locationAddress1 + " " + location.locationAddress2 : location.locationAddress1,
            locationCity: location.locationCity,
            amount: 0
        }
            
        setLocationToOrder(locationObject)
    }, [ ])

    function handleExpandClick2() {
        setExpanded2(!expanded2);
    }

    function handleClose() {
        setOpen(false);
        
    }

    const handleAmountChange = (amount) => {
        
        let newLocationToOrder = {...locationToOrder}
        console.log("Amount: ", amount)
        if(amount >= 0) {
            newLocationToOrder = { ...locationToOrder, amount: amount }
        } 

        setLocationToOrder(newLocationToOrder)
    }

    const handleAmountInputChange = (amount) => {

        let newAmountParsed = parseInt(amount)

        if(isNaN(newAmountParsed)){
            newAmountParsed = 0
        }

        let newLocationToOrder = {...locationToOrder}
        console.log("Amount: ", newAmountParsed)
        if(newAmountParsed >= 0) {
            newLocationToOrder = { ...locationToOrder, amount: newAmountParsed }
        } 

        setLocationToOrder(newLocationToOrder)
    }

    const addToOrder = () => {

        const isFuelFree = company.isFuelFree ? company.isFuelFree : false 
        const shipVia = company.shipVia ? company.shipVia : ""
        
        postProductFromSupplier(accessToken,locationToOrder,product, isFuelFree, orderId ).then(response =>{
            console.log("Response: ", response)
            if(response.status === 200){
                dispatch(cartCountActions.getProductCount(accessToken, user.id))
                setShowSnackBar(true)

                
                let locationAmountZero = {
                    ...location,
                    amount : 0
                }

                setExpanded2(!expanded2)

                setLocationToOrder(locationAmountZero)
                setIsOrderUpdated(!isOrderUpdated)
            }
        })

        getSupplierProductsNameBrand(supplier._id,accessToken,1,paginationOptions.sizePerPage,'', searchBrand, searchCategory, company._id).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:1,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    return(
        <>
        <div className="col-md-4 cursor-pointer">
            <Card className={`d-flex align-items-center mb-9 rounded p-5`} >
                <CardBody>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h3>{product.description ? product.description : product.name}</h3>
                            {
                                user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                        {`Item Id: ${product.sku}`}
                                    </span>
                                :
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                        {`Sku: ${product.sku}`}
                                    </span>
                            }
                            
                        </div>
                        { product.qtyOnHand || product.qtyOnHand == 0?
                            <div className="col-12 text-center">
                                <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                    {`QoH: ${product.qtyOnHand}`}
                                </span>
                            </div>
                            :
                            null
                        }
                        
                        
                        <div className="col-12 text-center">
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Unit Size: ${product.unitSize} ${product.unitMeasurement}`}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Units: ${product.units}`}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            {product.listPrice?
                                <p className="font-size-lg font-weight-bolder"><span className="label label-lg label-light-primary label-inline">${product.listPrice}</span></p>
                                :
                                <p className="font-size-lg font-weight-bolder"><span>${product.price}</span></p>
                            }
                        </div>
                    </div>
                    {expanded2 ? null :
                        <div className="col-12 text-center">
                            <button type="button" className="btn btn-primary" onClick={() => handleExpandClick2()}>Add to Order</button>
                        </div>

                    }
                    
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                        <div className="row col-12">
                            <div className="col-12">
                                <div>
                                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                        <thead>
                                        <tr className="text-lect">
                                            <th className="pr-0" >
                                                Shipping To
                                            </th>
                                            <th style={{ minWidth: "150px" }}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={locationToOrder._id}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                                        
                                                        <div className="d-flex flex-column flex-grow-1">
                                                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg pl-2">
                                                                {locationToOrder.locationName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col-12 text-right">
                                                        <div className="mb-1">
                                                            <input
                                                                value={locationToOrder.amount}
                                                                onChange={(e) => handleAmountInputChange(e.target.value)}
                                                                className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                type="number"
                                                                min="0"
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    
                        <div className="row col-12 justify-content-center">
                            <button type="button" className="btn btn-primary" onClick={() => addToOrder()}>Add to Order</button>
                        </div>
                    </Collapse>
                </CardBody>
            </Card>
        </div>

    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {dialogMessage}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
            OK
        </Button>
        </DialogActions>
    </Dialog>
</>
        
    )
}