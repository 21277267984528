import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";


function OrderFavoritesInputField(props){
    const [inputData, setInputData] = useState(props.data);

    const onInputBlur = (e) => {
        props.setData(e.target.value);
    }

    const onInputChange = (e) => {
        setInputData(e.target.value);
    }

    useEffect(() => {
       setInputData(props.data)
    }, [props.data]);

    return (
        <>
            <input
                value={inputData}
                onChange={onInputChange}
                onBlur={onInputBlur}
                className={`form-control form-control-solid h-auto py-2 px-6`}
                placeholder={props.placeholder}
                type="text"
            />
        </>
    )
}

export default injectIntl(OrderFavoritesInputField);