import {useHistory} from "react-router-dom"
import React, {useEffect} from "react";
import {CheckCircleOutline} from "@material-ui/icons";
import {ApprovedDetermination} from "./DeterminationModalMessages/ApprovedDetermination";
import App from "../../../../App";
import {Modal} from "react-bootstrap";
import {AdditionalInfoDetermination} from "./DeterminationModalMessages/AdditionalInfoDeterminaiton";
import {DeniedDetermination} from "./DeterminationModalMessages/DeniedDetermination";

export function ApplicationDeterminationModal({showDeterminationModal,setShowDeterminationModal,creditInformation,commonApp,application}){

    const history = useHistory();

    let determinationMessage = "";

    if(creditInformation.determination === "approved"){
        determinationMessage = <ApprovedDetermination application={application} creditInformation={creditInformation}/>
    }else if(creditInformation.determination === 'additionalInfo'){
        determinationMessage = <AdditionalInfoDetermination application={application} creditInformation={creditInformation}/>
    }else{
        determinationMessage = <DeniedDetermination application={application} creditInformation={creditInformation}/>

    }

    const handleClose = () => {
        setShowDeterminationModal(false);
        history.push('/applications')
    };

    return(
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                show={showDeterminationModal}
                backdrop="static"
                centered={true}
                size="lg"
            >
                <Modal.Body className="overlay overlay-block cursor-default row justify-content-center">
                    <div className="col-6 row justify-content-center">
                        <CheckCircleOutline color="primary" fontSize="large" style={{width: "70px", height:"70px"}}/>
                    </div>
                    <div className="col-8 row justify-content-center my-5">
                        {determinationMessage}
                    </div>
                    <div className="col-12 row justify-content-center">
                        <button className="btn btn-primary" onClick={handleClose}>
                            Ok
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}