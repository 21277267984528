import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import {fetchSupplierClientsPagination, fetchPriceListClientsPagination, updatePriceListClients} from "../AdminPagesCrud";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {CardBody, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import * as columnFormatters from "./column-formatters";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    textField: {
        width: 100,
    },
    summary: {
        width: '40%',
        position: 'center'
    }
}));

export default function PriceListClientConfig({ priceListId, currentPriceListClients, setEdit }) {
    const classes = useStyles();
    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }

    const [data, setData] = useState(initialData)
    const [plClientsData, setPlClientsData] = useState(initialData)
    const [priceListClients, setPriceListClients] = useState(currentPriceListClients)
    const [clientsToAdd, setClientsToAdd] = useState([])
    const [clientsToRemove, setClientsToRemove] = useState([])
    const {id} = useParams();

    const {user, accessToken} = useSelector(
            ({auth}) => ({
                user: auth.user != null ? auth.user : null,
                accessToken: auth.authToken,
            })
        )

    // const dispatch = useDispatch();

    useEffect(() => {
        

        // fetchSupplierClients(initialFilter)
    }, [])

    const fetchSupplierClients = async (queryParams) => {
        await fetchSupplierClientsPagination(id, queryParams, accessToken).then(response => {
            
            setData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }

    const fetchPlClients = async (queryParams) => {
        await fetchPriceListClientsPagination(priceListId, queryParams, accessToken).then(response => {
            
            setPlClientsData({
                data: response.data.data,
                totalCount: response.data.totalCount,
                pageSize: response.data.pageSize
            })
        }).catch(error => console.log(error))
    }
    
    const handlePriceListClientRemove = (clientId) => {
        const currClient = priceListClients.find(client => client._id == clientId)
        const newPriceListClients = priceListClients.filter(item => item._id != clientId)

        if(currClient.priceLists){
            const isPriceListClient = currClient.priceLists.find(currPriceList => currPriceList == priceListId)

            if(isPriceListClient){
                setClientsToRemove(oldClientsToRemove => [...oldClientsToRemove, clientId])
            }
        }
        
        const newClientsToAdd = clientsToAdd.filter(item => item != clientId)
        setClientsToAdd(newClientsToAdd)

        setPriceListClients(newPriceListClients)
    }

    const handleSubmit = async () => {
        
        console.log("Clients to Add: ", clientsToAdd)
        console.log("Clients to Remove: ", clientsToRemove)

        // const clientsSubmit = priceListClients.map(item => {
        //     return item._id
        // })

        const clientsToAddRemove = {
            clientsToAdd: clientsToAdd,
            clientsToRemove: clientsToRemove
        }

        await updatePriceListClients(priceListId, clientsToAddRemove, accessToken).then(response => {
            setEdit(false)

        }).catch(error => console.log(error))
    }

    const columns = [
        {
            dataField: "businessName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ClientAddFormatter,
            formatExtraData:{
                clientsToAdd: clientsToAdd
            },
        },
        {
            dataField: "businessEmail",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ActionColumnFormatter,
            formatExtraData:{
                clientsToAdd: clientsToAdd
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    const item = priceListClients.find(prod => prod._id == row._id)
                    if(!item){
                        setPriceListClients(oldPriceListClients => [...oldPriceListClients, {...row}])
                        setClientsToAdd(oldClientsToAdd => [...oldClientsToAdd, row._id])
                        if(clientsToRemove.length > 0){
                            const newClientsToRemove = clientsToRemove.filter(client => client != row._id)
                            setClientsToRemove(newClientsToRemove)
                        }
                    }
                }
            },
        },
    ];

    const sizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let initialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const sorting = [{dataField: "name", order: "asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    const plClientsColumns = [
        {
            dataField: "businessName",
            text: "Company",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ClientFormatter,
            formatExtraData:{
                clientsToRemove: clientsToRemove
            },
        },
        {
            dataField: "businessEmail",
            text: "Email",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.RemoveColumnFormatter,
            formatExtraData:{
                clientsToRemove: clientsToRemove
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handlePriceListClientRemove(row._id)
                }
            },
        },
    ];

    const plSizePerPageList = [
        {text: "3", value: 3},
        {text: "5", value: 5},
        {text: "10", value: 10}
    ]

    let plInitialFilter = {
        filter: {
            searchText: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10,
    }

    const plSorting = [{dataField: "name", order: "asc"}]
    const plPagination = {custom: true, sizePerPageList: plSizePerPageList}
    const plTableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const plPrepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.searchText = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.paper}>
                <CardHeader title="New Price List">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <PaginationTable
                        data={data}
                        columns={columns}
                        pagination={pagination}
                        isLoading={false}
                        sorting={sorting}
                        initialFilter={initialFilter}
                        tableClasses={tableClasses}
                        fetcher={fetchSupplierClients}
                        filterFunction={prepareFilter}
                        searchField={true}/>
                   
                </CardBody>
            </Card>
            {true ?
                <Card>
                    <CardHeader title={`Price List Summary: `}/>
                    <CardBody>
                        <PaginationTable
                            data={plClientsData}
                            columns={plClientsColumns}
                            pagination={plPagination}
                            isLoading={false}
                            sorting={plSorting}
                            initialFilter={plInitialFilter}
                            tableClasses={plTableClasses}
                            fetcher={fetchPlClients}
                            filterFunction={plPrepareFilter}
                            searchField={true}/>
                    </CardBody>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
                        Submit
                    </button>
                </Card>
                
                : null
            }
        </div>
    );
}