
import React from "react";

export function CategoriesFormatter(cellContent,row){

    return(
        <>
            <ul style={{ 'listStyleType':'none', padding:0, margin:0}} >
                {row.supplierCategories.map((category, index) => 
                <li key={`${row._id}_${index}`}>
                    <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {`${category.categoryName} (${category.productCount})`}
                    </span>
                </li>
            )}
            </ul>
        </>
    )
}