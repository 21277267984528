import axios from 'axios';
import {actions, actionTypes} from '../app/modules/Auth/_redux/authRedux';
import store from './store';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

api.interceptors.request.use(
    config => {
        const {
            auth: { authToken }
        } = store.getState();
        if (authToken) {
            // console.log("Intercepting");
            // config.headers.Authorization = `Bearer ${authToken}`;
            config.headers["x-auth-token"] = `${authToken}`;
        }
        // console.log(config.headers);
        return config;
    },
    err => Promise.reject(err)
)

api.interceptors.response.use(
    config => {

        
        return config
    },
    err => {
        return new Promise((resolve, reject) => {
            const originalReq = err.config;
            console.log("Let's Go! 3");
            if (err.response && err.response.status === 401 && err.config && !err.config._retry) {
                originalReq._retry = true;
                let res = fetch(process.env.REACT_APP_API_URL + '/auth/refresh_token', {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Device': 'device'
                    },
                    redirect: 'follow',
                    referrer: 'no-referrer',
                }).then(res => res.json()).then(res => {
                    if (!res.accessToken) {
                        store.dispatch(actions.logout())
                        return false;
                    } else {
                        store.dispatch(actions.getToken(res.accessToken));
                        originalReq.headers['x-auth-token'] = res.accessToken;
                        return axios(originalReq);
                    }
                });
                resolve(res)
            }
            return Promise.reject(err);
        })
    }
)
