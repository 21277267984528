
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pagination from "react-js-pagination"
import {Formik} from "formik";
import { getSupplierProductsNameBrand } from './SupplierSelectionCrud'
import {ProductBox} from "../../Suppliers/ProductBox";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader} from "../../../../../_metronic/_partials/controls";


export default function SupplierProductsModal  ( {open, scroll, handleClose, supplier, filterCategory} ) {
  
    const {accessToken} = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    const initialPaginationOptions = {
        totalSize: 0,
        sizePerPage: 10,
        page: 1
    }

    const [products,setProducts] = useState([]);
    const [paginationOptions,setPaginationOptions] = useState(initialPaginationOptions)
    const [searchName,setSearchName] = useState("")
    const [searchBrand, setSearchBrand] = useState("")
    const [searchCategory, setSearchCategory] = useState(filterCategory)
    const [filteredCategoryList, setFilteredCategoryList] = useState([filterCategory.name])

    const handlePageChange = (pageNumber) =>{
        getSupplierProductsNameBrand(supplier._id,accessToken,pageNumber,paginationOptions.sizePerPage,searchName, searchBrand, searchCategory).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:pageNumber,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const handleBrandClick = (brand) =>{
        if(brand === 'All'){
            setFilteredCategoryList([])
        }
        setSearchCategory({})
        setSearchBrand(brand)
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, brand, {}).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    useEffect(() => {
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, searchBrand, filterCategory).then(response=>{
            console.log("!!!!!!!!", response)
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // TODO: Implement for category search
    const handleCategoryClick = (category, catLevel) =>{
        setSearchCategory(category)
        setSearchBrand("")
        console.log(`${category.name}: ${catLevel}`, category)

        setFilteredCategoryList([...filteredCategoryList.slice(0, catLevel), category.name])
        
        getSupplierProductsNameBrand(supplier._id,accessToken,paginationOptions.page,paginationOptions.sizePerPage,searchName, "", category).then(response=>{
            setProducts(response.data.products)
            setPaginationOptions({
                page:paginationOptions.page,
                totalSize: response.data.totalCount,
                sizePerPage: paginationOptions.sizePerPage
            })
        })
    }

    const getCategoryBrandList = (categories, listItems = [], catLvl) => {
        
        categories.forEach(category => {
            let catLevel = 0
            if(typeof(catLvl) !== 'undefined'){
                catLevel = catLvl
            }
            
            // console.log("Local category lvl: ", catLevel, category.name)
            if(category.children.length === 0){
                //Product Categoriess
                listItems.push(<li style={{ 'list-style-type':'none', paddingLeft: catLevel * 15, margin:0}}>
                    <div className="d-flex align-items-center mb-1">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        <a 
                        href="javascript:void(0);"
                        onClick={() => handleCategoryClick(category, catLevel)}
                        className={"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}>
                            {category.name}
                        </a>
                    </div>
                </li>)

                if(filteredCategoryList.length > 0 && filteredCategoryList.includes(category.name)){
                    supplier.supplierProductBrands.forEach(categoryBrand => {
                        if(categoryBrand.category === category._id) {
                            //Product Brands
                            listItems.push(<li style={{ 'list-style-type':'none', paddingLeft: catLevel * 30, margin:0}}>
                                <div className="d-flex align-items-center mb-1">
                            <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <a 
                                href="javascript:void(0);"
                                onClick={() => handleBrandClick(categoryBrand.brand)}
                                className={"text-dark text-hover-primary mb-1 font-size-lg pl-2"}>
                                    {categoryBrand.brand}
                                </a>
                                </div>
                            </li>)
                        }
                    })
                } 
                
            } else {
                //Top level categories
                if(category.parent === 'root'){
                    listItems.push(<li style={{ 'list-style-type':'none', padding:0, margin:0}}>
                        <a 
                        href="javascript:void(0);"
                        onClick={() => handleCategoryClick(category, catLevel)}
                        className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                            {category.name}
                        </a>
                    </li>)
                } else {
                    listItems.push(<li style={{ 'list-style-type':'none', paddingLeft: catLevel * 15, margin:0}}>
                    <div className="d-flex align-items-center mb-1">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        <a 
                        href="javascript:void(0);"
                        onClick={() => handleCategoryClick(category, catLevel)}
                        className={"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"}>
                            {category.name}
                        </a>
                    </div>
                </li>)
                }
                
                // console.log(filteredCategory, filteredCategory === "" || filteredCategory === category.name)
                if(filteredCategoryList.length > 0 && filteredCategoryList.includes(category.name)){
                    listItems = getCategoryBrandList(category.children, listItems, ++catLevel)
                } 
            }
        })
        return listItems
    }

    return (
        <div>
        
            <Dialog
            fullWidth="true"
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title">Supplier Products</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
            <div className="row">
            <div className="col-lg-3 col-xxl-3">
                {/* <ul>
                    {supplier.supplierProductBrands.length > 0 ?
                        supplier.supplierProductBrands.map(brand => 
                            <li style={{ 'list-style-type':'none', padding:0, margin:0}}>
                                <a 
                                onClick={() => handleBrandClick(brand.brand)}
                                className="text-dark text-hover-primary mb-1 font-size-lg">
                                    {brand.brand}
                                </a>
                            </li>
                            
                        )
                        :
                        null
                    }
                </ul> */}
                <ul>
                    {supplier.supplierCategories.length > 0 ?
                        <div>
                            <li style={{ 'list-style-type':'none', paddingLeft:0, margin:0}}>
                                <a 
                                href="javascript:void(0);"
                                onClick={() => handleBrandClick("All")}
                                className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                    {"All"}
                                </a>
                            </li>
                            {getCategoryBrandList(supplier.supplierCategories)}
                        </div>
                        // null
                        :
                        null
                    }
                </ul>
            </div>
            <div className="col-lg-9 col-xxl-9">
            <div className="row justify-content-between mb-4">
                <Card >
                    <CardHeader title={supplier.entityName}>

                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            <p>{supplier.description}</p>
                        </div>
                    </CardBody>
                </Card>
                    <Formik
                        initialValues={{searchText:""}}
                        onSubmit={(values) =>{
                            setSearchName(values.searchText)
                            getSupplierProductsNameBrand(supplier._id,accessToken,1,paginationOptions.sizePerPage,values.searchText, searchBrand, searchCategory).then(response=>{
                                setProducts(response.data.products)
                                setPaginationOptions({
                                    page:1,
                                    totalSize: response.data.totalCount,
                                    sizePerPage: paginationOptions.sizePerPage
                                })
                            })

                        }}>
                        {({values, handleSubmit, handleBlur, handleChange, setFieldValue})=>(
                            <form onSubmit={handleSubmit} className="form form-label-right w-100">
                                <div className="form-group">
                                    <div className="col-lg-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="searchText"
                                            placeholder="Search"
                                            onBlur={handleBlur}
                                            value={values.searchText}
                                            onChange={(e) =>{
                                                setFieldValue("searchText",e.target.value);
                                                handleSubmit();
                                            }}
                                        />
                                        <small className="form-text text-muted">
                                            <b>Search</b> by name
                                        </small>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>

                <div className="row">


                    {typeof(products) !== 'undefined' ? products.map((product,index) => (
                        <ProductBox product={product}/>
                    ))
                        : null
                    }
                </div>
                <div className="row justify-content-center">
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={paginationOptions.page}
                        itemsCountPerPage={paginationOptions.sizePerPage}
                        totalItemsCount={paginationOptions.totalSize}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(this)}
                    />
                </div>
                {/* <SimpleSnackbar
                    showSnackBar={showSnackBar}
                    setShowSnackBar={setShowSnackBar}
                /> */}
                </div>
                </div>
            </DialogContent>
            
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
            </DialogActions>
            </Dialog>
            
        </div>
    );
}