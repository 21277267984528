
import React from "react";

export function UnitSizeFormatter(cellContent, row){

    return (
        <span className="">
                
            {   
                
                `${row.unitSize} / ${row.unitMeasurement}`
            }
        </span>
    )
}