
import React from "react";

export function CompanyFormatter(cellContent,row){

    return(
        <>
            <a
            href="javascript:void(0);"
                className="text-dark-75 font-weight-bolder text-hover-primary d-block font-size-lg"
            >
                {row.businessName}
            </a>
            <span className="text-muted font-weight-bold">
                {row.deliveryAddress}
            </span>
        </>
    )
}