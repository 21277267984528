
import React from "react";

export function RouteFormatter(cellContent,row){

    return(
        <>
            <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                {row?.routes[0] ? row.routes[0].name : "Special Route"}
            </span>
            <span className="text-muted font-weight-bold d-block text-center">
                {row?.routes[0] ? row.routes[0].dayOfTheWeek : "N/A"}
            </span>
        </>
    )
}