import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { MaskedInput } from 'react-text-mask';
import * as Yup from 'yup';
import AddCompanyPage from '../Companies/AddCompanyPage';
import ApplicationList from './ApplicationsList';
import ApplicationSummary from './ApplicationSummary/ApplicationSummary';
import {
    getCommonApplicationByCompanyId,
    getCompaniesLocations,
    patchApplicationsLocations,
    postApplications,
    postCommonApplication,
    postCommonApplicationDocuments,
    putApplication
} from './CommonApplicationCrud';
import GetStepContent from './StepContent';
import { getSuppliersCategories } from './SupplierSelection/SupplierSelectionCrud';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { getApplicationsByCompanyIdList } from './../../../../redux/actions/applications';
import { getLocationsByCompanyIdList } from './../../../../redux/actions/locations';
import { ContactSupportOutlined } from '@material-ui/icons';
import * as columnFormatters from "../Companies/column-formatters";
import { headerSortingClasses, sortCaret } from "../../../../_metronic/_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { PaginationTable } from "../../../components/PaginationTable/PaginationTable";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    paper: {
        padding: theme.spacing(2, 2),
        // height: 140,
        // width: 300,
    },
    modalPaper: {
        position: 'absolute',
        // width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Business Information', 'Personal Information', 'Partners Information', 'Bank References', 'Commercial Reference', 'Additional Information', 'Supplier Selection'];
}

function PhoneMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

PhoneMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const personalInitialValues = {
    personalFullLegalName: "",
    personalPhone: "",
    personalSocialSecurity: "",
    personalPhysicalAddress: "",
    personalPhysicalCity: "",
    personalPhysicalZipcode: "",
    personalPostalAddress: "",
    personalPostalCity: "",
    personalPostalZipcode: "",
    personalDriversLicense: "",
    personalBirthDate: "",
    personalSpouseFullName: "",
}

const partnerInitialValues = {
    partnerFullLegalName: "",
    partnerTitle: "",
    partnerPhone: "",
    partnerSocialSecurity: "",
    partnerPhysicalAddress: "",
    partnerPhysicalCity: "",
    partnerPhysicalZipcode: "",
    partnerPostalAddress: "",
    partnerPostalCity: "",
    partnerPostalZipcode: "",
    partnerDriversLicense: "",
    partnerBirthDate: "",
    partnerSpouseFullName: "",
}

const bankReferenceInitialValues = {
    institutionName: "",
    bankAccountNumber: "",
    bankBranch: "",
    bankPhoneNumber: "",
    // bankPhysicalAddress: "",
    // bankPhysicalCity: "",
    // bankPhysicalZipcode: "",
    // bankPostalAddress: "",
    // bankPostalCity: "",
    // bankPostalZipcode: "",
}

const commercialReferenceInitialValues = {
    commercialCompanyName: "",
    commercialAccountNumber: "",
    commercialPhysicalAddress: "",
    commercialPhysicalCity: "",
    commercialPhysicalZipcode: "",
    commercialPostalAddress: "",
    commercialPostalCity: "",
    commercialPostalZipcode: "",
}

const additionalInformationInitialValues = {
    patentName: "",
    insuranceCompany: "",
    liquorLicenseNumber: "",
    entitledPerson: "",
    mortgageTerm: "",
    monthlyRent: "",
    contractTerm: "",
    dateOfPurchase: "",
    financedBy: "",
    bankruptcyExplanation: "",
}

const newCompanyInitialValues = {
    corporationName: "",
    businessName: "",
    deliveryAddress: "",
    deliveryCity: "",
    deliveryZipcode: "",
    businessPhoneNumber: "",
    businessFax: "",
    businessEmail: "",
    businessType: "",
    employerIdentificationNumber: "",
    numeroCatastro: "",
    registroComerciante: "",
    businessEstablishmentDate: "",
    placeOfBusinessDescription: "",
}

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 25;
    // const left = 50 + rand();

    return {
        // top: `${top}%`,
        // left: `${left}%`,
        // transform: `translate(-${top}%, -${left}%)`,
    };
}

function CommonApplicationPage(props) {
    const { intl } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isNewApplication, setIsNewApplication] = useState(false)

    // console.log("~~~~~~~~~~Status: ", props)

    const { userId, accessToken } = useSelector(
        ({ auth }) => ({
            userId: auth.user.id,
            accessToken: auth.authToken,
        })),
        { companies } = useSelector(state => state.companyReducer),
        { locations } = useSelector(state => state.locationReducer),
        { applicationList } = useSelector(state => state.applicationReducer);


    const dispatch = useDispatch();

    const [buyer, setBuyer] = useState({})
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [postSuppliers, setPostSuppliers] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [submitSuppliers, setSubmitSuppliers] = useState([])
    const [initialCommonApplication, setInitialCommonApplication] = useState({})
    const [commonApplication, setCommonApplication] = useState({})
    const [selectedCompany, setSelectedCompany] = useState({})
    const [selectedCompanyLocations, setSelectedCompanyLocations] = useState([])
    const [isCompanySelected, setIsCompanySelected] = useState(false)
    const [existingClientList, setExistingClientList] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [bankReferenceList, setBankReferenceList] = useState([])
    const [commercialReferenceList, setCommercialReferenceList] = useState([])
    const [isAppSummary, setIsAppSummary] = useState(false)
    const [applicationLocations, setApplicationLocations] = useState([])
    const [locationsCategories, setLocationCategories] = useState([]);
    const [selectedCompanyUnsubmittedLocations, setSelectedCompanyUnsubmittedLocations] = useState([]);
    const [newLocationsCategories, setNewLocationCategories] = useState([]);

    const [isPartnerMarried, setIsPartnerMarried] = useState(false)
    const [isMarried, setIsMarried] = useState(false)
    const [isPropertyOwner, setIsPropertyOwner] = useState(false)
    const [isInLeaseContract, setIsInLeaseContract] = useState(false)
    const [isBeenInvolvedInBankruptcy, setIsBeenInvolvedInBankruptcy] = useState(false)
    const [isApplicationFound, setIsApplicationFound] = useState(false)

    const [isAddCompany, setIsAddCompany] = useState(false)
    const [isAddLocations, setIsAddLocations] = useState(false)
    // const [locations, setLocations] = useState([])
    const [addLocationsToComapnyId, setAddLocationsToCompanyId] = useState("")

    const [files, setFiles] = useState([])
    const [requiredFiles, setRequiredFiles] = useState(["Registro Comerciante (SC2918)", "Certificado de Revendedor (SC2963)", "Certificado de Compras Exentas (SC2916)",
        "Patente Municipal", "Licencia de Licores", "Estados Financieros", "Identificación con foto",
        "Certificación del Departamento del Estado si es Corporacion",
        "Seguro Social Patronal (EIN) y/o carta que indique no posee seguro social patronal (EIN)"])

    const [personalInitValues, setpersonalInitValues] = useState(personalInitialValues)
    const [partnerInitValues, setpartnerInitValues] = useState(partnerInitialValues)
    const [bankInitValues, setbankInitValues] = useState(bankReferenceInitialValues)
    const [commercialInitValues, setcommercialInitValues] = useState(commercialReferenceInitialValues)
    const [additionalInitValues, setAdditionalInitValues] = useState(additionalInformationInitialValues)

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const [skipped, setSkipped] = useState(new Set());

    const [open, setOpen] = useState(false);

    const [openBankDialogue, setOpenBankDialogue] = useState(false);
    const [openCommercialDialogue, setOpenCommercialDialogue] = useState(false);

    const initialCompanyData = {
        data: [],
        totalCount: 0,
        pageSize: 3
    }
    const [companyData, setCompanyData] = useState(initialCompanyData)

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBankDialogueOpen = () => {
        setOpenBankDialogue(true);
    };

    const handleBankDialogueClose = () => {
        setOpenBankDialogue(false);
    };

    const handleCommercialDialogueOpen = () => {
        setOpenCommercialDialogue(true);
    };

    const handleCommercialDialogueClose = () => {
        setOpenCommercialDialogue(false);
    };

    const handleSubmit = () => {
        props.handleSubmit()
        handleClose()
    }

    const steps = getSteps();

    async function fetchSupplierSelectionData() {
        await Promise.all([getSuppliersCategories(accessToken)]).then((values) => {
            const supplierResponse = values[0]
            console.log(supplierResponse.data)
            setSuppliers(supplierResponse.data)
        })
            .catch((error) => {
                console.log(error)
            });
    }

    async function fetchCommonApplicationData() {

        dispatch(getApplicationsByCompanyIdList())
        dispatch(getLocationsByCompanyIdList());

        if (typeof (props.pathId) !== 'undefined' && props.pathId !== '' && props.pathId !== 'callback') {
            console.log("PathId: ", props.pathId)
            const pathApplication = applicationList.find(application => application._id === props.pathId)
            if (typeof (pathApplication) !== 'undefined') {
                await getCommonAppFromApplication(pathApplication.companyId)
            } else {
                setIsAppSummary(true)
            }
        }
        
    }

    const fetchCompaniesLocations = async (queryParams) => {
        await getCompaniesLocations(userId, queryParams, accessToken).then(response => {
    
          const data = {
            data: response.data.data,
            totalCount: response.data.totalCount,
            pageSize: 5
          }
          setCompanyData(data)
        }).catch((error) => {
          console.log(error)
        });
      }

    useEffect(() => {
        fetchCommonApplicationData();
        fetchSupplierSelectionData();
    }, [companies])

    const handleSelectedSuppliersChange = (supplierParam) => {
        console.log("Selected Suppliers: ", selectedSuppliers)
        console.log("Param Supplier: ", supplierParam)
        // console.log(selectedSuppliers + " plus " + supplierName)
        const currentSupplier = suppliers.find((supplier) => supplier._id === supplierParam._id)

        if (selectedSuppliers.find(curr => curr._id === supplierParam._id)) {
            setSelectedSuppliers(selectedSuppliers => selectedSuppliers.filter((supplier) => supplierParam._id !== supplier._id))
            setPostSuppliers(postSuppliers => postSuppliers.filter((supplier) => currentSupplier._id !== supplier._id))
            console.log("Locations Categories: ", locationsCategories)
            if (typeof (locationsCategories.find(element => element.supplier === supplierParam.entityName) !== 'undefined')) {
                setLocationCategories(locationsCategories => locationsCategories.filter(element => element.supplier !== supplierParam.entityName))
            }
        } else {
            setSelectedSuppliers(selectedSuppliers => selectedSuppliers.concat(supplierParam))
            setPostSuppliers(postSuppliers => postSuppliers.concat(currentSupplier))
        }
    }

    function submitBusinessInformation() {
        try {
            getCommonApplicationByCompanyId(selectedCompany._id, accessToken).then((response) => {
                if (typeof (response.data[0]) !== 'undefined') {
                    // console.log(response.data[0])
                    // console.log(typeof(response.data[0].personalInformation !== 'undefined'))
                    // console.log(response.data[0].personalInformation)
                    // console.log(response.data[0].partnerInformatio)

                    if (typeof (response.data[0].personalInformation) !== 'undefined') {
                        setpersonalInitValues(response.data[0].personalInformation)
                    }
                    if (typeof (response.data[0].partnerInformation) !== 'undefined') {
                        setPartnerList(response.data[0].partnerInformation)
                    }
                    if (typeof (response.data[0].bankReferenceInformation) !== 'undefined') {
                        setBankReferenceList(response.data[0].bankReferenceInformation)
                    }
                    if (typeof (response.data[0].commercialReferenceInformation) !== 'undefined') {
                        setCommercialReferenceList(response.data[0].commercialReferenceInformation)
                    }
                    if (typeof (response.data[0].additionalInformation) !== 'undefined') {
                        setAdditionalInitValues(response.data[0].additionalInformation)
                        // console.log(response.data[0].additionalInformation.mortgageTerm !== "")
                        if (response.data[0].additionalInformation.mortgageTerm !== "") {
                            setIsPropertyOwner(true)
                        }
                        if (response.data[0].additionalInformation.contractTerm !== "") {
                            setIsInLeaseContract(true)
                        }
                        if (response.data[0].additionalInformation.bankruptcyExplanation !== "") {
                            setIsBeenInvolvedInBankruptcy(true)
                        }
                    }

                    setCommonApplication(response.data[0])
                    setSelectedCompanyLocations(locations.filter(location => location.companyId === selectedCompany._id))

                    handleComplete()
                    handleNext()
                } else {
                    let applications = []
                    console.log(selectedCompany)
                    const initialCommonApplication = {
                        companyId: selectedCompany._id,
                        businessInformation: {
                            corporationName: selectedCompany.corporationName,
                            businessName: selectedCompany.businessName,
                            deliveryAddress: selectedCompany.deliveryAddress,
                            deliveryCity: selectedCompany.deliveryCity,
                            deliveryZipcode: selectedCompany.deliveryZipcode,
                            businessPhoneNumber: selectedCompany.businessPhoneNumber,
                            businessFax: selectedCompany.businessFax,
                            businessEmail: selectedCompany.businessEmail,
                            businessType: selectedCompany.businessType,
                            taxIdNumber: selectedCompany.taxIdNumber,
                            businessEstablishmentDate: selectedCompany.businessEstablishmentDate,
                            placeOfBusinessDescription: selectedCompany.placeOfBusinessDescription,
                        },
                    }
                    try {
                        postCommonApplication(initialCommonApplication, accessToken).then((response) => {
                            const commonApp = response.data

                            applications.push(
                                {
                                    applicationNumber: commonApp.applicationNumber,
                                    companyId: commonApp.companyId,
                                    companyName: commonApp.businessInformation.businessName,
                                    companyAddress: commonApp.businessInformation.deliveryAddress + ", " + commonApp.businessInformation.deliveryCity,
                                    supplierName: "N/A",
                                    supplierEmail: "N/A",
                                    supplierId: "N/A",
                                    locationsCategories: "N/A",
                                    submissionDate: "N/A",
                                    contactName: "N/A",
                                    contactPhone: "N/A",
                                    status: 'draft',
                                }
                            )

                            const submitApplications = {
                                applications
                            }

                            postApplications(submitApplications, accessToken, false, false).then(response => {

                                const companyIdList = companies.map(item => item._id);

                                dispatch(getApplicationsByCompanyIdList({ companyIdList }));
                            }).catch((error) => {
                                console.log(error)
                            });

                            getCommonApplicationByCompanyId(selectedCompany._id, accessToken).then(commonAppResponse => {
                                setCommonApplication(commonAppResponse.data[0])
                            })
                            setSelectedCompanyLocations(locations.filter(location => location.companyId === selectedCompany._id))
                            handleComplete()
                            handleNext()
                        })
                    } catch (e) {
                        alert("An error there has been. Your common application was not saved");
                        // console.log(e.message);
                    }
                }
                setIsCompanySelected(true)
            })
        } catch (e) {
            alert("An error there has been. Your common application was not saved");
            // console.log(e.message);
        }
    }

    const submitApplicationToNewSupplierClick = (companyId) => {
        try {
            getCommonApplicationByCompanyId(companyId, accessToken).then((response) => {
                if (typeof (response.data[0]) !== 'undefined') {
                    const currCompanyLocations = locations.filter(location => location.companyId === companyId)

                    let submitedSuppliers = []

                    setCommonApplication(response.data[0])
                    const currCompany = companies.find(company => company._id === companyId)

                    applicationList.map(application => {
                        const submitedSupplier = suppliers.find(supplier => (supplier._id === application.supplierId && companyId === application.companyId))
                        if (typeof submitedSupplier !== 'undefined') {
                            submitedSuppliers.push(submitedSupplier.entityName)
                            let unsubmittedCompanyLocations = []
                            
                            console.log("APPLI: ", application)
                            currCompanyLocations.map(currLocation => {
                                const location = application.locationsCategories.find(location => currLocation._id === location.locationId)
                                if (typeof (location) === 'undefined') {
                                    unsubmittedCompanyLocations.push(currLocation)
                                }
                            })
                            if (unsubmittedCompanyLocations.length > 0) {
                                setSelectedCompanyUnsubmittedLocations(selectedCompanyUnsubmittedLocations => selectedCompanyUnsubmittedLocations.concat({
                                    submitedSupplier,
                                    unsubmittedCompanyLocations,
                                    applicationId: application._id
                                }))
                            }

                        }
                    })
                    console.log("locations: ", locations)
                    setSubmitSuppliers(submitedSuppliers)
                    setIsNewApplication(true)
                    setSelectedCompany(currCompany)
                    setSelectedCompanyLocations(locations.filter(location => location.companyId === companyId))
                    setIsCompanySelected(true)
                    setActiveStep(6);
                } else {

                }

            })
        } catch (e) {
            alert("An error there has been. Your common application was not saved");
            // console.log(e.message);
        }
    }

    const inProgressCommonApplicationClick = (companyId) => {
        try {
            getCommonApplicationByCompanyId(companyId, accessToken).then((response) => {
                if (typeof (response.data[0]) !== 'undefined') {

                    setCommonApplication(response.data[0])


                    if (typeof (response.data[0].personalInformation) !== 'undefined') {
                        setpersonalInitValues(response.data[0].personalInformation)
                    }
                    if (typeof (response.data[0].partnerInformation) !== 'undefined') {
                        setPartnerList(response.data[0].partnerInformation)
                    }
                    if (typeof (response.data[0].bankReferenceInformation) !== 'undefined') {
                        setBankReferenceList(response.data[0].bankReferenceInformation)
                    }
                    if (typeof (response.data[0].commercialReferenceInformation) !== 'undefined') {
                        setCommercialReferenceList(response.data[0].commercialReferenceInformation)
                    }
                    if (typeof (response.data[0].additionalInformation) !== 'undefined') {
                        setAdditionalInitValues(response.data[0].additionalInformation)
                        if (response.data[0].additionalInformation.mortgageTerm !== "") {
                            setIsPropertyOwner(true)
                        }
                        if (response.data[0].additionalInformation.contractTerm !== "") {
                            setIsInLeaseContract(true)
                        }
                        if (response.data[0].additionalInformation.bankruptcyExplanation !== "") {
                            setIsBeenInvolvedInBankruptcy(true)
                        }
                    }

                    setIsNewApplication(true)
                    setSelectedCompany(companies.find(company => company._id === companyId))
                    setSelectedCompanyLocations(locations.filter(location => location.companyId === companyId))
                    setIsCompanySelected(true)
                    handleComplete()
                    handleNext()

                } else {

                }

            })
        } catch (e) {
            alert("An error there has been. Your common application was not saved");
            console.log(e.message);
        }
    }

    const handleSubmitFiles = () => {
        const formData = new FormData();

        files.map((file, index) => {
            formData.append("files", file.file)
            formData.append("fileNames", file.name)
        })
        formData.append("title", "upload")

        postCommonApplicationDocuments(formData, commonApplication.companyId, accessToken)
            .then(res => console.log(res.data))
            .catch(err => console.error(err));
    }

    const handleSubmitCommonApplication = async () => {
        // console.log(applicationList.find(element => element.companyId === commonApplication.companyId).status === 'draft')
        if (applicationList.find(element => element.companyId === commonApplication.companyId).status === 'draft') {
            submitCommonApplicatonFirst()
        } else if (locationsCategories.length > 0) {
            submitCommonApplicatonNew()
        } else if (newLocationsCategories.length > 0) {
            await submitNewLocationsToApplications()
            handleSubmitFiles()
            fetchCommonApplicationData()
            handleReset()
            setSelectedCompany({})
            setSelectedCompanyLocations([])
            setIsCompanySelected(false)
            setIsNewApplication(false)
            setSelectedSuppliers([])
            setPostSuppliers([])
        }
    }

    const submitNewLocationsToApplications = async () => {
        // console.log(newLocationsCategories)
        let submitLocationsCategories = []
        newLocationsCategories.forEach(locationsCategory => {
            const currApplication = applicationList.find(application => application._id === locationsCategory.applicationId)
            submitLocationsCategories.push({
                ...locationsCategory,
                supplierEmail: currApplication.supplierEmail
            })
        })

        const finalLocationsCategories = {
            companyEmail: selectedCompany.businessEmail,
            locationsCategories: submitLocationsCategories
        }

        patchApplicationsLocations(finalLocationsCategories, accessToken).then(res => {

        }).catch((error) => {
            console.log(error)
        });
    }

    async function submitCommonApplicatonFirst() {
        let applications = []
        const firstSupplier = postSuppliers.slice(0, 1)[0]
        const restOfSuppliers = postSuppliers.slice(1)
        let firstSupplierApplication = {}

        const existingClientFirst = existingClientList.find(client => client.name === firstSupplier.entityName)
        const locationsCategoriesListFirst = locationsCategories.find(element => element.supplier === firstSupplier.entityName).locations

        if (typeof existingClientFirst !== 'undefined') {
            //add location categories here

            firstSupplierApplication =
            {
                applicationNumber: commonApplication.applicationNumber,
                companyId: commonApplication.companyId,
                companyName: commonApplication.businessInformation.businessName,
                companyAddress: commonApplication.businessInformation.deliveryAddress + ", " + commonApplication.businessInformation.deliveryCity,
                supplierName: firstSupplier.entityName,
                supplierEmail: firstSupplier.email,
                supplierId: firstSupplier._id,
                clientId: existingClientFirst.clientId,
                locationsCategories: locationsCategoriesListFirst,
                submissionDate: commonApplication.submissionDate,
                contactName: commonApplication.personalInformation.personalFullLegalName,
                contactPhone: commonApplication.personalInformation.personalPhone,
                status: 'submitted',
            }
        } else {
            //add location categories here

            firstSupplierApplication =
            {
                applicationNumber: commonApplication.applicationNumber,
                companyId: commonApplication.companyId,
                companyName: commonApplication.businessInformation.businessName,
                companyAddress: commonApplication.businessInformation.deliveryAddress + ", " + commonApplication.businessInformation.deliveryCity,
                supplierName: firstSupplier.entityName,
                supplierEmail: firstSupplier.email,
                supplierId: firstSupplier._id,
                locationsCategories: locationsCategoriesListFirst,
                submissionDate: commonApplication.submissionDate,
                contactName: commonApplication.personalInformation.personalFullLegalName,
                contactPhone: commonApplication.personalInformation.personalPhone,
                status: 'submitted',
            }
        }
        let statusChanged = firstSupplier.status !== firstSupplierApplication.status
        const draftApplicationId = applicationList.find(application => application.companyId === commonApplication.companyId)._id

        putApplication(firstSupplierApplication, commonApplication.companyId, accessToken, firstSupplier.email, selectedCompany.businessEmail, true, draftApplicationId).then(async response => {
            if (!restOfSuppliers.length > 0) {
                if (newLocationsCategories.length > 0) {
                    await submitNewLocationsToApplications()
                }
                handleSubmitFiles()
                fetchCommonApplicationData()
                handleReset()
                setSelectedCompany({})
                setSelectedCompanyLocations([])
                setIsCompanySelected(false)
                setIsNewApplication(false)
                setSelectedSuppliers([])
                setPostSuppliers([])
            }
        }).catch((error) => {
            console.log(error)
        });

        if (restOfSuppliers.length > 0) {
            restOfSuppliers.map(supplier => {
                const existingClient = existingClientList.find(client => client.name === supplier.entityName)
                const locationsCategoriesList = locationsCategories.find(element => element.supplier === supplier.entityName).locations
                if (typeof existingClient !== 'undefined') {
                    //add location categories here

                    applications.push(
                        {
                            applicationNumber: commonApplication.applicationNumber,
                            companyId: commonApplication.companyId,
                            companyName: commonApplication.businessInformation.businessName,
                            companyAddress: commonApplication.businessInformation.deliveryAddress + ", " + commonApplication.businessInformation.deliveryCity,
                            supplierName: supplier.entityName,
                            supplierEmail: supplier.email,
                            supplierId: supplier._id,
                            clientId: existingClient.clientId,
                            locationsCategories: locationsCategoriesList,
                            submissionDate: commonApplication.submissionDate,
                            contactName: commonApplication.personalInformation.personalFullLegalName,
                            contactPhone: commonApplication.personalInformation.personalPhone,
                            status: 'submitted',
                        }
                    )
                } else {
                    //add location categories here

                    applications.push(
                        {
                            applicationNumber: commonApplication.applicationNumber,
                            companyId: commonApplication.companyId,
                            companyName: commonApplication.businessInformation.businessName,
                            companyAddress: commonApplication.businessInformation.deliveryAddress + ", " + commonApplication.businessInformation.deliveryCity,
                            supplierName: supplier.entityName,
                            supplierEmail: supplier.email,
                            supplierId: supplier._id,
                            locationsCategories: locationsCategoriesList,
                            submissionDate: commonApplication.submissionDate,
                            contactName: commonApplication.personalInformation.personalFullLegalName,
                            contactPhone: commonApplication.personalInformation.personalPhone,
                            status: 'submitted',
                        }
                    )
                }
            })
            const submitApplications = {
                applications
            }

            if (newLocationsCategories.length > 0) {
                await submitNewLocationsToApplications()
            }

            postApplications(submitApplications, accessToken, true, false).then(response => {
                // console.log(response)
                handleSubmitFiles()
                fetchCommonApplicationData()
                handleReset()
                setSelectedCompany({})
                setSelectedCompanyLocations([])
                setIsCompanySelected(false)
                setIsNewApplication(false)
                setSelectedSuppliers([])
                setPostSuppliers([])

            }).catch((error) => {
                console.log(error)
            });
        }
    }

    async function submitCommonApplicatonNew() {
        let applications = []

        postSuppliers.map(supplier => {
            const existingClient = existingClientList.find(client => client.name === supplier.entityName)
            const locationsCategoriesList = locationsCategories.find(element => element.supplier === supplier.entityName).locations
            if (typeof existingClient !== 'undefined') {
                //add location categories here

                applications.push(
                    {
                        applicationNumber: commonApplication.applicationNumber,
                        companyId: commonApplication.companyId,
                        companyName: commonApplication.businessInformation.businessName,
                        companyAddress: commonApplication.businessInformation.deliveryAddress + ", " + commonApplication.businessInformation.deliveryCity,
                        supplierName: supplier.entityName,
                        supplierEmail: supplier.email,
                        supplierId: supplier._id,
                        clientId: existingClient.clientId,
                        locationsCategories: locationsCategoriesList,
                        submissionDate: commonApplication.submissionDate,
                        contactName: commonApplication.personalInformation.personalFullLegalName,
                        contactPhone: commonApplication.personalInformation.personalPhone,
                        status: 'submitted',
                    }
                )
            } else {
                //add location categories here
                applications.push(
                    {
                        applicationNumber: commonApplication.applicationNumber,
                        companyId: commonApplication.companyId,
                        companyName: commonApplication.businessInformation.businessName,
                        companyAddress: commonApplication.businessInformation.deliveryAddress + ", " + commonApplication.businessInformation.deliveryCity,
                        supplierName: supplier.entityName,
                        supplierEmail: supplier.email,
                        supplierId: supplier._id,
                        locationsCategories: locationsCategoriesList,
                        submissionDate: commonApplication.submissionDate,
                        contactName: commonApplication.personalInformation.personalFullLegalName,
                        contactPhone: commonApplication.personalInformation.personalPhone,
                        status: 'submitted',
                    }
                )
            }
        })

        const submitApplications = {
            applications
        }

        if (newLocationsCategories.length > 0) {
            await submitNewLocationsToApplications()
        }

        postApplications(submitApplications, accessToken, true, true).then(response => {
            // console.log(response)
            handleSubmitFiles()
            fetchCommonApplicationData()
            handleReset()
            setSelectedCompany({})
            setSelectedCompanyLocations([])
            setIsCompanySelected(false)
            setIsNewApplication(false)
            setSelectedSuppliers([])
            setPostSuppliers([])

        }).catch((error) => {
            console.log(error)
        });
    }

    function totalSteps() {
        return getSteps().length;
    }

    function isStepOptional(step) {
        return false;
    }

    function handleSkip() {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    }

    function skippedSteps() {
        return skipped.size;
    }

    function completedSteps() {
        return completed.size;
    }

    function allStepsCompleted() {
        return completedSteps() === totalSteps() - skippedSteps();
    }

    function isLastStep() {
        return activeStep === totalSteps() - 1;
    }

    function handleNext() {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed
                // find the first step that has been completed
                steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1;

        setActiveStep(newActiveStep);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleBackStep1() {
        setIsCompanySelected(false)
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    const handleStep = step => () => {
        setActiveStep(step);
    };

    function handleComplete() {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);

        /**
         * Sigh... it would be much nicer to replace the following if conditional with
         * `if (!this.allStepsComplete())` however state is not set when we do this,
         * thus we have to resort to not being very DRY.
         */
        if (completed.size !== totalSteps() - skippedSteps()) {
            handleNext();
        }
    }

    function handleReset() {
        setActiveStep(0);
        setCompleted(new Set());
        setSkipped(new Set());
    }

    function isStepSkipped(step) {
        return skipped.has(step);
    }

    function isStepComplete(step) {
        return completed.has(step);
    }

    const BusinessInformationSchema = Yup.object().shape({
        corporationName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        deliveryZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        numeroCatastro: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessPhoneNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessFax: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
        ,
        businessEmail: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessType: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        employerIdentificationNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        registroComerciante: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        businessEstablishmentDate: Yup.date()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        placeOfBusinessDescription: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const PersonalInformationSchema = Yup.object().shape({
        personalFullLegalName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPhone: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalSocialSecurity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPhysicalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPhysicalCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPhysicalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPostalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPostalCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalPostalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalDriversLicense: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalBirthDate: Yup.date()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        personalSpouseFullName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
        ,
    });

    const PartnerInformationSchema = Yup.object().shape({
        partnerFullLegalName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerTitle: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerPhone: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerSocialSecurity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerDriversLicense: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerBirthDate: Yup.date()
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerSpouseFullName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
        ,
        partnerPhysicalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerPhysicalCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerPhysicalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerPostalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerPostalCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        partnerPostalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const BankReferencesSchema = Yup.object().shape({
        institutionName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        bankAccountNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        bankBranch: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        bankPhoneNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const CommercialReferencesSchema = Yup.object().shape({
        commercialCompanyName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialAccountNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialPhysicalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialPhysicalCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialPhysicalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialPostalAddress: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialPostalCity: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        commercialPostalZipcode: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const AdditionalInformationSchema = Yup.object().shape({
        patentName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
        ,
        insuranceCompany: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        liquorLicenseNumber: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        entitledPerson: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        mortgageTerm: Yup.string()
            .min(1, "Minimum 1 symbols")
            .max(50, "Maximum 50 symbols"),
        monthlyRent: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        contractTerm: Yup.string()
            .min(1, "Minimum 1 symbols")
            .max(50, "Maximum 50 symbols"),
        dateOfPurchase: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        financedBy: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
        bankruptcyExplanation: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols"),
    });

    const columns = [
        // {
        //     dataField: "orderNumber",
        //     text: "Order Number",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        //     events: {
        //         onClick: (e, column, columnIndex, row, rowIndex) => {
        //             console.log("Pepe")
        //         }
        //     },
        //     // formatter: columnFormatters.OrderNumberFormatter,
        // },
        // {
        //     dataField: "companyName",
        //     text: "Company",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
          dataField: "businessName",
          text: "Company",
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
          formatter: columnFormatters.CompanyFormatter,
        },
        {
          dataField: "deliveryCity",
          text: "City",
          sort: true,
          sortCaret: sortCaret,
          headerSortingClasses,
          formatter: columnFormatters.CityFormatter,
        },
        {
          dataField: "createdDate",
          text: "Date Created",
          // sort: true,
          // sortCaret: sortCaret,
          // headerSortingClasses,
          formatter: columnFormatters.DateFormatter,
        },
        {
          text: "Locations",
          // sort: true,
          // sortCaret: sortCaret,
          // headerSortingClasses,
          formatter: columnFormatters.LocationsCountFormatter,
        },
        {
          // dataField: "status",
          text: "Actions",
          // sort: true,
          // sortCaret: sortCaret,
          // headerSortingClasses,
          formatter: columnFormatters.ApplicationsPageActionsColumnFormatter,
          formatExtraData:{
            selectedCompany: selectedCompany,
        },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                // console.log("Company row: ", row)
                setSelectedCompany(row)
            }
          },
        },
      ];

    const sizePerPageList = [
        { text: "3", value: 3 },
        { text: "5", value: 5 },
        { text: "10", value: 10 }
    ]

    const initialFilter = {
        filter: {
            businessName: "",
        },
        sortOrder: "asc",
        sortField: "businessName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{ dataField: "businessName", order: "asc" }]
    const pagination = { custom: true, sizePerPageList: sizePerPageList }
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center "

    const prepareFilter = (queryParams, values) => {
        const { searchText } = values;
        const newQueryParams = { ...queryParams };
        const filter = {};

        filter.businessName = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    const validationSchemas = {
        BusinessInformationSchema,
        PersonalInformationSchema,
        PartnerInformationSchema,
        BankReferencesSchema,
        CommercialReferencesSchema,
        AdditionalInformationSchema
    }

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    async function getCommonAppFromApplication(companyId) {
        await getCommonApplicationByCompanyId(companyId, accessToken).then(response => {
            // console.log(response.data[0])
            setCommonApplication(response.data[0])
            setIsApplicationFound(true)
            setIsAppSummary(true)
        }).catch((error) => {
            console.log(error)
        });
    }

    const newApplicationClick = () => {
        setIsNewApplication(true)
    }

    const handleAddCompanyClicked = () => {
        setIsAddCompany(isAddCompany => !isAddCompany)
    }

    return (<div>
        {(!isNewApplication) ?
            (!isAppSummary ?
                <ApplicationList className="card-stretch gutter-b"
                    handleCreateNewApplication={newApplicationClick}
                    applicationList={applicationList}
                    getCommonAppFromApplication={getCommonAppFromApplication}
                    submitApplicationToNewSupplierClick={submitApplicationToNewSupplierClick}
                    inProgressCommonApplicationClick={inProgressCommonApplicationClick}
                    status={props.status} />
                :
                isApplicationFound ?
                    <ApplicationSummary commonApp={commonApplication}
                        companyApplications={applicationList.filter(application => application.companyId === commonApplication.companyId)} />
                    :
                    <div className="card card-custom gutter-b">
                        <div className="card-body p-0">
                            <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                <div className="col-md-10">
                                    <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                        <div className="text-center mb-4">
                                            <span
                                                className="card-label font-weight-bolder text-dark text-center font-size-lg">Application Not Found</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )
            :
            <div>
                <div className={classes.root}>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepOptional(index)) {
                                buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepButton
                                        // onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                    >
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>

                        {allStepsCompleted() ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&apos;re finished
                                    </Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                                <Paper className={classes.paper}>
                                    {!isCompanySelected ?
                                        <div>
                                            <div>
                                                <div className="text-center mb-10 mb-lg-20">
                                                    <h3 className="font-size-h1">
                                                        Business Information
                                                        {/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
                                                    </h3>
                                                    {/* <p className="text-muted font-weight-bold">
                                                    Enter your details to be sent to suppliers.
                                                    </p> */}
                                                </div>
                                                <div className="card card-custom card-stretch gutter-b">
                                                    {isAddCompany ?
                                                        <AddCompanyPage userId={userId} accessToken={accessToken}
                                                            handleAddCompanyClose={handleAddCompanyClicked}
                                                            isAddLocations={isAddLocations}
                                                            setIsAddLocations={setIsAddLocations}
                                                            addLocationsToComapnyId={addLocationsToComapnyId} />
                                                        :
                                                        <div>
                                                            <div className="card-header border-0 py-5">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span
                                                                        className="card-label font-weight-bolder text-dark">Choose Company</span>
                                                                    {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">20+ new applications</span> */}
                                                                </h3>
                                                                <div className="card-toolbar">
                                                                    <div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={handleAddCompanyClicked}
                                                                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                                                        >
                                                                            <span>{"Add Company"}</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* end::Header */}
                                                            {/* begin::Body */}
                                                            <div>
                                                                <Card>
                                                                    <CardBody>
                                                                        <PaginationTable
                                                                        data={companyData}
                                                                        columns={columns}
                                                                        pagination={pagination}
                                                                        isLoading={false}
                                                                        sorting={sorting}
                                                                        tableClasses={tableClasses}
                                                                        fetcher={fetchCompaniesLocations}
                                                                        filterFunction={prepareFilter}
                                                                        initialFilter={initialFilter} />
                                                                    </CardBody>
                                                                </Card>
                                                            </div>
                                                            {/* end::Body */}
                                                            <div className="form-group d-flex flex-wrap flex-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => submitBusinessInformation()}
                                                                    // disabled={formik.isSubmitting}
                                                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                                                >
                                                                    <span>Save and Continue</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <GetStepContent
                                            activeStep={activeStep} initialCommonApplication={initialCommonApplication}
                                            setCommonApplication={setCommonApplication}
                                            commonApplication={commonApplication} validationSchemas={validationSchemas}
                                            handleNext={handleNext}
                                            handleBack={handleBack} handleComplete={handleComplete}
                                            completedSteps={completedSteps} totalSteps={totalSteps}
                                            handleSuppliersChange={handleSelectedSuppliersChange}
                                            selectedSuppliers={selectedSuppliers}
                                            submitCommonApplicaton={handleSubmitCommonApplication}
                                            postSuppliers={postSuppliers}
                                            handleSelectedSuppliersChange={handleSelectedSuppliersChange}
                                            suppliers={suppliers} buyer={buyer} open={open} handleOpen={handleOpen}
                                            handleClose={handleClose} modalStyle={modalStyle} classes={classes}
                                            companies={companies} selectedCompany={selectedCompany}
                                            setSelectedCompany={setSelectedCompany}
                                            submitBusinessInformation={submitBusinessInformation}
                                            personalInitValues={personalInitValues}
                                            partnerInitValues={partnerInitValues}
                                            bankInitValues={bankInitValues} commercialInitValues={commercialInitValues}
                                            additionalInitValues={additionalInitValues}
                                            userId={userId} accessToken={accessToken}
                                            newCompanyInitialValues={newCompanyInitialValues}
                                            isCompanySelected={isCompanySelected} handleBackStep1={handleBackStep1}
                                            setExistingClientList={setExistingClientList}
                                            submitSuppliers={submitSuppliers} existingClientList={existingClientList}
                                            partnerList={partnerList} setPartnerList={setPartnerList}
                                            selectedCompanyLocations={selectedCompanyLocations}
                                            locationsCategories={locationsCategories}
                                            setLocationCategories={setLocationCategories}
                                            isPropertyOwner={isPropertyOwner} setIsPropertyOwner={setIsPropertyOwner}
                                            isInLeaseContract={isInLeaseContract}
                                            setIsInLeaseContract={setIsInLeaseContract}
                                            isBeenInvolvedInBankruptcy={isBeenInvolvedInBankruptcy}
                                            setIsBeenInvolvedInBankruptcy={setIsBeenInvolvedInBankruptcy}
                                            bankReferenceList={bankReferenceList}
                                            setBankReferenceList={setBankReferenceList}
                                            commercialReferenceList={commercialReferenceList}
                                            setCommercialReferenceList={setCommercialReferenceList}
                                            files={files} setFiles={setFiles} requiredFiles={requiredFiles}
                                            selectedCompanyUnsubmittedLocations={selectedCompanyUnsubmittedLocations}
                                            newLocationsCategories={newLocationsCategories}
                                            setNewLocationCategories={setNewLocationCategories}
                                            handleBankDialogueOpen={handleBankDialogueOpen}
                                            handleCommercialDialogueOpen={handleCommercialDialogueOpen}
                                            isMarried={isMarried} setIsMarried={setIsMarried}
                                            isPartnerMarried={isPartnerMarried}
                                            setIsPartnerMarried={setIsPartnerMarried}
                                        />
                                    }
                                    <div>
                                    </div>
                                </Paper>
                            )}
                    </div>
                </div>
            </div>
        }
        <Dialog
            open={openBankDialogue}
            onClose={handleBankDialogueClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Bank Reference Missing"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You must add at least one bank reference.
                    </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBankDialogueClose} color="primary" autoFocus>
                    OK
                    </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openCommercialDialogue}
            onClose={handleCommercialDialogueClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Commercial Reference Missing"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You must add at least one commercial reference.
                    </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCommercialDialogueClose} color="primary" autoFocus>
                    OK
                    </Button>
            </DialogActions>
        </Dialog>
    </div>

    );

}

export default injectIntl(CommonApplicationPage);
