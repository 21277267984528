// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { Link } from "react-router-dom";

export function CompanyColumnFormatter(cellContent, row) {


    return (
        <>
            <Link to={'/applications/' + row._id}>
                <span className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {row.companyName}
                </span>
                <p><span className="text-muted mt-3 font-weight-bold font-size-sm">{row.email}</span></p>
            </Link>

        </>
    );
}