import React, {useState} from "react";
import { injectIntl } from "react-intl";
import {useSelector} from "react-redux";
import {headerSortingClasses, sortCaret} from "../../../_metronic/_helpers";
import axios from "axios";
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../_metronic/_partials/controls";
import {PaginationTable} from "../../components/PaginationTable/PaginationTable";
import {NewBuyer} from "./NewBuyer";
import {BuyerColumnFormatter} from "./ColumnsFormatter/BuyerColumnFormatter";

function BuyerAdminPage(props) {

    const type = 'buyer'

    const {accessToken} = useSelector (
        ({auth}) =>({
            accessToken: auth.authToken,
        })
    )

    const initialData = {
        data: [],
        totalCount: 0,
        pageSize: 10
    }

    const [data,setData] = useState(initialData)
    const [newBuyer, setBuyer] = useState(false)


    const fetchBuyers = (queryParams) => {
        // Authorization head should be fulfilled in interceptor.
        console.log("fetching users for table")
        queryParams.type = type
        queryParams.table = "buyers"

        const options = {
            headers: { 'x-auth-token': accessToken},
            validateStatus: status => status < 500,
            params: queryParams,
            type: type
        }

        axios.get(process.env.REACT_APP_API_URL + '/users/', options).then(response =>{

            let result = response.data

            result.data.forEach(buyer => {
                buyer.fullPostalAddress = buyer.postalAddress + ", " + buyer.postalCity + ", " + buyer.postalZipcode
                buyer.fullPhysicalAddress = buyer.physicalAddress + ", " + buyer.physicalCity + ", " + buyer.physicalZipcode
            })

            setData(result)
            console.log("Users: ", result);
        });
    }

    const columns = [
        {
            dataField: "entityName",
            text: "Company Name",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "fullName",
            text: "Owner",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "fullPostalAddress",
            text: "Postal Address",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "fullPhysicalAddress",
            text: "Postal Address",
            sort: true,
            sortCare: sortCaret,
            headerSortingClasses
        },
        {
            dataField: "delete",
            text: "Delete",
            formatter: BuyerColumnFormatter,
            // formatExtraData: {
            //     openEditCustomerDialog: clientsProps.openEditCustomerDialog,
            //     openDeleteCustomerDialog: clientsProps.openDeleteCustomerDialog,
            // },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        }
    ];

    const sizePerPageList = [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 }
    ]

    const initialFilter = {
        filter: {
            fullName:"",
            entityName: "",
            postalAddress:"",
            postalCity: "",
            postalZipcode: "",
            physicalAddress: "",
            physicalCity: "",
            physicalZipcode: ""
        },
        sortOrder: "asc",
        sortField: "entityName",
        pageNumber: 1,
        pageSize: 10
    }

    const sorting = [{dataField: "entityName",order:"asc"}]
    const pagination = {custom: true, sizePerPageList: sizePerPageList}
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center"

    const prepareFilter = (queryParams,values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.entityName = searchText ?? "";
        filter.fullName = searchText ?? "";
        filter.postalAddress = searchText ?? "";
        filter.postalCity = searchText ?? "";
        filter.postalZipcode = searchText ?? "";
        filter.physicalAddress = searchText ?? "";
        filter.physicalZipcode = searchText ?? "";
        filter.physicalCity = searchText ?? "";

        newQueryParams.filter = filter;
        return newQueryParams;
    }

    return (
        <>
            {newBuyer ?
                <NewBuyer/> :
                <Card>
                    <CardHeader title="Registered Buyers">
                        <CardHeaderToolbar>
                            <button type="button" className="btn btn-primary" onClick={() => setBuyer(true)}>
                                New Buying Company
                            </button>
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <PaginationTable
                            data={data}
                            columns={columns}
                            pagination={pagination}
                            isLoading={false}
                            sorting={sorting}
                            tableClasses={tableClasses}
                            fetcher={fetchBuyers}
                            filterFunction={prepareFilter}
                            initialFilter={initialFilter}/>
                    </CardBody>
                </Card>
            }
        </>
    );
}

export default injectIntl(BuyerAdminPage);