import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../../_metronic/_partials/controls";
import React, { Fragment } from "react";
import {MyTextInput} from "../../CustomFormInputs";
import {Formik,Form} from "formik";
import Grid from '@material-ui/core/Grid';

export default function PersonalInformation({personalInformation}) {

    return (
        <div className="col-12">
            <Card>
                <CardHeader title="Personal Information">
                    <CardHeaderToolbar>
                        <button type="button" className="btn btn-light" onClick={console.log("filtrando")}>
                            Expand
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={personalInformation}
                        onSubmit={(values) =>{
                            console.log(values)
                        }}
                    >
                        <Form>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Full Legal Name"
                                        name="personalFullLegalName"
                                        type="text"
                                        value={personalInformation.personalFullLegalName}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Social Security Number"
                                        name="personalSocialSecurity"
                                        type="text"
                                        value={personalInformation.personalSocialSecurity}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Driver's License"
                                        name="personalDriverLicense"
                                        type="text"
                                        value={personalInformation.personalDriversLicense}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Birth Date"
                                        name="personalBirthDate"
                                        type="text"
                                        value={personalInformation.personalBirthDate}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Phone Number"
                                        name="personalPhone"
                                        type="text"
                                        value={personalInformation.personalPhone}
                                        disabled
                                    />
                                    {personalInformation.personalSpouseFullName !== '' ? 
                                        <Fragment>
                                            <MyTextInput
                                                label="Married?"
                                                name="partnerIsMarried"
                                                type="text"
                                                value={"Yes"}
                                                disabled
                                            />
                                            <MyTextInput
                                                label="Spouse Full Name"
                                                name="partnerSpouseFullName"
                                                type="text"
                                                value={personalInformation.personalSpouseFullName}
                                                disabled
                                            />
                                        </Fragment>
                                        :
                                        <MyTextInput
                                            label="Married?"
                                            name="partnerIsMarried"
                                            type="text"
                                            value={"No"}
                                            disabled
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Physical Address"
                                        name="personalPhysicalAddress                                        "
                                        type="text"
                                        value={personalInformation.personalPhysicalAddress}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Physical City"
                                        name="personalPhysicalCity"
                                        type="text"
                                        value={personalInformation.personalPhysicalCity}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Physical Zipcode"
                                        name="personalPhysicalAddress"
                                        type="text"
                                        value={personalInformation.personalPhysicalZipcode}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Postal Address"
                                        name="personalPostalAddress                                        "
                                        type="text"
                                        value={personalInformation.personalPostalAddress}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Postal City"
                                        name="personalPostalCity"
                                        type="text"
                                        value={personalInformation.personalPostalCity}
                                        disabled
                                    />
                                    <MyTextInput
                                        label="Postal Zipcode"
                                        name="personalPostalZipcode"
                                        type="text"
                                        value={personalInformation.personalPostalZipcode}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    )
}