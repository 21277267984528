import React, {createContext, useContext,useState,useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ClientHelpers";

const ClientContext = createContext();

export function useClientContext() {
    return useContext(ClientContext);
}

export function ClientsProvider({clientsEvents, children}){
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if(isFunction(nextQueryParams)){
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if(isEqual(prevQueryParams,nextQueryParams)){
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    },[]);

    const initClient = {
        id: undefined,
        name: "",
        locations: "",
        shippingAddress: "",
        creditDueDate: "",
        totalCredit: 0,
        balance: "",
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initClient,
        newClientButtonClick: clientsEvents.newCustomerButtonClick,
        openEditCustomerDialog: clientsEvents.openEditCustomerDialog,
        openDeleteCustomerDialog: clientsEvents.openDeleteCustomerDialog,
        openDeleteClientsDialog: clientsEvents.openDeleteClientsDialog,
        openFetchClientsDialog: clientsEvents.openFetchClientsDialog,
        openUpdateClientsStatusDialog: clientsEvents.openUpdateClientsStatusDialog
    }

    return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
}