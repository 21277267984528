import React from "react";

function SupplierOnBoarding(props) {

    return (
        <div>
            { (
                <div style={{maxWidth:"600px"}}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Thank you for your registration on Allec Marketplace!
                        </h3>
                        <p className="text-muted font-weight-bold">
                            We are very excited to see the products your company offers. Our sales team will contact you shortly for pricing details and to complete the on-boarding process.
                        </p>
                    </div>

                </div>
            )}
        </div>
    );
}

export default SupplierOnBoarding;